import React, { useState } from 'react'
import { FormBox, InputBox } from '../../../../../components/AntdAddons'
import { Button, Col, Form, Row } from 'antd';
import { rules } from '../../Components/Form/rules';
import { validateFields } from '../../../../../config/Global';
import { useTranslation } from 'react-i18next';
import { Messages } from '../../../../../config/Messages';
import ErrorModel from '../ErrorModel';

const CitizenRegister: React.FC<any> = ({ getDetailsMobile, saving, showAddCitizenBtn, showFormBtn }) => {

	const { t } = useTranslation();
	const [form] = Form.useForm();
	const [disabled, setDisabled] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<boolean>(false);

	const validateForm = () => {
		validateFields(form, setDisabled);
		setDisabled(false)
	};
	const onSubmit = (data: any) => {
		if (data.first_name !== undefined || data.last_name !== undefined || data.phone !== undefined) {
			setErrorMessage(false)

			if ((data.first_name !== '' && data.first_name !== undefined) ||
				(data.last_name !== '' && data.last_name !== undefined) ||
				(data.phone !== '' && data.phone !== undefined)) {
				getDetailsMobile(data)
				setErrorMessage(false)
			} else {
				setErrorMessage(true)
			}
		} else {
			setErrorMessage(true)
		}
	}

	return (
		<FormBox
			form={form}
			id={"meetingForm"}
			onFinish={onSubmit}
			onValuesChange={validateForm}
		// className='meetingForm'
		>

			<Row gutter={15}>
				<Col sm={24} md={8} xxl={8} >
					<InputBox.Text
						label={t("GENERAL.MOBILE_NUMBER")}
						name="phone"
						addonBefore={"+91"}
						placeholder="xxxxxxxxxxx"
						rules={rules.whatsAppNumber()}
						maxLength={10}
					/>
				</Col>
				<Col sm={24} md={8} xxl={8}  >
					<InputBox.Text
						label={`${t("CITIZENMANAGEMENT.FIRST_NAME")}`}
						name="first_name"
						placeholder={`${t("CITIZENMANAGEMENT.FIRST_NAME")}`}
						rules={rules.name()}
					/>
				</Col>
				<Col sm={24} md={8} xxl={8}  >
					<InputBox.Text
						label={`${t("CITIZENMANAGEMENT.LAST_NAME")}`}
						name="last_name"
						placeholder={`${t("CITIZENMANAGEMENT.LAST_NAME")}`}
						rules={rules.name()}
					/>
				</Col>
			</Row>
			<div className='meetingBtn'>
				<Button
					key="1"
					disabled={disabled}
					form={"meetingForm"}
					loading={saving}
					htmlType="submit"
					type="primary"
				>
					{t("CITIZENMANAGEMENT.GET_DETAILS_BUTTON")}
				</Button>
				{showAddCitizenBtn &&
					<Button
						key="2"
						type="primary"
						onClick={() => showFormBtn()}
					>
						{`${t("GENERAL.ADD_DRAWER_TITLE")} ${t("CITIZENMANAGEMENT.CITIZEN")}`}
					</Button>
				}
			</div>
			<ErrorModel
				title={'Alert'}
				close={() => setErrorMessage(false)}
				open={errorMessage}
				msg={Messages.CITIGEN_REGISTER_MEETING_ERROR}
			/>
		</FormBox>
	)
}

export default CitizenRegister