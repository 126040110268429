import React from "react";
import { ReactComponent as SyncBtn } from "../assets/images/icon/refresh.svg";
import { ReactComponent as EditBtn } from "../assets/images/icon/edit.svg";
import { ReactComponent as DeleteBtn } from "../assets/images/icon/delete.svg";
import { ReactComponent as Close } from "../assets/images/icon/close.svg";
import { ReactComponent as View } from "../assets/images/icon/view.svg";
import { ReactComponent as Filter } from "../assets/images/icon/filter.svg";
import { ReactComponent as ChangePass } from "../assets/images/icon/change_password.svg";
import { ReactComponent as CitizenRegister } from "../assets/images/icon/citizenregister.svg";
import { ReactComponent as MlaGrant } from "../assets/images/icon/mlagrant.svg";
import { ReactComponent as MlaCalendar } from "../assets/images/icon/mlacalendar.svg";
import { ReactComponent as Village } from "../assets/images/icon/village.svg";
import { ReactComponent as Reports } from "../assets/images/icon/report.svg";
import { ReactComponent as RightArrow } from "../assets/images/icon/rightArrow.svg";
import { ReactComponent as Attachment } from "../assets/images/icon/Attachment.svg";
import { ReactComponent as Meeting } from "../assets/images/icon/Users-management.svg";
import { ReactComponent as Status } from "../assets/images/icon/status.svg";
interface IconProps {
  icon: string; //make the clear type to make switch
  color?: string;
  width?: number;
}

const SVGIcon: React.FC<IconProps> = ({ icon, color = "#696b71", width = 14 }: IconProps) => {
  // In this case you have to think about the switch and types in typescript.
  const Icons: any = {
    refresh: <SyncBtn fill={color} width={width} height={width} />,
    close: <Close fill={color} width={width} height={width} />,
    edit: <EditBtn fill={color} width={width} height={width} />,
    delete: <DeleteBtn fill={color} width={width} height={width} />,
    view: <View fill={color} width={width} height={width} />,
    filter: <Filter fill={color} width={width} height={width} />,
    changePass: <ChangePass fill={color} width={width} height={width} />,
    citizenRegister:<CitizenRegister fill={color} width={width} height={width} />,
    mlaGrant:<MlaGrant fill={color} width={width} height={width} />,
    mlaCalendar:<MlaCalendar fill={color} width={width} height={width} />,
    village:<Village fill={color} width={width} height={width} />,
    reports:<Reports fill={color} width={width} height={width} />,
    rightArrow:<RightArrow fill={color} width={width} height={width} />,
    attachment:<Attachment fill={color} width={width} height={width} />,
    meeting:<Meeting fill={color} width={width} height={width} />,
    status:<Status fill={color} width={width} height={width} />
  };
  return Icons[icon];
};

export default SVGIcon;
