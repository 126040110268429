const baseUrl = "/v1/admin/schemes"

export const API_URL = {
    LIST: `${baseUrl}`,
    CHANGE_STATUS: (id: number): string => `${baseUrl}/${id}/change-status`,
    CREATE: `${baseUrl}/create`,
    DETAILS: (id: number): string => `${baseUrl}/${id}/details`,
    DELETE: (id: number): string => `${baseUrl}/${id}/delete`,
    UPDATE: (id: number): string => `${baseUrl}/${id}/update`,
    SCHEME_LIST: (search?: string): string =>
    `/v1/admin/schemes/lov${search ? `?scheme_id=${search}` : ""}`,
}

