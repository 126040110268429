import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../store/app';
import { FormBox, InputBox } from '../../../../components/AntdAddons';
import { Button, Col, Row } from 'antd';
import { rules } from './rules';
import { govDeptLov, setGov_deptData } from '../../MLAGrantManagement/Components/utils/slice';
import { OfficeList } from '../../../../config/Global';
import { maxTelNumber } from '../../../../config/validations/validations';

const FormComponent: React.FC<any> = ({
    form,
    id,
    handleSubmit,
    onValuesChange,
    disabled,
    saving,
    drawerClose,
    editValues,
}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch()

    const { gov_deptList } = useAppSelector(
        state => state.mlaGrant
    );

    const onFinish = (data: any) => {
        handleSubmit(data)
    }

    useEffect(() => {
        if (editValues) {
            dispatch(govDeptLov()).catch((e: any) => e)
            if (editValues?.department) {
                dispatch(setGov_deptData([editValues?.department]))
            }
            form.setFieldsValue({
                ...editValues,
            });
        }
    }, [editValues, form]);
    return (
        <>
            <FormBox
                form={form}
                id={id}
                onFinish={onFinish}
                onValuesChange={onValuesChange}
            >
                <Row gutter={15}>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <InputBox.Text
                            label={t("GENERAL.FIRST_NAME")}
                            name="fname"
                            rules={rules.fName()}
                        />
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <InputBox.Text
                            label={t("GENERAL.MIDDLE_NAME")}
                            name="middle_name"
                            rules={rules.non_required()}
                        />
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <InputBox.Text
                            label={t("GENERAL.Last_NAME")}
                            name="lname"
                            rules={rules.fName()}
                        />
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <InputBox.Select
                            name="dept_id"
                            showSearch
                            optionFilterProp="children"
                            label={t("GOVERNMENTNONGOVERMENTCONTACTMANAGEMENT.DEPARTMENT")}
                            placeholder={t("GOVERNMENTNONGOVERMENTCONTACTMANAGEMENT.DEPARTMENT")}
                            onFocus={() => {
                                dispatch(govDeptLov())
                                    .catch((e: any) => e)
                            }}
                            options={{
                                list: gov_deptList,
                                valueKey: "id",
                                textKey: "dept",
                            }}
                            rules={rules.requiredFields()}
                        />
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <InputBox.Text
                            label={t("GOVERNMENTNONGOVERMENTCONTACTMANAGEMENT.DESIGNNATION")}
                            name="designation"
                            rules={rules.non_required()}
                        />
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <InputBox.Text
                            label={t("GENERAL.MOBILE_NUMBER")}
                            name="mobile_number"
                            addonBefore={"+91"}
                            placeholder="xxxxxxxxxx"
                            rules={rules.mobileNumber()}
                            maxLength={10}
                        />
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <InputBox.Text
                            label={t("GOVERNMENTNONGOVERMENTCONTACTMANAGEMENT.TELEPHONENUMBER")}
                            name="tel_number"
                            placeholder="xxxxxxxxxx"
                            rules={rules.telNumber()}
                            maxLength={maxTelNumber}
                        />
                    </Col>
                    <Col sm={24} md={12} xxl={12}>
                        <InputBox.Text
                            name="email"
                            label={`${t("GENERAL.EMAIL_ID")}`}
                            placeholder={`${t("GENERAL.EMAIL_ID_PLACEHOLDER")}`}
                            rules={rules.email()}
                        />
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <InputBox.Select
                            name="office_id"
                            showSearch
                            optionFilterProp="children"
                            label={t("GOVERNMENTNONGOVERMENTCONTACTMANAGEMENT.OFFICE")}
                            placeholder={t("GOVERNMENTNONGOVERMENTCONTACTMANAGEMENT.OFFICE")}
                            options={{
                                list: OfficeList,
                                valueKey: "id",
                                textKey: "name",
                            }}
                            rules={rules.requiredFields()}
                        />
                    </Col>
                    <Col sm={24} md={24} xxl={24}>
                        <InputBox.Text
                            name="address"
                            label={`${t("GENERAL.ADDRESS")}`}
                            placeholder={`${t("GENERAL.ADDRESS")}`}
                            rules={rules.address()}
                        />
                    </Col>
                </Row>
                <div className='citizenFormBtn'>
                    <Button
                        key="1"
                        disabled={disabled}
                        form={id}
                        loading={saving}
                        htmlType="submit"
                        type="primary"
                    >
                        {t("GENERAL.SAVE_BUTTON")}
                    </Button>
                    <Button
                        key="2"
                        htmlType="button"
                        className="cancelBtn"
                        onClick={drawerClose}
                    >
                        {t("GENERAL.CANCEL_BUTTON")}
                    </Button>
                </div>
            </FormBox>
        </>
    )
}

export default FormComponent