import { Button, Drawer, DrawerProps, Form } from 'antd';
import React, { useState } from 'react'
import { createRecord } from '../../utils/slice';
import FormComponent from './FormComponent';
import { useAppDispatch } from '../../../../../../store/app';
import { validateFields } from '../../../../../../config/Global';
import { assignErrorToInput } from '../../../../../../store/api';
import SVGIcon from '../../../../../../utils/SVGIcon';
import Config from '../../../../../../config/Config';
import { CONSTANT } from '../../../../../../config/Constant';
import { setVillageList } from '../../../VillageVsWard/utils/slice';
import dayjs from "dayjs"
import { useTranslation } from 'react-i18next';



interface AddDrawerProps extends DrawerProps {
    titleName: string;
    saveAndNext: any;
    setVillageId: any;
    setVillageDetailsId: any;
    close: () => void;
}
const AddComponent: React.FC<AddDrawerProps> = ({
    titleName,
    close,
    saveAndNext,
    setVillageId,
    setVillageDetailsId,
    ...rest
}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();
    const [disabled, setDisabled] = useState<boolean>(true);
    const [saving, setSaving] = useState<boolean>(false);
    const [submitForm, setSubmitForm] = useState<any>()
    const [fetchVillage, setFetchVillage] = useState<boolean>(true);
    const drawerClose = (submitForm = "") => {
        if (submitForm === "") {
            close()
        }
        form.resetFields();
        setDisabled(true);
        setFetchVillage(true);
        dispatch(setVillageList([]));
        setSaving(false)
    }
    const validateForm = () => {
        validateFields(form, setDisabled);
    };
    const handleFormSubmit = (data: any) => {
        setSaving(true);
        // data.data = dayjs(data.date).format(CONSTANT.POST_DATE_FORMAT)
        data.date = dayjs(data.date).format(CONSTANT.MONTH_FORMAT)
        dispatch(createRecord(data))
            .then((res: any) => {
                setVillageId(res?.data?.village_id)
                setVillageDetailsId(res?.data?.id)
                if (submitForm === "saveAndNext") {
                    drawerClose(submitForm);
                    saveAndNext(CONSTANT.STEP.VILLAGE_DETAILS)
                } else {
                    drawerClose();
                }
            })
            .catch((error: any) => {
                assignErrorToInput(form, error?.STATUS);
            })
            .finally(() => setSaving(false));

    };

    return (
        <Drawer
            title={`${titleName} ${t("GENERAL.ADD_DRAWER_TITLE")}`}
            placement="right"
            width={"70%"}
            onClose={() => drawerClose()}
            destroyOnClose
            closeIcon={<SVGIcon icon="close" color={Config.themePrimaryBtn} />}
            footer={[
                <Button
                    key="2"
                    htmlType="button"
                    className="cancelBtn"
                    onClick={() => drawerClose()}
                >
                    {t("GENERAL.CANCEL_BUTTON")}
                </Button>,
                <Button
                    key="1"
                    disabled={disabled}
                    form="addForm"
                    loading={saving}
                    htmlType="submit"
                    type="primary"
                    onClick={() => setSubmitForm("save")}
                >
                    {t("GENERAL.SAVE_BUTTON")}
                </Button>,
                <Button
                    key="3"
                    disabled={disabled}
                    form="addForm"
                    loading={saving}
                    htmlType="submit"
                    type="primary"
                    onClick={() => setSubmitForm("saveAndNext")}
                >
                    {t("GENERAL.SAVE_NEXT_BUTTON")}
                </Button>
            ]}
            {...rest}
        >

            <FormComponent
                form={form}
                id="addForm"
                handleSubmit={handleFormSubmit}
                onValuesChange={validateForm}
                fetchVillage={fetchVillage}
                setFetchVillage={setFetchVillage}
            />
        </Drawer>
    )
}

export default AddComponent