import { Button, Col, Drawer, DrawerProps, Form, Row } from 'antd';
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../store/app';
import { useTranslation } from 'react-i18next';
import { validateFields, villageType } from '../../../../../config/Global';
import SVGIcon from '../../../../../utils/SVGIcon';
import Config from '../../../../../config/Config';
import { FormBox, InputBox } from '../../../../../components/AntdAddons';
import { assignErrorToInput } from '../../../../../store/api';
import { createRecord } from '../../../Administration/VillageVsWard/utils/slice';
import { rules } from './rules';
import { fetchPincodeList } from '../../../Administration/PincodeManagement/utils/slice';
import { fetchTalukaList } from '../../../Administration/TalukaManagement/utils/slice';
interface VillageDrawerProps extends DrawerProps {
    titleName: string;
    open: boolean;
    close: () => void;
    pincodeId: any;
    talukaId: any;
}
const NewVillage: React.FC<VillageDrawerProps> = ({
    open,
    titleName,
    close,
    pincodeId,
    talukaId,
    ...rest
}) => {
    const dispatch = useAppDispatch()
    const [disabled, setDisabled] = useState<boolean>(true);
    const [saving, setSaving] = useState<boolean>(false);
    const { t } = useTranslation();
    const [form] = Form.useForm()
    const { pincodeList } = useAppSelector(
        state => state.pincode
    );
    const { talukaList } = useAppSelector(
        state => state.taluka
    );
    const onValuesChange = () => {
        validateFields(form, setDisabled);
    };
    const drawerClose = () => {
        form.resetFields();
        close()
    }
    const handleFormSubmit = (data: any) => {
        setSaving(true);
        dispatch(createRecord(data))
            .then(() => {
                drawerClose();
            })
            .catch((error: any) => {
                assignErrorToInput(form, error?.STATUS);
            })
            .finally(() => setSaving(false));
    };
    useEffect(() => {
        const setPincode = pincodeList && pincodeList?.filter((item: any) => item?.id === pincodeId)
        const setVillage = talukaList && talukaList?.filter((item: any) => item?.id === talukaId)
        if (setPincode) {
            form.setFieldValue("pincode_id", pincodeId)
        }
        if (setVillage) {
            form.setFieldValue("taluka_id", talukaId)
        }
    }, [pincodeId, talukaId, open])
    return (
        <>
            <Drawer
                open={open}
                title={`${titleName} ${t("GENERAL.ADD_DRAWER_TITLE")}`}
                placement="right"
                width={"70%"}
                onClose={drawerClose}
                closeIcon={<SVGIcon icon="close" color={Config.themePrimaryBtn} />}
                footer={[
                    <Button
                        key="2"
                        htmlType="button"
                        className="cancelBtn"
                        onClick={drawerClose}
                    >
                        {t("GENERAL.CANCEL_BUTTON")}
                    </Button>,
                    <Button
                        key="1"
                        disabled={disabled}
                        form="addNewVillageForm"
                        loading={saving}
                        htmlType="submit"
                        type="primary"
                    >
                        {t("GENERAL.SAVE_BUTTON")}
                    </Button>,
                ]}
                {...rest}
            >
                <>
                    <FormBox
                        form={form}
                        id={"addNewVillageForm"}
                        onFinish={handleFormSubmit}
                        onValuesChange={onValuesChange}
                    >
                        <Row gutter={15}>
                            <Col xs={{ span: 24 }} >
                                <InputBox.Text
                                    required
                                    label={t("GENERAL.VILLAGES")}
                                    name="name"
                                    placeholder={t("GENERAL.VILLAGES")}
                                    rules={rules.newvillage()}
                                />
                            </Col>
                        </Row>
                        <Row gutter={15}>
                            <Col xs={{ span: 8 }} >
                                <InputBox.Select
                                    required
                                    name="village_type_id"
                                    label={`${t("GENERAL.NAGERPALLIKA_VILLAGE_TYPE")}`}
                                    placeholder={`${t("GENERAL.NAGERPALLIKA_VILLAGE_TYPE")}`}
                                    options={{
                                        list: villageType,
                                        valueKey: "id",
                                        textKey: "name",
                                    }}
                                    rules={rules.villageType()}
                                />
                            </Col>
                            <Col xs={{ span: 8 }} >
                                <InputBox.Select
                                    required
                                    allowClear
                                    showSearch
                                    name="pincode_id"
                                    optionFilterProp="children"
                                    label={t("GENERAL.PINCODES")}
                                    placeholder={t("GENERAL.PINCODES")}
                                    onFocus={() => {
                                        dispatch(fetchPincodeList({ is_active: 1 }))
                                            .catch(() => {
                                                //
                                            })
                                    }}
                                    onChange={() => {
                                        form.setFieldsValue({
                                            taluka_id: null,
                                        });
                                    }}
                                    options={{
                                        list: pincodeList,
                                        valueKey: "id",
                                        textKey: "pincode",
                                    }}
                                    rules={rules.pincode()}
                                />
                            </Col>
                            <Col xs={{ span: 8 }} >
                                <InputBox.Select
                                    required
                                    showSearch
                                    allowClear
                                    name="taluka_id"
                                    optionFilterProp="children"
                                    label={t("GENERAL.TALUKA")}
                                    placeholder={t("GENERAL.TALUKA")}
                                    onFocus={() => {
                                        form.getFieldValue("pincode_id") !== undefined &&
                                            dispatch(fetchTalukaList({ pincode_id: form.getFieldValue("pincode_id"), is_active: 1 }))
                                                .catch(() => {
                                                    //
                                                })
                                    }}
                                    options={{
                                        list: talukaList,
                                        valueKey: "id",
                                        textKey: "name",
                                    }}
                                    rules={rules.taluka()}
                                />
                            </Col>
                        </Row>
                    </FormBox>
                </>
            </Drawer>
        </>
    )
}
export default NewVillage