import React from 'react'
// import { CONSTANT } from '../../../../../../config/Constant'
// import { useAppDispatch } from '../../../../../../store/app';
// import { getVillageAttractionList } from '../../utils/slice';
// import { Drawer, Table } from 'antd';
// import SVGIcon from '../../../../../../utils/SVGIcon';
// import Config from '../../../../../../config/Config';
// import CancelButton from '../../../../../../components/Buttons/CancelButton';
// import { useParams } from 'react-router-dom';
// import { attractionDisplay, partyPersonTypeDisplay, tablePagination } from '../../../../../../config/Global';
import { DrawerProps } from 'antd/lib';
import {Table } from 'antd';
import { attractionDisplay} from '../../../../../../config/Global';
import { useTranslation } from 'react-i18next';


interface VillageAttractionViewProps extends DrawerProps {
    // open: boolean;
    // close: () => void;
    // id: any
    // dynamicSteps: any
    villageAttraction: any
}

const VillageAttractionView: React.FC<VillageAttractionViewProps> = ({
    // open,
    // close,
    // id,
    // dynamicSteps,
    // ...rest
    villageAttraction
}) => {
    const { t } = useTranslation();
    // const { action } = useParams();
    // const dispatch = useAppDispatch();
    // const [villageAttractionList, setVillageAttractionList] = useState<any>([]);
    // const [pagination, setPagination] = useState(tablePagination);

    // const continueBtn = () => {
    //     saveAndNext(CONSTANT.STEP.VILLAGE_PROBLEM)
    // }

    // useEffect(() => {
    //     if (id && action === CONSTANT.DRAWER_TYPE.VIEW && dynamicSteps === CONSTANT.STEP.VILLAGE_ATTRACTION) {
    //         dispatch(getVillageAttractionList({ village_id: id }))
    //             .then((res: any) => {
    //                 setVillageAttractionList(res.rows)
    //             })
    //             .catch((e: any) => e)
    //     }
    // }, [dynamicSteps])
    const columns = [
        {
            title: `${t("GENERAL.NAME")}`,
            dataIndex: 'name',
            key: 'name',

        },
        {
            title: `${t("VILLAGEMANAGEMENT.TYPE")}`,
            dataIndex: 'type',
            key: 'type',

            render: (text: any) => {
                return (
                    <p>{attractionDisplay[text]}</p>
                )
            }
        },
        {
            title: `${t("VILLAGEMANAGEMENT.DETAIL")}`,
            dataIndex: 'detail',
            key: 'detail',
        },
        // {
        // 	title: 'Action',
        // 	key: 'action',
        // 	render: (text: any, record: any) => (
        // 		<div className='listActionbtn'>
        // 			<span onClick={() => handleAddEdit(record, "editModal")}>
        // 				<EditOutlined />
        // 			</span>
        // 			<span onClick={() => deleteModal(record)}>
        // 				<DeleteOutlined />
        // 			</span>
        // 		</div>
        // 	),
        // },
    ];
    // const drawerClose = () => {
    //     close();
    //     setVillageAttractionList([])
    // };
    return (

        <Table
            dataSource={villageAttraction}
            columns={columns}
            pagination={false}
        />
        // <Drawer
        //     title={`${VILLAGEMANAGEMENT.VILLAGE_ATTRACTION} ${GENERAL.VIEW__DRAWER_TITLE}`}
        //     open={open}
        //     placement="right"
        //     width={"70%"}
        //     onClose={drawerClose}
        //     destroyOnClose
        //     closeIcon={<SVGIcon icon="close" color={Config.themePrimaryBtn} />}
        //     footer={[
        //         <CancelButton key={2} onClick={drawerClose} />,
        //         // <Button
        //         //     htmlType="button"
        //         //     className="cancelBtn"
        //         //     key="2"
        //         //     type="primary"
        //         //     onClick={continueBtn}
        //         // >
        //         //     Continue
        //         // </Button>
        //     ]}
        //     {...rest}
        // >
        //     <Table
        //         dataSource={villageAttractionList}
        //         columns={columns}
        //         onChange={(pagination: any) => setPagination(pagination)}
        //         pagination={pagination} // Apply pagination configuration
        //     />
        // </Drawer>
    );
}

export default VillageAttractionView