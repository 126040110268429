import { ColDef } from "ag-grid-community";
import { renderNA } from "../../../../../components/AgGridWrapper/utils/commonFunctions";
import { srNoColumn, actionColumn } from "../../../../../components/AgGridWrapper/utils/commonColumns";
import { dateFormatter } from "../../../../../config/Global";
import { useTranslation } from "react-i18next";

const columnDefs = (): ColDef[] => {
	const { t } = useTranslation();
	const dateColumn: ColDef = {
		field: "entry_date",
		headerName: `${t("GENERAL.DATE_OF_GRANT")}`,
		sortable: true,
		width: 200,
		minWidth: 200,
		cellRenderer: (props: any) => {
			const start = props.data?.start && props.data.start
			const end = props.data?.end && props.data.end
			if (start && end) {
				const formattedStart = dateFormatter(start);
				const formattedEnd = dateFormatter(end);
				return `${formattedStart} - ${formattedEnd}`;
			} else {
				return "N/A";
			}
		},
		filter: false,
		filterParams: {
			inRangeInclusive: true,
			filterOptions: ["inRange"],
			suppressFilterIcon: true,
			inRangeFloatingFilterDateFormat: "DD/MM/YYYY"
		},

	};
	const detailColumn: ColDef = {
		field: "detail",
		headerName: `${t("GENERAL.DETAILS")}`,
		cellRenderer: (props: any) => {
			return renderNA(props.data?.detail);
		},
	};
	const titleColumn: ColDef = {
		field: "title",
		headerName: `${t("MLAMEETINGMANAGEMENT.TITLE")}`,
		cellRenderer: (props: any) => {
			return renderNA(props.data?.title);
		},
	};
	const placeColumn: ColDef = {
		field: "place",
		headerName: `${t("MLAMEETINGMANAGEMENT.PLACE")}`,
		cellRenderer: (props: any) => {
			return renderNA(props.data?.place);
		},
	};
	return [
		srNoColumn(t),
		dateColumn,
		detailColumn,
		titleColumn,
		placeColumn,
		{ ...actionColumn(t), width: 165, minWidth: 165 },

	];
};
export default columnDefs;
