
import { formRules } from "../../../../../config/validations/formRules";
import { maxComment, maxName, minComment, minName, validations } from "../../../../../config/validations/validations";

export const rules: any = {
  date: (field?: string) => [
    validations.required?.other(field),
  ],
  type: (field?: string) => [
    validations.required.text(field),
    validations.min.text(minName),
    validations.max.text(maxName),
  ],
  mobileNumber: () => formRules.mobileNumber(),
  comment:(field?: string) => [
    validations.required.text(field),
    validations.min.text(minComment),
    validations.max.text(maxComment),
  ]
};
