import React, { useEffect, useState } from 'react'
import { b64toBlob, checkPrivileges } from '../../../config/Global'
import { CONSTANT } from '../../../config/Constant'
import ToolBar from '../../../components/ToolBar'
import { MLAGrantBreadcrumb } from '../../../config/BreadcrumbConfig'
import ContentBox from '../../../components/ContentBox/ContentBox'
import { ModuleInfoProps } from '../../../config/InterfacesAndTypes'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../store/app'
import RecordPerPage from '../../../components/ToolBar/Dropdown/RecordPerPage'
import { deleteRecord, details, doDownloadFile, setPerPageSize } from './Components/utils/slice'
import Listing from './Components/Listing/Listing'
import AddComponent from './Components/Form/AddComponent'
import ViewDrawer from './Components/Form/ViewDrawer'
import DeleteModal from '../../../components/Modals/DeleteModal'
import EditComponent from './Components/Form/EditComponent'
import CreateButton from '../../../components/ToolBar/Buttons/CreateButton'
import { useTranslation } from 'react-i18next'
import { Button, Popover } from 'antd'
import { DownloadOutlined } from "@ant-design/icons";
import StatusModel from './StatusModel'

const MLAGrantManagement: React.FC<ModuleInfoProps> = (moduleInfo) => {
  const { t } = useTranslation();
  const { action, id } = useParams()
  const navigate = useNavigate()
  const [visibleDrawer, setVisibleDrawer] = useState<string | null>(null);
  const userDetail = useAppSelector((state) => state.auth.userDetail);
  const { perPage } = useAppSelector((state) => state.mlaGrant);
  const [deleteValue, setDeleteValue] = useState<any>();
  const [exportOpen, setExportOpen] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const [grantStatus, setGrantStatus] = useState<any>(null);
  const dispatch = useAppDispatch()
  const navigateToIndex = () => {
    setVisibleDrawer(null)
    navigate(`${moduleInfo.indexRoute}`)
  }
  const getDetails = (id: any, type: string) => {
    dispatch(details(id))
      .then(() => {
        setVisibleDrawer(type)
      })
      .catch(() => {
        navigateToIndex()
      })
  }
  useEffect(() => {
    if (action) {
      if (action === CONSTANT.DRAWER_TYPE.ADD && userDetail && !checkPrivileges(userDetail, `${moduleInfo.permissionPrefix}_CREATE`)) {
        navigateToIndex()
        return
      }
      if (action === CONSTANT.DRAWER_TYPE.EDIT && userDetail && !checkPrivileges(userDetail, `${moduleInfo.permissionPrefix}_UPDATE`)) {
        navigateToIndex()
        return
      }
      if (action === CONSTANT.DRAWER_TYPE.VIEW && userDetail && !checkPrivileges(userDetail, `${moduleInfo.permissionPrefix}_DETAILS`)) {
        navigateToIndex()
        return
      }
      if (!id && action) {
        setVisibleDrawer(action);
      } else if (id && action) {
        getDetails(id, action);
      } else {
        navigateToIndex();
      }
    }
  }, [userDetail, action, id]);
  const downloadFile = (base64Data: any, fileType: any) => {
    const b64Data = b64toBlob(base64Data, fileType)
    const fileUrl = URL.createObjectURL(b64Data);
    const downloadLink = document.createElement("a");
    downloadLink.href = fileUrl;
    downloadLink.download = "MLA_Grants"; // You can set the filename here
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(fileUrl);
  }
  const ExportFile = (value: any) => {
    if (value === "Excel") {
      dispatch(doDownloadFile(2)).then((res: any) => {
        const base64 = res?.list
        // const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        const fileType = "text/csv"
        downloadFile(base64, fileType)
        setExportOpen(false);
      })
    }
  }

  const handleOpenChange = (newOpen: boolean) => {
    setExportOpen(newOpen);
  };

  const content = (
    <div className='exportReportFile'>
      <div onClick={() => ExportFile("Excel")} style={{ cursor: "pointer" }}>{t("MLAGRANTMANAGEMENT.EXCEL")}</div>
      {/* <div onClick={() => ExportFile("Pdf")} style={{ cursor: "pointer" }}>{t("MLAGRANTMANAGEMENT.PDF")}</div> */}
    </div>
  );
  return (
    <>
      <ToolBar breadcrumbs={MLAGrantBreadcrumb(t)} >
        {
          checkPrivileges(userDetail, `${moduleInfo.permissionPrefix}_EXPORT`) && <Popover
            content={content}
            trigger="click"
            open={exportOpen}
            onOpenChange={handleOpenChange}
            placement='bottom'
          >
            <Button type="primary">{t("MLAGRANTMANAGEMENT.EXPORT_BUTTON")} <DownloadOutlined /></Button>
          </Popover>
        }
        <CreateButton
          action={() =>
            navigate(`${moduleInfo.indexRoute}/${CONSTANT.DRAWER_TYPE.ADD}`)
          }
          permissionKey={`${moduleInfo.permissionPrefix}_CREATE`}
        />
        <RecordPerPage
          perPage={perPage}
          onChange={(perPageSize: number) => {
            dispatch(setPerPageSize(perPageSize))
          }}
        />
      </ToolBar>
      <ContentBox>
        <Listing
          moduleInfo={moduleInfo}
          handleViewClick={(data: any) => {
            navigate(
              `${moduleInfo.indexRoute}/${CONSTANT.DRAWER_TYPE.VIEW}/${data.id}`
            );
          }}
          handleEditClick={(data: any) => {
            navigate(
              `${moduleInfo.indexRoute}/${CONSTANT.DRAWER_TYPE.EDIT}/${data.id}`
            );
          }}
          handleDeleteClick={setDeleteValue}
          handleStatusClick={(data: any) => {
            setGrantStatus({
              grant_id: data?.id,
              status: data?.status,
              status_date : data?.status_date
            })
            setOpenStatus(true)
          }}
        />
        <AddComponent
          titleName={`${t("MLAGRANTMANAGEMENT.MLA_GRNAT")}`}
          open={visibleDrawer === CONSTANT.DRAWER_TYPE.ADD}
          close={navigateToIndex}
        />
        <ViewDrawer
          titleName={t("MLAGRANTMANAGEMENT.MLA_GRNAT")}
          open={visibleDrawer === CONSTANT.DRAWER_TYPE.VIEW}
          close={navigateToIndex}
        />
        <DeleteModal
          title={t("MLAGRANTMANAGEMENT.MLA_GRNAT")}
          deleteValues={deleteValue}
          callApi={deleteRecord}
          close={() => setDeleteValue(null)}
          keyName="scheme_name"
        />
        <EditComponent
          titleName={t("MLAGRANTMANAGEMENT.MLA_GRNAT")}
          open={visibleDrawer === CONSTANT.DRAWER_TYPE.EDIT}
          close={navigateToIndex}
        />
        <StatusModel
          title={t("MLAGRANTMANAGEMENT.STATUS")}
          close={() => {
            setGrantStatus(null)
            setOpenStatus(false)
          }
          }
          open={openStatus}
          grantStatus={grantStatus}
        />
      </ContentBox>
    </>
  )
}
export default MLAGrantManagement