import { Form, DrawerProps, Drawer, Descriptions, Image } from "antd";
import { useAppSelector } from "../../../../store/app";
import SVGIcon from "../../../../utils/SVGIcon";
import Config from "../../../../config/Config";
import CancelButton from "../../../../components/Buttons/CancelButton";
import { renderNA } from "../../../../components/AgGridWrapper/utils/commonFunctions";
import dayjs from 'dayjs'
import { CONSTANT } from "../../../../config/Constant";
import { Link } from "react-router-dom";
import { FilePdfOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

interface ViewDrawerProps extends DrawerProps {
	titleName: string;
	open: boolean;
	close: () => void;
}

const ViewDrawer: React.FC<ViewDrawerProps> = ({
	titleName,
	open,
	close,
	...rest
}) => {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const details = useAppSelector(state => state.mlaEvent.details);
	const drawerClose = () => {
		close();
		form.resetFields();
	};



	return (
		<Drawer
			title={`${titleName} ${t("GENERAL.VIEW__DRAWER_TITLE")}`}
			open={open}
			placement="right"
			width={"70%"}
			onClose={drawerClose}
			destroyOnClose
			closeIcon={<SVGIcon icon="close" color={Config.themePrimaryBtn} />}
			footer={[<CancelButton key={2} onClick={drawerClose} />]}
			className="mlaGrantView"
			{...rest}
		>
			<Descriptions column={2} bordered layout="horizontal">
				<Descriptions.Item label={t("GENERAL.NAME")}>
					{renderNA(details?.name)}
				</Descriptions.Item>
				<Descriptions.Item label={t("GENERAL.MOBILE_NUMBER")}>
					{renderNA(details?.mobile_no)}
				</Descriptions.Item>
				<Descriptions.Item label={t("MLAMEETINGMANAGEMENT.TYPE_OF_VISIT")}>
					{renderNA(details?.visit_type)}
				</Descriptions.Item>
				<Descriptions.Item label={t("GENERAL.MEETING_DETAILS")}>
					{renderNA(details?.detail)}
				</Descriptions.Item>
				<Descriptions.Item label={`${t("GENERAL.FROM_DATE")} - ${t("GENERAL.TO_DATE")}`}>
					{renderNA(dayjs(details?.from_date).format(CONSTANT.DATE_RANGE_TIME_FORMAT)) + `${" - "}` + renderNA(dayjs(details?.to_date).format(CONSTANT.DATE_RANGE_TIME_FORMAT))}
				</Descriptions.Item>
				<Descriptions.Item label="Attachments">
					<div className='mlaGrantViewAttachments'>
						<div className='pdfView'>
							<div className='viewCommentAttachments'  >
								{
									details && details?.attachment.length > 0 ?
										details?.attachment.map((item: any) => {
											const fileExtension: any = item.url.split('.').pop();
											return (
												<>
													{fileExtension === "pdf" ?
														<Link to={item.url} target="_blank" rel="noopener noreferrer " className="pdf" >
															<FilePdfOutlined />
														</Link>
														:
														<Image src={item.url} width={50} height={50} style={{ padding: 5 }} />
													}
												</>
											)

										})
										: `${t("GENERAL.NO_FILE_UPLOADED")}`
								}
							</div>
						</div>
					</div>
				</Descriptions.Item>
				<Descriptions.Item label={t("MLAMEETINGMANAGEMENT.MEETING_PLACE")}>
					{renderNA(details?.place)}
				</Descriptions.Item>
			</Descriptions>
		</Drawer>
	);
};

export default ViewDrawer;
