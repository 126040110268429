import { Messages } from "../../../../../../config/Messages";
import { formRules } from "../../../../../../config/validations/formRules";
import { maxDescription, maxName, maxTaluka, maxVoting, minDescription, minName, minTaluka, minVoting, validations } from "../../../../../../config/validations/validations";


export const rules: any = {
  pincode: (field?: string) => [
    validations.required.other(field),
  ],
  villageType: (field?: string) => [
    validations.required.other(field),
  ],
  taluka: (field?: string) => [
    validations.required.other(field),
    ({ getFieldValue }: any) => ({
      validator() {
        if (getFieldValue("pincode_id") === undefined) {
          return Promise.reject(Messages.SELECT_PINCODE);
        }
        return Promise.resolve();
      },
    }),
  ],
  gender: (field?: string) => [
    validations.required.other(field),
  ],
  village: (field?: string) => [
    validations.required.text(field),
    validations.min.text(minTaluka),
    validations.max.text(maxTaluka),
  ],
  population: (field?: string) => [
    validations.required.number(field),
  ],
  bjp: (field?: string) => [
    validations.required.number(field),
    validations.min.number(minVoting),
    validations.max.number(maxVoting),
  ],
  voting: (field?: string) => [
    validations.required.number(field),
    validations.min.number(minVoting),
    validations.max.number(maxVoting),
  ],
  // party worker validation start
  name: (field?: string) => [
    validations.required.text(field),
    validations.min.text(minName),
    validations.max.text(maxName),
  ],
  mobileNumber: () => formRules.mobileNumber(),
  partyPersonType: (field?: string) => [
    validations.required.other(field),
  ],
  age: (field?: string) => [
    validations.pattern.numeric(),
    validations.required.number(field),
  ],
  deficiency:(field?: string)=>[
    validations.required.text(field),
    validations.min.text(minDescription),
    validations.max.text(maxDescription),
  ],
  caste:(field?: string)=>[
    validations.required.text(field),
    validations.min.text(minDescription),
    validations.max.text(maxDescription),
  ],
};
