import React from "react";
import { DatePicker } from "antd";
import { DatePickerInputBoxProps } from "./interface";
import { SplitInputWrapperProps, InputWrapper } from "../../functions";
import { CONSTANT } from "../../../../config/Constant";

const MonthPicker: React.FC<DatePickerInputBoxProps> = (props) => {
    const { formProps, inputProps } = SplitInputWrapperProps(props);
    return (
        <InputWrapper {...formProps}>
            <DatePicker  {...inputProps} format={CONSTANT.MONTH_FORMAT} picker="month" />
        </InputWrapper>
    );
};

export default MonthPicker;
