import { useEffect } from "react";
import { Col, Row } from "antd";
import { FormBox, InputBox } from "../../../../../../components/AntdAddons";
import { rules } from "./rules";
import {
	fetchRolesList,
} from "../../../../../../store/CommonSlice";
// import { CONSTANT } from "../../../../../config/Constant";
import {
	useAppDispatch,
	useAppSelector,
} from "../../../../../../store/app";
import { useTranslation } from "react-i18next";
// import dayjs from "dayjs";

const FormComponent: React.FC<any> = ({
	form,
	id,
	handleSubmit,
	onValuesChange,
	editValues,
}) => {
	const { t } = useTranslation();
	const { rolesList } = useAppSelector(
		state => state.common
	);

	// const {
	//   dob,
	// } = editValues ?? {};

	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(fetchRolesList()).catch((error: any) => error);
	}, []);

	useEffect(() => {
		if (editValues) {
			form.setFieldsValue({
				...editValues,
				// dob: dob && dayjs(dob),
			});
		}
	}, [editValues, form]);

	// const minDate = dayjs().subtract(18, "years");
	// const disableDate = (current: any) => {
	//   return current && current > minDate;
	// };

	const onFinish = (data: any) => {
		// data.dob = dayjs(data.dob).format(CONSTANT.POST_DATE_FORMAT);
		handleSubmit(data);
	};

	return (
		<>
			<FormBox
				form={form}
				id={id}
				onFinish={onFinish}
				onValuesChange={onValuesChange}
			>
				<Row gutter={15}>
					<>
						{/* <br />
            <br />
            <Col xs={{ span: 24 }}>
              <b>User Details:</b>
            </Col>
            <br />
            <br /> */}
						<Col xs={{ span: 24 }} md={{ span: 12 }}>
							<InputBox.Text
								required
								label={t("GENERAL.FIRST_NAME")}
								name="name"
								rules={rules.name()}
							/>
						</Col>
						<Col xs={{ span: 24 }} md={{ span: 12 }}>
							<InputBox.Text
								required
								label={t("GENERAL.Last_NAME")}
								name="last_name"
								rules={rules.name()}
							/>
						</Col>
						<Col xs={{ span: 24 }} md={{ span: 12 }}>
							<InputBox.Text
								required
								label={t("GENERAL.MOBILE_NUMBER")}
								name="mobile_no"
								addonBefore={"+91"}
								placeholder="xxxxxxxxxx"
								rules={rules.mobileNumber()}
								maxLength={10}
							/>
						</Col>
						<Col xs={{ span: 24 }} md={{ span: 12 }}>
							<InputBox.Text
								required
								name="email"
								label={t("GENERAL.EMAIL")}
								rules={rules.email()}
								disabled={editValues ? true : false}
							/>
						</Col>
						<Col xs={{ span: 24 }} md={{ span: 12 }}>
							<InputBox.Select
								required
								name="role_id"
								label={t("GENERAL.ROLE")}
								placeholder={t("GENERAL.ROLE")}
								options={{
									list: rolesList,
									valueKey: "id",
									textKey: "name",
								}}
								rules={rules.roleId()}
							/>
						</Col>
						{/* <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <InputBox.DatePicker
                  required
                  label="Date of birth"
                  name="dob"
                  rules={rules.dateOfBirth()}
                  disabledDate={disableDate}
                  defaultPickerValue={minDate}
                />
              </Col> */}
						{!editValues && (
							<Col xs={{ span: 24 }} md={{ span: 12 }}>
								<InputBox.Password
									required
									name="password"
									label={t("GENERAL.PASSWORD")}
									placeholder={t("GENERAL.PASSWORD")}
									rules={rules.password()}
								/>
							</Col>
						)}
					</>
				</Row>
			</FormBox>
		</>
	);
};

export default FormComponent;
