import React, { useEffect, useState } from "react";
import ToolBar from "../../../../components/ToolBar";
import CreateButton from "../../../../components/ToolBar/Buttons/CreateButton";
import RecordPerPage from "../../../../components/ToolBar/Dropdown/RecordPerPage";
import ContentBox from "../../../../components/ContentBox/ContentBox";
import Listing from "./components/Listing/Listing";
import {
	setPerPageSize,
	details,
	setDetails,
	deleteRecord,
} from "./utils/slice";
import DeleteModal from "../../../../components/Modals/DeleteModal";
import ViewDrawer from "./components/ViewDrawer";
import AddComponent from "./components/Form/AddComponent";
import EditComponent from "./components/Form/EditComponent";
import { UserBreadcrumb } from "../../../../config/BreadcrumbConfig";
import { CONSTANT } from "../../../../config/Constant";
import { useAppDispatch, useAppSelector } from "../../../../store/app";
import { useNavigate, useParams } from "react-router-dom";
import { checkPrivileges } from "../../../../config/Global";
import { ModuleInfoProps } from "../../../../config/InterfacesAndTypes";
import { useTranslation } from "react-i18next";

const UserManagement: React.FC<ModuleInfoProps> = (moduleInfo) => {
	const dispatch = useAppDispatch();
	const { perPage } = useAppSelector((state) => state.user);
	const userDetail = useAppSelector((state) => state.auth.userDetail);
	const [visibleDrawer, setVisibleDrawer] = useState<string | null>(null);
	const [deleteValue, setDeleteValue] = useState<any>();
	const { action, id } = useParams();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const getDetails = (id: any, type: string) => {
		dispatch(details(id))
			.then(() => {
				setVisibleDrawer(type);
			})
			.catch(() => {
				navigateToIndex();
			});
	};

	const navigateToIndex = () => {
		setDetails(null);
		setVisibleDrawer(null);
		navigate(`${moduleInfo.indexRoute}`);
	};


	useEffect(() => {
		if (action) {
			if (action === CONSTANT.DRAWER_TYPE.ADD && userDetail && !checkPrivileges(userDetail, `${moduleInfo.permissionPrefix}_CREATE`)) {
				navigateToIndex()
				return
			}
			if (action === CONSTANT.DRAWER_TYPE.EDIT && userDetail && !checkPrivileges(userDetail, `${moduleInfo.permissionPrefix}_UPDATE`)) {
				navigateToIndex()
				return
			}
			if (action === CONSTANT.DRAWER_TYPE.VIEW && userDetail && !checkPrivileges(userDetail, `${moduleInfo.permissionPrefix}_DETAILS`)) {
				navigateToIndex()
				return
			}

			if (!id && action) {
				setVisibleDrawer(action);
			} else if (id && action) {
				getDetails(id, action);
			} else {
				navigateToIndex();
			}
		}
	}, [userDetail, action, id]);

	return (
		<>
			<ToolBar breadcrumbs={UserBreadcrumb(t)}>
				<CreateButton
					action={() =>
						navigate(`${moduleInfo.indexRoute}/${CONSTANT.DRAWER_TYPE.ADD}`)
					}
					permissionKey={`${moduleInfo.permissionPrefix}_CREATE`}
				/>
				<RecordPerPage
					perPage={perPage}
					onChange={(perPageSize: number) => {
						dispatch(setPerPageSize(perPageSize));
					}}
				/>
			</ToolBar>
			<ContentBox>
				<Listing
					moduleInfo={moduleInfo}
					handleViewClick={(data: any) => {
						navigate(
							`${moduleInfo.indexRoute}/${CONSTANT.DRAWER_TYPE.VIEW}/${data.id}`
						);
					}}
					handleEditClick={(data: any) => {
						navigate(
							`${moduleInfo.indexRoute}/${CONSTANT.DRAWER_TYPE.EDIT}/${data.id}`
						);
					}}
					handleDeleteClick={setDeleteValue}
				/>
				<AddComponent
					titleName={t("USERMANAGEMENT.USER")}
					open={visibleDrawer === CONSTANT.DRAWER_TYPE.ADD}
					close={navigateToIndex}
				/>
				<ViewDrawer
					titleName={t("USERMANAGEMENT.USER")}
					open={visibleDrawer === CONSTANT.DRAWER_TYPE.VIEW}
					close={navigateToIndex}
				/>
				<EditComponent
					titleName={t("USERMANAGEMENT.USER")}
					open={visibleDrawer === CONSTANT.DRAWER_TYPE.EDIT}
					close={navigateToIndex}
				/>
				<DeleteModal
					title={t("USERMANAGEMENT.USER")}
					deleteValues={deleteValue}
					callApi={deleteRecord}
					close={() => setDeleteValue(null)}
					keyName="name"
				/>
			</ContentBox>
		</>
	);
};

export default UserManagement;
