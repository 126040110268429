import { Upload } from "antd";
import { InputWrapper, SplitInputWrapperProps } from "../../functions";
import { useEffect, useState } from "react";
import { RcFile } from "antd/es/upload";
import { UploadInputBoxProps } from "./interface";
import SVGIcon from "../../../../utils/SVGIcon";

const UploadInputBox: React.FC<UploadInputBoxProps> = (props) => {
  const { formProps, inputProps } = SplitInputWrapperProps(props);
  const {
    accept = ".png,.jpeg,.jpg,",
    maxCount = 1,
    showUploadList = true,
    multiple = false,
    listType = "picture-card",
    fileList,
    onChange,
    beforeUpload,
    onRemove,
    ...inputPropsRest
  } = inputProps;

  const [newFileList, setNewFileList] = useState<any>([]);
  useEffect(() => {
    let tempFileList = []
    if (fileList) {
      tempFileList = fileList;
      if (!Array.isArray(fileList)) {
        tempFileList = [fileList];
      }

      tempFileList = tempFileList.map((file: any) => {
        if (file && typeof file === "string") {
          return {
            status: "done",
            url: file,
          }
        } else {
          return file
        }
      });
    }
    setNewFileList(tempFileList);
  }, [fileList]);

  const uploadButton = (
    <div>
      <SVGIcon icon="attachment" width={40} />
    </div>
  );

  const onPreview = async (file: any) => {
    let src = file.url;
    if (file.fullimage) {
      src = file.fullimage;
    }
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result);
      });
    } else {
      window.open(src, "_blank");
    }
  };

  const wrapperOnChange = (data: any) => {
    if (data.file?.status === "error" || data.file?.status === "uploading") {
      setNewFileList([]);
    } else {
      setNewFileList(data.fileList);
    }
  };

  const wrapperBeforeUpload = (file: RcFile) => {
    file;
    return false;
  };

  const getValueFromEvent = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    if (maxCount == 1) {
      return e && e.fileList[0];
    } else {
      return e && e.fileList;
    }
  };

  return (
    <InputWrapper getValueFromEvent={getValueFromEvent} {...formProps}>
      <Upload
        name={formProps.name}
        accept={accept}
        maxCount={maxCount}
        onPreview={onPreview}
        fileList={newFileList}
        showUploadList={showUploadList}
        multiple={multiple}
        listType={listType}
        onChange={onChange ? onChange : wrapperOnChange}
        onRemove={onRemove ? onRemove : wrapperOnChange}
        beforeUpload={beforeUpload ? beforeUpload : wrapperBeforeUpload}
        {...inputPropsRest}
      >
        {newFileList?.length < maxCount && uploadButton}
      </Upload>
    </InputWrapper>
  );
};

export default UploadInputBox;