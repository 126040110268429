import React from 'react'
// import { useAppDispatch } from '../../../../../../store/app';
// import { getVillageAchievementsList } from '../../utils/slice';
// import { Button, Drawer, Table } from 'antd';
// import SVGIcon from '../../../../../../utils/SVGIcon';
// import Config from '../../../../../../config/Config';
// import CancelButton from '../../../../../../components/Buttons/CancelButton';
// import { useParams } from 'react-router-dom';
// import { genderOptionsDisplay, tablePagination } from '../../../../../../config/Global';
import { CONSTANT } from '../../../../../../config/Constant'
import { Table } from 'antd';
import { genderOptionsDisplay } from '../../../../../../config/Global';
import { DrawerProps } from 'antd/lib';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

interface VillageAchievementsViewProps extends DrawerProps {
    // open: boolean;
    // close: () => void;
    // id: any
    // saveAndNext: any;
    // dynamicSteps: any
    villageAchievements: any
}

const VillageAchievementsView: React.FC<VillageAchievementsViewProps> = ({
    // open,
    // close,
    // id,
    // saveAndNext,
    // dynamicSteps,
    // ...rest
    villageAchievements
}) => {
    const { t } = useTranslation();
    // const { action } = useParams();
    // const dispatch = useAppDispatch();
    // const [achievementsList, setVillageAchievementsList] = useState<any>([]);
    // const [pagination, setPagination] = useState(tablePagination);

    // const continueBtn = () => {
    //     saveAndNext(CONSTANT.STEP.VILLAGE_FESTIVAL)
    // }

    // useEffect(() => {
    //     if (id && action === CONSTANT.DRAWER_TYPE.VIEW && dynamicSteps === CONSTANT.STEP.VILLAGE_ACHIEVEMENTS) {
    //         dispatch(getVillageAchievementsList({ village_id: id }))
    //             .then((res: any) => {
    //                 setVillageAchievementsList(res.rows)
    //             })
    //             .catch((e: any) => e)
    //     }
    // }, [dynamicSteps])
    const columns:any = [
        {
            title: t("GENERAL.FIRST_NAME"),
            dataIndex: 'first_name',
            key: 'first_name',
            fixed:'left',
            width: 120,
        },
        {
            title: t("GENERAL.MIDDLE_NAME"),
            dataIndex: 'middle_name',
            key: 'middle_name',
            width: 120,
        },
        {
            title: t("GENERAL.Last_NAME"),
            dataIndex: 'last_name',
            key: 'last_name',
            width: 120,
        },
        {
            title: t("GENERAL.DATE_OF_BIRTH"),
            dataIndex: 'dob',
            key: 'dob',
            width: 120,
            render: (text: any) => {
                return (
                    <span>{text !== null && dayjs(text).format(CONSTANT.DATE_FORMAT)}</span>
                )
            }
        },
        {
            title: `${t("GENERAL.GENDER")}`,
            dataIndex: 'gender',
            key: 'gender',
            width: 120,
            render: (text: any) => {
                return (
                    <span>{genderOptionsDisplay[text]}</span>
                )
            }
        },
        {
            title: `${t("VILLAGEMANAGEMENT.TYPE")}`,
            dataIndex: 'type',
            key: 'type',
            width: 120,
        },
        {
            title: `${t("VILLAGEMANAGEMENT.CONTECT_NUMBER")}`,
            dataIndex: 'phone',
            key: 'phone',
            width: 120,
        }
        // {
        // 	title: 'Action',
        // 	key: 'action',
        // 	render: (text: any, record: any) => (
        // 		<div className='listActionbtn'>
        // 			<span onClick={() => handleAddEdit(record, "editModal")}>
        // 				<EditOutlined />
        // 			</span>
        // 			<span onClick={() => deleteModal(record)}>
        // 				<DeleteOutlined />
        // 			</span>
        // 		</div>
        // 	),
        // },
    ];
    // const drawerClose = () => {
    //     close();
    //     setVillageAchievementsList([])
    // };
    return (

        <Table
            dataSource={villageAchievements}
            columns={columns}
            pagination={false}
            scroll={{ x: 992 }}
        />
        // <Drawer
        //     title={`${VILLAGEMANAGEMENT.VILLAGE_ACHIEVEMENTS} ${GENERAL.VIEW__DRAWER_TITLE}`}
        //     open={open}
        //     placement="right"
        //     width={"70%"}
        //     onClose={drawerClose}
        //     destroyOnClose
        //     closeIcon={<SVGIcon icon="close" color={Config.themePrimaryBtn} />}
        //     footer={[
        //         <CancelButton key={2} onClick={drawerClose} />,
        //         <Button
        //             htmlType="button"
        //             className="cancelBtn"
        //             key="2"
        //             type="primary"
        //             onClick={continueBtn}
        //         >
        //             {GENERAL.CONTINUE_BUTTON}
        //         </Button>
        //     ]}
        //     {...rest}
        // >
        //     <Table
        //         dataSource={achievementsList}
        //         columns={columns}
        //         onChange={(pagination: any) => setPagination(pagination)}
        //         pagination={pagination} // Apply pagination configuration
        //     />
        // </Drawer>
    );
}

export default VillageAchievementsView