import React from 'react'
import AgGridWrapper from '../../../../../components/AgGridWrapper/AgGridWrapper';
import { setupGrid } from '../utils/slice';
import columnDefs from './columnDefs';
import ActionButtons from '../../../../../components/ActionButtons';
import {  useAppSelector } from '../../../../../store/app';
const Listing: React.FC<any> = ({
    moduleInfo,
    handleViewClick,
    handleDeleteClick,
    handleEditClick,
    handleStatusClick
}) => {
    const { pinnedData } = useAppSelector((state) => state.mlaGrant);
    const ActionRenderer = (props: any) => {
        if (props.node.rowPinned) {
            if (props.node.data.actionColumn === "") return null
        }
        return (
            <ActionButtons
                data={props}
                view={{
                    action: handleViewClick,
                    permissionKey: `${moduleInfo.permissionPrefix}_DETAILS`,
                }}
                edit={{
                    action: handleEditClick,
                    permissionKey: `${moduleInfo.permissionPrefix}_UPDATE`,
                }}
                deleteButton={{
                    action: handleDeleteClick,
                    permissionKey: `${moduleInfo.permissionPrefix}_DELETE`,
                }}
                statusButton={{
                    action: handleStatusClick,
                    permissionKey: `${moduleInfo.permissionPrefix}_DELETE`,
                }}
            ></ActionButtons>
        )
    }
    return (
        <>
            <AgGridWrapper
                type="serverSide"
                components={
                    {
                        ActionRenderer
                    }
                }
                onGridReadyWithDispatch={setupGrid}
                columnDefs={columnDefs()}
                pinnedBottomRowDataRow={pinnedData}
            ></AgGridWrapper>
        </>
    )
}
export default Listing