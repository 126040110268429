import { Button, Col, Drawer, Row } from 'antd'
import React from 'react'
import {
  EditOutlined
} from '@ant-design/icons';
import SVGIcon from '../../../../../utils/SVGIcon';
import Config from '../../../../../config/Config';
import { FormBox, InputBox } from '../../../../../components/AntdAddons';
import { useAppSelector } from '../../../../../store/app';
import { rules } from './rules';

const PreferencesForm: React.FC<any> = ({
  onValuesChange,
  setVisibleDrawer,
  form,
  visibleDrawer,
  disabled,
  saving,
  handleSubmit,
  OnDrawerClose
}) => {
  const details = useAppSelector(state => state.configuration.details)
  const OnDrawerOpen = () => {
    form.setFieldsValue({
      brand_name: details?.brand_name,
      office_address: details?.office_address,
      office_name: details?.office_name
    })
    setVisibleDrawer(true)
  }
  const onFinish = (data: any) => {
    handleSubmit(data)
  }
  return (
    <>
      <Row gutter={[30, 30]}>
        <Col xl={24} >
          <div className='imageColumn'>
            <div className='imageAndTitle'>
              <h3>Preferences</h3>
              <Button
                onClick={OnDrawerOpen}
                className='ant-btn-text ant-btn-lg editIcon'>
                <EditOutlined />
              </Button>
            </div>
            <div>
              <ul className='preferencesList'>
                <li>
                  <div className='preferenceText'>
                    <div>
                      <p>Brand</p>
                    </div>
                    <div>
                      <span>{details?.brand_name}</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div className='preferenceText'>
                    <div>
                      <p>Office Address</p>
                    </div>
                    <div>
                      <span>{details?.office_address}</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div className='preferenceText'>
                    <div>
                      <p>Office Name</p>
                    </div>
                    <div>
                      <span>{details?.office_name}</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </Col>
      </Row>
      <Drawer
        title={`Change Preferences`}
        placement="right"
        width={"70%"}
        onClose={OnDrawerClose}
        destroyOnClose
        closeIcon={<SVGIcon icon="close" color={Config.themePrimaryBtn} />}
        open={visibleDrawer}
        footer={[
          <Button
            key="1"
            disabled={disabled}
            form={'preferencesForm'}
            loading={saving}
            htmlType="submit"
            type="primary"
          >
            Save
          </Button>,
          <Button
            key="2"
            htmlType="button"
            className="cancelBtn"
            onClick={OnDrawerClose}
          >
            Cancel
          </Button>
        ]}
      >
        <FormBox
          form={form}
          onFinish={onFinish}
          onValuesChange={onValuesChange}
          id='preferencesForm'
        >
          <Row gutter={15}>
            <Col sm={12}>
              <InputBox.Text
                required
                label="Brand"
                name="brand_name"
                rules={rules.name()}
              />
            </Col>
            <Col sm={12}>
              <InputBox.Text
                required
                label="Office Address"
                name="office_address"
                rules={rules.name()}
              />
            </Col>
            <Col sm={12}>
              <InputBox.Text
                required
                label="Office Name"
                name="office_name"
                rules={rules.name()}
              />
            </Col>
          </Row>
        </FormBox>
      </Drawer>
    </>
  )
}
export default PreferencesForm