import React from 'react'
// import { CONSTANT } from '../../../../../../config/Constant'
// import { useAppDispatch } from '../../../../../../store/app';
// import { getUnavailableServicesList } from '../../utils/slice';
// import { Button, Drawer, Table } from 'antd';
// import SVGIcon from '../../../../../../utils/SVGIcon';
// import Config from '../../../../../../config/Config';
// import CancelButton from '../../../../../../components/Buttons/CancelButton';
// import { useParams } from 'react-router-dom';
// import { tablePagination } from '../../../../../../config/Global';
// import { GENERAL, VILLAGEMANAGEMENT } from '../../../../../../config/lang/guj';
import { Table } from 'antd';
import { DrawerProps } from 'antd/lib';
import { useTranslation } from 'react-i18next';

interface UnavailableServicesViewProps extends DrawerProps {
    // open: boolean;
    // close: () => void;
    // id: any
    // saveAndNext: any;
    // dynamicSteps: any
    unavailableServices: any
}

const UnavailableServicesView: React.FC<UnavailableServicesViewProps> = ({
    unavailableServices
}) => {
    const { t } = useTranslation();
    // const { action } = useParams();
    // const dispatch = useAppDispatch();
    // const [unavailableList, setUnavailableServicesList] = useState<any>([]);
    // const [pagination, setPagination] = useState(tablePagination);

    // const continueBtn = () => {
    //     saveAndNext(CONSTANT.STEP.VILLAGE_PROBLEM)
    // }

    // useEffect(() => {
    //     if (id && action === CONSTANT.DRAWER_TYPE.VIEW && dynamicSteps === CONSTANT.STEP.VILLAGE_UNAVAILABLE_SERVICES){

    //         dispatch(getUnavailableServicesList({ village_id: id }))
    //         .then((res: any) => {
    //             setUnavailableServicesList(res.rows)
    //         })
    //         .catch((e: any) => e)
    //     }
    // }, [dynamicSteps])
    const columns = [
        {
            title: `${t("VILLAGEMANAGEMENT.TYPE")}`,
            dataIndex: 'service_type',
            key: 'service_type',
        },
        {
            title: `${t("VILLAGEMANAGEMENT.DETAIL")}`,
            dataIndex: 'service_detail',
            key: 'service_detail',
        },
        // {
        // 	title: 'Action',
        // 	key: 'action',
        // 	render: (text: any, record: any) => (
        // 		<div className='listActionbtn'>
        // 			<span onClick={() => handleAddEdit(record, "editModal")}>
        // 				<EditOutlined />
        // 			</span>
        // 			<span onClick={() => deleteModal(record)}>
        // 				<DeleteOutlined />
        // 			</span>
        // 		</div>
        // 	),
        // },
    ];
    // const drawerClose = () => {
    //     close();
    //     setUnavailableServicesList([])
    // };
    return (
        <Table
            dataSource={unavailableServices}
            columns={columns}
            pagination={false}
        />
        // <Drawer
        //     title={`${VILLAGEMANAGEMENT.VILLAGE_UNAVAILABLE_SERVICES} ${GENERAL.VIEW__DRAWER_TITLE}`}
        //     open={open}
        //     placement="right"
        //     width={"70%"}
        //     onClose={drawerClose}
        //     destroyOnClose
        //     closeIcon={<SVGIcon icon="close" color={Config.themePrimaryBtn} />}
        //     footer={[
        //         <CancelButton key={2} onClick={drawerClose} />,
        //         <Button
        //             htmlType="button"
        //             className="cancelBtn"
        //             key="2"
        //             type="primary"
        //             onClick={continueBtn}
        //         >
        //             {GENERAL.CONTINUE_BUTTON}
        //         </Button>
        //     ]}
        //     {...rest}
        // >
        //     <Table
        //         dataSource={unavailableList}
        //         columns={columns}
        //         onChange={(pagination: any) => setPagination(pagination)}
        //         pagination={pagination} // Apply pagination configuration
        //     />
        // </Drawer>
    );
}

export default UnavailableServicesView