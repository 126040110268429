import { Drawer, DrawerProps, Form } from 'antd';
import React, { useState } from 'react'
import { useAppDispatch } from '../../../../../store/app';
import { validateFields } from '../../../../../config/Global';
import { createRecord } from '../../utils/slice';
import { assignErrorToInput } from '../../../../../store/api';
import SVGIcon from '../../../../../utils/SVGIcon';
import Config from '../../../../../config/Config';
import FormComponent from './FormComponent';
import { setPincodeList } from '../../../Administration/PincodeManagement/utils/slice';
import { setTalukaList } from '../../../Administration/TalukaManagement/utils/slice';
import { setVillageList } from '../../../Administration/VillageVsWard/utils/slice';
import { useTranslation } from 'react-i18next';



interface AddDrawerProps extends DrawerProps {
    titleName: string;
    close: () => void;
}
const AddComponent: React.FC<AddDrawerProps> = ({
    titleName,
    close,
    ...rest
}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();
    const [disabled, setDisabled] = useState<boolean>(true);
    const [saving, setSaving] = useState<boolean>(false);
    const [fetchpincode, setFetchPincode] = useState<boolean>(true);
    const [fetchTaluka, setFetchTaluka] = useState<boolean>(true);
    const [fetchVillage, setFetchVillage] = useState<boolean>(true);

    const drawerClose = () => {
        close()
        form.resetFields();
        setDisabled(true);
        setFetchPincode(true);
        setFetchTaluka(true);
        setFetchVillage(true);
        dispatch(setTalukaList([]))
        dispatch(setPincodeList([]))
        dispatch(setVillageList([]))
    }
    const validateForm = () => {
        validateFields(form, setDisabled);
    };
    const handleFormSubmit = (data: any) => {
        setSaving(true);
        data.whatsapp_number = data.whatsapp_number ? data.whatsapp_number : " "
        dispatch(createRecord(data))
            .then(() => {
                drawerClose();
            })
            .catch((error: any) => {
                assignErrorToInput(form, error?.STATUS);
            })
            .finally(() => setSaving(false));
    };
    return (
        <Drawer
            title={`${titleName} ${t("GENERAL.REGISTER")} ${t("GENERAL.ADD_DRAWER_TITLE")}`}
            placement="right"
            width={"70%"}
            onClose={drawerClose}
            destroyOnClose
            closeIcon={<SVGIcon icon="close" color={Config.themePrimaryBtn} />}
            className="citizenForm"
            {...rest}
        >

            <FormComponent
                form={form}
                id="addForm"
                handleSubmit={handleFormSubmit}
                onValuesChange={validateForm}
                fetchpincode={fetchpincode}
                setFetchPincode={setFetchPincode}
                fetchTaluka={fetchTaluka}
                setFetchTaluka={setFetchTaluka}
                fetchVillage={fetchVillage}
                setFetchVillage={setFetchVillage}
                disabled={disabled}
                saving={saving}
                drawerClose={drawerClose}
            />
        </Drawer>
    )
}

export default AddComponent