import React from 'react'
import ActionButtons from '../../../../components/ActionButtons';
import AgGridWrapper from '../../../../components/AgGridWrapper/AgGridWrapper';
import { setupGrid } from '../utils/slice';
import columnDefs from './columnDefs';

const Listing: React.FC<any> = ({
    moduleInfo,
    handleViewClick,
    handleDeleteClick,
    handleEditClick,
}) => {
    const ActionRenderer: React.FC<any> = (props: any) => {
        return (
            <ActionButtons
                data={props}
                view={{
                    action: handleViewClick,
                    permissionKey: `${moduleInfo.permissionPrefix}_VIEW`,
                }}
                edit={{
                    action: handleEditClick,
                    permissionKey: `${moduleInfo.permissionPrefix}_UPDATE`,
                }}
                deleteButton={{
                    action: handleDeleteClick,
                    permissionKey: `${moduleInfo.permissionPrefix}_DELETE`,
                }}
            />
        )
    }
    return (
        <>
            <AgGridWrapper
                type="serverSide"
                components={{
                    ActionRenderer,
                }}
                onGridReadyWithDispatch={setupGrid}
                columnDefs={columnDefs()}
            />
        </>
    )
}

export default Listing