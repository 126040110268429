import { Form, DrawerProps, Drawer, Descriptions } from "antd";
import { useAppSelector } from "../../../../../store/app";
import SVGIcon from "../../../../../utils/SVGIcon";
import Config from "../../../../../config/Config";
import CancelButton from "../../../../../components/Buttons/CancelButton";
import { renderNA } from "../../../../../components/AgGridWrapper/utils/commonFunctions";
import { villageTypeDisplay } from "../../../../../config/Global";
import { useTranslation } from "react-i18next";
// import { genderOptions } from "../../../../config/Constant";

interface ViewDrawerProps extends DrawerProps {
  titleName: string;
  open: boolean;
  close: () => void;
}

const ViewDrawer: React.FC<ViewDrawerProps> = ({
  titleName,
  open,
  close,
  ...rest
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const details = useAppSelector(state => state.village.details);

  const drawerClose = () => {
    close();
    form.resetFields();
  };



  return (
    <Drawer
      title={`${titleName} ${t("GENERAL.VIEW__DRAWER_TITLE")}`}
      open={open}
      placement="right"
      width={"70%"}
      onClose={drawerClose}
      destroyOnClose
      closeIcon={<SVGIcon icon="close" color={Config.themePrimaryBtn} />}
      footer={[
        <CancelButton key={2} onClick={drawerClose} />,

      ]}
      {...rest}
    >
      <Descriptions column={1} bordered layout="horizontal">
        <Descriptions.Item label={`${t("GENERAL.VILLAGES")}`}>
          {renderNA(details?.name)}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions column={3} bordered layout="horizontal">
        <Descriptions.Item label={`${t("GENERAL.NAGERPALLIKA_VILLAGE_TYPE")}`}>
          {renderNA(villageTypeDisplay[details?.village_type_id])}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions column={2} bordered layout="horizontal">
        <Descriptions.Item label={`${t("GENERAL.PINCODES")}`}>
          {renderNA(details?.pincode?.pincode)}
        </Descriptions.Item>
        <Descriptions.Item label={`${t("GENERAL.TALUKA")}`}>
          {renderNA(details?.taluka?.name)}
        </Descriptions.Item>
        <Descriptions.Item label={`${t("GENERAL.VILLAGES")}`}>
          {renderNA(details?.name)}
        </Descriptions.Item>
      </Descriptions>


    </Drawer>
  )
};

export default ViewDrawer;
