import React from 'react'
import AgGridWrapper from '../../../../../components/AgGridWrapper/AgGridWrapper';
import ActionButtons from '../../../../../components/ActionButtons';
import columnDefs from './columnDefs';
import { useAppSelector } from '../../../../../store/app';
import { initialFilter } from '../../utils/slice';

const ListingData: React.FC<any> = ({
	moduleInfo,
	handleViewClick,
	handleDeleteClick,
	handleEditClick
}) => {
	const rowData = useAppSelector(state => state.mlaEvent.rowData);
	const ActionRenderer = (props: any) => {
		return (
			<ActionButtons
				data={props}
				view={{
					action: handleViewClick,
					permissionKey: `${moduleInfo.permissionPrefix}_DETAILS`,
				}}
				edit={{
					action: handleEditClick,
					permissionKey: `${moduleInfo.permissionPrefix}_UPDATE`,
				}}
				deleteButton={{
					action: handleDeleteClick,
					permissionKey: `${moduleInfo.permissionPrefix}_DELETE`,
				}}
			></ActionButtons>
		)
	}
	return (
		<>
			<AgGridWrapper
				components={{
					ActionRenderer,
				}}
				rowData={rowData}
				onGridReadyWithDispatch={initialFilter}
				// onGridReady={initialFilter}
				columnDefs={columnDefs()}

			/>
		</>
	)
}

export default ListingData