

import AgGridWrapper from '../../../../../components/AgGridWrapper/AgGridWrapper';
import { setupGrid } from '../utils/slice';
import columnDefs from './columnDefs';

const Listing: React.FC<any> = () => {

	return (
		<>
			<AgGridWrapper
				type="serverSide"
				onGridReadyWithDispatch={setupGrid}
				columnDefs={columnDefs()}

			/>
		</>
	);
}

export default Listing