import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../../../store/app";
import api from "../../../../store/api";
import { MEETINGAPP_URL } from "./meetingApiUrl";
// import Config from "../../../../config/Config";

interface InitialState {
    isLoading: boolean;
    details: any;
    agGrid: any;
    rowData: any;
    perPage: any;
}
const initialState = {
    isLoading: false,
    details: null,
    agGrid: null,
    rowData: null,
    // perPage: Config.grid.local.gridOptions?.paginationPageSize,
    perPage: 10,
} as InitialState;

const MeetingSlice = createSlice({
    name: "meeting",
    initialState,
    reducers: {
        start: state => {
            state.isLoading = true;
        },
        success: state => {
            state.isLoading = false;
        },
        failure: state => {
            state.isLoading = false;
        },
        setGrid: (state, action: PayloadAction<any>) => {
            state.agGrid = action?.payload;
        },
        setSingleRowData: (state, action: PayloadAction<any>) => {
            if (state.rowData && state.rowData.length > 0) {
                state.rowData = [action?.payload, ...state.rowData];
            } else {
                state.rowData = [action?.payload];
            }
        },
        setPerPage: (state, action: PayloadAction<any>) => {
            state.perPage = action?.payload;
            if (state.agGrid) {
                state.agGrid.api.paginationSetPageSize(Number(state.perPage));
                state.agGrid.api.setCacheBlockSize(state.perPage);
            }
        },
        setRowData: (state, action: PayloadAction<any>) => {
            state.rowData = action?.payload;
        },
        setDetails: (state, action: PayloadAction<any>) => {
            state.details = action?.payload;
        },
    },
});
export const { start, success, failure, setDetails, setGrid, setRowData, setSingleRowData, setPerPage } =
    MeetingSlice.actions;

export const createMeeting =
    (action: any): AppThunk<any> =>
        async (dispatch,) => {
            // getState
            try {
                dispatch(start());
                const response = await api.post(MEETINGAPP_URL.CREATE, action);
                dispatch(setSingleRowData(response.data.data));
                dispatch(success(response.data));
                return Promise.resolve(response.data);
            } catch (error: any) {
                dispatch(failure(error.data));
                return Promise.reject(error.data);
            }
        };
export const setPerPageSize =
    (size: number): AppThunk<any> =>
        async dispatch => {
            dispatch(setPerPage(size));
        };


export const meetingDetails =
    (id: number): AppThunk<any> =>
        async dispatch => {
            try {
                const response = await api.get(MEETINGAPP_URL.MEETINGDETAILS(id));
                dispatch(setDetails(response.data.data));
                return Promise.resolve(response.data);
            } catch (error: any) {
                return Promise.reject(error.data);
            }
        };
export const meetingComments = (action: any): AppThunk<any> =>
    async (dispatch,) => {
        // getState
        try {
            dispatch(start());
            const response = await api.post(MEETINGAPP_URL.MEETINGCOMMENTS, action);
            dispatch(success(response.data));
            return Promise.resolve(response.data);
        } catch (error: any) {
            dispatch(failure(error.data));
            return Promise.reject(error.data);
        }
    };
export const meetingList = (action: any): AppThunk<any> =>
    async (dispatch,) => {
        // getState
        try {
            dispatch(start());
            const response = await api.post(MEETINGAPP_URL.MEETINGCOMMENTLIST, action);
            dispatch(success(response.data));
            return Promise.resolve(response.data);
        } catch (error: any) {
            dispatch(failure(error.data));
            return Promise.reject(error.data);
        }
    };
export const editMeetingComment = (action: any,id:any): AppThunk<any> =>
async (dispatch,) => {
        // getState
        try {
            dispatch(start());
            const response = await api.post(MEETINGAPP_URL.UPDATEMEETINGCOMMENTS(id), action);
            dispatch(success(response.data));
            return Promise.resolve(response.data);
        } catch (error: any) {
            dispatch(failure(error.data));
            return Promise.reject(error.data);
        }
    };
export const deleteMeetingComment = (id: any): AppThunk<any> =>
    async (dispatch,) => {
        // getState
        try {
            dispatch(start());
            const response = await api.delete(MEETINGAPP_URL.DELETEMEETINGCOMMENTS(id));
            dispatch(success(response.data));
            return Promise.resolve(response.data);
        } catch (error: any) {
            dispatch(failure(error.data));
            return Promise.reject(error.data);
        }
    };
    export const deleteMeetingCommentAttachment = (id: any): AppThunk<any> =>
    async (dispatch,) => {
        // getState
        try {
            dispatch(start());
            const response = await api.delete(MEETINGAPP_URL.DELETEMEETINGCOMMENTSATTACHMENT(id));
            dispatch(success(response.data));
            return Promise.resolve(response.data);
        } catch (error: any) {
            dispatch(failure(error.data));
            return Promise.reject(error.data);
        }
    };
const userReducer = MeetingSlice.reducer;
export default userReducer;