import React, { useEffect } from 'react'
import { Col, Row, Spin } from 'antd';
import { rules } from './rules';
import { useAppDispatch, useAppSelector } from '../../../../../../store/app';
import { FormBox, InputBox } from '../../../../../../components/AntdAddons';
import { Messages } from '../../../../../../config/Messages';
import { fetchPincodeList, setPincodeList } from '../../../PincodeManagement/utils/slice';
import { fetchTalukaList, setTalukaList } from '../../../TalukaManagement/utils/slice';
import { villageType } from '../../../../../../config/Global';
import { useTranslation } from 'react-i18next';



const FormComponent: React.FC<any> = ({
    form,
    id,
    handleSubmit,
    onValuesChange,
    editValues,
    fetchpincode,
    setFetchPincode,
    fetchTaluka,
    setFetchTaluka,
}) => {
    const { t } = useTranslation();
    const is_active = 1
    const dispatch = useAppDispatch()
    const { talukaList } = useAppSelector(
        state => state.taluka
    );
    const { pincodeList } = useAppSelector(
        state => state.pincode
    );


    // useEffect(() => {
    //     dispatch(fetchPincodeList()).catch((e: any) => e)
    //     dispatch(fatchTalukaList()).catch((e: any) => e)
    // }, [])


    useEffect(() => {
        if (editValues) {
            dispatch(fetchPincodeList({ is_active: is_active })).catch((e: any) => e)
            dispatch(fetchTalukaList({ is_active: is_active })).catch((e: any) => e)
            if (editValues?.taluka) {
                dispatch(setTalukaList([editValues.taluka]))
            }
            if (editValues?.pincode) {
                dispatch(setPincodeList([editValues.pincode]))
            }
            form.setFieldsValue({
                ...editValues,
            });
        }
    }, [editValues, form]);
    const onFinish = (data: any) => {
        handleSubmit(data);
    };

    return (
        <FormBox
            form={form}
            id={id}
            onFinish={onFinish}
            onValuesChange={onValuesChange}
        >
            <Row gutter={15}>
                <Col xs={{ span: 24 }} >
                    <InputBox.Text
                        required
                        label={t("GENERAL.VILLAGES")}
                        name="name"
                        placeholder={t("GENERAL.VILLAGES")}
                        rules={rules.village()}
                    />
                </Col>
            </Row>
            <Row gutter={15}>
                <Col xs={{ span: 8 }} >
                    <InputBox.Select
                        required
                        name="village_type_id"
                        label={`${t("GENERAL.NAGERPALLIKA_VILLAGE_TYPE")}`}
                        placeholder={`${t("GENERAL.NAGERPALLIKA_VILLAGE_TYPE")}`}
                        options={{
                            list: villageType,
                            valueKey: "id",
                            textKey: "name",
                        }}
                        rules={rules.villageType()}
                    />
                </Col>
                <Col xs={{ span: 8 }} >
                    <InputBox.Select
                        required
                        showSearch
                        name="pincode_id"
                        optionFilterProp="children"
                        label={t("GENERAL.PINCODES")}
                        placeholder={t("GENERAL.PINCODES")}
                        onFocus={() => {
                            fetchpincode &&
                                dispatch(fetchPincodeList({ is_active: is_active }))
                                    .then(() => setFetchPincode(false))
                                    .catch((e: any) => e)
                                    .finally(() => setFetchPincode(false))
                        }}
                        onChange={() => {
                            form.setFieldsValue({
                                taluka_id: null,
                            });
                            setFetchTaluka(true)
                        }}
                        notFoundContent={fetchpincode ? <Spin size="small" /> : Messages.NO_RECORD_FOUND}
                        options={{
                            list: pincodeList,
                            valueKey: "id",
                            textKey: "pincode",
                        }}
                        rules={rules.pincode()}
                    />
                </Col>
                <Col xs={{ span: 8 }} >
                    <InputBox.Select
                        required
                        showSearch
                        name="taluka_id"
                        optionFilterProp="children"
                        label={t("GENERAL.TALUKA")}
                        placeholder={t("GENERAL.TALUKA")}
                        onFocus={() => {
                            fetchTaluka && form.getFieldValue("pincode_id") !== undefined &&
                                dispatch(fetchTalukaList({ pincode_id: form.getFieldValue("pincode_id"), is_active: is_active }))
                                    .then(() => setFetchTaluka(false))
                                    .catch((e: any) => e)
                                    .finally(() => setFetchTaluka(false))
                        }}
                        notFoundContent={fetchTaluka ? <Spin size="small" /> : Messages.NO_RECORD_FOUND}
                        onChange={() => {
                            setFetchTaluka(true)
                        }}
                        options={{
                            list: talukaList,
                            valueKey: "id",
                            textKey: "name",
                        }}
                        rules={rules.taluka()}
                    />
                </Col>
            </Row>
        </FormBox>
    )
}

export default FormComponent