import React from "react";
import { DatePicker } from "antd";
import { DateRangePickerInputBoxProps } from "./interface";
import { SplitInputWrapperProps, InputWrapper } from "../../functions";
import { CONSTANT } from "../../../../config/Constant";

const DateRangePickerInputBox: React.FC<DateRangePickerInputBoxProps> = (
	props
) => {
	const { 
		formProps, 
		inputProps: { showTime = false, ...inputProps },
	} = SplitInputWrapperProps(props);
	return (
		<InputWrapper {...formProps}>
			<DatePicker.RangePicker format={!showTime ? CONSTANT.DATE_FORMAT : CONSTANT.DATE_TIME_FORMAT} showTime={showTime} {...inputProps} />
		</InputWrapper>
	);
};

export default DateRangePickerInputBox;
