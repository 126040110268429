import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Config, { getServerListPayload } from "../../../../../config/Config";
import api from "../../../../../store/api";
import { API_URL } from "./apiUrls";
import { GridOptions, GridReadyEvent, IServerSideGetRowsParams } from "ag-grid-community";
import { AppThunk, RootState } from "../../../../../store/app";
interface InitialState {
    isLoading: boolean;
    agGrid: any;
    perPage: any;
    filter: any;
}
const initialState = {
    isLoading: false,
    agGrid: null,
    perPage: Config.grid.server.gridOptions?.paginationPageSize,
    filter: null
} as InitialState;

const ReportsSlice = createSlice({
    name: "reports",
    initialState,
    reducers: {
        start: state => {
            state.isLoading = true;
        },
        success: state => {
            state.isLoading = false;
        },
        failure: state => {
            state.isLoading = false;
        },
        setGrid: (state, action: PayloadAction<any>) => {
            state.agGrid = action?.payload;
        },
        setPerPage: (state, action: PayloadAction<any>) => {
            state.perPage = action?.payload;
            if (state.agGrid) {
                state.agGrid.api.paginationSetPageSize(Number(state.perPage));
                state.agGrid.api.setCacheBlockSize(state.perPage);
            }
        },
        setFilterData: (state, action: PayloadAction<any>) => {
            state.filter = action?.payload;
        },

    }
})
export const { start,
    success,
    failure,
    setGrid,
    setPerPage,
    setFilterData
} = ReportsSlice.actions
/** For Listing:Start */
const fetchList = async (payload?: any): Promise<any> => {
    return await api.post(API_URL.VILLAGE_REPORTS, payload)
        .then(({ data }) => {
            return data;
        })
        .catch(error => error);
}
export const setFilters =
    (filter: any): AppThunk<any> =>
        async (dispatch, getState) => {
            dispatch(setFilterData(filter));
            getState()?.villageReports?.agGrid && dispatch(setupGrid(getState().villageReports.agGrid));
        };

export const setPerPageSize =
    (size: number): AppThunk<any> =>
        async (dispatch, getState: () => RootState) => {
            dispatch(setPerPage(size));
            dispatch(setupGrid(getState().villageReports.agGrid));
        };
export const setupGrid =
    (params: GridReadyEvent): AppThunk<any> =>
        async (dispatch, getState: () => RootState) => {

            try {
                dispatch(setGrid(params));
                const dataSource = await createDataSource(
                    dispatch,
                    getState,
                    Config.grid.server.gridOptions
                );
                params.api.setServerSideDatasource(dataSource);
            } catch (error: any) {
                //
            }
        };
// const changeFilterAndSort = (params: any) => {
//     params;
//     if (params.filterModel && params.filterModel["is_active"]) {
//         params.filterModel["is_active"].values = convertTextToID(
//             params.filterModel["is_active"],
//             isActiveStatusValue
//         );
//     }
// };
const createDataSource = (
    dispatch: any,
    getState: () => RootState,
    gridOptions?: GridOptions
) => {
    return {
        gridOptions,
        getRows: (params: IServerSideGetRowsParams) => {
            // changeFilterAndSort(params.request);
            let payload: any;
            if (getState().villageReports.filter) {
                payload = getServerListPayload(params, getState().villageReports.filter);
            } else {
                payload = getServerListPayload(params);
            }
            fetchList(payload).then(data => {
                if (data.count > 0) {
                    const startPage = (payload.page - 1) * payload.per_page;
                    data.rows.map((item: any, index: number) => {
                        item.srno = startPage + index + 1;
                        return null;
                    });
                }
                params.success({ rowData: data?.rows, rowCount: data?.count });
                if (data.count <= 0) {
                    params.api.showNoRowsOverlay();
                } else {
                    params.api.hideOverlay();
                }

                dispatch(setGrid(params))
            }).catch((error: any) => error);
        },
    };
};

export const getRecord = (action: any): AppThunk<any> =>
    async (dispatch) => {
        try {
            dispatch(start())
            const response = await api.post(API_URL.VILLAGE_REPORTS, action)
            // dispatch(setupGrid(getState().villageReports.agGrid));
            dispatch(success(response.data));
            return Promise.resolve(response.data);

        } catch (error: any) {
            dispatch(failure(error.data))
            return Promise.reject(error.data)
        }
    }
const reportReducer = ReportsSlice.reducer
export default reportReducer