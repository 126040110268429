import React from 'react'
import { Form, DrawerProps, Drawer, Descriptions, Image } from "antd";
import SVGIcon from '../../../../../utils/SVGIcon';
import Config from '../../../../../config/Config';
import CancelButton from '../../../../../components/Buttons/CancelButton';
import { useAppSelector } from '../../../../../store/app';
import { renderNA } from '../../../../../components/AgGridWrapper/utils/commonFunctions';
import { CONSTANT } from '../../../../../config/Constant';
import dayjs from "dayjs"
import { Link } from 'react-router-dom';
import { FilePdfOutlined } from "@ant-design/icons";
import { GrantStatusListDisplay, villageTypeDisplay } from '../../../../../config/Global';
import { useTranslation } from 'react-i18next';
interface ViewDrawerProps extends DrawerProps {
    titleName: string;
    open: boolean;
    close: () => void;
}
const ViewDrawer: React.FC<ViewDrawerProps> = ({
    titleName,
    open,
    close,
    ...rest
}) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const details = useAppSelector(state => state.mlaGrant.details);

    const drawerClose = () => {
        close();
        form.resetFields();
    };
    return (
        <>
            <Drawer
                title={`${titleName} ${t("GENERAL.VIEW__DRAWER_TITLE")}`}
                open={open}
                placement="right"
                width={"70%"}
                onClose={drawerClose}
                destroyOnClose
                closeIcon={<SVGIcon icon="close" color={Config.themePrimaryBtn} />}
                footer={[<CancelButton key={2} onClick={drawerClose} />]}
                {...rest}
            >
                <Descriptions column={2} bordered layout="horizontal">
                    <Descriptions.Item label={t("GENERAL.VILLAGES")}>
                        {renderNA(details?.village?.name)}
                    </Descriptions.Item>
                    <Descriptions.Item label={`${t("GENERAL.NAGERPALLIKA_VILLAGE_TYPE")}`}>
                        {renderNA(villageTypeDisplay[details?.village_type_id])}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("GENERAL.TALUKA")}>
                        {renderNA(details?.taluka?.name)}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("MLAGRANTMANAGEMENT.TYPE_OF_WORK")}>
                        {renderNA(details?.type_of_work)}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("GENERAL.NAME_OF_SHCEME")}>
                        {renderNA(details?.scheme?.name)}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("MLAGRANTMANAGEMENT.AMOUNT")}>
                        {renderNA(details?.amount)}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("GENERAL.DATE_OF_GRANT")}>
                        {details?.entry_date !== null ? renderNA(dayjs(details?.entry_date).format(CONSTANT.DATE_FORMAT)) : "N/A"}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("MLAGRANTMANAGEMENT.Id_Number")}>
                        {renderNA(details?.grant_id_number)}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("MLAGRANTMANAGEMENT.Year_of_grant")}>
                        {renderNA(details?.grant_years?.year)}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("MLAGRANTMANAGEMENT.Government_Department")}>
                        {renderNA(details?.gov_depts?.dept)}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("MLAGRANTMANAGEMENT.CURRENT_STATUS")}>
                        {renderNA(GrantStatusListDisplay[details?.status])}
                    </Descriptions.Item>
                </Descriptions>

                <div className='mlaGrantViewAttachments'>
                    <label className='formLabel'>{t("GENERAL.ATTECHMENT")}</label>
                    <div className='pdfView'>
                        <div className='viewCommentAttachments'  >
                            {
                                details && details?.attachment.length > 0 ?
                                    details?.attachment.map((item: any) => {
                                        const fileExtension: any = item.url.split('.').pop();
                                        return (
                                            <>
                                                {fileExtension === "pdf" ?
                                                    <Link to={item.url} target="_blank" rel="noopener noreferrer" >
                                                        <FilePdfOutlined />
                                                    </Link>
                                                    :
                                                    <Image src={item.url} width={120} height={120} style={{ padding: 10 }} />
                                                }
                                            </>
                                        )

                                    })
                                    : `${t("GENERAL.NO_FILE_UPLOADED")}`
                            }
                        </div>
                    </div>
                </div>
                
                {details?.grant_details !== null && <Descriptions title={`${t("MLAGRANTMANAGEMENT.DISPLAY_STATUS")}`} column={2} bordered layout="horizontal" className='display_status'>
                    {details?.grant_details?.date_of_preliminary_approval !== null && <>
                        <Descriptions.Item label={t("MLAGRANTMANAGEMENT.Date_of_Preliminary_Approval")}>
                            {dayjs(details?.grant_details?.date_of_preliminary_approval).format(CONSTANT.DATE_FORMAT)}
                        </Descriptions.Item>
                        <Descriptions.Item label={t("MLAGRANTMANAGEMENT.Preliminary_Approval_number")}>
                            {renderNA(details?.grant_details?.number_of_preliminary_approval)}
                        </Descriptions.Item>
                    </>

                    }
                    {details?.grant_details?.tantric_approval_date !== null && <>
                        <Descriptions.Item label={t("MLAGRANTMANAGEMENT.Tantric_approval_Date")}>
                            {dayjs(details?.grant_details?.tantric_approval_date).format(CONSTANT.DATE_FORMAT)}
                        </Descriptions.Item>
                        <Descriptions.Item label={t("MLAGRANTMANAGEMENT.Tantric_approval_number")}>
                            {renderNA(details?.grant_details?.tantric_approval_number)}
                        </Descriptions.Item>
                    </>
                    }
                    {details?.grant_details?.date_of_administration_approval !== null && <>
                        <Descriptions.Item label={t("MLAGRANTMANAGEMENT.Date_of_Administration_approval")}>
                            {dayjs(details?.grant_details?.date_of_administration_approval).format(CONSTANT.DATE_FORMAT)}
                        </Descriptions.Item>
                        <Descriptions.Item label={t("MLAGRANTMANAGEMENT.Administration_approval_number")}>
                            {renderNA(details?.grant_details?.number_of_administration_approval)}
                        </Descriptions.Item>
                    </>
                    }
                    {details?.grant_details?.grant_allotment_authority_date !== null && <>
                        <Descriptions.Item label={t("MLAGRANTMANAGEMENT.Grant_Allotment_Authority_Date")}>
                            {dayjs(details?.grant_details?.grant_allotment_authority_date).format(CONSTANT.DATE_FORMAT)}
                        </Descriptions.Item>
                        <Descriptions.Item label={t("MLAGRANTMANAGEMENT.Grant_Allotment_Authority_number")}>
                            {renderNA(details?.grant_details?.grant_allotment_authority_number)}
                        </Descriptions.Item>
                    </>
                    }
                </Descriptions>}
            </Drawer>
        </>
    )
}
export default ViewDrawer