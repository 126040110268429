import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../store/app';
import { ModuleInfoProps } from '../../../../config/InterfacesAndTypes';
import { checkPrivileges } from '../../../../config/Global';
import { CONSTANT } from '../../../../config/Constant';
import ToolBar from '../../../../components/ToolBar';
import { SchemeBreadcrumb } from '../../../../config/BreadcrumbConfig';
import CreateButton from '../../../../components/ToolBar/Buttons/CreateButton';
import RecordPerPage from '../../../../components/ToolBar/Dropdown/RecordPerPage';
import ContentBox from '../../../../components/ContentBox/ContentBox';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteRecord, details, setDetails, setPerPageSize } from './utils/slice';
import Listing from './Components/Listing/Listing';
import AddComponent from './Components/Form/AddComponent';
import ViewDrawer from './Components/ViewDrawer';
import DeleteModal from '../../../../components/Modals/DeleteModal';
import EditComponent from './Components/Form/EditComponent';
import { useTranslation } from 'react-i18next';



const SchemeManagement: React.FC<ModuleInfoProps> = (moduleInfo) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { action, id } = useParams();
    const [visibleDrawer, setVisibleDrawer] = useState<string | null>(null);
    const userDetail = useAppSelector((state) => state.auth.userDetail);
    const { perPage } = useAppSelector((state) => state.scheme);
    const [deleteValue, setDeleteValue] = useState<any>();

    const getDetails = (id: any, type: string) => {
        dispatch(details(id))
        	.then(() => {
        		setVisibleDrawer(type)
        	})
        	.catch(() => {
        		navigateToIndex()
        	})
    }
    const navigateToIndex = () => {
        setDetails(null);
        setVisibleDrawer(null);
        navigate(`${moduleInfo.indexRoute}`);
    };

    useEffect(() => {

        if (action) {
            if (action === CONSTANT.DRAWER_TYPE.ADD && userDetail && !checkPrivileges(userDetail, `${moduleInfo.permissionPrefix}_CREATE`)) {
                navigateToIndex()
                return
            }
            if (action === CONSTANT.DRAWER_TYPE.EDIT && userDetail && !checkPrivileges(userDetail, `${moduleInfo.permissionPrefix}_UPDATE`)) {
                navigateToIndex()
                return
            }
            if (action === CONSTANT.DRAWER_TYPE.VIEW && userDetail && !checkPrivileges(userDetail, `${moduleInfo.permissionPrefix}_DETAILS`)) {
                navigateToIndex()
                return
            }
        }
        if (!id && action) {
            setVisibleDrawer(action);
        } else if (id && action) {
            getDetails(id, action);
        } else {
            navigateToIndex();
        }
    }, [userDetail, action, id])

    return (
        <>
            <ToolBar breadcrumbs={SchemeBreadcrumb(t)}>
                <CreateButton
                    action={() =>
                        navigate(`${moduleInfo.indexRoute}/${CONSTANT.DRAWER_TYPE.ADD}`)
                    }
                    permissionKey={`${moduleInfo.permissionPrefix}_CREATE`}
                />
                <RecordPerPage
                    perPage={perPage}
                    onChange={(perPageSize: number) => {
                        dispatch(setPerPageSize(perPageSize));
                    }}
                />
            </ToolBar>
            <ContentBox>
                <Listing
                    moduleInfo={moduleInfo}
                    handleViewClick={(data: any) => {
                        navigate(
                            `${moduleInfo.indexRoute}/${CONSTANT.DRAWER_TYPE.VIEW}/${data.id}`
                        );
                    }}
                    handleEditClick={(data: any) => {
                        navigate(
                            `${moduleInfo.indexRoute}/${CONSTANT.DRAWER_TYPE.EDIT}/${data.id}`
                        );
                    }}
                    handleDeleteClick={setDeleteValue}
                />
                <AddComponent
                    titleName={t("SCHEMEMANAGEMENT.SCHEME")}
                    open={visibleDrawer === CONSTANT.DRAWER_TYPE.ADD}
                    close={navigateToIndex}
                />
                <ViewDrawer
                    titleName={t("SCHEMEMANAGEMENT.SCHEME")}
                    open={visibleDrawer === CONSTANT.DRAWER_TYPE.VIEW}
                    close={navigateToIndex}
                />
                <DeleteModal
					title={t("SCHEMEMANAGEMENT.SCHEME")}
					deleteValues={deleteValue}
					callApi={deleteRecord}
					close={() => setDeleteValue(null)}
					keyName="name"
				/>
                <EditComponent
					titleName={t("SCHEMEMANAGEMENT.SCHEME")}
					open={visibleDrawer === CONSTANT.DRAWER_TYPE.EDIT}
					close={navigateToIndex}
				/>
            </ContentBox>
        </>
    )
}

export default SchemeManagement