import { ColDef } from "ag-grid-community";
import { renderNA } from "../../../../../../components/AgGridWrapper/utils/commonFunctions";
import { useTranslation } from "react-i18next";
import { isActiveColumn, srNoColumn,actionColumn } from "../../../../../../components/AgGridWrapper/utils/commonColumns";


const columnDefs = (): ColDef[] => {  
  const { t } = useTranslation();

  const firstNameColumn: ColDef = {
    field: "pincode",
    headerName: `${t("GENERAL.PINCODES")}`,
    cellRenderer: (props: any) => {
      return renderNA(props.data?.pincode);
    },
  };

  return [
    srNoColumn(t),
    firstNameColumn,
    isActiveColumn(t),
    { ...actionColumn(t), width: 165, minWidth: 165 },
  ];
};
export default columnDefs;
