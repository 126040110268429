import React, { useCallback, useState } from "react";
import { Button, ModalProps } from "antd";
import CommonModal from "./CommonModal";
import { useAppDispatch } from "../../store/app";
import { useTranslation } from "react-i18next";

interface ChangeStatusModalProps extends ModalProps {
  titleName: string;
  close: any;
  data: any;
  callApi: any;
  keyName?: string | number
}

const ChangeStatusModal: React.FC<ChangeStatusModalProps> = ({
  titleName,
  close,
  data,
  callApi,
  keyName,
  ...rest
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [saving, setSaving] = useState<boolean>(false);

  const onYesClick = useCallback(() => {
    setSaving(true);
    const isActive = data?.is_active === 1 ? "0" : "1"
    dispatch(callApi(data?.id, isActive))
      .then(() => {
        close();
      })
      .finally(() => setSaving(false))
      .catch((e: any) => e);
  }, [data]);

  return data ? (
    <CommonModal
      className="deleteModal statusModal"
      title={`${titleName} ${data?.is_active ? `${t("GENERAL.DEACTIVE")}` : `${t("GENERAL.ACTIVATE")}`}?`}
      open={data ? true : false}
      onCancel={close}
      footer={[
        <Button key="2" htmlType="button" className="cancelBtn" onClick={close}>
          {t("GENERAL.NO")}
        </Button>,
        <Button
          key="1"
          htmlType="button"
          type="primary"
          loading={saving}
          onClick={onYesClick}
        >
          {t("GENERAL.YES")}
        </Button>,
      ]}
      {...rest}
    >
      <div className="deleteNote">
        {t("GENERAL.ARE_YOU_SURE_WANT_TO_STATUS_MODEL_MESSAGE")} {data?.is_active ? `${t("GENERAL.DEACTIVE")}` : `${t("GENERAL.ACTIVATE")}`} <span>{data && data[`${keyName}`]}?</span>
      </div>
    </CommonModal>
  ) : (
    <></>
  );
};
export default ChangeStatusModal;
