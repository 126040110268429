import { ColDef } from "ag-grid-community";
import { renderNA, setDateFilterDefs } from "../../../../../components/AgGridWrapper/utils/commonFunctions";
import { srNoColumn, actionColumn } from "../../../../../components/AgGridWrapper/utils/commonColumns";
import { GrantStatusList, GrantStatusListDisplay, dateFormatter } from "../../../../../config/Global";
import { useTranslation } from "react-i18next";
const columnDefs = (): ColDef[] => {
	const { t } = useTranslation();
	const entry_dateColumn: ColDef = {
		field: "entry_date",
		headerName: `${t("GENERAL.DATE_OF_GRANT")}`,
		sortable: true,
		width: 200,
		minWidth: 200,
		cellRenderer: (props: any) => {
			return props.data?.entry_date ? dateFormatter(props.data?.entry_date) : "N/A";
		},
		...setDateFilterDefs()
	};
	const scheme_nameColumn: ColDef = {
		field: "scheme_name",
		headerName: `${t("GENERAL.NAME_OF_SHCEME")}`,
		cellRenderer: (props: any) => {
			return renderNA(props.data?.scheme_name);
		},
	};
	const taluka_nameColumn: ColDef = {
		field: "taluka_name",
		headerName: `${t("GENERAL.TALUKA")}`,
		cellRenderer: (props: any) => {
			return renderNA(props.data?.taluka_name);
		},
	};
	const villages_nameColumn: ColDef = {
		field: "village_name",
		headerName: `${t("GENERAL.VILLAGES")}`,
		cellRenderer: (props: any) => {
			return renderNA(props.data?.village_name);
		},
	};
	const amountColumn: ColDef = {
		field: "amount",
		headerName: `${t("MLAGRANTMANAGEMENT.AMOUNT")}`,
		cellRenderer: (props: any) => {
			return renderNA(props.data?.amount);
		},
		filter: "agNumberColumnFilter",
	};
	const statusColumn: ColDef = {
		field: "status",
		headerName: `${t("MLAGRANTMANAGEMENT.STATUS")}`,
		cellRenderer: (props: any) => {
			return renderNA(GrantStatusListDisplay[props.data?.status]);
		},
		filter: "agSetColumnFilter",
		filterParams: {
			values: (params: any) => {
				if (GrantStatusList) {
					params.success(GrantStatusList.map(x => x.name));
				}
			},
			buttons: ["apply", "reset"],
			closeOnApply: true,
		},
	};
	return [
		srNoColumn(t),
		villages_nameColumn,
		taluka_nameColumn,
		scheme_nameColumn,
		amountColumn,
		entry_dateColumn,
		statusColumn,
		{ ...actionColumn(t), width: 140, minWidth: 140 },
	]
}
export default columnDefs