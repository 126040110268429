import { ColDef } from "ag-grid-community";
import { renderNA, setDateFilterDefs } from "../../../../../components/AgGridWrapper/utils/commonFunctions";
import { actionColumn, srNoColumn } from "../../../../../components/AgGridWrapper/utils/commonColumns";
import { dateFormatter,
  //  meetingDisplay
   } from "../../../../../config/Global";
import { useTranslation } from "react-i18next";


const columnDefs = (): ColDef[] => {
  const { t } = useTranslation();
  const typeColumn: ColDef = {
    field: "type",
    headerName: `${t("CITIZENMANAGEMENT.TYPE_OF_MEETING")}`,
    cellRenderer: (props: any) => {
      return renderNA(props.data?.type);
    },
  };
  const dateOfEntryColumn: ColDef = {
    field: "date",
    headerName: `${t("GENERAL.DATE_OF_ENTRY")}`,
    sortable: true,
    width: 200,
    minWidth: 200,
    cellRenderer: (props: any) => {
      return props.data?.date ? dateFormatter(props.data.date) : "N/A";
    },
    ...setDateFilterDefs()
  };
  // const statusColumn: ColDef = {
  //   field: "status",
  //   headerName: `${t("CITIZENMANAGEMENT.ACTIVITY_STATUS")}`,
  //   cellRenderer: (props: any) => {
  //     return renderNA(meetingDisplay[props.data?.status]);
  //   },
  // };

  return [srNoColumn(t), typeColumn, dateOfEntryColumn, actionColumn(t)];
};

export default columnDefs;
