import { Button, ButtonProps } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const SaveButton: React.FC<ButtonProps> = ({
  disabled,
  loading,
  ...rest
}) => {
  const { t } = useTranslation();
  return (
    <Button
      disabled={disabled}
      form="form"
      loading={loading}
      htmlType="submit"
      type="primary"
      {...rest}
    >
      {t("GENERAL.SAVE_BUTTON")}
    </Button>
  );
};

export default SaveButton;
