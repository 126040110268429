import React from "react";
import { Button } from "antd";
import { ButtonProps } from "../../Buttons/interface/ButtonInterface";
import { checkPrivileges } from "../../../config/Global";
import { useAppSelector } from "../../../store/app";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const CreateButton: React.FC<ButtonProps> = ({
  action,
  permissionKey,
  permission,
}) => {
  const { t } = useTranslation();
  const userDetail = useAppSelector(state => state.auth.userDetail);

  const permitted = permissionKey
    ? checkPrivileges(userDetail, permissionKey)
    : permission;

  return permitted ? (
    <Button className="primaryBtn" onClick={action}>
      {t("GENERAL.CREATE_BUTTON")} <PlusOutlined />
    </Button>
  ) : (
    <></>
  );
};

export default CreateButton;
