
import { mobileValidation } from "../Global";
import { Messages } from "../Messages";
import { maxName, maxPassword, minName, minPassword, validations } from "./validations";

export const formRules: any = {
  name: (min?: number, max?: number, field?: string) => [
    validations.required.text(field),
    validations.min.text(minName, field),
    validations.max.text(maxName, field),
  ],
  phone: (field?: string) => [
    validations.required.text(field),
  ],
  mobileNumber: (field?: string) => [
    validations.required.text(field),
    validations.pattern.mobile(),
    validations.min.text(10),
    validations.max.text(10),
    () => ({
      validator(value: any, rule: any) {
        if (rule && rule.length >= 10) {
          var validate = mobileValidation(rule);
          if (!validate) {
            return Promise.reject(Messages.MOBILE_INVALID);
          }
        }
        return Promise.resolve();
      },
    }),
  ],
  whatsNumber: () => [
    validations.pattern.mobile(),
    validations.min.text(10),
    validations.max.text(10),
    () => ({
      validator(value: any, rule: any) {
        if (rule && rule.length >= 10) {
          var validate = mobileValidation(rule);
          if (!validate) {
            return Promise.reject(Messages.MOBILE_INVALID);
          }
        }
        return Promise.resolve();
      },
    }),
  ],
  email: (field?: string) => [
    validations.required.text(field),
    validations.email(field),
  ],
  password: (min = minPassword, max = maxPassword, field?: string) => [
    validations.required.text(field),
    validations.pattern.password(field),
    validations.min.text(min),
    validations.max.text(max),
  ],
};
