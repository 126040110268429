import React from 'react'
import CommonModal from '../../../../components/Modals/CommonModal'
import { useTranslation } from 'react-i18next';
import { Button, ModalProps } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

interface DeleteModalProps extends ModalProps {
    title?: string;
    close: any;
    open: boolean;
    msg?: string;
}

const ErrorModel: React.FC<DeleteModalProps> = ({
    title,
    close,
    open,
    msg
}) => {
    const { t } = useTranslation();
    return (
        <CommonModal
            className="deleteModal warningTitle"
            title={`${title}`}
            open={open}
            onCancel={close}
            footer={[
                    <Button
                        key="1"
                        htmlType="button"
                        type="primary"
                        onClick={() => close()}
                    >
                        {t("GENERAL.Ok_button")}
                    </Button>
            ]}
        >
            <div className="deleteNote">
                {<FontAwesomeIcon className="" icon={faExclamationTriangle} />}{" "} {msg}
            </div>
        </CommonModal>
    )
}

export default ErrorModel