import React, { useEffect, useState } from 'react'
import { FormBox, InputBox } from '../../../../../components/AntdAddons';
import { Button, Checkbox, Col, Row, Spin } from 'antd';
import { rules } from './rules';
import { useAppDispatch, useAppSelector } from '../../../../../store/app';
import { Messages } from '../../../../../config/Messages';
import { EntryFromOptions, genderOptions, villageType } from '../../../../../config/Global';
import { fetchPincodeList, setPincodeList } from '../../../Administration/PincodeManagement/utils/slice';
import { fetchTalukaList, setTalukaList } from '../../../Administration/TalukaManagement/utils/slice';
import { fetchVillageList, setVillageList } from '../../../Administration/VillageVsWard/utils/slice';
import { useTranslation } from 'react-i18next';
import NewPincode from './NewPincode';
import { CONSTANT } from '../../../../../config/Constant';
import NewTaluka from './NewTaluka';
import NewVillage from './NewVillage';
import dayjs from "dayjs"
import moment from 'moment';

const FormComponent: React.FC<any> = ({
    form,
    id,
    handleSubmit,
    onValuesChange,
    editValues,
    fetchpincode,
    setFetchPincode,
    fetchTaluka,
    setFetchTaluka,
    fetchVillage,
    setFetchVillage,
    disabled,
    saving,
    drawerClose,
    disabledFields,
}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch()
    const [sameAsWhatappNo, setsameAsWhatappNo] = useState<boolean>(false)
    const [checkDisable, setcheckDisable] = useState<boolean>(false)
    const [newDrawer, setDrawer] = useState<string>("")
    const [showPincode, setshowPincode] = useState<boolean>(false)
    const [showTaluka, setshowTaluka] = useState<boolean>(false)
    const [showVillage, setshowVillage] = useState<boolean>(false)
    const is_active = 1
    const { villageList } = useAppSelector(
        state => state.village
    );
    const { pincodeList } = useAppSelector(
        state => state.pincode
    )
    const { talukaList } = useAppSelector(
        state => state.taluka
    )

    // useEffect(() => {
    //     dispatch(fetchPincodeList()).catch((e: any) => e)
    //     dispatch(fatchTalukaList()).catch((e: any) => e)
    // }, [])




    useEffect(() => {
        if (editValues) {
            dispatch(fetchPincodeList({ is_active: is_active })).catch((e: any) => e)
            dispatch(fetchTalukaList({ is_active: is_active })).catch((e: any) => e)
            dispatch(fetchVillageList({ is_active: is_active })).catch((e: any) => e)
            if (editValues?.taluka) {
                dispatch(setTalukaList([editValues.taluka]))
            }
            if (editValues?.pincode) {
                dispatch(setPincodeList([editValues.pincode]))
            }
            if (editValues?.village) {
                dispatch(setVillageList([editValues.village]))
            }
            // if (editValues?.gender) {
            //     setFetchGender(editValues.gender)
            // }
            // if (editValues?.village_type_id) {
            //     setFetchVillageType(editValues.village_type_id)
            // }

            form.setFieldsValue({
                ...editValues,
                dob: editValues?.dob && dayjs(editValues?.dob)
            });
        }
    }, [editValues, form]);
    const onFinish = (data: any) => {
        if (data?.dob) {
            data.dob = dayjs(data?.dob).format(CONSTANT.POST_DATE_FORMAT)
        }
        handleSubmit(data);
    };

    const handleChangePhone = (changedValues: any) => {
        if (sameAsWhatappNo && changedValues) {
            form.setFieldsValue({ whatsapp_number: changedValues });
        }
    };

    const newDrawerOpen = (value: any) => {
        setDrawer(value)
    }
    const handleSearch = (value: any) => {
        let filteredOptions: any = [];
        if (value) {
            form.setFieldsValue({
                taluka_id: null,
                village_id: null,
            });
            dispatch(setTalukaList([]))
            dispatch(setVillageList([]))
            setFetchTaluka(true)
            filteredOptions = pincodeList && pincodeList.filter((option: any) =>
                option.pincode.includes(value)
            );
            if (!filteredOptions || filteredOptions?.length === 0) {
                form.setFieldValue("pincode_id", null);
                setshowPincode(true)
                setshowTaluka(true)
                setshowVillage(true)
            } else {
                setshowPincode(false)
            }
        }
    };

    const handleTalukaSearch = (value: any) => {
        let filteredOptions: any = [];
        if (value) {
            form.setFieldsValue({
                village_id: null,
            });
            dispatch(setVillageList([]));
            setFetchVillage(true);
            filteredOptions = talukaList && talukaList.filter((option: any) =>
                option.name?.toLowerCase().includes(value?.toLowerCase())
            );
            if (filteredOptions && filteredOptions?.length === 0) {
                form.setFieldValue("taluka_id", null);
                setshowTaluka(true)
            } else {
                setshowTaluka(false)
            }
        }
    };
    const handleVillageSearch = (value: any) => {
        let filteredOptions: any = [];
        if (value) {
            dispatch(setVillageList([]));
            setFetchVillage(true);
            filteredOptions = talukaList && talukaList.filter((option: any) =>
                option.name?.toLowerCase().includes(value?.toLowerCase())
            );
            if (filteredOptions && filteredOptions?.length === 0) {
                form.setFieldValue("village_id", null);
                setshowVillage(true)
            } else {
                setshowVillage(false)
            }
        }
    };
    const newDrawerClose = () => {
        setFetchPincode(true)
        setshowPincode(false)
        setDrawer("")
    }
    const newDrawerTalukaClose = () => {
        setFetchTaluka(true)
        setshowTaluka(false)
        setDrawer("")
    }
    const newDrawerVillageClose = () => {
        setFetchVillage(true)
        setshowVillage(false)
        setDrawer("")
    }

    const minDate = dayjs().subtract(10, "years");
    const disablePreviousDates = (current: any) => {
        return (current && current > moment().utcOffset(CONSTANT.UTCOFFSET).subtract(10, "years").startOf("day"))
        // return current && current > minDate;
    };
    return (
        <>

            <FormBox
                form={form}
                id={id}
                onFinish={onFinish}
                onValuesChange={onValuesChange}
            >
                <Row gutter={15}>
                    <Col sm={24} md={12} xxl={12}  >
                        <InputBox.Text
                            required
                            label={`${t("CITIZENMANAGEMENT.FIRST_NAME")}`}
                            name="first_name"
                            placeholder={`${t("CITIZENMANAGEMENT.FIRST_NAME")}`}
                            disabled={disabledFields}
                            rules={rules.name()}
                        />
                    </Col>
                    <Col sm={24} md={12} xxl={12}  >
                        <InputBox.Text
                            label={`${t("CITIZENMANAGEMENT.MIDDLE_NAME")}`}
                            name="middle_name"
                            placeholder={`${t("CITIZENMANAGEMENT.MIDDLE_NAME")}`}
                            disabled={disabledFields}
                            rules={rules.name()}
                        />
                    </Col>
                    <Col sm={24} md={12} xxl={12}  >
                        <InputBox.Text
                            required
                            label={`${t("CITIZENMANAGEMENT.LAST_NAME")}`}
                            name="last_name"
                            placeholder={`${t("CITIZENMANAGEMENT.LAST_NAME")}`}
                            disabled={disabledFields}
                            rules={rules.name()}
                        />
                    </Col>
                    <Col sm={24} md={12} xxl={12}>
                        <InputBox.Text
                            name="email"
                            label={`${t("GENERAL.EMAIL_ID")}`}
                            placeholder={`${t("GENERAL.EMAIL_ID_PLACEHOLDER")}`}
                            disabled={disabledFields}
                            rules={rules.email()}
                        />
                    </Col>
                </Row>
                <Row gutter={15}>
                    <Col sm={24} md={12} xxl={12}  >
                        <InputBox.DatePicker
                            allowClear={false}
                            disabled={disabledFields}
                            name="dob"
                            label={t("GENERAL.DATE_OF_BIRTH")}
                            placeholder={t("GENERAL.DATE_OF_BIRTH")}
                            defaultPickerValue={minDate}
                            disabledDate={disablePreviousDates}
                        />
                    </Col>
                    <Col sm={24} md={12} xxl={12} >
                        <InputBox.Select
                            required
                            name="gender"
                            label={`${t("GENERAL.GENDER")}`}
                            placeholder={`${t("GENERAL.GENDER")}`}
                            disabled={disabledFields}
                            options={{
                                list: genderOptions,
                                valueKey: "id",
                                textKey: "name",
                            }}
                            rules={rules.gender()}
                        />
                    </Col>
                    <Col sm={24} md={12} xxl={12} >
                        <InputBox.Text
                            required
                            label={`${t("GENERAL.MOBILE_NUMBER")}`}
                            name="phone"
                            addonBefore={"+91"}
                            placeholder="xxxxxxxxxxx"
                            disabled={disabledFields} // disabledFields || openFrom === "meetingComponent"
                            rules={rules.mobileNumber()}
                            maxLength={10}
                            onChange={(e) => {
                                form.validateFields(['phone']).then(() => {
                                    setcheckDisable(false)
                                    handleChangePhone(e.target.value)
                                }).catch(() => {
                                    setcheckDisable(true)
                                });
                            }}
                        />
                    </Col>
                    <Col sm={24} md={12} xxl={12} >
                        <InputBox.Text
                            label={`${t("CITIZENMANAGEMENT.WHATSAPP_NUMBER")}`}
                            name="whatsapp_number"
                            readOnly={sameAsWhatappNo}
                            addonBefore={"+91"}
                            placeholder="xxxxxxxxxxx"
                            disabled={disabledFields}
                            maxLength={10}
                            rules={rules.whatsAppNumber()}
                        />
                        <div className='citizenCheckBox'>
                            <Checkbox
                                name="sameAsWhatapp"
                                className='sameAsWhatapp'
                                onChange={(e) => {
                                    setsameAsWhatappNo(e.target.checked)
                                    if (e.target.checked) {
                                        const getMobileNumber = form.getFieldValue("phone")
                                        form.setFieldsValue({ whatsapp_number: getMobileNumber })
                                    }
                                }}
                                disabled={checkDisable || disabledFields}
                            >
                                {`${t("CITIZENMANAGEMENT.Same_as_a_MobileNumber")}`}
                            </Checkbox>
                        </div>
                    </Col>

                </Row>
                <Row gutter={15}>
                    <Col sm={24} md={12} xxl={12} >
                        <InputBox.Select
                            required
                            allowClear
                            showSearch
                            name="pincode_id"
                            optionFilterProp="children"
                            label={`${t("GENERAL.PINCODES")}`}
                            placeholder={`${t("GENERAL.PINCODES")}`}
                            onSearch={handleSearch}
                            disabled={disabledFields}
                            onFocus={() => {
                                fetchpincode &&
                                    dispatch(fetchPincodeList({ is_active: is_active }))
                                        .then(() => setFetchPincode(false))
                                        .catch((e: any) => e)
                                        .finally(() => setFetchPincode(false))
                            }}
                            onChange={(e) => {
                                if (e) {
                                    setshowPincode(false)
                                    setshowTaluka(false)
                                    setshowVillage(false)
                                }
                                form.setFieldsValue({
                                    taluka_id: null,
                                    village_id: null,
                                });
                                dispatch(setTalukaList([]))
                                dispatch(setVillageList([]))
                                setFetchTaluka(true)
                            }}
                            notFoundContent={
                                fetchpincode ? <Spin size="small" /> : Messages.NO_RECORD_FOUND
                            }
                            options={{
                                list: pincodeList,
                                valueKey: "id",
                                textKey: "pincode",
                            }}
                            rules={rules.pincode()}
                        />
                        <div className='citizenNewBtn'>
                            {showPincode && <Button
                                onClick={() => newDrawerOpen(CONSTANT.DRAWER_TYPE.NEWPINCODE)}
                            >
                                {`${t("GENERAL.ADD_DRAWER_TITLE")}`} {`${t("PINCODEMANAGEMENT.PINCODES")}`}
                            </Button>}
                        </div>
                    </Col>
                    <Col sm={24} md={12} xxl={12} >
                        <InputBox.Select
                            required
                            showSearch
                            allowClear
                            name="taluka_id"
                            optionFilterProp="children"
                            onSearch={handleTalukaSearch}
                            label={`${t("GENERAL.TALUKA")}`}
                            placeholder={`${t("GENERAL.TALUKA")}`}
                            disabled={disabledFields}
                            onFocus={() => {
                                fetchTaluka && form.getFieldValue("pincode_id") !== (undefined || null) &&
                                    dispatch(fetchTalukaList({ pincode_id: form.getFieldValue("pincode_id"), is_active: is_active }))
                                        .then((res: any) => {
                                            const newTalukaBtn = res?.data
                                            if (newTalukaBtn?.length === 0) {
                                                setshowTaluka(true)
                                            }
                                            setFetchTaluka(false)
                                        })
                                        .catch((e: any) => e)
                                        .finally(() => setFetchTaluka(false))
                            }}
                            notFoundContent={fetchTaluka ? <Spin size="small" /> : Messages.NO_RECORD_FOUND}
                            onChange={(e) => {
                                if (e) {
                                    setshowTaluka(false)
                                }
                                form.setFieldsValue({
                                    village_id: null,
                                });
                                dispatch(setVillageList([]));
                                setFetchVillage(true);
                            }}
                            options={{
                                list: talukaList,
                                valueKey: "id",
                                textKey: "name",
                            }}
                            rules={rules.taluka()}
                        />
                        <div className='citizenNewBtn'>
                            {showTaluka && <Button onClick={() => newDrawerOpen(CONSTANT.DRAWER_TYPE.NEWTALUKA)}>
                                {`${t("GENERAL.ADD_DRAWER_TITLE")}`} {`${t("TALUKAMANAGEMENT.TALUKA")}`}
                            </Button>}
                        </div>
                    </Col>
                    <Col sm={24} md={12} xxl={12} >
                        <InputBox.Select
                            required
                            allowClear
                            showSearch
                            name="village_id"
                            optionFilterProp="children"
                            onSearch={handleVillageSearch}
                            label={`${t("GENERAL.VILLAGES")}`}
                            placeholder={`${t("GENERAL.VILLAGES")}`}
                            disabled={disabledFields}
                            onFocus={() => {
                                fetchVillage && form.getFieldValue("taluka_id") &&
                                    dispatch(fetchVillageList({ taluka_id: form.getFieldValue("taluka_id"), is_active: is_active }))
                                        .then((res: any) => {
                                            const newVillage = res?.data
                                            if (newVillage?.length === 0) {
                                                setshowVillage(true)
                                            }
                                            setFetchVillage(false)
                                        })
                                        .catch((e: any) => e)
                                        .finally(() => setFetchVillage(false))
                            }}
                            notFoundContent={fetchVillage ? <Spin size="small" /> : Messages.NO_RECORD_FOUND}
                            options={{
                                list: villageList,
                                valueKey: "id",
                                textKey: "name",
                            }}
                            onChange={() => setshowVillage(false)}
                            rules={rules.village()}
                        />
                        <div className='citizenNewBtn'>
                            {showVillage && <Button onClick={() => newDrawerOpen(CONSTANT.DRAWER_TYPE.NEWVILLAGE)}>
                                {`${t("GENERAL.ADD_DRAWER_TITLE")}`} {`${t("GENERAL.VILLAGES")}`}
                            </Button>}
                        </div>
                    </Col>
                    <Col sm={24} md={12} xxl={12} >
                        <InputBox.Select
                            required
                            name="village_type_id"
                            label={`${t("GENERAL.NAGERPALLIKA_VILLAGE_TYPE")}`}
                            placeholder={`${t("GENERAL.NAGERPALLIKA_VILLAGE_TYPE")}`}
                            disabled={disabledFields}
                            options={{
                                list: villageType,
                                valueKey: "id",
                                textKey: "name",
                            }}
                            rules={rules.villageType()}
                        />
                    </Col>
                </Row>

                <Row gutter={15}>
                    <Col sm={24} md={12} xxl={12} >
                        <InputBox.Select
                            name="entry_from"
                            label={`${t("CITIZENMANAGEMENT.Entry_from")}`}
                            placeholder={`${t("CITIZENMANAGEMENT.Entry_from")}`}
                            disabled={disabledFields}
                            options={{
                                list: EntryFromOptions,
                                valueKey: "id",
                                textKey: "name",
                            }}
                            // rules={rules.gender()}
                        />
                    </Col>
                    <Col xs={{ span: 24 }} >
                        <InputBox.Text
                            required
                            label={`${t("GENERAL.ADDRESS")}`}
                            name="address"
                            placeholder={`${t("GENERAL.ADDRESS")}`}
                            disabled={disabledFields}
                            rules={rules.address()}
                        />
                    </Col>
                </Row>
            </FormBox>
            {/* newpincode */}
            <NewPincode
                titleName={t("PINCODEMANAGEMENT.PINCODES")}
                open={newDrawer === CONSTANT.DRAWER_TYPE.NEWPINCODE}
                close={() => newDrawerClose()}
            />
            {/* NewTaluka */}
            <NewTaluka
                titleName={t("TALUKAMANAGEMENT.TALUKA")}
                open={newDrawer === CONSTANT.DRAWER_TYPE.NEWTALUKA}
                close={() => newDrawerTalukaClose()}
                pincodeId={form.getFieldValue("pincode_id")}
            />
            {/* NewVillage */}
            <NewVillage
                titleName={t("VILLAGEVSWORDMANAGEMENT.DRAWER_TYPE_ADD_TITLE")}
                open={newDrawer === CONSTANT.DRAWER_TYPE.NEWVILLAGE}
                close={() => newDrawerVillageClose()}
                pincodeId={form.getFieldValue("pincode_id")}
                talukaId={form.getFieldValue("taluka_id")}
            />
            {!disabledFields &&
                <div className='citizenFormBtn'>
                    <Button
                        key="1"
                        disabled={disabled}
                        form={id}
                        loading={saving}
                        htmlType="submit"
                        type="primary"
                    >
                        {t("GENERAL.SAVE_BUTTON")}
                    </Button>
                    <Button
                        key="2"
                        htmlType="button"
                        className="cancelBtn"
                        onClick={drawerClose}
                    >
                        {t("GENERAL.CANCEL_BUTTON")}
                    </Button>
                </div>
            }
        </>

    )
}

export default FormComponent