import { Drawer, DrawerProps, Form } from 'antd';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../store/app';
import { validateFields } from '../../../../config/Global';
import { updateRecord } from '../utils/slice';
import { assignErrorToInput } from '../../../../store/api';
import SVGIcon from '../../../../utils/SVGIcon';
import Config from '../../../../config/Config';
import FormComponent from './FormComponent';

interface EditDrawerProps extends DrawerProps {
    titleName: string;
    open: boolean;
    close: () => void;
}
const EditComponent: React.FC<EditDrawerProps> = ({
    titleName,
    open,
    close,
    ...rest
}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const details = useAppSelector(state => state.GovernmentNonGovermentContact.details);
    const [form] = Form.useForm();
    const [disabled, setDisabled] = useState<boolean>(true);
    const [saving, setSaving] = useState<boolean>(false);

    const drawerClose = () => {
        close();
        form.resetFields();
        setDisabled(true);
    };

    const validateForm = () => {
        validateFields(form, setDisabled);
    };

    const handleSubmit = (data: any) => {
        setSaving(true);
        dispatch(updateRecord(details.id, data))
            .then(() => {
                drawerClose();
            })
            .catch((error: any) => {
                assignErrorToInput(form, error?.STATUS);
            })
            .finally(() => setSaving(false));
    };
    return (
        <>
            <Drawer
                title={`${titleName} ${t("GENERAL.EDIT__DRAWER_TITLE")}`}
                open={open}
                width={"70%"}
                onClose={drawerClose}
                destroyOnClose
                closeIcon={<SVGIcon icon="close" color={Config.themePrimaryBtn} />}
                className="mlaGrantForm"
                {...rest}
            >
                <FormComponent
                    form={form}
                    id="editForm"
                    editValues={details}
                    handleSubmit={handleSubmit}
                    onValuesChange={validateForm}
                    disabled={disabled}
                    saving={saving}
                    drawerClose={drawerClose}
                    setDisabled={setDisabled}
                />
            </Drawer>
        </>
    )
}
export default EditComponent