import { BreadcrumbConfigProps } from "./InterfacesAndTypes";


export const defaultBreadcrumbPath = (t :any) => [{ name: t("BREADCRUMCONFIG.DASHBOARD"), link: "/" }];
export const administratorBreadcrumbPath = (t :any) => [{ name: `${t("BREADCRUMCONFIG.ADMINISTRATOR")}` }]

export const UserBreadcrumb = (t: any): BreadcrumbConfigProps => ({
  title: "Users Management",
  path: [...defaultBreadcrumbPath(t), ...administratorBreadcrumbPath(t), { name: `${t("BREADCRUMCONFIG.USER_MANAGEMENT")}` }],
});

export const RoleBreadcrumb = (t: any): BreadcrumbConfigProps => ({
  title: "Roles Management",
  path: [...defaultBreadcrumbPath(t), ...administratorBreadcrumbPath(t), { name: `${t("BREADCRUMCONFIG.ROLES")}` }],
});

export const DashboardBreadcrumb = (t: any): BreadcrumbConfigProps => ({
  title: "Dashboard",
  path: [...defaultBreadcrumbPath(t)],
});
export const PincodeBreadcrumb = (t: any): BreadcrumbConfigProps => ({
  title: "Pincodes Management",
  path: [...defaultBreadcrumbPath(t), ...administratorBreadcrumbPath(t), { name: `${t("BREADCRUMCONFIG.PINCODE_MANAGEMENT")}` }],
});
export const TalukaBreadcrumb = (t: any): BreadcrumbConfigProps => ({
  title: "Talukas Management",
  path: [...defaultBreadcrumbPath(t), ...administratorBreadcrumbPath(t), { name: `${t("BREADCRUMCONFIG.TALUKA_MANAGEMENT")}` }],
});
export const VillageBreadcrumb = (t: any): BreadcrumbConfigProps => ({
  title: "Villages Management",
  path: [...defaultBreadcrumbPath(t), ...administratorBreadcrumbPath(t), { name: `${t("BREADCRUMCONFIG.VILLAGE_MANAGEMENT")}` }],
});
export const VillageDetailBreadcrumb = (t: any): BreadcrumbConfigProps => ({
  title: "Villages Details Management",
  path: [...defaultBreadcrumbPath(t), ...administratorBreadcrumbPath(t), { name: `${t("BREADCRUMCONFIG.VILLAGE__DETAIL_MANAGEMENT")}` }],
});
export const CitizenBreadcrumb = (t: any): BreadcrumbConfigProps => ({
  title: "Citizens Register",
  path: [...defaultBreadcrumbPath(t), { name: `${t("BREADCRUMCONFIG.CITIZENS_REGISTER")}` }],
});
export const MLACalenderBreadcrumb = (t: any): BreadcrumbConfigProps => ({
  title: "MLA Calender",
  path: [...defaultBreadcrumbPath(t), { name: `${t("BREADCRUMCONFIG.MLA_CALENDER")}` }],
});
export const ActivityBreadcrumb = (t: any): BreadcrumbConfigProps => ({
  title: "Activity",
  // path: [...defaultBreadcrumbPath(t), { name: "Activity" }],
  path: [
    ...defaultBreadcrumbPath(t),
    // { name: `${t("BREADCRUMCONFIG.CITIZENS_REGISTER")}`, link: "/citizens" },
    { name: `${t("BREADCRUMCONFIG.CitizenMeeting")}`, link: "/citizen-meetings" },
    {
      name: `${t("BREADCRUMCONFIG.ACTIVITY")}`,
    },

  ],
});
export const CitizenMeetingBreadcrumb = (t: any): BreadcrumbConfigProps => ({
  title: "Citizens Meeting",
  path: [...defaultBreadcrumbPath(t), { name: `${t("BREADCRUMCONFIG.CitizenMeeting")}` }],
});
export const MLAGrantBreadcrumb = (t: any): BreadcrumbConfigProps => ({
  title: "MLA Grants Management",
  path: [...defaultBreadcrumbPath(t), { name: `${t("BREADCRUMCONFIG.MLA_GRANTS_MANAGEMEN")}` }],
});
export const SchemeBreadcrumb = (t: any): BreadcrumbConfigProps => ({
  title: "Schemes Management",
  path: [...defaultBreadcrumbPath(t), ...administratorBreadcrumbPath(t), { name: `${t("BREADCRUMCONFIG.SCHEMES_MANAGEMENT")}` }],
});
export const ReportBreadcrumb = (t: any): BreadcrumbConfigProps => ({
  title: "Reports",
  path: [...defaultBreadcrumbPath(t), { name: `${t("BREADCRUMCONFIG.REPORT")}` }],
});
export const GovernmentNonGovermentContactBreadcrumb = (t: any): BreadcrumbConfigProps => ({
  title: "Government & Non Government Contact Form",
  path: [...defaultBreadcrumbPath(t), { name: `${t("BREADCRUMCONFIG.GOVERNMENTN_ONGOVERMENT_CONTACT_FORM")}` }],
});
