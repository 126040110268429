import React, { useState } from 'react'
import { FormBox, InputBox } from '../../../../../components/AntdAddons';
import { Button, Col, Row, Upload, message } from 'antd';
import { rules } from './rules';
import { CONSTANT } from '../../../../../config/Constant';
import CommonModal from '../../../../../components/Modals/CommonModal';
import { assignErrorToInput } from '../../../../../store/api';
import { deleteMeetingCommentAttachment } from '../../../CitizenManagement/utils/meetingSlice';
import { useAppDispatch } from '../../../../../store/app';
import { useTranslation } from 'react-i18next';

const FormComponent: React.FC<any> = ({
    form,
    id,
    handleSubmit,
    onValuesChange,
}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch()
    const [deleteAttachment, setDeleteAttachment] = useState<any>(false);
    const [deleteAttachmentSaving, setDeleteAttachmentSaving] = useState<boolean>(false);
    const onFinish = (data: any) => {
        if (data.date_range) {
            data.from_date = data.date_range[0].format(CONSTANT.POST_DATE_FULL_TIME_FORMAT);
            data.to_date = data.date_range[1].format(CONSTANT.POST_DATE_FULL_TIME_FORMAT);
        }
        handleSubmit(data)
    };

    const checkFileType = (file: any) => {
        const isLt5M = file.size / 1024 / 1024 < 1;
        const acceptedFormats = [
            "application/pdf",
            "image/png",
            "image/jpeg",
            "image/jpg",
        ];
        if (!acceptedFormats.includes(file.type)) {
            message.error(CONSTANT.INVALID_FILE);
            form.setFields([{ name: "attachment", errors: [CONSTANT.INVALID_FILE] }]);
            return Upload.LIST_IGNORE;
        }
        if (!isLt5M) {
            message.error(CONSTANT.BIGGER_SIZE);
            form.setFields([{ name: "attachment", errors: [CONSTANT.BIGGER_SIZE] }]);
            return Upload.LIST_IGNORE;
        } else {
            return false;
        }
    };
    const closeDeletAttachmentModal = () => {
        setDeleteAttachment(false)
    }
    const closeAttachmentDelete = () => {
        setDeleteAttachment(false)
    }
    const openAttachmentDelete = (data: any) => {
        setDeleteAttachment(data)
        return false
    }
    const handleRemoveImage = () => {
        if (!deleteAttachment.id) {
            const removedImage = form.getFieldValue("attachment").filter((item: any) => item?.uid !== deleteAttachment.uid)
            form.setFieldsValue({
                attachment: removedImage
            })
            closeAttachmentDelete()
        } else {
            setDeleteAttachmentSaving(true);
            const removedImage = form.getFieldValue("attachment").filter((item: any) => item?.id !== deleteAttachment.id)
            dispatch(deleteMeetingCommentAttachment(deleteAttachment.id))
                .then(() => {
                    setDeleteAttachmentSaving(false)
                    form.setFieldsValue({
                        attachment: removedImage
                    })
                    closeAttachmentDelete()
                }).catch((error: any) => {
                    assignErrorToInput(form, error?.STATUS);
                    setDeleteAttachmentSaving(false)
                })
                .finally(() => setDeleteAttachmentSaving(false));
        }
    }
    return (
        <>

            <FormBox
                form={form}
                id={id}
                onFinish={onFinish}
                onValuesChange={onValuesChange}
            >
                <Row gutter={15}>
                    <Col sm={24} md={12} xxl={12}>
                        <InputBox.Text
                            label={t("GENERAL.NAME")}
                            name="name"
                            rules={rules.name()}
                        />
                    </Col>
                    <Col sm={24} md={12} xxl={12}>
                        <InputBox.Text
                            label={t("GENERAL.MOBILE_NUMBER")}
                            name="mobile_no"
                            addonBefore={"+91"}
                            placeholder="xxxxxxxxxx"
                            rules={rules.mobileNumber()}
                            maxLength={10}
                        />
                    </Col>
                    <Col sm={24} md={12} xxl={12} >
                        <InputBox.Text
                            required
                            label={t("MLAMEETINGMANAGEMENT.TYPE_OF_VISIT")}
                            name="visit_type"
                            placeholder={t("MLAMEETINGMANAGEMENT.TYPE_OF_VISIT")}
                            rules={rules.visit_type()}
                        />
                    </Col>
                    <Col sm={24} md={12} xxl={12} >
                        <InputBox.Text
                            name="detail"
                            label={t("MLAMEETINGMANAGEMENT.VISIT_DETAIL")}
                            placeholder={t("GENERAL.MEETING_DETAILS")}
                            rules={rules.detail()}
                        />
                    </Col>
                    <Col sm={24} md={12} xxl={12} >
                        <InputBox.DateRangePicker
                            required
                            label={`${t("MLAMEETINGMANAGEMENT.DATE_OF_VISIT")}`}
                            name="date_range"
                            format={CONSTANT.DATE_RANGE_TIME_FORMAT}
                            allowClear={false}
                            showTime={{ format: "HH:mm A" }}
                            rules={rules.date_range()}
                        />
                    </Col>
                    <Col sm={12}>
                        <InputBox.Upload
                            required={false}
                            label={t("GENERAL.ATTECHMENT")}
                            name="attachment"
                            maxCount={5}
                            multiple={true}
                            listType="picture"
                            className="inputUpload"
                            fileList={form.getFieldValue("attachment")}
                            beforeUpload={checkFileType}
                            onRemove={openAttachmentDelete}
                        />
                    </Col>
                </Row>

                <Row gutter={15}>
                    <Col xs={{ span: 24 }} >
                        <InputBox.Text
                            required
                            label={t("MLAMEETINGMANAGEMENT.MEETING_PLACE")}
                            name="place"
                            placeholder={t("MLAMEETINGMANAGEMENT.MEETING_PLACE")}
                            rules={rules.place()}
                        />
                    </Col>
                </Row>
            </FormBox>

            <CommonModal
                className="deleteModal"
                title={`${t("GENERAL.ATTECHMENT")} ${t("GENERAL.DELETE__DRAWER_TITLE")}`}
                open={deleteAttachment}
                onCancel={closeDeletAttachmentModal}
                footer={[
                    <>
                        <Button
                            key="2"
                            htmlType="button"
                            className="cancelBtn"
                            onClick={closeAttachmentDelete}
                        >
                            {t("GENERAL.CLOSE_BUTTON")}
                        </Button>
                        <Button
                            key="1"
                            type="primary"
                            loading={deleteAttachmentSaving}
                            htmlType='submit'
                            onClick={handleRemoveImage}
                        >
                            {t("GENERAL.DELETE_BUTTON")}
                        </Button>
                    </>
                ]}
            >
                <div className="deleteNote">
                    {t("GENERAL.DELETE_MODEL_MESSAGE")} {t("GENERAL.ATTECHMENT")} ?
                </div>

            </CommonModal>
        </>
    )
}

export default FormComponent