import { Button, Col, Divider, Drawer, DrawerProps, Form, Row } from 'antd';
import React, { useEffect, useState } from 'react'
import { useAppDispatch } from '../../../../../../store/app';
import { validateFields } from '../../../../../../config/Global';
import { addVillageDetails, updateVillageDetails, viewVillageDetails } from '../../utils/slice';
import { assignErrorToInput } from '../../../../../../store/api';
import SVGIcon from '../../../../../../utils/SVGIcon';
import Config from '../../../../../../config/Config';
import { FormBox, InputBox } from '../../../../../../components/AntdAddons';
import { rules } from '../Form/rules';
import { CONSTANT } from '../../../../../../config/Constant';
import { useParams } from 'react-router-dom';
import { getVillagePartyFormList } from '../../../VillageVsWard/utils/slice';
import { useTranslation } from 'react-i18next';

interface VillageDetailsDrawerProps extends DrawerProps {
	titleName: string;
	saveAndNext: any;
	skip: any;
	villageId: any;
	dynamicSteps: any;
	villageDetailsId: any;
	close: () => void;
}
const VillageDetailsComponent: React.FC<VillageDetailsDrawerProps> = ({
	titleName,
	close,
	saveAndNext,
	// skip,
	villageId,
	dynamicSteps,
	villageDetailsId,
	...rest
}) => {
	const { t } = useTranslation();
	const { action } = useParams();
	const dispatch = useAppDispatch();
	const [form] = Form.useForm();
	const [disabled, setDisabled] = useState<boolean>(true);
	const [saving, setSaving] = useState<boolean>(false);
	const [submitForm, setSubmitForm] = useState<any>();
	const [editValues, setEditValues] = useState<any>();
	const [partyFields, setPartyFields] = useState<any>()
	const drawerClose = (submitForm = "") => {
		if (submitForm === "") {
			close()
			form.resetFields();
		}
		form.resetFields();
		setDisabled(true);
		setSaving(false);
		setEditValues(null)
	}
	const validateForm = () => {
		validateFields(form, setDisabled);
	};
	// const skipPage = () => {
	// 	skip(Number(dynamicSteps) + 1)
	// }

	const handleFormSubmit = (data: any) => {
		setSaving(true);
		data.village_id = villageId
		data.village_detail_id = villageDetailsId
		if (action === CONSTANT.DRAWER_TYPE.EDIT && editValues?.id) {
			dispatch(updateVillageDetails(editValues.id, data))
				.then(() => {
					if (submitForm === "saveAndNext") {
						drawerClose(submitForm);
						saveAndNext(CONSTANT.STEP.PARTY_WORKER)
					} else {
						drawerClose();
					}
				})
				.catch((error: any) => {
					assignErrorToInput(form, error?.STATUS);
				})
				.finally(() => setSaving(false));
		} else {
			dispatch(addVillageDetails(data))
				.then(() => {
					if (submitForm === "saveAndNext") {
						drawerClose(submitForm);
						saveAndNext(CONSTANT.STEP.PARTY_WORKER)
					} else {
						drawerClose();
					}
				})
				.catch((error: any) => {
					assignErrorToInput(form, error?.STATUS);
				})
				.finally(() => setSaving(false));

		}

	};
	const skipPage = () => {
		saveAndNext(CONSTANT.STEP.PARTY_WORKER)
		form.resetFields()
	}

	useEffect(() => {
		dispatch(getVillagePartyFormList())
			.then((data: any) => {
				setPartyFields(data.data)
			}).catch((e: any) => e)
		if (villageDetailsId && action === CONSTANT.DRAWER_TYPE.EDIT && dynamicSteps === CONSTANT.STEP.VILLAGE_DETAILS) {

			dispatch(viewVillageDetails(villageDetailsId))
				.then((data: any) => {
					setEditValues(data.data)
					form.setFieldsValue({
						...data.data,
					});
				}).catch((error: any) => error)
		}
	}, [dynamicSteps])

	return (
		<Drawer
			title={`${titleName} ${action === CONSTANT.DRAWER_TYPE.ADD ? `${t("GENERAL.ADD_DRAWER_TITLE")}` : `${t("GENERAL.EDIT__DRAWER_TITLE")}`}`}
			placement="right"
			width={"70%"}
			onClose={() => drawerClose()}
			destroyOnClose
			closeIcon={<SVGIcon icon="close" color={Config.themePrimaryBtn} />}
			footer={[
				<Button
					key="2"
					htmlType="button"
					className="cancelBtn"
					onClick={() => drawerClose()}
				>
					{t("GENERAL.CANCEL_BUTTON")}
				</Button>,
				<Button
					key="4"
					htmlType="button"
					type="primary"
					onClick={() => skipPage()}
				>
					{t("GENERAL.CONTINUE_BUTTON")}
				</Button>,
				<Button
					key="1"
					form="villageDetailsForm"
					disabled={disabled}
					loading={saving}
					htmlType="submit"
					type="primary"
					onClick={() => setSubmitForm("save")}
				>
					{t("GENERAL.SAVE_BUTTON")}
				</Button>,
				<Button
					key="3"
					disabled={disabled}
					form="villageDetailsForm"
					loading={saving}
					htmlType="submit"
					type="primary"
					onClick={() => setSubmitForm("saveAndNext")}
				>
					{t("GENERAL.SAVE_NEXT_BUTTON")}
				</Button>
			]}
			{...rest}
		>
			<FormBox
				form={form}
				id="villageDetailsForm"
				onValuesChange={validateForm}
				onFinish={handleFormSubmit}
			>
				<Row gutter={15}>
					<Col sm={24} md={12} xl={12} xxl={12}>
						<InputBox.Number
							required
							label={t("VILLAGEMANAGEMENT.POPULATION_OF_VILLAGE")}
							name="population"
							placeholder={t("VILLAGEMANAGEMENT.POPULATION_OF_VILLAGE")}
							rules={rules.population()}
							min={0}
						/>
					</Col>
				</Row>
				<Divider />
				<h3>{t("VILLAGEMANAGEMENT.VOTER_INFO_H3")}</h3>
				<Row gutter={15}>
					<Col sm={24} md={12} xl={6} xxl={6} >
						<InputBox.Number
							required
							label={t("VILLAGEMANAGEMENT.TOTAL_VOTER")}
							name="total_voter"
							placeholder={t("VILLAGEMANAGEMENT.TOTAL_VOTER")}
							rules={rules.voter()}
							min={0}
						/>
					</Col>
					<Col sm={24} md={12} xl={6} xxl={6} >
						<InputBox.Number
							required
							label={`${t("VILLAGEMANAGEMENT.TOTAL_VOTING")} (%)`}
							name="total_voting"
							placeholder={`${t("VILLAGEMANAGEMENT.TOTAL_VOTING")} (%)`}
							rules={rules.voting()}
							min={0}
							max={100}
						/>
					</Col>
					<Col sm={24} md={12} xl={6} xxl={6} >
						<InputBox.Number
							required
							label={`${t("VILLAGEMANAGEMENT.TOTAL_VOTING_CENTER")}`}
							name="total_voting_center"
							placeholder={`${t("VILLAGEMANAGEMENT.TOTAL_VOTING_CENTER")}`}
							rules={rules.population()}
							min={0}
						/>
					</Col>
					<Col sm={24} md={12} xl={6} xxl={6} >
						<InputBox.Number
							required
							label={`${t("VILLAGEMANAGEMENT.TOTAL_BOOTH")}`}
							name="total_booth"
							placeholder={`${t("VILLAGEMANAGEMENT.TOTAL_BOOTH")}`}
							rules={rules.population()}
							min={0}
						/>
					</Col>
				</Row>
				<Divider />
				<h3>{t("VILLAGEMANAGEMENT.VOTING_RESULTS_PARTY_WISE_H3")}</h3>
				<Row gutter={15}>
					{/* <Col sm={24} md={12} xl={6} xxl={6} >
						<InputBox.Number
							required
							label={`${GENERAL.BJP} (%)`}
							name="bjp"
							placeholder={`${GENERAL.BJP} (%)`}
							rules={rules.population()}
							min={0}
						/>
					</Col>
					<Col sm={24} md={12} xl={6} xxl={6} >
						<InputBox.Number
							required
							label={`${GENERAL.CONGRASS} (%)`}
							name="congress"
							placeholder={`${GENERAL.CONGRASS} (%)`}
							rules={rules.population()}
							min={0}
						/>
					</Col>
					<Col sm={24} md={12} xl={6} xxl={6} >
						<InputBox.Number
							required
							label={`${GENERAL.AAP} (%)`}
							name="aap"
							placeholder={`${GENERAL.AAP} (%)`}
							rules={rules.population()}
							min={0}
						/>
					</Col>
					<Col sm={24} md={12} xl={6} xxl={6} >
						<InputBox.Number
							required
							label={`${GENERAL.OTHER} (%)`}
							name="other"
							placeholder={`${GENERAL.OTHER} (%)`}
							rules={rules.population()}
							min={0}
						/>
					</Col> */}
					{partyFields?.map((item: any, index: number) => {
						return (
							<Col sm={24} md={12} xl={6} xxl={6} key={index}>
								<InputBox.Number
									required
									// label={GENERAL[item?.name as keyof typeof GENERAL]}
									label={item?.label.replace(" ", '')}
									name={item?.name}
									placeholder={`${item?.label} (%)`}
									rules={rules.totalSum(item?.name, partyFields)}
									min={0}
								/>
							</Col>
						)
					})}
				</Row>
			</FormBox>

		</Drawer>
	)
}

export default VillageDetailsComponent