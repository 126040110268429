import React, { useState } from 'react'
import { Button, Col, Drawer, DrawerProps, Form, Row } from 'antd'
import SVGIcon from '../../../../../utils/SVGIcon';
import Config from '../../../../../config/Config';
import { useTranslation } from 'react-i18next';
import { FormBox, InputBox } from '../../../../../components/AntdAddons';
import { useAppDispatch } from '../../../../../store/app';
import { createRecord } from '../../../Administration/PincodeManagement/utils/slice';
import { assignErrorToInput } from '../../../../../store/api';
import { rules } from './rules';
import { validateFields } from '../../../../../config/Global';

interface PincodeDrawerProps extends DrawerProps {
  titleName: string;
  open: boolean;
  close: () => void;
}
const NewPincode: React.FC<PincodeDrawerProps> = ({
  open,
  titleName,
  close,
  ...rest
}) => {
  const dispatch = useAppDispatch()
  const [disabled, setDisabled] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  const { t } = useTranslation();
  const [form] = Form.useForm()
  const drawerClose = () => {
    form.resetFields();
    close()
  }
  const onFinish = (data: any) => {
    setSaving(true)
    dispatch(createRecord(data))
      .then(() => {
        drawerClose();
      })
      .catch((error: any) => {
        assignErrorToInput(form, error?.STATUS);
      })
      .finally(() => setSaving(false));
  }
  const onValuesChange = () => {
    validateFields(form, setDisabled);
  };

  return (
    <>
      <Drawer
        open={open}
        title={`${titleName} ${t("GENERAL.ADD_DRAWER_TITLE")}`}
        placement="right"
        width={"70%"}
        onClose={drawerClose}
        closeIcon={<SVGIcon icon="close" color={Config.themePrimaryBtn} />}
        footer={[
          <Button
            key="2"
            htmlType="button"
            className="cancelBtn"
            onClick={drawerClose}
          >
            {t("GENERAL.CANCEL_BUTTON")}
          </Button>,
          <Button
            key="1"
            disabled={disabled}
            form="addNewPincodeForm"
            loading={saving}
            htmlType="submit"
            type="primary"
          >
            {t("GENERAL.SAVE_BUTTON")}
          </Button>,
        ]}
        {...rest}
      >
        <>
          <FormBox
            form={form}
            id={"addNewPincodeForm"}
            onFinish={onFinish}
            onValuesChange={onValuesChange}
          >
            <Row gutter={15}>
              <Col xs={{ span: 24 }} >
                <InputBox.Text
                  required
                  label={t("GENERAL.PINCODES")}
                  name="pincode"
                  placeholder={t("GENERAL.PINCODES")}
                  rules={rules.newPincode()}
                  maxLength={6}
                />
              </Col>
            </Row>
          </FormBox>
        </>
      </Drawer>
    </>
  )
}
export default NewPincode