import { useRoutes } from "react-router-dom";
import ModuleWrap from "../components/ModuleWrap";

import AuthLayout from "../views/layouts/AuthLayout";
import MainLayout from "../views/layouts/MainLayout";

import Login from "../views/modules/Auth/Login/Login";
import PageNotFound from "../views/errors/PageNotFound";

import Dashboard from "../views/modules/Dashboard/Dashboard";
import UserManagement from "../views/modules/Administration/UserManagement";
import RoleManagement from "../views/modules/Administration/RoleManagement";
import ResetPassword from "../views/modules/Auth/ResetPassword/ResetPassword";
import ForgotPassword from "../views/modules/Auth/ForgotPassword/ForgotPassword";
import Profile from "../views/modules/Profile";
import PincodeManagement from "../views/modules/Administration/PincodeManagement";
import TalukaManagement from "../views/modules/Administration/TalukaManagement";
import CitizensManagement from "../views/modules/CitizenManagement"
import VillageDetailsManagement from "../views/modules/Administration/VillageDetailsManagement";
import MLAMeetingManagement from "../views/modules/MLAMeetingManagement";
import { toUpperCaseWithUnderscore } from "../components/AgGridWrapper/utils/commonFunctions";
import MeetingViewDrawer from "../views/modules/CitizenManagement/MeetingComponent/MeetingView";
import MLAGrantManagement from "../views/modules/MLAGrantManagement";
import SchemeManagement from "../views/modules/Administration/SchemeManagement";
import Reports from "../views/modules/VillageReports";
import ConfigurationManagement from "../views/modules/Configuration";
import VillageVsWardManagement from "../views/modules/Administration/VillageVsWard";
import CitizenMeetingManagement from "../views/modules/CitizenManagement/Citizen-meetingComponent";
import GovernmentNonGovermentContactManagement from "../views/modules/GovernmentNonGovermentContactManagement";

export default function Router() {
	return useRoutes(RouterConfig);
}

export const RouterConfig = [

	{
		element: <AuthLayout />,
		children: [
			{
				path: "/login",
				element: <Login />,
			},
			{
				path: "/forgot-password",
				element: <ForgotPassword />,
			},
			{
				path: "/reset/:otp",
				element: <ResetPassword />,
			},
			{
				path: "/about-us",
				element: <ResetPassword />,
			},
		],
	},
	{
		element: <MainLayout />,
		children: [
			{
				path: "/",
				element: <ModuleWrap module={Dashboard} />,
			},
			{
				path: "/profile",
				element: <ModuleWrap module={Profile} />,
			},
			{
				path: "/users/:action?/:id?",
				element: <ModuleWrap module={UserManagement} permissionPrefix={toUpperCaseWithUnderscore("User")} indexRoute={"/users"} />,
			},
			{
				path: "/roles/:action?/:id?",
				element: <ModuleWrap module={RoleManagement} permissionPrefix={toUpperCaseWithUnderscore("Role")} indexRoute={"/roles"} />,
			},
			{
				path: "/pincodes/:action?/:id?",
				element: <ModuleWrap module={PincodeManagement} permissionPrefix={toUpperCaseWithUnderscore("Pincode")} indexRoute={"/pincodes"} />,
			},
			{
				path: "/talukas/:action?/:id?",
				element: <ModuleWrap module={TalukaManagement} permissionPrefix={toUpperCaseWithUnderscore("Taluka")} indexRoute={"/talukas"} />,
			},
			{
				path: "/village-details/:action?/:id?",
				element: <ModuleWrap module={VillageDetailsManagement} permissionPrefix={toUpperCaseWithUnderscore("Village_detail")} indexRoute={"/village-details"} />,
			},
			{
				path: "/villages/:action?/:id?",
				element: <ModuleWrap module={VillageVsWardManagement} permissionPrefix={toUpperCaseWithUnderscore("Village")} indexRoute={"/villages"} />,
			},
			{
				path: "/citizens/:action?/:id?",
				element: <ModuleWrap module={CitizensManagement} permissionPrefix={toUpperCaseWithUnderscore("Citizen")} indexRoute={"/citizens"} />,
			},
			{
				path: "/citizens/meeting_details/:action?/:id?",
				element: <ModuleWrap module={MeetingViewDrawer} permissionPrefix={toUpperCaseWithUnderscore("Meeting")} indexRoute={"/citizens"} />,
			},
			{
				path: "/mla-calender/:action?/:id?",
				element: <ModuleWrap module={MLAMeetingManagement} permissionPrefix={toUpperCaseWithUnderscore("Calender")} indexRoute={"/mla-calender"} />,
			},
			{
				path: "/citizen-meetings/:action?/:id?",
				element: <ModuleWrap module={CitizenMeetingManagement} permissionPrefix={toUpperCaseWithUnderscore("Meeting")} indexRoute={"/citizen-meetings"} />,
			},
			{
				path: "/grants/:action?/:id?",
				element: <ModuleWrap module={MLAGrantManagement} permissionPrefix={toUpperCaseWithUnderscore("Grant")} indexRoute={"/grants"} />,
			},
			{
				path: "/schemes/:action?/:id?",
				element: <ModuleWrap module={SchemeManagement} permissionPrefix={toUpperCaseWithUnderscore("Scheme")} indexRoute={"/schemes"} />,
			},
			{
				path: "/reports",
				element: <ModuleWrap module={Reports} permissionPrefix={toUpperCaseWithUnderscore("Report_detail")} indexRoute={"/reports"} />,
			},
			{
				path: "/government-contact/:action?/:id?",
				element: <ModuleWrap module={GovernmentNonGovermentContactManagement} permissionPrefix={toUpperCaseWithUnderscore("Government_contact")} indexRoute={"/government-contact"} />,
			},
			{
				path: "/system/configuration",
				element: <ModuleWrap module={ConfigurationManagement} title={"Scheme"} permissionPrefix={toUpperCaseWithUnderscore("Pincode")} indexRoute={"/system/configuration"} />,
			}
		],
	},
	{
		path: "*",
		element: <PageNotFound />,
	},
];
