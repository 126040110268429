import React from "react";
import LoginForm from "./LoginForm";
import loginBgImage from "../../../../assets/images/apps/loginBgImage.png"
import logoImage from "../../../../assets/images/apps/New-Jan-Utkarsh-Yojna-Logo.png"
import {  useAppSelector } from "../../../../store/app";
import LanguageDropdown from "../../../../components/LanguageDropdown/LanguageDropdown";

const Login: React.FC = () => {
	// const syncData = useAppSelector(state => state.auth.initialData?.syncData)
	const syncDataNew = useAppSelector(state => state?.configuration?.details)
	
	
	return (
		<section className="loginSection" style={{ backgroundImage: `url(${(syncDataNew?.hero_image) ? (syncDataNew?.hero_image) : loginBgImage})`, paddingLeft: "25px", paddingRight: "25px" }}>
			<div className="loginLogo">
				{/* <img src={(syncData?.logo) ? (syncData?.logo) : logoImage} alt="logoImage" /> */}
				<img src={(syncDataNew?.logo) ? (syncDataNew?.logo) : logoImage} alt="logoImage" />
				{/* <h4 className="loginTitle">{(syncData?.office_name) ? (syncData?.office_name) : t("GENERAL.ATALDHARA")}</h4> */}
				<h4 className="loginTitle">{syncDataNew?.office_name}</h4>
			</div>

			<div className="loginWrap"  >
				<LanguageDropdown />
				<LoginForm />
			</div>
		</section>
	);
};

export default Login;
