import React, { useEffect, useState } from 'react'
import ContentBox from '../../../components/ContentBox/ContentBox'
import { Col, Form, Row } from 'antd';
import { dataToFormDataConverterA, validateFields } from '../../../config/Global';
import { useAppDispatch } from '../../../store/app';
import { ConfigImag, getDetails, setDetails, updateDetails } from './utils/slice';
import PreferencesForm from './Components/Form/PreferencesForm';
import SystemConfigurationComponent from './Components/Form/FormComponent';
import { assignErrorToInput } from '../../../store/api';

const ConfigurationManagement = () => {
  const [openFromModal, setOpenFromModal] = useState<any>();
  const [imageform] = Form.useForm()
  const [detailsform] = Form.useForm()
  const [disabled, setDisabled] = useState<boolean>(true)
  const [saving, setSaving] = useState<boolean>(false)
  const [visibleDrawer, setVisibleDrawer] = useState<any>(false);
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(getDetails())
      .then((res: any) => {
        dispatch(setDetails(res?.data))
        imageform.setFieldsValue({
          ...res.data
        })
      })
      .catch((e: any) => e)
  }, [])
  const onFinish = (data: any, type: any) => {
    data.type = type;
    const formData = dataToFormDataConverterA(data)
    setSaving(true)
    dispatch(ConfigImag(formData, type))
      .then(() => {
        closeModal()
      }).catch((error: any) => {
        assignErrorToInput(imageform, error?.STATUS)
      })
      .finally(() => setSaving(false))
  }
  const onValuesChange = () => {
    validateFields(imageform, setDisabled)
  }
  const onDetailsChange = () => {
    validateFields(detailsform, setDisabled)
  }
  const closeModal = () => {
    imageform.resetFields()
    setDisabled(true)
    setOpenFromModal(null)
  }
  const OnDrawerClose = () => {
    setDisabled(true)
    detailsform.resetFields()
    setVisibleDrawer(false)
  }
  const handleSubmit = (data: any) => {
    setSaving(true)
    dispatch(updateDetails(data))
      .then(() => {
        setSaving(false)
        OnDrawerClose()
      })
      .catch((error: any) => {
        assignErrorToInput(detailsform, error?.STATUS)
      })
      .finally(() => setSaving(false))
  }
  return (
    <>
      <ContentBox>
        <Row gutter={15}>
          <Col md={24} xl={12}>
            <SystemConfigurationComponent
              form={imageform}
              openFromModal={openFromModal}
              setOpenFromModal={setOpenFromModal}
              disabled={disabled}
              onValuesChange={onValuesChange}
              onFinish={onFinish}
              saving={saving}
              destroyOnClose={true}
              closeModal={closeModal}
            />
          </Col>
          <Col md={24} xl={12}>
            <PreferencesForm
              form={detailsform}
              onValuesChange={onDetailsChange}
              setVisibleDrawer={setVisibleDrawer}
              visibleDrawer={visibleDrawer}
              disabled={disabled}
              saving={saving}
              handleSubmit={handleSubmit}
              OnDrawerClose={OnDrawerClose}
            />
          </Col>
        </Row>
      </ContentBox>
    </>
  )
}
export default ConfigurationManagement