import { ColDef } from "ag-grid-community";
import { renderNA } from "../../../../../components/AgGridWrapper/utils/commonFunctions";
import { srNoColumn } from "../../../../../components/AgGridWrapper/utils/commonColumns";
import { useTranslation } from "react-i18next";


const columnDefs = (): ColDef[] => {
	const { t } = useTranslation();
	const villageColumn: ColDef = {
		field: "village_name",
		headerName: `${t("GENERAL.VILLAGES")}`,
		cellRenderer: (props: any) => {
			return renderNA(props.data?.village_name);
		},
	};

	const talukaColumn: ColDef = {
		field: "taluka_name",
		headerName: `${t("GENERAL.TALUKA")}`,
		cellRenderer: (props: any) => {
			return renderNA(props.data.taluka_name);
		},
	};

	const bjpColumn: ColDef = {
		field: "bjp",
		headerName: `${t("GENERAL.BJP")} (%)`,
		cellRenderer: (props: any) => {
			return props.data?.bjp > 0 ? renderNA(props.data.bjp) : "0";
		},
		filter: "agNumberColumnFilter",
	};
	const aapColumn: ColDef = {
		field: "aap",
		headerName: `${t("GENERAL.AAP")} (%)`,
		cellRenderer: (props: any) => {
			return props.data?.aap > 0 ? renderNA(props.data.aap) : "0";
		},
		filter: "agNumberColumnFilter",
	};

	const congressColumn: ColDef = {
		field: "congress",
		headerName: `${t("GENERAL.CONGRASS")} (%)`,
		cellRenderer: (props: any) => {
			return props.data?.congress > 0 ? renderNA(props.data.congress) : "0";
		},
		filter: "agNumberColumnFilter",
	};
	const otherColumn: ColDef = {
		field: "other",
		headerName: `${t("GENERAL.OTHER")} (%)`,
		cellRenderer: (props: any) => {
			return props.data?.other > 0 ? renderNA(props.data.other) : "0";
		},
		filter: "agNumberColumnFilter",
	};

	return [
		srNoColumn(t),
		villageColumn,
		talukaColumn,
		bjpColumn,
		aapColumn,
		congressColumn,
		otherColumn

	];
};
export default columnDefs;
