import React, { useEffect, useState } from 'react'
import { Button, Col, Drawer, DrawerProps, Form, Row, Table } from 'antd'
import { useAppDispatch } from '../../../../../../store/app'
import { assignErrorToInput } from '../../../../../../store/api'
import { attractionDisplay, attractionOptionsType, tablePagination, validateFields } from '../../../../../../config/Global'
import { rules } from '../Form/rules'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import CommonModal from '../../../../../../components/Modals/CommonModal'
import { CONSTANT } from '../../../../../../config/Constant'
import { PlusOutlined } from "@ant-design/icons";
import { FormBox, InputBox } from '../../../../../../components/AntdAddons'
import { addVillageAttraction, deleteVillageAttraction, getVillageAttractionList, updateVillageAttraction } from '../../utils/slice'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

interface VillageAttractionDrawerProps extends DrawerProps {
    saveAndNext: any;
    open: any;
    villageId: any;
    dynamicSteps: any;
    villageDetailsId: any
    close: () => void;
}
const VillageAttraction: React.FC<VillageAttractionDrawerProps> = ({
    close,
    open,
    saveAndNext,
    villageId,
    dynamicSteps,
    villageDetailsId,
    ...rest
}) => {
    const { t } = useTranslation();
    const { action } = useParams();
    const dispatch = useAppDispatch();
    const [addEditForm] = Form.useForm();
    const [disabled, setDisabled] = useState<boolean>(true);
    const [saving, setSaving] = useState<boolean>(false);
    const [villageAttractionList, setVillageAttractionList] = useState<any>([]);
    const [openDeleteModal, setOpenDeleteModal] = useState<any>();
    const [openAddEditModal, setOpenAddEditModal] = useState<any>();
    const [openAddEditForm, setOpenAddEditForm] = useState<any>();
    const [pagination, setPagination] = useState(tablePagination);
    useEffect(() => {
        if (villageDetailsId && action === CONSTANT.DRAWER_TYPE.EDIT && dynamicSteps === CONSTANT.STEP.VILLAGE_ATTRACTION) {
            dispatch(getVillageAttractionList({ village_detail_id: villageDetailsId, village_id: villageId }))
                .then((res: any) => {
                    if (res.count > 0) {
                        setVillageAttractionList([...res.rows])
                    } else {
                        setVillageAttractionList(res.rows)
                    }
                })
                .catch((e: any) => e)
        }
    }, [dynamicSteps])
    const columns = [
        {
            title: `${t("GENERAL.NAME")}`,
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: `${t("VILLAGEMANAGEMENT.DETAIL")}`,
            dataIndex: 'detail',
            key: 'detail',
        },
        {
            title: `${t("VILLAGEMANAGEMENT.TYPE")}`,
            dataIndex: 'type',
            key: 'type',
            render: (text: any) => {
                return (
                    <span>{attractionDisplay[text]}</span>
                )
            }
        },
        {
            title: `${t("GENERAL.ACTIONS")}`,
            key: 'action',
            render: (text: any, record: any) => (
                <div className='listActionbtn'>
                    <span onClick={() => handleAddEdit(record, "editModal")}>
                        <EditOutlined />
                    </span>
                    <span onClick={() => deleteModal(record)}>
                        <DeleteOutlined />
                    </span>
                </div>
            ),
        },
    ];
    const drawerClose = (submitForm = "") => {
        if (submitForm === "") {
            close()
        } else {
            saveAndNext(CONSTANT.STEP.VILLAGE_ATTRACTION)
        }
        setVillageAttractionList([])
        addEditForm.resetFields();
        setDisabled(true);
    }

    const handleAddEdit = (data: any, openForm: any) => {
        setOpenAddEditForm(openForm)
        if (openForm === "editModal") {
            const setValues = villageAttractionList.find((item: any) => item.id === data.id);
            addEditForm.setFieldsValue({
                ...setValues
            })
            setOpenAddEditModal(data)
        } else {
            setOpenAddEditModal(true)
        }
    }
    const deleteModal = (data: any) => {
        setOpenDeleteModal(data)
    }
    const closeEditModal = () => {
        setOpenAddEditModal(null)
        addEditForm.resetFields();
        setDisabled(true);
    }
    const validateEditForm = () => {
        validateFields(addEditForm, setDisabled);
    };
    const handleUpdateVillageAttraction = (data: any) => {
        setSaving(true)
        data.village_id = villageId
        data.village_detail_id = villageDetailsId

        if (openAddEditForm === "editModal") {
            dispatch(updateVillageAttraction(openAddEditModal.id, data))
                .then((res: any) => {
                    const indexToUpdate = villageAttractionList.findIndex((item: any) => item.id === res.data.id);
                    if (indexToUpdate !== -1) {
                        const updatedVillageAttractionList = [...villageAttractionList];
                        updatedVillageAttractionList[indexToUpdate] = res.data;
                        setVillageAttractionList(updatedVillageAttractionList);
                    }
                    setSaving(false)
                    closeEditModal();
                })
                .catch((error: any) => {
                    assignErrorToInput(addEditForm, error?.STATUS);
                    setSaving(false)
                })
                .finally(() => setSaving(false));
        } else {
            dispatch(addVillageAttraction(data))
                .then((res: any) => {
                    setVillageAttractionList([res.data, ...villageAttractionList])
                    closeEditModal();
                    addEditForm.resetFields();
                    setSaving(false);
                })
                .catch((error: any) => {
                    assignErrorToInput(addEditForm, error?.STATUS);
                    setSaving(false)
                })
                .finally(() => setSaving(false));
        }
    }
    const closeDeleteModal = () => {
        setOpenDeleteModal(null)
    }
    const deleteData = () => {
        setSaving(true)
        dispatch(deleteVillageAttraction(openDeleteModal.id, openDeleteModal))
            .then(() => {
                const deleteData = villageAttractionList.filter((item: any) => item.id !== openDeleteModal.id)
                setVillageAttractionList(deleteData)
                setOpenDeleteModal(null)
                setSaving(false)
            })
            .catch((error: any) => error)
            .finally(() => setSaving(false));
    }
    return (
        <>
            <Drawer
                title={t("VILLAGEMANAGEMENT.VILLAGE_ATTRACTION")}
                placement="right"
                onClose={() => drawerClose()}
                open={open}
                className='villageInnerPages'
                width={"70%"}
                footer={[
                    <Button
                        key="2"
                        htmlType="button"
                        className="cancelBtn"
                        onClick={() => drawerClose()}
                    >
                        {t("VILLAGEMANAGEMENT.DONE")}
                    </Button>
                ]}
                {...rest}
            >
                <div className='add_modal_button'>
                    <Button
                        key="3"
                        type="primary"
                        onClick={() => handleAddEdit(null, "addModal")}
                    >
                        {t("GENERAL.ADD_DRAWER_TITLE")} <PlusOutlined />
                    </Button>
                </div>
                <Table
                    dataSource={villageAttractionList}
                    columns={columns}
                    onChange={(pagination: any) => setPagination(pagination)}
                    pagination={pagination} // Apply pagination configuration
                />
                <CommonModal
                    open={openDeleteModal}
                    className={"deleteModal"}
                    title={`${t("VILLAGEMANAGEMENT.VILLAGE_ATTRACTION")} ${t("GENERAL.DELETE__DRAWER_TITLE")}`}
                    onCancel={closeDeleteModal}
                    footer={[
                        <Button key="2" htmlType="button" className="cancelBtn" onClick={closeDeleteModal}>
                            {t("GENERAL.CLOSE_BUTTON")}
                        </Button>,
                        <Button
                            key="1"
                            type="primary"
                            loading={saving}
                            htmlType='submit'
                            onClick={deleteData}
                        >
                            {t("GENERAL.DELETE_BUTTON")}
                        </Button>
                    ]}
                >
                    <div className="deleteNote">
                        {t("GENERAL.DELETE_MODEL_MESSAGE")} <span>{openDeleteModal?.name}</span>?
                    </div>
                </CommonModal>
                <CommonModal
                    open={openAddEditModal}
                    title={openAddEditForm === "editModal" ? `${t("VILLAGEMANAGEMENT.VILLAGE_ATTRACTION")} ${t("GENERAL.EDIT__DRAWER_TITLE")}` : `${t("VILLAGEMANAGEMENT.VILLAGE_ATTRACTION")} ${t("GENERAL.ADD_DRAWER_TITLE")}`}
                    onCancel={closeEditModal}
                    footer={[
                        <Button key="2" htmlType="button" className="cancelBtn" onClick={closeEditModal}>
                            {t("GENERAL.CLOSE_BUTTON")}
                        </Button>,
                        <Button
                            key="1"
                            type="primary"
                            loading={saving}
                            htmlType='submit'
                            disabled={disabled}
                            form='villageAttractionForm'
                        >
                            {t("GENERAL.SAVE_BUTTON")}
                        </Button>
                    ]}
                >
                    <FormBox
                        form={addEditForm}
                        id="villageAttractionForm"
                        onValuesChange={validateEditForm}
                        onFinish={handleUpdateVillageAttraction}
                    >
                        <Row gutter={15}>
                            <Col sm={24} md={24}>
                                <InputBox.Text
                                    required
                                    label={t("GENERAL.NAME")}
                                    name="name"
                                    placeholder={t("GENERAL.NAME")}
                                    rules={rules.name()}
                                />
                            </Col>
                            <Col sm={24} md={24}>
                                <InputBox.Text
                                    required
                                    label={t("VILLAGEMANAGEMENT.DETAIL")}
                                    name="detail"
                                    placeholder={t("VILLAGEMANAGEMENT.DETAIL")}
                                    rules={rules.name()}
                                />
                            </Col>
                            <Col sm={24} md={24}>
                                <InputBox.Select
                                    required
                                    name="type"
                                    label={t("VILLAGEMANAGEMENT.TYPE")}
                                    placeholder={t("VILLAGEMANAGEMENT.TYPE")}
                                    options={{
                                        list: attractionOptionsType,
                                        valueKey: "id",
                                        textKey: "name",
                                    }}
                                    rules={rules.partyPersonType()}
                                />
                            </Col>
                        </Row>
                    </FormBox>
                </CommonModal>
            </Drawer>
        </>
    )
}

export default VillageAttraction