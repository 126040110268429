import { maxPincode, minPincode, validations } from "../../../../../../config/validations/validations";

export const rules: any = {
  pincode: (field?: string) => [
    validations.required.text(field),
    validations.pattern.numeric(),
    validations.min.text(minPincode),
    validations.max.text(maxPincode),
  ]
};
