import { Divider, Drawer, DrawerProps, Form } from 'antd'
import React, { useState } from 'react'
import SVGIcon from '../../../../utils/SVGIcon';
import Config from '../../../../config/Config';
import CitizenRegister from './Form/CitizenRegister';
import { checkPrivileges, validateFields } from '../../../../config/Global';
import { useAppDispatch, useAppSelector } from '../../../../store/app';
import { assignErrorToInput } from '../../../../store/api';
import { createRecord, getCitizenList, setGrid } from '../utils/slice';
import FormComponent from '../Components/Form/FormComponent';
import MeetingForm from './Form/MeetingForm';
import MeetingTable from './Form/MeetingTable';
import { setRowData } from '../utils/meetingSlice';
import { useNavigate } from 'react-router-dom';
import { fetchPincodeList, setPincodeList } from '../../Administration/PincodeManagement/utils/slice';
import { fetchTalukaList, setTalukaList } from '../../Administration/TalukaManagement/utils/slice';
import { fetchVillageList, setVillageList } from '../../Administration/VillageVsWard/utils/slice';
import { useTranslation } from 'react-i18next';
import { CONSTANT } from '../../../../config/Constant';
import AgGridWrapper from '../../../../components/AgGridWrapper/AgGridWrapper';
import ActionButtons from '../../../../components/ActionButtons';
import columnDefs from './Listing/meetingcolumnDefs';
import dayjs from 'dayjs';
interface MeetingDrawerProps extends DrawerProps {
    titleName: string;
    moduleInfo: any;
    close: () => void;
}
const MeetingComponent: React.FC<MeetingDrawerProps> = ({
    moduleInfo, 
    close,
    ...rest
}) => {
    const { t } = useTranslation();
    const is_active = 1
    const rowData = useAppSelector(state => state.meeting.rowData);
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();
    const [createMeetingform] = Form.useForm();
    const [disabled, setDisabled] = useState<boolean>(true);
    const [saving, setSaving] = useState<boolean>(false);
    const [fetchpincode, setFetchPincode] = useState<boolean>(true);
    const [fetchTaluka, setFetchTaluka] = useState<boolean>(true);
    const [fetchVillage, setFetchVillage] = useState<boolean>(true);
    const [citizenDetails, setCitizenDetails] = useState<any>(null);//this state use for citizen form value
    const [disabledFields, setDisabledFields] = useState<boolean>(false); //this state use for disabled field in citizen form
    const [showAddCitizenBtn, setShowAddCitizenBtn] = useState<boolean>(false); //this state use for show citizen form btn
    const [showForm, setShowForm] = useState<boolean>(false);//this state use for show citizen form show and hide
    const [showMeetingForm, setShowMeetingFrom] = useState<boolean>(false);//this state use for show meeting form show and hide
    const [showGirdList, setShowGirdList] = useState<boolean>(false);
    const [citizenRow, setcitizenRow] = useState<any>([]);
    const userDetail = useAppSelector(state => state.auth.userDetail);
    const navigate = useNavigate();

    const drawerClose = () => {
        close()
        form.resetFields();
        setDisabled(true);
        setFetchPincode(true);
        setFetchTaluka(true);
        setFetchVillage(true);
        setShowForm(false);
        setShowAddCitizenBtn(false);
        setCitizenDetails(null);
        setShowMeetingFrom(false);
        setShowGirdList(false)
        dispatch(setRowData([]));
        setcitizenRow([])
    }
    const validateForm = () => {
        validateFields(form, setDisabled);
    };
    const handleFormSubmit = (data: any) => {
        setSaving(true);
        dispatch(createRecord(data))
            .then((res: any) => {
                setDisabledFields(true);
                setSaving(false);
                setDisabled(true);
                setFetchPincode(true);
                setFetchTaluka(true);
                setFetchVillage(true);
                setShowMeetingFrom(true);
                setCitizenDetails(res.citizenDetails);
                dispatch(setTalukaList([]));
                dispatch(setVillageList([]));
                if (res?.citizenMeeting?.length > 0) {
                    dispatch(setRowData(res?.citizenMeeting));
                } else {
                    dispatch(setRowData([]));
                }
            })
            .catch((error: any) => {
                assignErrorToInput(form, error?.STATUS);
            })
            .finally(() => setSaving(false));
    };

    const getDetailsMobile = (data: any) => {
        setSaving(true);
        form?.resetFields();
        setShowMeetingFrom(false)

        form.setFieldsValue({
            phone: data?.phone,
        })
        if (
            (data?.first_name !== '' && data?.first_name !== undefined) ||
            (data?.last_name !== '' && data?.last_name !== undefined) ||
            (data?.phone !== '' && data?.phone !== undefined)
        ) {
            dispatch(getCitizenList(data))
                .then((res: any) => {
                    setSaving(false);
                    if (res?.citizenDetails.length !== 0) {
                        setShowAddCitizenBtn(false);
                        setcitizenRow(res?.citizenDetails)
                        setShowGirdList(true)
                        setShowForm(false)
                    } else {
                        setcitizenRow([])
                        setShowGirdList(false);
                        setShowForm(false)
                        setCitizenDetails(null);
                        setShowAddCitizenBtn(true);
                        setDisabledFields(false);
                    }
                })
                .catch((error: any) => {
                    if (error?.STATUS) {
                        setShowGirdList(false)
                        setShowAddCitizenBtn(true);
                        setCitizenDetails(null);
                        setShowAddCitizenBtn(true);
                        setShowForm(false);
                        setDisabledFields(false);
                        dispatch(setRowData([]));
                        setcitizenRow([])
                        assignErrorToInput(form, error.STATUS);
                    }
                })
                .finally(() => setSaving(false));
        } else {
            setcitizenRow([])
            setShowGirdList(false);
            setShowForm(false)
            setCitizenDetails(null);
            setShowAddCitizenBtn(false);
            setDisabledFields(false);
            setSaving(false);
        }
    };
    const showFormBtn = () => {
        setCitizenDetails(null);
        setShowForm(true)
        dispatch(setTalukaList([]));
        dispatch(setVillageList([]));
    }

    const handleViewClick = (data: any) => {
        setShowForm(false);
        setShowMeetingFrom(false)
        if (data?.citizen_meetings?.length > 0) {
            dispatch(setRowData(data?.citizen_meetings));
        } else {
            dispatch(setRowData([]));
        }
        if (data) {
            createMeetingform.resetFields()
            setCitizenDetails(data);
            setShowForm(true);
            setDisabledFields(true);
            setShowMeetingFrom(true);
            dispatch(fetchPincodeList({ is_active: is_active })).catch((e: any) => e)
            dispatch(fetchTalukaList({ is_active: is_active })).catch((e: any) => e)
            dispatch(fetchVillageList({ is_active: is_active })).catch((e: any) => e)
            if (data?.taluka) {
                dispatch(setTalukaList([data.taluka]))
            }
            if (data?.pincode) {
                dispatch(setPincodeList([data.pincode]))
            }
            if (data?.village) {
                dispatch(setVillageList([data.village]))
            }
            form.setFieldsValue({
                ...data,
                dob: data?.dob && dayjs(data?.dob)
            });
        } else {
            setCitizenDetails(null);
            setShowAddCitizenBtn(true);
            setShowForm(false);
            setDisabledFields(false);
        }


    }
    const ActionRenderer = (props: any) => {
        return (
            <ActionButtons
                data={props}
                meeting={{
                    action: handleViewClick,
                    permissionKey: `${moduleInfo.permissionPrefix}_MEETING_DETAILS`,
                }}

            />
        );
    };

    const localGridConfig = {
        ...Config.grid.local,
        gridOptions: {
            ...Config.grid.local.gridOptions,
            paginationPageSize: 10
        }
    };


    return (
        <Drawer
            placement="right"
            width={"72%"}
            onClose={drawerClose}
            destroyOnClose
            closeIcon={<SVGIcon icon="close" color={Config.themePrimaryBtn} />}
            {...rest}
            className={"citizenMeeting "}
        >
            <div className='tag'><span>{t("CITIZENMANAGEMENT.Visitor")} {t("GENERAL.REGISTER")}</span></div>
            <CitizenRegister
                saving={saving}
                getDetailsMobile={getDetailsMobile}
                showAddCitizenBtn={showAddCitizenBtn}
                showFormBtn={showFormBtn}
            />
            {showGirdList && <div className='citizenMeetingGird'>
                <AgGridWrapper
                    components={{
                        ActionRenderer,
                    }}
                    rowData={citizenRow || []}
                    onGridReadyWithDispatch={setGrid}
                    columnDefs={columnDefs(t)}
                    gridConfig={localGridConfig}
                />
            </div>
            }
            {showForm &&
                <>
                    <Divider />
                    <div className='addCitizenForm'>
                        <div className='tag'><span>{`${t("CITIZENMANAGEMENT.Visitor")} ${citizenDetails ? `${t("GENERAL.VIEW__DRAWER_TITLE")}` : `${t("GENERAL.ADD_DRAWER_TITLE")}`}`}</span></div>
                        <FormComponent
                            form={form}
                            id="addCitizenForm"
                            handleSubmit={handleFormSubmit}
                            onValuesChange={validateForm}
                            fetchpincode={fetchpincode}
                            setFetchPincode={setFetchPincode}
                            fetchTaluka={fetchTaluka}
                            setFetchTaluka={setFetchTaluka}
                            fetchVillage={fetchVillage}
                            setFetchVillage={setFetchVillage}
                            disabled={disabled}
                            saving={saving}
                            drawerClose={drawerClose}
                            disabledFields={disabledFields}
                            openFrom="meetingComponent"
                        />
                    </div>
                </>
            }
            {showMeetingForm && checkPrivileges(userDetail, `${moduleInfo.permissionPrefix}_MEETING_CREATE`) &&
                <>
                    <Divider />
                    <MeetingForm
                        citizenId={citizenDetails?.id}
                        drawerClose={drawerClose}
                        form={createMeetingform}
                    />
                </>
            }
            {
                showMeetingForm && rowData?.length > 0 && checkPrivileges(userDetail, `${moduleInfo.permissionPrefix}_MEETING_CREATE`) &&
                <>
                    <Divider />
                    <MeetingTable
                        permissionPrefix={moduleInfo.permissionPrefix}
                        handleViewClick={(data: any) => {
                            dispatch(setRowData([]));
                            navigate(
                                // `/${CONSTANT.NAVIGATE_LINK.CITIZEN_MEETING}`      
                                `${moduleInfo.indexRoute}/${CONSTANT.DRAWER_TYPE.CITIZENSWITHMEETING}_details/${CONSTANT.DRAWER_TYPE.VIEW}/${data.id}`
                            );
                        }}
                    />
                </>
            }
        </Drawer>
    )
}

export default MeetingComponent