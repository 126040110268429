import React, { useEffect, useState } from 'react'
import { Button, Col, Drawer, DrawerProps, Form, Row, Table } from 'antd'
import { useAppDispatch, useAppSelector } from '../../../../../../store/app';
import { assignErrorToInput } from '../../../../../../store/api';
import { tablePagination, validateFields } from '../../../../../../config/Global';
import { rules } from '../Form/rules';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import CommonModal from '../../../../../../components/Modals/CommonModal';
import { CONSTANT } from '../../../../../../config/Constant';
import { PlusOutlined } from "@ant-design/icons";
import { addVillageFestival, deleteVillageFestival, getReligionsloc, getVillageFestivalsList, updateVillageFestival } from '../../utils/slice';
import { FormBox, InputBox } from '../../../../../../components/AntdAddons';
import dayjs from 'dayjs'
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
interface VillageFestivalDrawerProps extends DrawerProps {
    saveAndNext: any;
    open: any;
    villageId: any;
    dynamicSteps: any;
    villageDetailsId: any;
    close: () => void;
}
const VillageFestival: React.FC<VillageFestivalDrawerProps> = ({
    close,
    open,
    saveAndNext,
    villageId,
    dynamicSteps,
    villageDetailsId,
    ...rest
}) => {
    const { t } = useTranslation();
    const { action } = useParams();
    const dispatch = useAppDispatch();
    const [addEditForm] = Form.useForm();
    const [disabled, setDisabled] = useState<boolean>(true);
    const [saving, setSaving] = useState<boolean>(false);
    const [villageFestivalList, setVillageFestivalList] = useState<any>([]);
    const [openDeleteModal, setOpenDeleteModal] = useState<any>();
    const [openAddEditModal, setOpenAddEditModal] = useState<any>();
    const [openAddEditForm, setOpenAddEditForm] = useState<any>();
    const [pagination, setPagination] = useState(tablePagination);
    const is_active = 1
    const religionList = useAppSelector(state => state.villageDetails.religionDetails)
    useEffect(() => {
        if (villageDetailsId && action === CONSTANT.DRAWER_TYPE.EDIT && dynamicSteps === CONSTANT.STEP.VILLAGE_FESTIVAL) {
            dispatch(getReligionsloc({ is_active: is_active }))
                .catch((e: any) => e)
            dispatch(getVillageFestivalsList({ village_detail_id: villageDetailsId, village_id: villageId }))
                .then((res: any) => {
                    if (res.count > 0) {
                        setVillageFestivalList([...res.rows])
                    } else {
                        setVillageFestivalList(res.rows)
                    }
                })
                .catch((e: any) => e)

        }

    }, [dynamicSteps])
    const columns = [
        {
            title: `${t("GENERAL.NAME")}`,
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: `${t("VILLAGEMANAGEMENT.RELIGION")}`,
            dataIndex: 'religion_id',
            key: 'religion_id',
            render: (text: any) => {
                const religinData = religionList.find((i: any) => i?.id === text)?.name
                return (
                    <span>{religinData}</span>
                )
            }
        },
        {
            title: `${t("GENERAL.FROM_DATE")}`,
            dataIndex: 'from_date',
            key: 'from_date',
        },
        {
            title: `${t("GENERAL.TO_DATE")}`,
            dataIndex: 'to_date',
            key: 'to_date',
        },
        {
            title: `${t("GENERAL.ACTIONS")}`,
            key: 'action',
            render: (text: any, record: any) => (
                <div className='listActionbtn'>
                    <span onClick={() => handleAddEdit(record, "editModal")}>
                        <EditOutlined />
                    </span>
                    <span onClick={() => deleteModal(record)}>
                        <DeleteOutlined />
                    </span>
                </div>
            ),
        },
    ];
    const drawerClose = (submitForm = "") => {
        if (submitForm === "") {
            close()
        } else {
            saveAndNext(CONSTANT.STEP.VILLAGE_ATTRACTION)
        }
        setVillageFestivalList([])
        addEditForm.resetFields();
        setDisabled(true);
    }
    const handleAddEdit = (data: any, openForm: any) => {
        setOpenAddEditForm(openForm)
        if (openForm === "editModal") {
            const setValues = villageFestivalList.find((item: any) => item.id === data.id);
            const dateRange = [dayjs(setValues?.from_date), dayjs(setValues?.to_date)]

            addEditForm.setFieldsValue({
                ...setValues,
                date: dateRange
            })
            setOpenAddEditModal(data)
        } else {
            setOpenAddEditModal(true)
        }
    }
    const deleteModal = (data: any) => {
        setOpenDeleteModal(data)
    }
    const closeEditModal = () => {
        setOpenAddEditModal(null)
        addEditForm.resetFields();
        setDisabled(true);
    }
    const validateEditForm = () => {
        validateFields(addEditForm, setDisabled);
    };
    const handleUpdateVillageFestival = (data: any) => {
        setSaving(true)
        data.village_id = villageId
        data.village_detail_id = villageDetailsId
        data.from_date = dayjs(data.date[0]).format(CONSTANT.POST_DATE_FORMAT)
        data.to_date = dayjs(data.date[1]).format(CONSTANT.POST_DATE_FORMAT)
        delete data.date
        if (openAddEditForm === "editModal") {
            // data.from_date = dayjs(data.from_date).format(CONSTANT.POST_DATE_FORMAT)
            // data.to_date = dayjs(data.to_date).format(CONSTANT.POST_DATE_FORMAT)
            dispatch(updateVillageFestival(openAddEditModal.id, data))
                .then((res: any) => {
                    const indexToUpdate = villageFestivalList.findIndex((item: any) => item.id === res.data.id);
                    if (indexToUpdate !== -1) {
                        const updatedVillageFestivalList = [...villageFestivalList];
                        updatedVillageFestivalList[indexToUpdate] = res.data;
                        setVillageFestivalList(updatedVillageFestivalList);
                    }
                    setSaving(false)
                    closeEditModal();
                })
                .catch((error: any) => {
                    assignErrorToInput(addEditForm, error?.STATUS);
                    setSaving(false)
                })
                .finally(() => setSaving(false));
        } else {
            dispatch(addVillageFestival(data))
                .then((res: any) => {
                    setVillageFestivalList([res.data, ...villageFestivalList])
                    closeEditModal();
                    addEditForm.resetFields();
                    setSaving(false);
                })
                .catch((error: any) => {
                    assignErrorToInput(addEditForm, error?.STATUS);
                    setSaving(false)
                })
                .finally(() => setSaving(false));
        }
    }
    const closeDeleteModal = () => {
        setOpenDeleteModal(null)
    }
    const deleteData = () => {
        setSaving(true)
        dispatch(deleteVillageFestival(openDeleteModal.id, openDeleteModal))
            .then(() => {
                const deleteData = villageFestivalList.filter((item: any) => item.id !== openDeleteModal.id)
                setVillageFestivalList(deleteData)
                setOpenDeleteModal(null)
                setSaving(false)
            })
            .catch((error: any) => error)
            .finally(() => setSaving(false));
    }
    return (
        <>
            <Drawer
                title={t("VILLAGEMANAGEMENT.VILLAGE_FESTIVAL")}
                placement="right"
                onClose={() => drawerClose()}
                open={open}
                className='villageInnerPages'
                width={"70%"}
                footer={[
                    <Button
                        key="2"
                        htmlType="button"
                        className="cancelBtn"
                        onClick={() => drawerClose()}
                    >
                        {t("GENERAL.CANCEL_BUTTON")}
                    </Button>,
                    <Button
                        key="3"
                        type="primary"
                        onClick={() => drawerClose("saveAndNext")}
                    >
                        {t("GENERAL.CONTINUE_BUTTON")}
                    </Button>
                ]}
                {...rest}
            >
                <div className='add_modal_button'>
                    <Button
                        key="3"
                        type="primary"
                        onClick={() => handleAddEdit(null, "addModal")}
                    >
                        {t("GENERAL.ADD_DRAWER_TITLE")} <PlusOutlined />
                    </Button>
                </div>
                <Table
                    dataSource={villageFestivalList}
                    columns={columns}
                    onChange={(pagination: any) => setPagination(pagination)}
                    pagination={pagination} // Apply pagination configuration
                />
                <CommonModal
                    open={openDeleteModal}
                    className={"deleteModal"}
                    title={`${t("VILLAGEMANAGEMENT.VILLAGE_FESTIVAL")} ${t("GENERAL.DELETE__DRAWER_TITLE")}`}
                    onCancel={closeDeleteModal}
                    footer={[
                        <Button key="2" htmlType="button" className="cancelBtn" onClick={closeDeleteModal}>
                            {t("GENERAL.CLOSE_BUTTON")}
                        </Button>,
                        <Button
                            key="1"
                            type="primary"
                            loading={saving}
                            htmlType='submit'
                            onClick={deleteData}
                        >
                            {t("GENERAL.DELETE_BUTTON")}
                        </Button>
                    ]}
                >
                    <div className="deleteNote">
                        {t("GENERAL.DELETE_MODEL_MESSAGE")} <span>{openDeleteModal?.name}</span>?
                    </div>
                </CommonModal>
                <CommonModal
                    open={openAddEditModal}
                    title={openAddEditForm === "editModal" ? `${t("VILLAGEMANAGEMENT.VILLAGE_FESTIVAL")} ${t("GENERAL.EDIT__DRAWER_TITLE")}` : `${t("VILLAGEMANAGEMENT.VILLAGE_FESTIVAL")} ${t("GENERAL.ADD_DRAWER_TITLE")}`}
                    onCancel={closeEditModal}
                    footer={[
                        <Button key="2" htmlType="button" className="cancelBtn" onClick={closeEditModal}>
                            {t("GENERAL.CLOSE_BUTTON")}
                        </Button>,
                        <Button
                            key="1"
                            type="primary"
                            loading={saving}
                            htmlType='submit'
                            disabled={disabled}
                            form='villageFestivalForm'
                        >
                            {t("GENERAL.SAVE_BUTTON")}
                        </Button>
                    ]}
                >
                    <FormBox
                        form={addEditForm}
                        id="villageFestivalForm"
                        onValuesChange={validateEditForm}
                        onFinish={handleUpdateVillageFestival}
                    >
                        <Row gutter={15}>
                            <Col sm={24} md={24}>
                                <InputBox.Text
                                    required
                                    label={t("GENERAL.NAME")}
                                    name="name"
                                    placeholder={t("GENERAL.NAME")}
                                    rules={rules.name()}
                                />
                            </Col>
                            <Col sm={24} md={24}>
                                <InputBox.Select
                                    required
                                    name="religion_id"
                                    label={t("VILLAGEMANAGEMENT.RELIGION")}
                                    placeholder={t("VILLAGEMANAGEMENT.RELIGION")}
                                    onFocus={() => {
                                        dispatch(getReligionsloc({ is_active: is_active }))
                                            .catch((e: any) => e)
                                    }}
                                    options={{
                                        list: religionList,
                                        valueKey: "id",
                                        textKey: "name",
                                    }}
                                    rules={rules.partyPersonType()}
                                />
                            </Col>
                            <Col sm={24} md={24}>
                                <InputBox.DateRangePicker
                                    allowClear={false}
                                    required
                                    label={`${t("GENERAL.FROM_DATE")} - ${t("GENERAL.TO_DATE")}`}
                                    name="date"
                                    format={CONSTANT.DATE_FORMAT}
                                    rules={rules.partyPersonType()}
                                />
                            </Col>
                        </Row>
                    </FormBox>
                </CommonModal>
            </Drawer>
        </>
    )
}
export default VillageFestival