import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import languageEN from './translations/en/eng.json'
import languageGUJ from './translations/guj/guj.json'
const storedLang = localStorage.getItem("language") || 'en';
i18n
    .use(initReactI18next)
    .init({
        lng: storedLang,
        resources: {
            en: {
                translation: languageEN,
            },
            gu: {
                translation: languageGUJ,
            },
        },
    });

export default i18n;
