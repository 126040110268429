import {
    minAmount,
    maxAmount,
    validations,
    minComment,
    maxComment,
    minTaluka,
    maxTaluka,
    maxGrantIdNumber
} from "../../../../../config/validations/validations";
export const rules: any = {
    amount: (field?: string) => [
        validations.required.text(field),
        validations.min.text(minAmount),
        validations.max.text(maxAmount)
    ],
    description: () => [
        validations.min.text(minComment),
        validations.max.text(maxComment)
    ],
    typeOfWork: (field?: string) => [
        validations.required.text(field),
        validations.min.text(minTaluka),
        validations.max.text(maxTaluka)
    ],
    schemeName: (field?: string) => [
        validations.required.text(field),
        validations.min.text(minTaluka),
        validations.max.text(maxTaluka)
    ],
    taluka: (field?: string) => [
        validations.required.other(field),
    ],
    village: (field?: string) => [
        validations.required.other(field),
        // ({ getFieldValue }: any) => ({
        //     validator() {
        //         if (getFieldValue("taluka_id") === undefined) {
        //             return Promise.reject(Messages.SELECT_TALUKA);
        //         }
        //         return Promise.resolve();
        //     },
        // }),
    ],
    entry_date: (field?: string) => [
        validations.required.other(field),
    ],
    grant_id_number: (field?: string) => [
        validations.max.text(maxGrantIdNumber),
        validations.pattern.IdNumber(field)
    ],
    grant_status: (field?: string) => [
        validations.required.other(field),
    ],
}