import { ColDef } from "ag-grid-community";
import { renderNA } from "../../../../../components/AgGridWrapper/utils/commonFunctions";
import { actionColumn } from "../../../../../components/AgGridWrapper/utils/commonColumns";

const columnDefs = (t: any): ColDef[] => {
    const firstNameColumn: ColDef = {
        field: "first_name",
        headerName: `${t("CITIZENMANAGEMENT.FIRST_NAME")}`,
        cellRenderer: (props: any) => {
            return renderNA(props.data?.first_name);
        },
    };
    const lastNameColumn: ColDef = {
        field: "last_name",
        headerName: `${t("CITIZENMANAGEMENT.LAST_NAME")}`,
        cellRenderer: (props: any) => {
            return renderNA(props.data?.last_name);
        },
    };
    const phoneColumn: ColDef = {
        field: "phone",
        headerName: `${t("GENERAL.MOBILE_NUMBER")}`,
        filter: "agNumberColumnFilter",
        cellRenderer: (props: any) => {
            return renderNA(props.data?.phone);
        },
    };
    return [firstNameColumn, lastNameColumn, phoneColumn, actionColumn(t)]
}
export default columnDefs