import { ColDef } from "ag-grid-community";
import { renderNA } from "../../../../../../components/AgGridWrapper/utils/commonFunctions";
import { srNoColumn, actionColumn } from "../../../../../../components/AgGridWrapper/utils/commonColumns";
import { useTranslation } from "react-i18next";


const columnDefs = (): ColDef[] => {
	const { t } = useTranslation();
	const dateColumn: ColDef = {
		field: "date",
		// headerName: `${GENERAL.DATE_OF_GRANT}`,
		headerName: `${t("VILLAGEMANAGEMENT.DATE")}`,
		sortable: true,
		width: 200,
		minWidth: 200,
		cellRenderer: (props: any) => {
			return renderNA(props.data?.date);
		},
	};
	const villageColumn: ColDef = {
		field: "name",
		headerName: `${t("GENERAL.VILLAGES")}`,
		cellRenderer: (props: any) => {
			return renderNA(props.data?.name);
		},
	};

	const talukaColumn: ColDef = {
		field: "taluka_name",
		headerName: `${t("GENERAL.TALUKA")}`,
		cellRenderer: (props: any) => {
			return renderNA(props.data.taluka_name);
		},
	};

	const pincodeColumn: ColDef = {
		field: "pincode",
		headerName: `${t("GENERAL.PINCODES")}`,
		cellRenderer: (props: any) => {
			return renderNA(props.data.pincode);
		},
	};

	return [
		srNoColumn(t),
		dateColumn,
		villageColumn,
		talukaColumn,
		pincodeColumn,
		// isActiveColumn,
		{ ...actionColumn(t), width: 160, minWidth: 160 },
	];
};
export default columnDefs;
