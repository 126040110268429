import { maxTaluka, minTaluka, validations } from "../../../../../../config/validations/validations";

export const rules: any = {
  pincode: (field?: string) => [
    validations.required.other(field),
  ],
  taluka: (field?: string) => [
    validations.required.text(field),
    validations.min.text(minTaluka),
    validations.max.text(maxTaluka),
  ]
};
