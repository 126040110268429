import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import authReducer from "./AuthSlice";
import commonReducer from "./CommonSlice";
import userReducer from "../views/modules/Administration/UserManagement/utils/slice";
import roleReducer from "../views/modules/Administration/RoleManagement/utils/slice";
import pincodeReducer from "../views/modules/Administration/PincodeManagement/utils/slice";
import talukaReducer from "../views/modules/Administration/TalukaManagement/utils/slice";
import citizenReducer from "../views/modules/CitizenManagement/utils/slice";
import mlaEventReducer from "../views/modules/MLAMeetingManagement/utils/slice";
import meetingReducer from "../views/modules/CitizenManagement/utils/meetingSlice";
import mlaGrantReducer from "../views/modules/MLAGrantManagement/Components/utils/slice";
import schemeReducer from "../views/modules/Administration/SchemeManagement/utils/slice";
import villageDetailsReducer from "../views/modules/Administration/VillageDetailsManagement/utils/slice"
import villageReducer from "../views/modules/Administration/VillageVsWard/utils/slice"
import reportReducer from "../views/modules/VillageReports/Components/utils/slice";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import systemConfigurationReducer from "../views/modules/Configuration/utils/slice";
import citizenMeetingReducer from "../views/modules/CitizenManagement/Citizen-meetingComponent/utils/slice";
import GovernmentNonGovermentContactReducer from "../views/modules/GovernmentNonGovermentContactManagement/utils/slice";

const reducer = {
  common: commonReducer,
  auth: authReducer,
  user: userReducer,
  role: roleReducer,
  pincode: pincodeReducer,
  taluka: talukaReducer,
  villageDetails: villageDetailsReducer,
  citizen: citizenReducer,
  citizenMeeting : citizenMeetingReducer,
  village: villageReducer,
  mlaEvent: mlaEventReducer,
  meeting: meetingReducer,
  mlaGrant: mlaGrantReducer,
  scheme: schemeReducer,
  villageReports: reportReducer,
  GovernmentNonGovermentContact : GovernmentNonGovermentContactReducer,
  configuration: systemConfigurationReducer,
};

const store = configureStore({
  reducer: reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<any>
>;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
type DispatchFunc = () => AppDispatch;
export const useAppDispatch: DispatchFunc = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store; // Export the store as the default export
