import React, { useState } from "react";
import AgGridWrapper from "../../../../../../components/AgGridWrapper/AgGridWrapper";
import ActionButtons from "../../../../../../components/ActionButtons";
import { changeStatus, setupGrid } from "../../utils/slice";
import ChangeStatusModal from "../../../../../../components/Modals/ChangeStatusModal";
import columnDefs from "./columnDefs";
import { useAppSelector } from "../../../../../../store/app";
import { checkPrivileges } from "../../../../../../config/Global";
import { useTranslation } from "react-i18next";
// import { Button } from "antd";
// import SVGIcon from "../../../../../utils/SVGIcon";

const Listing: React.FC<any> = ({
	moduleInfo,
	handleViewClick,
	handleDeleteClick,
	handleEditClick,
}) => {
	const { t } = useTranslation();
	const [statusData, setStatusData] = useState<any>();
	const userDetail = useAppSelector(state => state.auth.userDetail);
	const ActionRenderer = (props: any) => {
		return (
			<>
				<ActionButtons
					data={props}
					view={{
						action: handleViewClick,
						permissionKey: `${moduleInfo.permissionPrefix}_DETAILS`,
					}}
					edit={{
						action: handleEditClick,
						permissionKey: `${moduleInfo.permissionPrefix}_UPDATE`,
					}}
					deleteButton={{
						action: handleDeleteClick,
						permissionKey: `${moduleInfo.permissionPrefix}_DELETE`,
					}}
				/>
				{/* <Button
          type="text"
          title={"Change Password"}
          className="editIcon"
          // onClick={}
        >
          <SVGIcon icon="changePass" />
        </Button> */}
			</>
		);
	};

	return (
		<>
			<ChangeStatusModal
				titleName={t("USERMANAGEMENT.USER")}
				data={statusData}
				close={() => setStatusData(null)}
				callApi={changeStatus}
				keyName={"name"}
			/>
			<AgGridWrapper
				type="serverSide"
				components={{
					ActionRenderer,
				}}
				onGridReadyWithDispatch={setupGrid}
				columnDefs={columnDefs()}
				context={{
					setStatusData,
					changeStatusPermission: checkPrivileges(
						userDetail,
						`${moduleInfo.permissionPrefix}_CHANGE_STATUS`
					)
				}}
			/>
		</>
	);
};

export default Listing;
