import React from 'react'
import { ModuleInfoProps } from '../../../../config/InterfacesAndTypes'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ToolBar from '../../../../components/ToolBar';
import { CitizenMeetingBreadcrumb } from '../../../../config/BreadcrumbConfig';
import ContentBox from '../../../../components/ContentBox/ContentBox';
import { CONSTANT } from '../../../../config/Constant';
import Listing from './Components/Listing/Listing';

const CitizenMeetingManagement: React.FC<ModuleInfoProps> = (moduleInfo) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <>
            <ToolBar breadcrumbs={CitizenMeetingBreadcrumb(t)} />
            <ContentBox>
                <Listing
                    moduleInfo={moduleInfo}
                    handleViewClick={(data: any) => {
                        navigate(
                            `/${CONSTANT.NAVIGATE_LINK.CITIZENS}/${CONSTANT.DRAWER_TYPE.CITIZENSWITHMEETING}_details/${CONSTANT.DRAWER_TYPE.VIEW}/${data.id}`
                        );
                    }}

                />
            </ContentBox>
        </>
    )
}

export default CitizenMeetingManagement