import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import api from "../../../../store/api";
import { API_URL } from "./apiUrls";
import { AppThunk, } from "../../../../store/app";
import dayjs from "dayjs"
import Config from "../../../../config/Config";

interface InitialState {
    isLoading: boolean;
    rowData: any;
    startDate: any;
    endDate: any;
    agGrid: any;
    perPage: any;
    details: any
}

const initialState = {
    isLoading: false,
    rowData: null,
    startDate: null,
    endDate: null,
    agGrid: null,
    perPage: Config.grid.server.gridOptions?.paginationPageSize,
    details: null
} as InitialState;


const MLAEventSlice = createSlice({
    name: "mlaEvent",
    initialState,
    reducers: {
        start: state => {
            state.isLoading = true;
        },
        success: state => {
            state.isLoading = false;
        },
        failure: state => {
            state.isLoading = false;
        },
        setGrid: (state, action: PayloadAction<any>) => {
            state.agGrid = action?.payload;
        },
        setStartDate: (state, action: PayloadAction<any>) => {
            state.startDate = action?.payload;
        },
        setEndDate: (state, action: PayloadAction<any>) => {
            state.endDate = action?.payload;
        },
        setRowData: (state, action: PayloadAction<any>) => {
            state.rowData = action?.payload;
        },
        setPerPage: (state, action: PayloadAction<any>) => {
            state.perPage = action?.payload;
            if (state.agGrid) {
                state.agGrid.api.paginationSetPageSize(Number(state.perPage));
                state.agGrid.api.setCacheBlockSize(state.perPage);
            }
        },

        setDetails: (state, action: PayloadAction<any>) => {
            state.details = action?.payload;
        },
    },
});

export const { start, success, failure, setRowData, setStartDate, setGrid, setEndDate, setPerPage, setDetails } =
    MLAEventSlice.actions;


/** For Listing:Start */
export const fetchList = (action?: any): AppThunk<any> =>
    async dispatch => {
        try {
            dispatch(start());
            const response = await api.post(API_URL.LIST, action);
            if (response.data.rows.length > 0) {
                await response.data.rows.map((item: any, index: number) => {
                    item.srno = index + 1;
                    return null;
                })
            }
            dispatch(setRowData(response.data.rows));
            dispatch(success());
            return Promise.resolve(response.data);
        } catch (error: any) {
            dispatch(failure());
            return Promise.reject(error.data);
        }
    };

export const initialFilter =
    (action: any): AppThunk<any> =>
        async (dispatch, getState) => {
            action.api.setFilterModel({
                entry_date: { dateFrom: dayjs(getState().mlaEvent.startDate).format("DD/MM/YYYY"), dateTo: dayjs(getState().mlaEvent.endDate).format("DD/MM/YYYY"), type: "inRange", filterType: "date" }
            });
            dispatch(setGrid(action));
        }

export const setPerPageSize =
    (size: number): AppThunk<any> =>
        async dispatch => {
            dispatch(setPerPage(size));
            dispatch(fetchList());
        };
/** For Listing:End */

export const createRecord =
    (action: any): AppThunk<any> =>
        async (dispatch, getState) => {
            try {
                dispatch(start());
                const response = await api.post(API_URL.CREATE, action);
                const payload = {
                    from_date: getState().mlaEvent.startDate,
                    to_date: getState().mlaEvent.endDate,
                }
                dispatch(fetchList(payload));
                dispatch(success(response.data));
                return Promise.resolve(response.data);
            } catch (error: any) {
                dispatch(failure(error.data));
                return Promise.reject(error.data);
            }
        };

export const updateRecord =
    (id: number, action: any): AppThunk<any> =>
        async (dispatch, getState) => {
            try {
                dispatch(start());
                const response = await api.post(API_URL.UPDATE(id), action);
                const payload = {
                    from_date: getState().mlaEvent.startDate,
                    to_date: getState().mlaEvent.endDate,
                }
                dispatch(fetchList(payload));
                dispatch(success(response.data));
                return Promise.resolve(response.data);
            } catch (error: any) {
                dispatch(failure(error.data));
                return Promise.reject(error.data);
            }
        };

export const deleteRecord =
    (id: number, action: any): AppThunk<any> =>
        async (dispatch, getState) => {
            try {
                dispatch(start());
                const response = await api.delete(API_URL.DELETE(id), action);
                const payload = {
                    from_date: getState().mlaEvent.startDate,
                    to_date: getState().mlaEvent.endDate,
                }
                dispatch(fetchList(payload));
                dispatch(success(response.data));
                return Promise.resolve(response.data);
            } catch (error: any) {
                dispatch(failure(error.data));
                return Promise.reject(error.data);
            }
        };

export const details =
    (id: number): AppThunk<any> =>
        async dispatch => {
            try {
                const response = await api.get(API_URL.DETAILS(id));
                dispatch(setDetails(response.data.data));
                return Promise.resolve(response.data);
            } catch (error: any) {
                return Promise.reject(error.data);
            }
        };

// export const changeStatus =
//     (id: number, is_active: number): AppThunk<any> =>
//         async (dispatch, getState) => {
//             try {
//                 const response = await api.post(API_URL.CHANGE_STATUS(id), { is_active: is_active });
//                 dispatch(setupGrid(getState().citizen.agGrid));
//                 return Promise.resolve(response.data);
//             } catch (error: any) {
//                 return Promise.reject(error.data);
//             }
//         };


const mlaEventReducer = MLAEventSlice.reducer;
export default mlaEventReducer;