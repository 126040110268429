import React from 'react'
import { ButtonProps } from '../../Buttons/interface/ButtonInterface'
import { Button } from 'antd';
import SVGIcon from '../../../utils/SVGIcon';

const StatusButton: React.FC<Partial<ButtonProps>> = ({
    action,
    data,
    permission,
    ...rest
}) => {
    if (!permission) {
        return null;
    }

    delete rest?.permissionKey;
    return (
        <Button
            disabled={data?.status === 4 ? true : false }
            type="text"
            title={"Status"}
            className="editIcon"
            onClick={() => action && action(data)}
            {...rest}
        >
            <SVGIcon icon="status" />
        </Button>
    );
}

export default StatusButton