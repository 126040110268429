import React, { useEffect, useState } from "react";
import { Layout, Menu } from "antd";
import Config from "../../../config/Config";
import { useLocation, useNavigate } from "react-router-dom";
import LogoComponent from "../Components/LogoComponent";
import { AppstoreOutlined,ContactsOutlined } from "@ant-design/icons";
import { camelCaseString } from "../../../config/Global";
import { ReactComponent as UsersManagement } from "../../../assets/images/icon/Users-management.svg";
import { ReactComponent as Dashboard } from "../../../assets/images/icon/dashboard.svg";
import { ReactComponent as CitizensManagement } from "../../../assets/images/icon/citizenregister.svg";
import { ReactComponent as Roles } from "../../../assets/images/icon/Roles.svg";

import { ReactComponent as PincodeManagement } from "../../../assets/images/icon/PinCode.svg";
import { ReactComponent as VillageManagement } from "../../../assets/images/icon/VillageIcon.svg";
import { ReactComponent as MLACalenderManagement } from "../../../assets/images/icon/CalendarBlue.svg";
import { ReactComponent as MLAGrantManagement } from "../../../assets/images/icon/mlagrant.svg";
import { ReactComponent as  Reports} from "../../../assets/images/icon/report.svg";
import MenuBG from "../../../../src/assets/images/apps/menu_bg.png";
import { useAppSelector } from "../../../store/app";
import { useTranslation } from "react-i18next";

interface AppSidebarViewProps {
  collapsed: boolean;
}
const SidebarView: React.FC<AppSidebarViewProps> = ({ collapsed }) => {
  const { t } = useTranslation();
  const userDetail = useAppSelector(state => state.auth.userDetail);
  const location = useLocation();
  const navigate = useNavigate();
  const [menu, setMenu] = useState<any>([]);
  const [openMenu, setOpenMenu] = useState<any>([]);
  const defaultMenuIcon = <AppstoreOutlined />;

  const svgIcons: any = {
    Dashboard: <Dashboard />,
    UsersManagement: <UsersManagement />,
    Roles: <Roles />,
    CitizenManagement: <CitizensManagement />,
    PincodesManagement: <PincodeManagement />,
    "Village/wardManagement": <VillageManagement />,
    MlaCalendar: <MLACalenderManagement />,
    MlaGrantsManagement: <MLAGrantManagement />,
    CitizenMeetingManagement: <CitizensManagement />,
    "Government&NonGovernmentContactForm":<ContactsOutlined />,
    Reports : <Reports />
  };

  const AppMenu = (
    menu_item: any,
    open: any = [],
    parentKey: any = null
  ): any => {
    return menu_item
      ? Object.keys(menu_item).map(key => {
        const item: any = menu_item[key];
        const translatedLabel = `${t(`SIDEBARVIEW.${item.label}`)}` || item.label;
        if (item.children) {
          return {
            key: item.id + "",
            url: item.path,
            // label: item.label,
            label: translatedLabel,   // SideBar multiple language label store json file
            icon:
              svgIcons[camelCaseString(item.label).replaceAll(" ", "")] ??
              defaultMenuIcon,
            children: AppMenu(item.children, open, item.id + ""),
          };
        } else {
          if (
            location.pathname == item.path &&
            openMenu.length <= 0 &&
            parentKey
          ) {
            if (open.length === 0) {
              open.push(parentKey);
            }
            setOpenMenu(open);
          }
          return {
            key: item.path,
            url: item.path,
            // label: item.label,
            label: translatedLabel,  // SideBar multiple language label store json file
            icon:
              svgIcons[camelCaseString(item.label).replaceAll(" ", "")] ??
              defaultMenuIcon,
          };
        }
      })
      : null;
  };

  useEffect(() => {
    const menuItemsTemp = AppMenu(userDetail?.menu);
    setMenu(menuItemsTemp);
  }, [userDetail, location, localStorage.getItem("language")]);

  return (
    <Layout.Sider
      collapsed={collapsed}
      width={Config.sidebar_width}
      className="main__page__appsidebar"
      style={{ backgroundImage: `url(${MenuBG})` }}
    >
      <LogoComponent collapsed={collapsed} />
      <Menu
        mode="inline"
        theme="dark"
        activeKey={location.pathname}
        items={menu}
        onClick={item => navigate(item.key)}
        openKeys={openMenu}
        onOpenChange={openKeys => {
          setOpenMenu(openKeys);
        }}
      />
    </Layout.Sider>
  );
};
export default SidebarView;
