import React from 'react'
import { useState } from "react";
import { Form, DrawerProps, Drawer } from "antd";
import { updateRecord } from '../utils/slice';
import { dataToFormDataConverterNew, validateFields } from '../../../../../config/Global';
import SVGIcon from '../../../../../utils/SVGIcon';
import Config from '../../../../../config/Config';
import FormComponent from './FormComponent';
import { assignErrorToInput } from '../../../../../store/api';
import { useAppDispatch, useAppSelector } from '../../../../../store/app';
import { setTalukaList } from '../../../Administration/TalukaManagement/utils/slice';
import { setSchemeList } from '../../../Administration/SchemeManagement/utils/slice';
import { setVillageList } from '../../../Administration/VillageVsWard/utils/slice';
import { useTranslation } from 'react-i18next';
interface EditDrawerProps extends DrawerProps {
    titleName: string;
    open: boolean;
    close: () => void;
}
const EditComponent: React.FC<EditDrawerProps> = ({
    titleName,
    open,
    close,
    ...rest
}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const details = useAppSelector(state => state.mlaGrant.details);
    const [form] = Form.useForm();
    const [disabled, setDisabled] = useState<boolean>(true);
    const [saving, setSaving] = useState<boolean>(false);
    const [fetchTaluka, setFetchTaluka] = useState<boolean>(true);
    const [fetchVillage, setFetchVillage] = useState<boolean>(true);
    const [fetchScheme, setFetchScheme] = useState<boolean>(true);
    const drawerClose = () => {
        close();
        form.resetFields();
        setDisabled(true);
        setFetchTaluka(true);
        setFetchVillage(true);
        setFetchScheme(true)
        dispatch(setTalukaList([]))
        dispatch(setVillageList([]))
        dispatch(setSchemeList([]))
    };
    const validateForm = () => {
        validateFields(form, setDisabled);
    };
    const handleSubmit = (data: any) => {
        setSaving(true);
        if (data?.attachment.length > 0) {
            data.attachment = data.attachment.filter((obj: any) => !('id' in obj));
        }
        const formData = dataToFormDataConverterNew(data)
        dispatch(updateRecord(details.id, formData))
            .then(() => {
                drawerClose();
            })
            .catch((error: any) => {
                assignErrorToInput(form, error?.STATUS);
            })
            .finally(() => setSaving(false));
    };
    return (
        <>
            <Drawer
                title={`${titleName} ${t("GENERAL.EDIT__DRAWER_TITLE")}`}
                open={open}
                width={"70%"}
                onClose={drawerClose}
                destroyOnClose
                closeIcon={<SVGIcon icon="close" color={Config.themePrimaryBtn} />}
                className="mlaGrantForm"
                {...rest}
            >
                <FormComponent
                    form={form}
                    id="editForm"
                    editValues={details}
                    handleSubmit={handleSubmit}
                    onValuesChange={validateForm}
                    fetchTaluka={fetchTaluka}
                    setFetchTaluka={setFetchTaluka}
                    fetchVillage={fetchVillage}
                    setFetchVillage={setFetchVillage}
                    setFetchScheme={setFetchScheme}
                    fetchScheme={fetchScheme}
                    disabled={disabled}
                    saving={saving}
                    drawerClose={drawerClose}
                    setDisabled={setDisabled}
                />
            </Drawer>
        </>
    )
}
export default EditComponent