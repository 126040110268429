import React, { useEffect, useState } from 'react'
import ToolBar from '../../../components/ToolBar'
import { ReportBreadcrumb } from '../../../config/BreadcrumbConfig'
import { ModuleInfoProps } from '../../../config/InterfacesAndTypes'
import ContentBox from '../../../components/ContentBox/ContentBox'
import ReportForm from './Components/Form/Form'
import { useAppDispatch, useAppSelector } from '../../../store/app'
// import { Button, Divider, Form, Popover } from 'antd'
import { Button, Form, Popover } from 'antd'
import { setTalukaList } from '../Administration/TalukaManagement/utils/slice'
import { b64toBlob, validateFields } from '../../../config/Global'
import { assignErrorToInput } from '../../../store/api'
import { getRecord, setFilterData, setFilters } from './Components/utils/slice'
import Listing from './Components/Listing/Listing'
import { setCategories } from '../../../store/CommonSlice'
import { DownloadOutlined } from "@ant-design/icons";
import { setVillageList } from '../Administration/VillageVsWard/utils/slice'
import { useTranslation } from 'react-i18next'

const Reports: React.FC<ModuleInfoProps> = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const villageReports = useAppSelector(state => state.villageReports)

    const [form] = Form.useForm();
    const [disabled, setDisabled] = useState<boolean>(true);
    const [saving, setSaving] = useState<boolean>(false);
    const [fetchTaluka, setFetchTaluka] = useState<boolean>(true);
    const [fetchVillage, setFetchVillage] = useState<boolean>(true);
    const [fetchCategories, setFetchCategories] = useState<boolean>(true);
    const [open, setOpen] = useState(false);

    const blankForm = () => {
        form.resetFields()
        setDisabled(true)
        setFetchTaluka(true)
        setFetchVillage(true)
        setFetchCategories(true)
        dispatch(setTalukaList([]))
        dispatch(setVillageList([]))
        dispatch(setCategories([]))
        dispatch(setFilterData(null))
    }
    const validateForm = () => {
        validateFields(form, setDisabled)
    }

    const handleFormSubmit = (data: any, type: any) => {
        setSaving(true);
        if (type) {
            data = {
                advance_filter: {
                    ...data,
                    do_export: 1,
                    export_type: type,
                }
            }
            dispatch(getRecord(data))
                .then((res: any) => {
                    var fileType: any
                    if (type === 1) {
                        fileType = "application/pdf"
                    } else {
                        fileType = "text/csv"
                    }
                    const file = b64toBlob(res.rows, fileType);
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL, '_blank');
                })
                .catch((error: any) => {
                    assignErrorToInput(form, error?.STATUS);
                    setSaving(false);
                })
                .finally(() => setSaving(false));
        } else {
            dispatch(setFilters(data))
                .catch((error: any) => {
                    assignErrorToInput(form, error?.STATUS);
                    setSaving(false);
                })
                .finally(() => setSaving(false));
        }
    }
    const handleOpenChange = (newOpen: boolean) => {
        setOpen(newOpen);
    };

    const content = (
        <div className='exportReportFile'>
            {/* <div onClick={() => handleFormSubmit(villageReports?.filter, 1)} >Pdf</div>
            <Divider /> */}
            <div onClick={() => handleFormSubmit(villageReports?.filter, 2)}>{t("REPORTSMANAGEMENT.CSV")}</div>
        </div>
    );

    useEffect(() => {
        blankForm()
    }, []);

    return (
        <>
            <ToolBar breadcrumbs={ReportBreadcrumb(t)} >
                {villageReports.agGrid?.api?.getModel()?.getRowCount() > 0 && villageReports?.filter &&
                    <Popover
                        content={content}
                        trigger="click"
                        open={open}
                        onOpenChange={handleOpenChange}
                        placement='bottom'
                    >
                        <Button type="primary">{t("REPORTSMANAGEMENT.EXPORT_BUTTON")} <DownloadOutlined /></Button>
                    </Popover>
                }
            </ToolBar>
            <ContentBox>
                <ReportForm
                    form={form}
                    id="reportForm"
                    handleSubmit={handleFormSubmit}
                    onValuesChange={validateForm}
                    fetchTaluka={fetchTaluka}
                    setFetchTaluka={setFetchTaluka}
                    fetchVillage={fetchVillage}
                    setFetchVillage={setFetchVillage}
                    disabled={disabled}
                    saving={saving}
                    blankForm={blankForm}
                    setFetchCategories={setFetchCategories}
                    fetchCategories={fetchCategories}
                />
                {villageReports && villageReports.filter && <Listing />}
            </ContentBox>
        </>
    )
}

export default Reports