import React from 'react'
import { ButtonProps } from '../../Buttons/interface/ButtonInterface'
import { Button } from 'antd';
import SVGIcon from '../../../utils/SVGIcon';

const MeetingButton: React.FC<Partial<ButtonProps>> = ({
    action,
    data,
    permission = true,
    iconProps,
    ...rest
}) => {
    if (!permission) {
        return null;
    }
    delete rest?.permissionKey;
    return (
        <Button
            type="text"
            title={"Meeting"}
            className=""
            onClick={() => action && action(data)}
            {...rest}
        >
            <SVGIcon icon="meeting" width={16} {...iconProps} />
        </Button>
    );
}

export default MeetingButton