const baseUrl = "/v1/admin/villages";
const baseUrlCreateVillageDetails = "/v1/admin/villages/mst-village-details";
const baseReligions = "/v1/admin/religions"
export const API_URL = {
  LIST: `${baseUrlCreateVillageDetails}`,
  CREATE: `${baseUrlCreateVillageDetails}/create`,
  UPDATE: (id: number): string => `${baseUrlCreateVillageDetails}/${id}/update`,
  DELETE: (id: number): string => `${baseUrlCreateVillageDetails}/${id}/delete`,
  DETAILS: (id: number): string => `${baseUrlCreateVillageDetails}/${id}/view`,
  CHANGE_STATUS: (id: number): string => `${baseUrlCreateVillageDetails}/${id}/change-status`,
  VILLAGE_LIST: (search?: string): string =>
    `/v1/admin/villages/lov${search ? `?taluka_id=${search}` : ""}`,
  VILLAGE_DETAILS: `${baseUrl}/village-details/create`,
  VILLAGE_DETAILS_UPDATE: (id: number): string => `${baseUrl}/village-details/${id}/update`,
  PARTY_WORKER_LIST: `${baseUrl}/village-party-persons`,
  PARTY_WORKER_ADD: `${baseUrl}/village-party-persons/create`,
  PARTY_WORKER_UPDATE: (id: number): string => `${baseUrl}/village-party-persons/${id}/update`,
  PARTY_WORKER_DELETE: (id: number): string => `${baseUrl}/village-party-persons/${id}/delete`,
  MALNUTRIONED_CHILDRENS_LIST: `${baseUrl}/village-malnutrioned-childrens`,
  MALNUTRIONED_CHILDRENS_ADD: `${baseUrl}/village-malnutrioned-childrens/create`,
  MALNUTRIONED_CHILDRENS_UPDATE: (id: number): string => `${baseUrl}/village-malnutrioned-childrens/${id}/update`,
  MALNUTRIONED_CHILDRENS_DELETE: (id: number): string => `${baseUrl}/village-malnutrioned-childrens/${id}/delete`,
  VILLAGE_DETAILS_VIEW: (id: number): string => `${baseUrl}/village-details/${id}/details`,
  VILLAGE_GOV_SCHEMES_LIST: `${baseUrl}/village-gov-schemes`,
  VILLAGE_UNAVAILABLE_SERVICES: `${baseUrl}/village-unavailable-services`,
  VILLAGE_PROBLEMS: `${baseUrl}/village-problems`,
  VILLAGE_ACHIEVEMENTS: `${baseUrl}/village-achievements`,
  VILLAGE_FESTIVALS: `${baseUrl}/village-festivals`,
  VILLAGE_ATTRACTION: `${baseUrl}/village-attractions`,
  VILLAGE_GOVE_SCHEMES_ADD: `${baseUrl}/village-gov-schemes/create`,
  VILLAGE_GOVE_SCHEMES_DELETE: (id: number): string => `${baseUrl}/village-gov-schemes/${id}/delete`,
  VILLAGE_GOVE_SCHEMES_UPDATE: (id: number): string => `${baseUrl}/village-gov-schemes/${id}/update`,
  VILLAGE_UNAVAILABLE_SCHEMES_ADD: `${baseUrl}/village-unavailable-services/create`,
  VILLAGE_UNAVAILABLE_SCHEMES_DELETE: (id: number): string => `${baseUrl}/village-unavailable-services/${id}/delete`,
  VILLAGE_UNAVAILABLE_SCHEMES_UPDATE: (id: number): string => `${baseUrl}/village-unavailable-services/${id}/update`,
  VILLAGE_PROBLEM_ADD: `${baseUrl}/village-problems/create`,
  VILLAGE_PROBLEM_DELETE: (id: number): string => `${baseUrl}/village-problems/${id}/delete`,
  VILLAGE_PROBLEM_UPDATE: (id: number): string => `${baseUrl}/village-problems/${id}/update`,
  VILLAGE_ACHIEVEMENTS_ADD: `${baseUrl}/village-achievements/create`,
  VILLAGE_ACHIEVEMENTS_DELETE: (id: number): string => `${baseUrl}/village-achievements/${id}/delete`,
  VILLAGE_ACHIEVEMENTS_UPDATE: (id: number): string => `${baseUrl}/village-achievements/${id}/update`,
  VILLAGE_FESTIVALS_Religions: (search?: string): string =>
    `${baseReligions}/lov${search ? `?taluka_id=${search}` : ""}`,
  VILLAGE_FESTIVALS_ADD: `${baseUrl}/village-festivals/create`,
  VILLAGE_FESTIVALS_DELETE: (id: number): string => `${baseUrl}/village-festivals/${id}/delete`,
  VILLAGE_FESTIVALS_UPDATE: (id: number): string => `${baseUrl}/village-festivals/${id}/update`,
  VILLAGE_ATTRACTION_ADD: `${baseUrl}/village-attractions/create`,
  VILLAGE_ATTRACTION_DELETE: (id: number): string => `${baseUrl}/village-attractions/${id}/delete`,
  VILLAGE_ATTRACTION_UPDATE: (id: number): string => `${baseUrl}/village-attractions/${id}/update`,
};