import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import { useAppDispatch, useAppSelector } from '../../../../../store/app';
import { fetchList, setEndDate, setStartDate } from '../../utils/slice';
import { Tooltip } from 'antd';

const CustomView: React.FC<any> = (eventInfo) => {
	return (
		<>
			<b>{eventInfo.timeText}</b>
			<h4>{eventInfo.event.title}</h4>
			<h4>{eventInfo.event.extendedProps.detail}</h4>
			<h5 >{eventInfo.event.extendedProps.place}</h5>
		</>
	);
}

const Listing: React.FC<any> = () => {
	const dispatch = useAppDispatch();
	const rowData = useAppSelector(state => state.mlaEvent.rowData);
	//[		{ title: 'Meeting', start: new Date(), end:  }	]
	const renderEventContent = (eventInfo: any) => {
		return (
			<>
				<b>{eventInfo.timeText}</b>
				<Tooltip title={CustomView(eventInfo)}>
					<i>{eventInfo.event.title}</i>
				</Tooltip>
			</>
		)
	}
	const datesSet = (data: any) => {
		dispatch(setStartDate(data.startStr))
		dispatch(setEndDate(data.endStr))
		const payload = {
			from_date: data.startStr,
			to_date: data.endStr,
		}
		dispatch(fetchList(payload)).catch((error: any) => error);

	}
	return (
		<FullCalendar
			initialView='timeGridWeek'
			events={rowData}
			eventContent={renderEventContent}
			plugins={[dayGridPlugin, timeGridPlugin]}
			headerToolbar={{
				left: 'prev,next today',
				center: 'title',
				right: 'timeGridWeek dayGridMonth'
			}}
			datesSet={datesSet}
		/>
	);
}

export default Listing