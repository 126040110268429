const baseUrl = "/v1/admin/mla/grants"
export const API_URL = {
    LIST: `${baseUrl}`,
    CREATE: `${baseUrl}/create`,
    DETAILS: (id: number): string => `${baseUrl}/${id}/details`,
    DELETE: (id: number): string => `${baseUrl}/${id}/delete`,
    UPDATE: (id: number): string => `${baseUrl}/${id}/update`,
    GRANTYEARLOV: `${baseUrl}/grant_year/lov`,
    GOVDEPTLOV: `${baseUrl}/gov_dept/lov`,
    STATUS: `/v1/admin/mla/grants-detail/status`,
}