export const Messages = {
    NO_RECORD_FOUND: "No Record Found.",
    SELECT_PINCODE: "Please select pincode first",
    SELECT_TALUKA: "Please select taluka first",
    MOBILE_INVALID: "Invalid mobile number",
    SELECT_FROM_DATE: "Please select from date first",
    FROM_DATE_INVALID: "To date is less than from date",
    TOTAL_SUM: "Must be total sum of party is 100",
    TOTAL_VOTER: "Must be less then or equal of population of village",
    TOTAL_VOTING: "Value should be less then equal to 100",
    CITIGEN_REGISTER_MEETING_ERROR:"Please fill any one of the details."
} 