import React from "react";
import LOGO_IMAGE from "../../../assets/images/apps/logo.png";
import SLIDEBAR_LOGO_IMAGE from "../../../assets/images/apps/New-Jan-Utkarsh-Yojna-Logo.png";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../store/app";

const LogoComponent: React.FC<any> = ({ collapsed }) => {

  // const syncData = useAppSelector(state => state.auth.initialData?.syncData)
  const syncDataNew = useAppSelector(state => state?.configuration?.details)

  return (
    <div className="logo__wrapper">
      {collapsed ? (
        <Link to="/">
          <img src={(syncDataNew?.logo) ? (syncDataNew?.logo) : LOGO_IMAGE} alt="LOGO" style={{ maxWidth: 60 }} />
        </Link>
      ) : (
        <Link to="/">
          {/* <img src={(syncDataNew?.logo) ? (syncDataNew?.logo) : LOGO_IMAGE} alt="LOGO" /> */}
          <img src={(syncDataNew?.logo) ? (syncDataNew?.logo) : SLIDEBAR_LOGO_IMAGE} alt="LOGO" />
        </Link>
      )}
    </div>
  );
};

export default LogoComponent;
