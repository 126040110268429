import { ColDef } from "ag-grid-community";
import { dateFormatter, isActiveStatusValue } from "../../../config/Global";
import { renderNA, setDateFilterDefs } from "./commonFunctions";
import SwitchCellRenderer from "./cellRenderer/SwitchCellRenderer";

export const idColumn: ColDef = {
  field: "id",
  headerName: "# ID",
  pinned: "left",
  width: 120,
  minWidth: 120,
  filter: "agNumberColumnFilter",
};

export const srNoColumn = (t: any): ColDef => ({
  field: "srno",
  headerName: t("GENERAL.ID"), // Using the translation function here
  pinned: "left",
  width: 120,
  minWidth: 120,
  filter: false,
  sortable: false,
  suppressMenu: true,
});

export const isActiveColumn = (t: any): ColDef => ({
  field: "is_active",
  headerName: `${t("GENERAL.IS_ACTIVE")}`,
  sortable: false,
  width: 100,
  minWidth: 100,
  cellRenderer: SwitchCellRenderer,
  filter: "agSetColumnFilter",
  filterParams: {
    values: (params: any) => {
      if (isActiveStatusValue) {
        params.success(isActiveStatusValue.map(x => x.name));
      }
    },
    buttons: ["apply", "reset"],
    closeOnApply: true,
  },
});

export const statusColumn: ColDef = {
  field: "display_status",
  headerName: "Status",
  filter: "agSetColumnFilter",
  cellRenderer: (props: any) => {
    return renderNA(props.data.display_status);
  },
  filterParams: {
    values: (params: any) => {
      // const data = ["Active", "Deactivate"];
      const data = ["Active", "Inactive"];
      params.success(data);
    },
    buttons: ["apply", "reset"],
    closeOnApply: true,
  },
};

export const actionColumn = (t: any): ColDef => ({
  field: "",
  headerName: `${t("GENERAL.ACTIONS")}`,
  type: "actionColumn",
  sortable: false,
  filter: false,
  width: 120,
  minWidth: 120,
  pinned: "right",
  suppressMenu: true,
  cellRenderer: "ActionRenderer",
});

export const createdAtColumn: ColDef = {
  field: "created_at",
  headerName: "Created at",
  sortable: true,
  width: 200,
  minWidth: 200,
  cellRenderer: (props: any) => {
    return props.data.created_at ? dateFormatter(props.data.created_at) : "N/A";
  },
  ...setDateFilterDefs()
};

export const updatedAtColumn: ColDef = {
  field: "updated_at",
  headerName: "Updated at",
  sortable: true,
  width: 200,
  minWidth: 200,
  cellRenderer: (props: any) => {
    return props.data.updated_at ? dateFormatter(props.data.updated_at) : "N/A";
  },
  ...setDateFilterDefs()
};
