import { Col, Row } from "antd";
// import React, { useState } from "react";
// import SidebarView from "../../layouts/MainLayout/SidebarView";
// import HeaderView from "../../layouts/MainLayout/HeaderView";
// import FooterView from "../../layouts/MainLayout/FooterView";
// import Config from "../../../config/Config";
import ToolBar from "../../../components/ToolBar";
import { DashboardBreadcrumb } from "../../../config/BreadcrumbConfig";
import SVGIcon from "../../../utils/SVGIcon";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../store/app";
import { checkPrivileges } from "../../../config/Global";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
	// const [collapsed, SetCollapsed] = useState<boolean>(false);
	const userDetail = useAppSelector((state) => state.auth.userDetail);
	const { t } = useTranslation();
	const dashboardData = [
		{
			id: 1,
			icon: <SVGIcon icon="citizenRegister" />,
			title: `${t("DASHBOARD.CITIZEN_REGISTER")}`,
			link: "/citizens",
			permission_key: "CITIZEN_INDEX"
		},
		{
			id: 2,
			icon: <SVGIcon icon="mlaGrant" />,
			title: `${t("DASHBOARD.MLA_GRANTS")}`,
			link:"/grants",
			permission_key: "GRANT_INDEX"
		},
		{
			id: 3,
			icon: <SVGIcon icon="mlaCalendar" />,
			title: `${t("DASHBOARD.MLA_CALENDAR")}`,
			link: "/mla-calender",
			permission_key: "CALENDER_INDEX"
		},
		{
			id: 4,
			icon: <SVGIcon icon="village" />,
			title: `${t("DASHBOARD.VILLAGE_REPORT")}`,
			link:"/villages",
			permission_key: "VILLAGE_INDEX"
		},
		{
			id: 5,
			icon: <SVGIcon icon="reports" />,
			title: `${t("DASHBOARD.REPORT")}`,
			link:"/reports",
			permission_key: "REPORT_DETAIL_INDEX"
		},
	]
	
	return (
		<>
			{/* <Layout className={`main__page__wrapper has__appsidebar`}>
				<SidebarView collapsed={collapsed} />
				<Layout className="site-layout">
					<HeaderView collapsed={collapsed} SetCollapsed={SetCollapsed} />
					<Layout.Content className="main__app__content"> */}
			<ToolBar breadcrumbs={DashboardBreadcrumb(t)} />
			<Row gutter={[20, 20]}>
				{dashboardData.map((item: any) => (
					item.permission_key === " " || checkPrivileges(userDetail, `${item.permission_key}`) ? (
						<Col key={item.id} sm={24} lg={12} xl={6} xxl={6} className="dashboardCol">
							<Link to={item.link}>
								<div className="dashboardCards">
									<div className="iconBox">{item.icon}</div>
									<h3 className="cardTitle">{item.title}</h3>
									<div className="iconBg">{item.icon}</div>
									<div className="rightArrow">
										<SVGIcon icon="rightArrow" />
									</div>
								</div>
							</Link>
						</Col>
					) : null
				))}
			</Row>
			{/* </Layout.Content>
				</Layout>
			</Layout>
			<FooterView /> */}
		</>
	)

};

export default Dashboard;
