import React, { useState } from 'react'
import { useAppSelector } from '../../../../../../store/app';
import ActionButtons from '../../../../../../components/ActionButtons';
import AgGridWrapper from '../../../../../../components/AgGridWrapper/AgGridWrapper';
import { changeStatus, setupGrid } from '../../utils/slice';
import columnDefs from './columnDefs';
import { checkPrivileges } from '../../../../../../config/Global';
import ChangeStatusModal from '../../../../../../components/Modals/ChangeStatusModal';
import { useTranslation } from 'react-i18next';


const Listing: React.FC<any> = ({
    moduleInfo,
	handleViewClick,
	handleDeleteClick,
	handleEditClick,
}) => {
    const { t } = useTranslation();
    const [statusData, setStatusData] = useState<any>();
    const userDetail = useAppSelector(state => state.auth.userDetail);

    const ActionRenderer = (props: any) => {
        return (
            <ActionButtons
                data={props}
                view={{
					action: handleViewClick,
					permissionKey: `${moduleInfo.permissionPrefix}_DETAILS`,
				}}
				edit={{
					action: handleEditClick,
					permissionKey: `${moduleInfo.permissionPrefix}_UPDATE`,
				}}
				deleteButton={{
					action: handleDeleteClick,
					permissionKey: `${moduleInfo.permissionPrefix}_DELETE`,
				}}
            ></ActionButtons>
        )
    }
    return (
        <>
            <ChangeStatusModal
                titleName={t("SCHEMEMANAGEMENT.SCHEME")}
                data={statusData}
                close={() => setStatusData(null)}
                callApi={changeStatus}
                keyName={"name"}
            />
            <AgGridWrapper
                type="serverSide"
                components={{
                    ActionRenderer,
                }}
                onGridReadyWithDispatch={setupGrid}
                columnDefs={columnDefs()}
                context={{
                    setStatusData, changeStatusPermission: checkPrivileges(
                        userDetail,
                        `${moduleInfo.permissionPrefix}_CHANGE_STATUS`
                    )
                }}
            ></AgGridWrapper>
        </>
    )
}

export default Listing