import React, { useEffect } from 'react'
import { FormBox, InputBox } from '../../../../../../components/AntdAddons';
import { Col, Row } from 'antd';
import { rules } from './rules';
import { useTranslation } from 'react-i18next';

const FormComponent: React.FC<any> = ({
    form,
    id,
    handleSubmit,
    onValuesChange,
    editValues,
}) => {
    const { t } = useTranslation();
    useEffect(() => {
        if (editValues) {
            form.setFieldsValue({
                ...editValues,
            });
        }
    }, [editValues, form]);
    const onFinish = (data: any) => {
        handleSubmit(data);
    };
    return (
        <FormBox
            form={form}
            id={id}
            onFinish={onFinish}
            onValuesChange={onValuesChange}
        >
            <Row gutter={15}>
                <Col xs={{ span: 24 }} >
                    <InputBox.Text
                        required
                        label={t("GENERAL.PINCODES")}
                        name="pincode"
                        placeholder={t("GENERAL.PINCODES")}
                        rules={rules.pincode()}
                        maxLength={6}
                    />
                </Col>
            </Row>
        </FormBox>
    )
}

export default FormComponent