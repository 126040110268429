import { Switch } from "antd";

const SwitchCellRenderer = (props: any) => {
  const {
    data,
    context: { setStatusData, changeStatusPermission = true },
  } = props;


  return (
    <div>
      <Switch
        checked={data?.is_active == 1}
        disabled={!changeStatusPermission}
        onClick={() => {
          // setStatusData({ id: data?.id, status: Number(!data?.is_active), name: data });
          setStatusData(data);
        }}
      />
    </div>
  );
};
export default SwitchCellRenderer;
