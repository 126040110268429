import React from 'react'
// import { CONSTANT } from '../../../../../../config/Constant'
// import { useAppDispatch, useAppSelector } from '../../../../../../store/app';
// import { getReligionsloc, getVillageFestivalsList } from '../../utils/slice';
// import { Button, Drawer, Table } from 'antd';
// import SVGIcon from '../../../../../../utils/SVGIcon';
// import Config from '../../../../../../config/Config';
// import CancelButton from '../../../../../../components/Buttons/CancelButton';
// import { useParams } from 'react-router-dom';
// import { tablePagination } from '../../../../../../config/Global';
import { DrawerProps } from 'antd/lib';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';


interface VillageFestivalViewProps extends DrawerProps {
    // open: boolean;
    // close: () => void;
    // id: any
    // saveAndNext: any;
    // dynamicSteps: any
    villageFestival: any
}

const VillageFestivalView: React.FC<VillageFestivalViewProps> = ({
    // open,
    // close,
    // id,
    // saveAndNext,
    // dynamicSteps,
    // ...rest
    villageFestival
}) => {
    const { t } = useTranslation();
    // const { action } = useParams();
    // const dispatch = useAppDispatch();
    // const [festivalList, setVillageFestivalList] = useState<any>([]);
    // const is_active = 1
    // const religionList = useAppSelector(state => state.villageDetails.religionDetails)
    // const [pagination, setPagination] = useState(tablePagination);

    // const continueBtn = () => {
    //     saveAndNext(CONSTANT.STEP.VILLAGE_ATTRACTION)
    // }

    // useEffect(() => {
    //     if (id && action === CONSTANT.DRAWER_TYPE.VIEW && dynamicSteps === CONSTANT.STEP.VILLAGE_FESTIVAL) {

    //         dispatch(getVillageFestivalsList({ village_id: id }))
    //             .then((res: any) => {
    //                 setVillageFestivalList(res.rows)
    //             })
    //             .catch((e: any) => e)
    //         dispatch(getReligionsloc({ is_active: is_active }))
    //             .catch((e: any) => e)
    //     }
    // }, [dynamicSteps])
    const columns: any = [
        {
            title: `${t("GENERAL.NAME")}`,
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            width: 50,
        },
        {
            title: `${t("VILLAGEMANAGEMENT.RELIGION")}`,
            dataIndex: 'religion_name',
            key: 'religion_name',
            width: 80,
            // render:(text:any) => {
            //     // const schemeName:any = religionList.find((item:any) => item.id == text)
            //     debugger
            //     return (
            //       <span>{schemeName.name}</span>
            //     )
            //   }
        },
        {
            title: `${t("GENERAL.FROM_DATE")}`,
            dataIndex: 'from_date',
            key: 'from_date',
            width: 80,
        },
        {
            title: `${t("GENERAL.TO_DATE")}`,
            dataIndex: 'to_date',
            key: 'to_date',
            width: 80,
        }
        // {
        // 	title: 'Action',
        // 	key: 'action',
        // 	render: (text: any, record: any) => (
        // 		<div className='listActionbtn'>
        // 			<span onClick={() => handleAddEdit(record, "editModal")}>
        // 				<EditOutlined />
        // 			</span>
        // 			<span onClick={() => deleteModal(record)}>
        // 				<DeleteOutlined />
        // 			</span>
        // 		</div>
        // 	),
        // },
    ];
    // const drawerClose = () => {
    //     close();
    //     setVillageFestivalList([])
    // };
    return (
        <Table
            dataSource={villageFestival}
            columns={columns}
            pagination={false}
            scroll={{ x: 992 }}
        />
        // <Drawer
        //     title={`${VILLAGEMANAGEMENT.VILLAGE_FESTIVAL} ${GENERAL.VIEW__DRAWER_TITLE}`}
        //     open={open}
        //     placement="right"
        //     width={"70%"}
        //     onClose={drawerClose}
        //     destroyOnClose
        //     closeIcon={<SVGIcon icon="close" color={Config.themePrimaryBtn} />}
        //     footer={[
        //         <CancelButton key={2} onClick={drawerClose} />,
        //         <Button
        //             htmlType="button"
        //             className="cancelBtn"
        //             key="2"
        //             type="primary"
        //             onClick={continueBtn}
        //         >
        //             {GENERAL.CONTINUE_BUTTON}
        //         </Button>
        //     ]}
        //     {...rest}
        // >
        //     <Table
        //         dataSource={festivalList}
        //         columns={columns}
        //         onChange={(pagination: any) => setPagination(pagination)}
        //         pagination={pagination} // Apply pagination configuration
        //     />
        // </Drawer>
    );
}

export default VillageFestivalView