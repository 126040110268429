import { ColDef } from "ag-grid-community";
import {
	srNoColumn,
	actionColumn,
	isActiveColumn,
} from "../../../../../../components/AgGridWrapper/utils/commonColumns";
import { renderNA } from "../../../../../../components/AgGridWrapper/utils/commonFunctions";
import { useTranslation } from "react-i18next";


const columnDefs = (): ColDef[] => {
	const { t } = useTranslation();
	const talukaColumn: ColDef = {
		field: "name",
		headerName: `${t("GENERAL.TALUKA")}`,
		cellRenderer: (props: any) => {
			return renderNA(props.data?.name);
		},
	};

	const pincodeColumn: ColDef = {
		field: "pincode",
		headerName: `${t("GENERAL.PINCODES")}`,
		cellRenderer: (props: any) => {
			return renderNA(props.data.pincode);
		},
	};

	return [
		srNoColumn(t),
		talukaColumn,
		pincodeColumn,
		isActiveColumn(t),
		{ ...actionColumn(t), width: 165, minWidth: 165 },
	];
};
export default columnDefs;
