import { ColDef } from "ag-grid-community";
import { renderNA } from "../../../../../../components/AgGridWrapper/utils/commonFunctions";
import { srNoColumn, isActiveColumn, actionColumn } from "../../../../../../components/AgGridWrapper/utils/commonColumns";
import { villageType, villageTypeDisplay } from "../../../../../../config/Global";
import { useTranslation } from "react-i18next";

const columnDefs = (): ColDef[] => {
	const { t } = useTranslation();
	const villageTypeColumn: ColDef = {
		field: "village_type_id",
		headerName: `${t("GENERAL.NAGERPALLIKA_VILLAGE_TYPE")}`,
		cellRenderer: (props: any) => {
			return renderNA(villageTypeDisplay[props?.data?.village_type_id]);
		},
		filter: "agSetColumnFilter",
		filterParams: {
			values: (params: any) => {
				if (villageType) {
					params.success(villageType.map(x => x.name));
				}
			},
			buttons: ["apply", "reset"],
			closeOnApply: true,
		},
	};
	const villageColumn: ColDef = {
		field: "name",
		headerName: `${t("GENERAL.VILLAGES")}`,
		cellRenderer: (props: any) => {
			return renderNA(props.data?.name);
		},
	};

	const talukaColumn: ColDef = {
		field: "taluka_name",
		headerName: `${t("GENERAL.TALUKA")}`,
		cellRenderer: (props: any) => {
			return renderNA(props.data.taluka_name);
		},
	};

	const pincodeColumn: ColDef = {
		field: "pincode",
		headerName: `${t("GENERAL.PINCODES")}`,
		cellRenderer: (props: any) => {
			return renderNA(props.data.pincode);
		},
	};

	return [
		srNoColumn(t),
		villageColumn,
		villageTypeColumn,
		talukaColumn,
		pincodeColumn,
		isActiveColumn(t),
		{ ...actionColumn(t), width: 165, minWidth: 165 },
	];
};
export default columnDefs;
