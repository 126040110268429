import { Button, ButtonProps } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const CancelButton: React.FC<ButtonProps> = ({ onClick, ...rest }) => {
  const { t } = useTranslation();
  return (
    <Button htmlType="button" className="cancelBtn" onClick={onClick} {...rest}>
      {t("GENERAL.CANCEL_BUTTON")}
    </Button>
  );
};

export default CancelButton;
