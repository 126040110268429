import { ColDef } from "ag-grid-community";
import { renderNA } from "../../../../components/AgGridWrapper/utils/commonFunctions";
import { srNoColumn, actionColumn } from "../../../../components/AgGridWrapper/utils/commonColumns";
import { useTranslation } from "react-i18next";
import { govDeptLov } from "../../MLAGrantManagement/Components/utils/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/app";
import { useEffect } from "react";

const columnDefs = (): ColDef[] => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch()
    const { gov_deptList } = useAppSelector(
        state => state.mlaGrant
    );

    useEffect(() => {
        dispatch(govDeptLov()).catch((e: any) => e)
    }, [dispatch])

    const firstNameColumn: ColDef = {
        field: "fname",
        headerName: `${t("GENERAL.FIRST_NAME")}`,
        cellRenderer: (props: any) => {
            return renderNA(props.data?.fname);
        },
    };
    const middleNameColumn: ColDef = {
        field: "middle_name",
        headerName: `${t("GENERAL.MIDDLE_NAME")}`,
        cellRenderer: (props: any) => {
            return renderNA(props.data?.middle_name);
        },
    };
    const lastNameColumn: ColDef = {
        field: "lname",
        headerName: `${t("GENERAL.Last_NAME")}`,
        cellRenderer: (props: any) => {
            return renderNA(props.data?.lname);
        },
    };
    const departmentColumn: ColDef = {
        field: "dept_id",
        headerName: `${t("GOVERNMENTNONGOVERMENTCONTACTMANAGEMENT.DEPARTMENT")}`,
        cellRenderer: (props: any) => {
            return renderNA(props.data?.department?.dept);
        },
        filter: "agSetColumnFilter",
        filterParams: {
            values: (params: any) => {

                if (gov_deptList) {
                    params.success(gov_deptList.map((x: any) => x.dept));
                }
            },
            buttons: ["apply", "reset"],
            closeOnApply: true,
        },
    };
    const designationColumn: ColDef = {
        field: "designation",
        headerName: `${t("GOVERNMENTNONGOVERMENTCONTACTMANAGEMENT.DESIGNNATION")}`,
        cellRenderer: (props: any) => {
            return renderNA(props.data?.designation);
        },
    };
    const mobileNumberColumn: ColDef = {
        field: "mobile_number",
        headerName: `${t("GENERAL.MOBILE_NUMBER")}`,
        cellRenderer: (props: any) => {
            return renderNA(props.data.mobile_number);
        },
    };
    return [
        srNoColumn(t),
        firstNameColumn,
        middleNameColumn,
        lastNameColumn,
        departmentColumn,
        designationColumn,
        mobileNumberColumn,
        actionColumn(t)
    ];
};
export default columnDefs;