import { Messages } from "../../../../../../config/Messages";
import { formRules } from "../../../../../../config/validations/formRules";
import { maxDescription, maxName, maxVoting, minDescription, minName, minVoting, validations } from "../../../../../../config/validations/validations";



export const rules: any = {
  totalSum: (field: any, partyFields: any) => [
    validations.required.number(field),
    ({ getFieldValue }: any) => ({
      validator() {
        let totalSum = 0;
        partyFields.map((item: any) => totalSum += (getFieldValue(item.name) ?? 0));
        if (totalSum !== 100) {
          return Promise.reject(Messages.TOTAL_SUM);
        }

        return Promise.resolve();
      },
    }),
  ],
  voter: (field?: string) => [
    validations.required.number(field),
    ({ getFieldValue }: any) => ({
      validator(_: any, value: any) {
        const population = getFieldValue("population")
        if (population < value) {
          return Promise.reject(Messages.TOTAL_VOTER);
        }
        return Promise.resolve();
      },
    }),
  ],
  dateOfBirth: (field?: string) => [
    validations.required.other(field),
  ],
  pincode: (field?: string) => [
    validations.required.other(field),
  ],
  taluka: (field?: string) => [
    validations.required.other(field),
    ({ getFieldValue }: any) => ({
      validator() {
        if (getFieldValue("pincode_id") === undefined) {
          return Promise.reject(Messages.SELECT_PINCODE);
        }
        return Promise.resolve();
      },
    }),
  ],
  gender: (field?: string) => [
    validations.required.other(field),
  ],
  date: (field?: string) => [
    validations.required.other(field),
  ],
  village: (field?: string) => [
    validations.required.other(field),
  ],
  population: (field?: string) => [
    validations.required.number(field),
  ],
  bjp: (field?: string) => [
    validations.required.number(field),
    validations.min.number(minVoting),
    validations.max.number(maxVoting),
  ],
  voting: (field?: string) => [
    validations.required.number(field),
    validations.min.number(minVoting),
    validations.max.number(maxVoting),
    ({ getFieldValue }: any) => ({
      validator() {
        // old : getFieldValue("total_voting") < 100
        if (getFieldValue("total_voting") > 100) {
          return Promise.reject(Messages.TOTAL_VOTING);
        }
        return Promise.resolve();
      },
    }),
  ],
  // party worker validation start
  name: (field?: string) => [
    validations.required.text(field),
    validations.min.text(minName),
    validations.max.text(maxName),
  ],
  mobileNumber: () => formRules.mobileNumber(),
  partyPersonType: (field?: string) => [
    validations.required.other(field),
  ],
  age: (field?: string) => [
    validations.pattern.numeric(),
    validations.required.number(field),
  ],
  deficiency: (field?: string) => [
    validations.required.text(field),
    validations.min.text(minDescription),
    validations.max.text(maxDescription),
  ],
  caste: (field?: string) => [
    validations.required.text(field),
    validations.min.text(minDescription),
    validations.max.text(maxDescription),
  ],
};
