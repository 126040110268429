import { ColDef } from "ag-grid-community";
import { srNoColumn, actionColumn, isActiveColumn } from "../../../../../../components/AgGridWrapper/utils/commonColumns";
import { renderNA } from "../../../../../../components/AgGridWrapper/utils/commonFunctions";
import { useTranslation } from "react-i18next";

const columnDefs = (): ColDef[] => {
  const { t } = useTranslation();
    const firstNameColumn: ColDef = {
        field: "name",
        headerName: `${t("GENERAL.NAME")}`,
        cellRenderer: (props: any) => {
          return renderNA(props.data?.name);
        },
      };
    return [srNoColumn(t),
        firstNameColumn,
        isActiveColumn(t),
        { ...actionColumn(t), width: 165, minWidth: 165 },]
}
export default columnDefs;
