import React from 'react'
import { FormBox, InputBox } from '../../../../../components/AntdAddons'
import { Button, Col, Row, Upload, message } from 'antd'
import {
    EditOutlined
} from '@ant-design/icons';
import CommonModal from '../../../../../components/Modals/CommonModal';
import { CONSTANT } from '../../../../../config/Constant';
import { useAppSelector } from '../../../../../store/app';

const SystemConfigurationComponent: React.FC<any> = ({
    openFromModal,
    setOpenFromModal,
    form,
    onFinish,
    onValuesChange,
    disabled,
    saving,
    closeModal
}) => {
    const details = useAppSelector(state => state.configuration.details)
    const openModal = (modal: any) => {
        setOpenFromModal(modal)
        form.setFieldsValue({
            hero_image: details.hero_image && `${details.hero_image}?t=${Math.random()}`,
            favicon: details.favicon && `${details.favicon}?t=${Math.random()}`,
            logo: details.logo && `${details.logo}?t=${Math.random()}`,
        })
    }

    const checkFileType = (file: any) => {
        const isLt5M = file.size / 1024 / 1024 < 1;
        const acceptedFormats = [
            "image/png",
            "image/jpeg",
            "image/jpg",
            "image/vnd.microsoft.icon"
        ];
        if (!acceptedFormats.includes(file.type)) {
            message.error(CONSTANT.INVALID_FILE);
            return Upload.LIST_IGNORE;
        }
        if (!isLt5M) {
            message.error(CONSTANT.BIGGER_SIZE);
            return Upload.LIST_IGNORE;
        } else {
            return false;
        }
    };
    const handlePreview = async (file: any) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow: any = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };
    return (
        <div>
            <Row gutter={[30, 30]}>
                <Col xl={24} >
                    <div className='imageColumn'>
                        <div className='imageAndTitle'>
                            <h3>Hero Image</h3>
                            <Button
                                onClick={() => openModal(CONSTANT.SYSTEM_CONFIG.HERO_IMG)}
                                className='ant-btn-text ant-btn-lg editIcon'>
                                <EditOutlined />
                            </Button>
                        </div>
                        {details && <img
                            src={details?.hero_image && `${details?.hero_image}?t=${Math.random()}`}
                            alt='hero_img'
                        />}
                    </div>
                </Col>
                <Col xl={12} >
                    <div className='imageColumn'>
                        <div className='imageAndTitle'>
                            <h3>Favicon Image</h3>
                            <Button
                                onClick={() => openModal(CONSTANT.SYSTEM_CONFIG.FAV_IMG)}
                                className='ant-btn-text ant-btn-lg editIcon'>
                                <EditOutlined />
                            </Button>
                        </div>
                        <img
                            src={details?.favicon && `${details?.favicon}?t=${Math.random()}`}
                            alt='favicon'
                        />
                    </div>
                </Col>
                <Col xl={12} >
                    <div className='imageColumn'>
                        <div className='imageAndTitle'>
                            <h3>Logo Image</h3>
                            <Button
                                onClick={() => openModal(CONSTANT.SYSTEM_CONFIG.LOGO)}
                                className='ant-btn-text ant-btn-lg editIcon'>
                                <EditOutlined />
                            </Button>
                        </div>
                        <img
                            src={details?.logo && `${details?.logo}?t=${Math.random()}`}
                            alt='logo'
                        />
                    </div>
                </Col>
            </Row>
            <CommonModal
                open={openFromModal === CONSTANT.SYSTEM_CONFIG.HERO_IMG}
                title="Change Hero Image"
                onCancel={() => closeModal()}
                destroyOnClose
                footer={[
                    <Button
                        key="1"
                        disabled={disabled}
                        form={'configForm'}
                        loading={saving}
                        htmlType="submit"
                        type="primary"
                    >
                        Save
                    </Button>,
                    <Button
                        key="2"
                        htmlType="button"
                        className="cancelBtn"
                        onClick={() => closeModal()}
                    >
                        Cancel
                    </Button>
                ]}
            >
                <FormBox
                    form={form}
                    onFinish={(data: any) => onFinish(data, CONSTANT.SYSTEM_CONFIG.HERO_IMG)}
                    onValuesChange={onValuesChange}
                    id='configForm'

                >
                    <Row gutter={15}>
                        <Col xl={24}>
                            <InputBox.Upload
                                required={false}
                                name="hero_image"
                                maxCount={1}
                                multiple={true}
                                fileList={form.getFieldValue("hero_image")}
                                beforeUpload={checkFileType}
                                onPreview={handlePreview}
                            />

                        </Col>
                        <Col xl={24}>
                            <ul className='helpText' >
                                <li>Valid formats are JPEG, JPG and PNG</li>
                                <li>Image must be landscape.</li>
                                <li>Image dimension should be 1500 width X 900 height or in proportion to it</li>
                                <li>Image size should not exceed 1 MB</li>
                            </ul>
                        </Col>
                    </Row>
                </FormBox>
            </CommonModal>
            <CommonModal
                open={openFromModal === CONSTANT.SYSTEM_CONFIG.FAV_IMG}
                title="Change Favicon Image"
                onCancel={() => closeModal()}
                destroyOnClose
                footer={[
                    <Button
                        key="1"
                        disabled={disabled}
                        form={'favIconForm'}
                        loading={saving}
                        htmlType="submit"
                        type="primary"
                    >
                        Save
                    </Button>,
                    <Button
                        key="2"
                        htmlType="button"
                        className="cancelBtn"
                        onClick={() => closeModal()}
                    >
                        Cancel
                    </Button>
                ]}
            >
                <FormBox
                    form={form}
                    onFinish={(data: any) => onFinish(data, CONSTANT.SYSTEM_CONFIG.FAV_IMG)}
                    onValuesChange={onValuesChange}
                    id='favIconForm'
                >
                    <Row gutter={15}>
                        <Col sm={12}>
                            <InputBox.Upload
                                required={false}
                                name="favicon"
                                maxCount={1}
                                multiple={true}
                                fileList={form.getFieldValue("favicon")}
                                beforeUpload={checkFileType}
                                onPreview={handlePreview}
                            />

                        </Col>
                        <Col>
                            <ul className="helpText">
                                <li>Valid formats are JPEG, JPG and PNG</li>
                                <li>Image must be squre, meaning width and height must be same
                                </li>
                                <li>Image dimension should be 16 width X 16 height or in proportion to it
                                </li>
                                <li>Image size should not exceed 100 KB</li>
                            </ul>
                        </Col>
                    </Row>
                </FormBox>
            </CommonModal>
            <CommonModal
                open={openFromModal === CONSTANT.SYSTEM_CONFIG.LOGO}
                title="Change logo Image"
                onCancel={() => closeModal()}
                destroyOnClose
                footer={[
                    <Button
                        key="1"
                        disabled={disabled}
                        form={'logoForm'}
                        loading={saving}
                        htmlType="submit"
                        type="primary"
                    >
                        Save
                    </Button>,
                    <Button
                        key="2"
                        htmlType="button"
                        className="cancelBtn"
                        onClick={() => closeModal()}
                    >
                        Cancel
                    </Button>
                ]}
            >
                <FormBox
                    form={form}
                    onFinish={(data: any) => onFinish(data, CONSTANT.SYSTEM_CONFIG.LOGO)}
                    onValuesChange={onValuesChange}
                    id='logoForm'
                >
                    <Row gutter={15}>
                        <Col xl={24}>
                            <InputBox.Upload
                                required={false}
                                name="logo"
                                maxCount={1}
                                multiple={true}
                                fileList={form.getFieldValue("logo")}
                                beforeUpload={checkFileType}
                                onPreview={handlePreview}
                            />

                        </Col>
                        <Col xl={24}>
                            <ul className="helpText">
                                <li>Valid formats are JPEG, JPG and PNG</li>
                                <li>Image must be landscape.</li>
                                <li>Image dimension should be 200 width X 75 height or in proportion to it</li>
                                <li>Image size should not exceed 100 KB</li>
                            </ul>
                        </Col>
                    </Row>
                </FormBox>
            </CommonModal>
        </div>
    )
}
export default SystemConfigurationComponent