
import { Messages } from "../../../../../config/Messages";
import { formRules } from "../../../../../config/validations/formRules";
import { maxDescription, maxName, maxPincode, maxTaluka, minName, minPincode, minTaluka, validations } from "../../../../../config/validations/validations";

export const rules: any = {
  pincode: (field?: string) => [
    validations.required.other(field),
  ],
  gender: (field?: string) => [
    validations.required.other(field),
  ],
  villageType: (field?: string) => [
    validations.required.other(field),
  ],
  email: (field?: string) => [
    validations.email(field),
  ],
  mobileNumber: () => formRules.mobileNumber(),
  whatsAppNumber: () => formRules.whatsNumber(),
  taluka: (field?: string) => [
    validations.required.other(field),
    ({ getFieldValue }: any) => ({
      validator() {
        if (getFieldValue("pincode_id") === undefined) {
          return Promise.reject(Messages.SELECT_PINCODE);
        }
        return Promise.resolve();
      },
    }),
  ],
  village: (field?: string) => [
    validations.required.other(field),
    ({ getFieldValue }: any) => ({
      validator() {
        if (getFieldValue("taluka_id") === (undefined || null)) {
          return Promise.reject(Messages.SELECT_TALUKA);
        }
        return Promise.resolve();
      },
    }),
  ],
  name: () => [
    validations.min.text(minName),
    validations.max.text(maxName),
  ],
  address: () => [
    validations.min.text(minName),
    validations.max.text(maxDescription),
  ],
  newPincode: (field?: string) => [
    validations.required.text(field),
    validations.pattern.numeric(),
    validations.min.text(minPincode),
    validations.max.text(maxPincode),
  ],
  newtaluka: (field?: string) => [
    validations.required.text(field),
    validations.min.text(minTaluka),
    validations.max.text(maxTaluka),
    ({ getFieldValue }: any) => ({
      validator() {
        if (getFieldValue("pincode_id") === undefined) {
          return Promise.reject(Messages.SELECT_PINCODE);
        }
        return Promise.resolve();
      },
    }),
  ],
  newvillage: (field?: string) => [
    validations.required.text(field),
    validations.min.text(minTaluka),
    validations.max.text(maxTaluka),
  ],
  dateOfBirth: (field?: string) => [
    validations.required.other(field),
  ],
};
