import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "../../../../store/api";
import { AppThunk } from "../../../../store/app";
import { API_URL } from "./apiUrl";
import { initialAppOptions } from "../../../../store/AuthSlice";
import { CONSTANT } from "../../../../config/Constant";
interface InitialState {
    details: any;
}
const initialState = {
    details: null,
} as InitialState;
const systemConfigurationSlice = createSlice({
    name: "systemConfiguration",
    initialState,
    reducers: {
        setDetails: (state, action: PayloadAction<any>) => {
            state.details = action?.payload;
        },
    }
})
export const { setDetails } =
    systemConfigurationSlice.actions;
export const getDetails = (): AppThunk<any> =>
    async dispatch => {
        try {
            const response = await api.get(API_URL.GET_DETAILS);
            dispatch(setDetails(response.data.data));
            return Promise.resolve(response.data);
        } catch (error: any) {
            return Promise.reject(error.data);
        }
    };
export const ConfigImag = (action: any, type: any): AppThunk<any> =>
    async dispatch => {
        const response = await api.post(API_URL.UPLOAD, action)
        dispatch(getDetails())
        if (type === CONSTANT.SYSTEM_CONFIG.HERO_IMG) {
            changeBrandingLocalStorage("hero_image", `${response.data.url}?t=${Math.random()}`)
        } else if (type === CONSTANT.SYSTEM_CONFIG.FAV_IMG) {
            changeBrandingLocalStorage("favicon", `${response.data.url}?t=${Math.random()}`)
        } else {
            changeBrandingLocalStorage("logo", `${response.data.url}?t=${Math.random()}`)
        }
        dispatch(initialAppOptions());
    }
export const updateDetails = (action: any): AppThunk<any> =>
    async dispatch => {
        try {
            const response = await api.post(API_URL.UPDATE_DETAILS, action)
            changeBrandingLocalStorage("brand_name", response.data.brand_name)
            changeBrandingLocalStorage("office_address", response.data.office_address)
            changeBrandingLocalStorage("office_name", response.data.office_name)
            dispatch(getDetails())
            return Promise.resolve(response.data)
        } catch (error: any) {
            return Promise.reject(error.data)
        }
    }
const changeBrandingLocalStorage = (key: any, value: any) => {
    let initialData: any = localStorage.getItem("initialData");
    initialData = JSON.parse(initialData);
    initialData.syncData[key] = value;
    localStorage.setItem("initialData", JSON.stringify(initialData));
};
const systemConfigurationReducer = systemConfigurationSlice.reducer
export default systemConfigurationReducer