import React, { useEffect } from 'react'
import { Col, Row, Spin } from 'antd';
import { rules } from './rules';
import { useAppDispatch, useAppSelector } from '../../../../../../store/app';
import { FormBox, InputBox } from '../../../../../../components/AntdAddons';
import { Messages } from '../../../../../../config/Messages';
import { fetchVillageList, setVillageList } from '../../../VillageVsWard/utils/slice';
import { useTranslation } from 'react-i18next';

const FormComponent: React.FC<any> = ({
    form,
    id,
    handleSubmit,
    onValuesChange,
    editValues,
    fetchVillage,
    setFetchVillage
}) => {
    const { t } = useTranslation();
    const is_active = 1
    const dispatch = useAppDispatch()
    const { villageList } = useAppSelector(
        state => state.village
    );

    // useEffect(() => {
    //     dispatch(fetchPincodeList()).catch((e: any) => e)
    //     dispatch(fatchTalukaList()).catch((e: any) => e)
    // }, [])


    useEffect(() => {

        if (editValues) {
            if (editValues?.village) {
                dispatch(setVillageList([editValues.village]))
            }
            form.setFieldsValue({
                ...editValues,
            });
        }
    }, [editValues, form]);
    const onFinish = (data: any) => {
        handleSubmit(data);
    };

    return (
        <FormBox
            form={form}
            id={id}
            onFinish={onFinish}
            onValuesChange={onValuesChange}
        >
            <Row gutter={15}>
                <Col xs={{ span: 12 }} >
                    <InputBox.Select
                        required
                        showSearch
                        name="village_id"
                        optionFilterProp="children"
                        label={t("GENERAL.VILLAGES")}
                        placeholder={t("GENERAL.VILLAGES")}
                        onFocus={() => {
                            fetchVillage
                            dispatch(fetchVillageList({ is_active: is_active }))
                                .then(() => setFetchVillage(false))
                                .catch((e: any) => e)
                                .finally(() => setFetchVillage(false))
                        }}
                        notFoundContent={fetchVillage ? <Spin size="small" /> : Messages.NO_RECORD_FOUND}
                        options={{
                            list: villageList,
                            valueKey: "id",
                            textKey: "name",
                        }}
                        rules={rules.village()}
                    />
                </Col>
                <Col xs={{ span: 12 }} >
                    <InputBox.MonthPicker
                        // label={GENERAL.DATE}
                        label={t("VILLAGEMANAGEMENT.DATE")}
                        name="date"
                        // format={CONSTANT.DATE_FORMAT}
                        allowClear={false}
                        picker="month"
                        rules={rules.date()}
                    />
                </Col>
            </Row>
        </FormBox>
    )
}

export default FormComponent