import { formRules } from "../../../../config/validations/formRules";
import { maxDescription, maxName, maxTelNumber, minName, minTelNumber, validations } from "../../../../config/validations/validations";

export const rules: any = {
    fName: (field?: string) => [
        validations.required.text(field),
        validations.min.text(minName),
        validations.max.text(maxName)
    ],
    non_required: () => [
        validations.min.text(minName),
        validations.max.text(maxName)
    ],
    requiredFields: (field?: string) => [
        validations.required.other(field),
    ],
    mobileNumber: () => formRules.mobileNumber(),
    telNumber: () => [
        validations.pattern.mobile(),
        validations.min.text(minTelNumber),
        validations.max.text(maxTelNumber),
    ],
    email: (field?: string) => [
        validations.email(field),
    ],
    address: () => [
        validations.min.text(minName),
        validations.max.text(maxDescription),
    ],
}