import React, { useEffect, useState } from 'react'
import { Button, Col, Drawer, DrawerProps, Form, Row, Table } from 'antd'
import { assignErrorToInput } from '../../../../../../store/api';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import CommonModal from '../../../../../../components/Modals/CommonModal';
import { CONSTANT } from '../../../../../../config/Constant'
import { PlusOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from '../../../../../../store/app';
import { genderOptions, genderOptionsDisplay, tablePagination, validateFields } from '../../../../../../config/Global';
import { FormBox, InputBox } from '../../../../../../components/AntdAddons';
import { rules } from '../Form/rules';
import { addVillageGovernmentSchemes, deleteVillageGovernmentSchemes, getGovSchemesList, updateVillageGovernmentSchemes } from '../../utils/slice';
import { fetchschemeList } from '../../../SchemeManagement/utils/slice';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
interface VillageGovSchemesDrawerProps extends DrawerProps {
	saveAndNext: any;
	open: any;
	villageId: any;
	dynamicSteps: any;
	villageDetailsId: any
	close: () => void;
}
const VillageGovSchemes: React.FC<VillageGovSchemesDrawerProps> = ({
	close,
	open,
	saveAndNext,
	villageId,
	dynamicSteps,
	villageDetailsId,
	...rest
}) => {
	const { t } = useTranslation();
	const { action } = useParams();
	const dispatch = useAppDispatch();
	const [addEditForm] = Form.useForm();
	const [disabled, setDisabled] = useState<boolean>(true);
	const [saving, setSaving] = useState<boolean>(false);
	const [villageGovSchemesList, setVillageGovSchemesList] = useState<any>([]);
	const [openDeleteModal, setOpenDeleteModal] = useState<any>();
	const [openAddEditModal, setOpenAddEditModal] = useState<any>();
	const [openAddEditForm, setOpenAddEditForm] = useState<any>();
	const [pagination, setPagination] = useState(tablePagination);
	const is_active = 1
	const { schemeList } = useAppSelector(
		state => state.scheme
	);
	const drawerClose = (submitForm = "") => {
		if (submitForm === "") {
			close()
		} else {
			saveAndNext(CONSTANT.STEP.VILLAGE_UNAVAILABLE_SERVICES)
		}
		setVillageGovSchemesList([])
		addEditForm.resetFields();
		setDisabled(true);
	}
	useEffect(() => {
		if (villageDetailsId && action === CONSTANT.DRAWER_TYPE.EDIT && dynamicSteps === CONSTANT.STEP.VILLAGE_GOV_SCHEMES) {
			dispatch(getGovSchemesList({ village_detail_id: villageDetailsId, village_id: villageId }))
				.then((res: any) => {
					if (res.count > 0) {
						setVillageGovSchemesList([...res.rows])
					} else {
						setVillageGovSchemesList(res.rows)
					}
				})
				.catch((e: any) => e)
			dispatch(fetchschemeList({ is_active: is_active }))
				.catch((e: any) => e)
		}
	}, [dynamicSteps])
	const handleAddEdit = (data: any, openForm: any) => {
		setOpenAddEditForm(openForm)
		if (openForm === "editModal") {
			const setValues = villageGovSchemesList.find((item: any) => item.id === data.id);
			addEditForm.setFieldsValue({
				...setValues
			})
			setOpenAddEditModal(data)
		} else {
			setOpenAddEditModal(true)
		}
	}
	const deleteModal = (data: any) => {
		setOpenDeleteModal(data)
	}
	const columns = [
		{
			title: t("GENERAL.FIRST_NAME"),
			dataIndex: 'first_name',
			key: 'first_name',
		},
		{
			title: t("GENERAL.MIDDLE_NAME"),
			dataIndex: 'middle_name',
			key: 'middle_name',
		},
		{
			title: t("GENERAL.Last_NAME"),
			dataIndex: 'last_name',
			key: 'last_name',
		},
		{
			title: `${t("GENERAL.GENDER")}`,
			dataIndex: 'gender',
			key: 'gender',
			render: (text: any) => {
				return (
					<span>{genderOptionsDisplay[text]}</span>
				)
			}
		},
		{
			title: `${t("VILLAGEMANAGEMENT.CONTECT_NUMBER")}`,
			dataIndex: 'phone',
			key: 'phone',
		},
		{
			title: `${t("GENERAL.NAME_OF_SHCEME")}`,
			dataIndex: 'scheme_id',
			key: 'scheme_id',
			render: (text: any) => {
				const schemeName: any = schemeList.find((item: any) => item.id == text)
				return (
					<span>{schemeName?.name}</span>
				)
			}
		},
		{
			title: `${t("GENERAL.ACTIONS")}`,
			key: 'action',
			render: (text: any, record: any) => (
				<div className='listActionbtn'>
					<span onClick={() => handleAddEdit(record, "editModal")}>
						<EditOutlined />
					</span>
					<span onClick={() => deleteModal(record)}>
						<DeleteOutlined />
					</span>
				</div>
			),
		},
	];
	const closeEditModal = () => {
		setOpenAddEditModal(null);
		addEditForm.resetFields();
		setDisabled(true);
	}
	const validateForm = () => {
		validateFields(addEditForm, setDisabled);
	};
	const handleAddEditvillageGovSchemesList = (data: any) => {
		setSaving(true)
		data.village_id = villageId
		data.village_detail_id = villageDetailsId
		if (openAddEditForm === "editModal") {
			dispatch(updateVillageGovernmentSchemes(openAddEditModal.id, data))
				.then((res: any) => {
					const indexToUpdate = villageGovSchemesList.findIndex((item: any) => item.id === res.data.id);
					if (indexToUpdate !== -1) {
						const updatedvillageGovSchemesList = [...villageGovSchemesList];
						updatedvillageGovSchemesList[indexToUpdate] = res.data;
						setVillageGovSchemesList(updatedvillageGovSchemesList)
					}
					setSaving(false);
					closeEditModal();
				})
				.catch((error: any) => {
					assignErrorToInput(addEditForm, error?.STATUS);
					setSaving(false)
				})
				.finally(() => setSaving(false));
		} else {
			dispatch(addVillageGovernmentSchemes(data))
				.then((res: any) => {
					setVillageGovSchemesList([res.data, ...villageGovSchemesList])
					closeEditModal();
				})
				.catch((error: any) => {
					assignErrorToInput(addEditForm, error?.STATUS);
					setSaving(false)
					closeEditModal();
				})
				.finally(() => setSaving(false));
		}
	}
	const closeDeleteModal = () => {
		setOpenDeleteModal(null)
	}
	const deleteData = () => {
		setSaving(true)
		dispatch(deleteVillageGovernmentSchemes(openDeleteModal.id, openDeleteModal))
			.then(() => {
				const deleteData = villageGovSchemesList.filter((item: any) => item.id !== openDeleteModal.id)
				setVillageGovSchemesList(deleteData)
				setOpenDeleteModal(null)
				setSaving(false)
			})
			.catch((e: any) => {
				e;
				setSaving(false)
			})
			.finally(() => setSaving(false));
	}
	return (
		<div>
			<Drawer
				title={`${t("VILLAGEMANAGEMENT.VILLAGE_GOV_SHCMES")}`}
				placement="right"
				onClose={() => drawerClose()}
				open={open}
				width={"70%"}
				className='villageInnerPages'
				footer={[
					<Button
						key="2"
						htmlType="button"
						className="cancelBtn"
						onClick={() => drawerClose()}
					>
						{t("GENERAL.CANCEL_BUTTON")}
					</Button>,
					<Button
						key="3"
						type="primary"
						onClick={() => drawerClose("saveAndNext")}
					>
						{t("GENERAL.CONTINUE_BUTTON")}
					</Button>
				]}
				{...rest}
			>
				<div className='add_modal_button'>
					<Button
						key="3"
						type="primary"
						onClick={() => handleAddEdit(null, "addModal")}
					>
						{t("GENERAL.ADD_DRAWER_TITLE")} <PlusOutlined />
					</Button>
				</div>
				<Table
					dataSource={villageGovSchemesList}
					columns={columns}
					onChange={(pagination: any) => setPagination(pagination)}
					pagination={pagination} // Apply pagination configuration
				/>
				<CommonModal
					open={openDeleteModal}
					className={"deleteModal"}
					title={`${t("VILLAGEMANAGEMENT.VILLAGE_GOV_SHCMES")} ${t("GENERAL.DELETE__DRAWER_TITLE")}`}
					onCancel={closeDeleteModal}
					footer={[
						<Button key="2" htmlType="button" className="cancelBtn" onClick={closeDeleteModal}>
							{t("GENERAL.CLOSE_BUTTON")}
						</Button>,
						<Button
							key="1"
							type="primary"
							loading={saving}
							htmlType='submit'
							onClick={deleteData}
						>
							{t("GENERAL.DELETE_BUTTON")}
						</Button>
					]}
				>
					<div className="deleteNote">
						{t("GENERAL.DELETE_MODEL_MESSAGE")} <span>{openDeleteModal?.first_name}</span> ?
					</div>
				</CommonModal>
				<CommonModal
					open={openAddEditModal}
					title={openAddEditForm === "editModal" ? `${t("VILLAGEMANAGEMENT.VILLAGE_GOV_SHCMES")} ${t("GENERAL.EDIT__DRAWER_TITLE")}` : `${t("VILLAGEMANAGEMENT.VILLAGE_GOV_SHCMES")} ${t("GENERAL.ADD_DRAWER_TITLE")}`}
					onCancel={closeEditModal}
					footer={[
						<Button key="2" htmlType="button" className="cancelBtn" onClick={closeEditModal}>
							{t("GENERAL.CLOSE_BUTTON")}
						</Button>,
						<Button
							key="1"
							type="primary"
							loading={saving}
							disabled={disabled}
							htmlType='submit'
							form='addEditVillageGovernmentSchemes'
						>
							{t("GENERAL.SAVE_BUTTON")}
						</Button>
					]}
				>
					<FormBox
						form={addEditForm}
						id="addEditVillageGovernmentSchemes"
						onValuesChange={validateForm}
						onFinish={handleAddEditvillageGovSchemesList}
					>
						<Row gutter={15}>
							<Col sm={24} md={24}  >
								<InputBox.Text
									required
									label={t("GENERAL.FIRST_NAME")}
									name="first_name"
									placeholder={t("GENERAL.FIRST_NAME")}
									rules={rules.name()}
								/>
							</Col>
							<Col sm={24} md={24}  >
								<InputBox.Text
									required
									label={t("GENERAL.MIDDLE_NAME")}
									name="middle_name"
									placeholder={t("GENERAL.MIDDLE_NAME")}
									rules={rules.name()}
								/>
							</Col>
							<Col sm={24} md={24}  >
								<InputBox.Text
									required
									label={t("GENERAL.Last_NAME")}
									name="last_name"
									placeholder={t("GENERAL.Last_NAME")}
									rules={rules.name()}
								/>
							</Col>
							<Col sm={24} md={24}  >
							<InputBox.Select
								required
								name="gender"
								label={t("GENERAL.GENDER")}
								placeholder={t("GENERAL.GENDER")}
								options={{
									list: genderOptions,
									valueKey: "id",
									textKey: "name",
								}}
								rules={rules.gender()}
							/>
						</Col>
							<Col sm={24} md={24}>
								<InputBox.Text
									required
									label={t("VILLAGEMANAGEMENT.CONTECT_NUMBER")}
									name="phone"
									addonBefore={"+91"}
									placeholder="xxxxxxxxxxx"
									rules={rules.mobileNumber()}
									maxLength={10}
								/>
							</Col>
							<Col sm={24} md={24}>
								<InputBox.Select
									required
									name="scheme_id"
									label={t("GENERAL.NAME_OF_SHCEME")}
									placeholder={t("GENERAL.NAME_OF_SHCEME")}
									onFocus={() => {
										dispatch(fetchschemeList({ is_active: is_active }))
											.catch((e: any) => e)
									}}
									options={{
										list: schemeList,
										valueKey: "id",
										textKey: "name",
									}}
								/>
							</Col>
						</Row>
					</FormBox>
				</CommonModal>
			</Drawer>
		</div>
	)
}
export default VillageGovSchemes