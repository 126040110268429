import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./config/RouterConfig";
import { initialAppOptions } from "./store/AuthSlice";
import PageSpinner from "./components/PageSpinner/PageSpinner";
import { handleStorageEvents } from "./config/Global";
import { useAppDispatch, useAppSelector } from "./store/app";

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const appLoading = useAppSelector(state => state.auth.appLoading);
  const initialData = useAppSelector(state => state.auth.initialData);

  useEffect(() => {
    dispatch(initialAppOptions()).catch((error: any) => error);
    window.addEventListener("storage", handleStorageEvents);
  }, [dispatch]);

  useEffect(() => {
		var favicon = document.getElementById("favicon") as HTMLLinkElement;
    if(initialData){
      favicon.href = initialData?.favicon
    }
	}, [initialData]);

  return (
    <BrowserRouter>{appLoading ? <PageSpinner /> : <Router />}</BrowserRouter>
  );
}

export default App;
