const baseUrl = "/v1/admin/citizens/meeting";

export const MEETINGAPP_URL = {
  LIST: `${baseUrl}`,
  CREATE: `${baseUrl}/create`,
  MEETINGDETAILS: (id: number): string => `${baseUrl}/${id}/details`,
  MEETINGCOMMENTS: `${baseUrl}/comment/create`,
  MEETINGCOMMENTLIST: `${baseUrl}/comments`,
  DELETEMEETINGCOMMENTS: (id: number): string => `${baseUrl}/comment/${id}/delete`,
  UPDATEMEETINGCOMMENTS: (id: number): string => `${baseUrl}/comment/${id}/update`,
  DELETEMEETINGCOMMENTSATTACHMENT: (id: number): string => `${baseUrl}/comment/attachment/${id}/delete`,
};