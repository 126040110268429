import { useState } from "react";
import { Form, DrawerProps, Drawer, Button } from "antd";
import { updateRecord } from "../../utils/slice";
import FormComponent from "./FormComponent";
import { assignErrorToInput } from "../../../../../../store/api";
import {
	useAppDispatch,
	useAppSelector,
} from "../../../../../../store/app";
import { validateFields } from "../../../../../../config/Global";
import SVGIcon from "../../../../../../utils/SVGIcon";
import Config from "../../../../../../config/Config";
import { setPincodeList } from "../../../PincodeManagement/utils/slice";
import { setTalukaList } from "../../../TalukaManagement/utils/slice";
import { useTranslation } from "react-i18next";

interface EditDrawerProps extends DrawerProps {
	titleName: string;
	open: boolean;
	close: () => void;
}

const EditComponent: React.FC<EditDrawerProps> = ({
	titleName,
	open,
	close,
	...rest
}) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const details = useAppSelector(state => state.village.details);
	const [form] = Form.useForm();
	const [disabled, setDisabled] = useState<boolean>(true);
	const [saving, setSaving] = useState<boolean>(false);
	const [fetchpincode, setFetchPincode] = useState<boolean>(true);
	const [fetchTaluka, setFetchTaluka] = useState<boolean>(true);

	const drawerClose = () => {
		close()
		form.resetFields();
		setDisabled(true);
		setFetchPincode(true);
		setFetchTaluka(true);
		dispatch(setTalukaList([]));
		dispatch(setPincodeList([]));
		setSaving(false)
	}

	const validateForm = () => {
		validateFields(form, setDisabled);
	};

	const handleSubmit = (data: any) => {
		setSaving(true);
		dispatch(updateRecord(details.id, data))
			.then(() => {
				drawerClose();
			})
			.catch((error: any) => {
				assignErrorToInput(form, error?.STATUS);
			})
			.finally(() => setSaving(false));
	};

	return (
		<Drawer
			title={`${titleName} ${t("GENERAL.EDIT__DRAWER_TITLE")}`}
			open={open}
			width={"70%"}
			onClose={() => drawerClose()}
			destroyOnClose
			closeIcon={<SVGIcon icon="close" color={Config.themePrimaryBtn} />}
			footer={[
				<Button
					key="2"
					htmlType="button"
					className="cancelBtn"
					onClick={() => drawerClose()}
				>
					{t("GENERAL.CANCEL_BUTTON")}
				</Button>,

				<Button
					key="1"
					form="editForm"
					disabled={disabled}
					loading={saving}
					htmlType="submit"
					type="primary"
				>
					{t("GENERAL.SAVE_BUTTON")}
				</Button>,


			]}
			{...rest}
		>
			<FormComponent
				form={form}
				id="editForm"
				editValues={details}
				handleSubmit={handleSubmit}
				onValuesChange={validateForm}
				fetchpincode={fetchpincode}
				setFetchPincode={setFetchPincode}
				fetchTaluka={fetchTaluka}
				setFetchTaluka={setFetchTaluka}
			/>
		</Drawer>
	);
};

export default EditComponent;
