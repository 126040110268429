import { Dropdown, MenuProps } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next';
import { DownOutlined } from "@ant-design/icons";
import { CONSTANT } from '../../config/Constant';

const LanguageDropdown = () => {
    const { t, i18n } = useTranslation();
    const changeLanguage = (lng: string) => {
        localStorage.setItem("language", lng);
        i18n.changeLanguage(lng);
    };
    const items: MenuProps['items'] = [
        {
            label: (
                <div onClick={() => changeLanguage('en')}>
                    <span>{CONSTANT.LANGUAGE_LABEL.ENGLISH}</span>
                </div>
            ),
            key: `${CONSTANT.LANGUAGE_LABEL.ENGLISH}`,
        },
        {
            label: (
                <div onClick={() => changeLanguage('gu')}>
                    <span>{CONSTANT.LANGUAGE_LABEL.GUJARATI}</span>
                </div>
            ),
            key: `${CONSTANT.LANGUAGE_LABEL.GUJARATI}`,
        },
    ];
    return (
        <div className="languageList"  >

            <Dropdown
                menu={{
                    items,
                    selectable: true,
                    defaultSelectedKeys: [`${CONSTANT.LANGUAGE_LABEL.ENGLISH}`],
                }}
                trigger={["click"]}
                overlayClassName="userMenuList removepadding"
            >
                <div className="userMenuWrap">
                    <span className="userName">
                        {t("GENERAL.LANGUAGE")}
                    </span>
                    <DownOutlined />
                </div>
            </Dropdown>
        </div>
    )
}

export default LanguageDropdown