import React, { useState } from 'react'
import { FormBox, InputBox } from '../../../../../components/AntdAddons'
import { Button, Col, Row, message } from 'antd';
import { useAppDispatch } from '../../../../../store/app';
import { dataToFormDataConverterNew, validateFields } from '../../../../../config/Global';
import { assignErrorToInput } from '../../../../../store/api';
import { createMeeting } from '../../utils/meetingSlice';
import { rules } from './rules';
import moment from 'moment';
import { CONSTANT } from '../../../../../config/Constant';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

const MeetingForm: React.FC<any> = ({ citizenId, drawerClose,form }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    // const [form] = Form.useForm();
    const [disabled, setDisabled] = useState<boolean>(true);
    const [saving, setSaving] = useState<boolean>(false);
    const validateForm = () => {
        validateFields(form, setDisabled);
    };
    const handleFormSubmit = (data: any) => {
        setSaving(true);
        data.citizen_id = citizenId
        data.date = dayjs(data?.date)?.format(CONSTANT.POST_DATE_FORMAT)
        const formData = dataToFormDataConverterNew(data)
        dispatch(createMeeting(formData))
            .then(() => {
                form.resetFields()
                setSaving(false)
            })
            .catch((error: any) => {
                assignErrorToInput(form, error?.STATUS);
                setSaving(false)
            })
            .finally(() => setSaving(false));
    };

    const checkFileType = (file: any) => {
        const isLt5M = file.size / 1024 / 1024 < 5;
        const acceptedFormats = [
            "application/pdf",
            "image/png",
            "image/jpeg",
            "image/jpg",
        ];

        if (!acceptedFormats.includes(file.type)) {
            message.error(CONSTANT.INVALID_FILE);
            return true;
        }
        if (!isLt5M) {
            message.error(CONSTANT.BIGGER_SIZE);
            return true;
        } else {
            return false;
        }
    };

    return (
        <div className='addCitizenForm'>
            <div className='tag'><span>{t("CITIZENMANAGEMENT.CREATE_MEETING_TAG")}</span></div>
            <FormBox
                form={form}
                id={"addMeetingForm"}
                onFinish={handleFormSubmit}
                onValuesChange={validateForm}
                className='meetingForm'
            >
                <Row gutter={30}>
                    <Col sm={24} md={12} xxl={8} >
                        <InputBox.Text
                            required
                            label={t("CITIZENMANAGEMENT.TYPE_OF_MEETING")}
                            name="type"
                            placeholder={t("CITIZENMANAGEMENT.TYPE_OF_MEETING")}
                            rules={rules.type()}
                        />
                    </Col>
                    <Col sm={24} md={12} xxl={8} >
                        <InputBox.DatePicker
                            required
                            name="date"
                            label={t("CITIZENMANAGEMENT.DATE_OF_MEETING")}
                            placeholder={t("CITIZENMANAGEMENT.DATE_OF_MEETING")}
                            format={CONSTANT.DATE_FORMAT}
                            allowClear={false}
                            rules={rules.date()}
                            disabledDate={(d: any) => !d || d.isBefore(moment()?.utcOffset(CONSTANT?.UTCOFFSET), 'day')}
                        />
                    </Col>
                    <Col sm={24} md={12} xxl={8} >
                        <InputBox.Upload
                            required={false}
                            label={t("GENERAL.ATTECHMENT")}
                            name="attachment"
                            maxCount={5}
                            multiple={true}
                            listType="picture"
                            className="inputUpload"
                            fileList={form.getFieldValue("attachment")}
                            accept=".png,.jpeg,.jpg,.pdf"
                            beforeUpload={checkFileType}
                        />
                    </Col>
                </Row>
                <Row gutter={30}>
                    <Col xxl={24} >
                        <InputBox.TextArea
                            required
                            label={t("GENERAL.MEETING_DETAILS")}
                            name="description"
                            placeholder={t("GENERAL.MEETING_DETAILS")}
                            rows={1}
                        // rules={rules.mobileNumber()}

                        />
                    </Col>
                </Row>
                <div className='citizenFormBtn'>
                    <Button
                        key="1"
                        disabled={disabled}
                        form="addMeetingForm"
                        loading={saving}
                        htmlType="submit"
                        type="primary"
                    >
                        {t("GENERAL.SAVE_BUTTON")}
                    </Button>
                    <Button
                        key="2"
                        htmlType="button"
                        className="cancelBtn"
                        onClick={() => drawerClose()}
                    >
                        {t("GENERAL.CANCEL_BUTTON")}
                    </Button>
                </div>
            </FormBox>
        </div>
    )
}

export default MeetingForm