import { Button, Col, Drawer, DrawerProps, Form, Row, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useAppDispatch } from '../../../../../../store/app';
import { assignErrorToInput } from '../../../../../../store/api';
import { addMalnutrionedChildrens, deleteMalnutrionedChildrens, getMalnutrionedChildrensList, updateMalnutrionedChildrens } from '../../utils/slice';
import { FormBox, InputBox } from '../../../../../../components/AntdAddons';
import { genderOptions, genderOptionsDisplay, tablePagination, validateFields } from '../../../../../../config/Global';
import { rules } from '../Form/rules';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import CommonModal from '../../../../../../components/Modals/CommonModal';
import { CONSTANT } from '../../../../../../config/Constant';
import { PlusOutlined } from "@ant-design/icons";
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from "dayjs"

interface MalnutrionedChildrenDrawerProps extends DrawerProps {
	saveAndNext: any;
	open: any;
	villageId: any;
	dynamicSteps: any;
	villageDetailsId: any
	close: () => void;
}

const MalnutrionedChildren: React.FC<MalnutrionedChildrenDrawerProps> = ({
	close,
	open,
	saveAndNext,
	villageId,
	dynamicSteps,
	villageDetailsId,
	...rest
}) => {
	const { t } = useTranslation();
	const { action } = useParams();
	const dispatch = useAppDispatch();
	const [addEditForm] = Form.useForm();
	const [disabled, setDisabled] = useState<boolean>(true);
	const [saving, setSaving] = useState<boolean>(false);
	const [malnutrionedChildrenList, setMalnutrionedChildrenList] = useState<any>([]);
	const [openDeleteModal, setOpenDeleteModal] = useState<any>();
	const [openAddEditModal, setOpenAddEditModal] = useState<any>();
	const [openAddEditForm, setOpenAddEditForm] = useState<any>();
	const [pagination, setPagination] = useState(tablePagination);
	const minDate = dayjs()
	const disableDate = (current: any) => {
		return current && current > minDate;
	};
	useEffect(() => {
		if (villageDetailsId && action === CONSTANT.DRAWER_TYPE.EDIT && dynamicSteps === CONSTANT.STEP.MALNUTRIONED_CHILDREN) {
			dispatch(getMalnutrionedChildrensList({ village_detail_id: villageDetailsId, village_id: villageId }))
				.then((res: any) => {
					if (res.count > 0) {
						setMalnutrionedChildrenList([...res.rows])
					} else {
						setMalnutrionedChildrenList(res.rows)
					}
				})
				.catch((e: any) => e)
		}
	}, [dynamicSteps])

	const handleAddEdit = (data: any, openForm: any) => {
		setOpenAddEditForm(openForm)

		if (openForm === "editModal") {
			const setValues = malnutrionedChildrenList.find((item: any) => item.id === data.id);
			addEditForm.setFieldsValue({
				...setValues,
				dob: setValues.dob && dayjs(setValues.dob),
			})
			setOpenAddEditModal(data)
		} else {
			setOpenAddEditModal(true)
		}
	}


	const closeEditModal = () => {
		setOpenAddEditModal(null);
		addEditForm.resetFields();
		setDisabled(true);
	}
	const handleAddEditMalnutrionedChildrens = (data: any) => {
		setSaving(true)
		data.village_id = villageId
		data.village_detail_id = villageDetailsId
		data.dob = dayjs(data.dob).format(CONSTANT.POST_DATE_FORMAT)
		if (openAddEditForm === "editModal") {
			dispatch(updateMalnutrionedChildrens(openAddEditModal.id, data))
				.then((res: any) => {
					const indexToUpdate = malnutrionedChildrenList.findIndex((item: any) => item.id === res.data.id);
					if (indexToUpdate !== -1) {
						const updatedPartyWorkerList = [...malnutrionedChildrenList];
						updatedPartyWorkerList[indexToUpdate] = res.data;
						setMalnutrionedChildrenList(updatedPartyWorkerList);
					}
					setSaving(false);
					closeEditModal();
				})
				.catch((error: any) => {
					assignErrorToInput(addEditForm, error?.STATUS);
					setSaving(false)
				})
				.finally(() => setSaving(false));
		} else {
			dispatch(addMalnutrionedChildrens(data))
				.then((res: any) => {
					setMalnutrionedChildrenList([res.data, ...malnutrionedChildrenList])
					closeEditModal();
				})
				.catch((error: any) => {
					assignErrorToInput(addEditForm, error?.STATUS);
					setSaving(false)
				})
				.finally(() => setSaving(false));

		}
	}
	const deleteModal = (data: any) => {
		setOpenDeleteModal(data)
	}
	const closeDeleteModal = () => {
		setOpenDeleteModal(null)
	}
	const deleteData = () => {
		setSaving(true)
		dispatch(deleteMalnutrionedChildrens(openDeleteModal.id, openDeleteModal))
			.then(() => {
				const deleteData = malnutrionedChildrenList.filter((item: any) => item.id !== openDeleteModal.id)
				setMalnutrionedChildrenList(deleteData)
				setOpenDeleteModal(null)
				setSaving(false)
			})
			.catch((e: any) => {
				e;
				setSaving(false)
			})
			.finally(() => setSaving(false));
	}
	const drawerClose = (submitForm = "") => {
		if (submitForm === "") {
			close()
		} else {
			saveAndNext(CONSTANT.STEP.VILLAGE_GOV_SCHEMES)
		}
		setMalnutrionedChildrenList([])
		addEditForm.resetFields();
		setDisabled(true);
	}
	const validateForm = () => {
		validateFields(addEditForm, setDisabled);
	};
	const columns = [
		{
			title: t("GENERAL.FIRST_NAME"),
			dataIndex: 'first_name',
			key: 'first_name',
		},
		{
			title: t("GENERAL.MIDDLE_NAME"),
			dataIndex: 'middle_name',
			key: 'middle_name',
		},
		{
			title: t("GENERAL.Last_NAME"),
			dataIndex: 'last_name',
			key: 'last_name',
		},
		{
			title: t("GENERAL.DATE_OF_BIRTH"),
			dataIndex: 'dob',
			key: 'dob',
			render: (text: any) => {
				return (
					<span>{text !== null && dayjs(text).format(CONSTANT.DATE_FORMAT)}</span>
				)
			}
		},
		{
			title: `${t("VILLAGEMANAGEMENT.AGE")}`,
			dataIndex: 'age',
			key: 'age',
		},
		{
			title: `${t("VILLAGEMANAGEMENT.CONTECT_NUMBER")}`,
			dataIndex: 'phone',
			key: 'phone',
		},
		{
			title: `${t("GENERAL.GENDER")}`,
			dataIndex: 'gender',
			key: 'gender',
			render: (text: any) => {
				return (
					<span>{genderOptionsDisplay[text]}</span>
				)
			}
		},
		{
			title: `${t("VILLAGEMANAGEMENT.DEFICIENCY")}`,
			dataIndex: 'deficiency',
			key: 'deficiency',
		},
		{
			title: `${t("VILLAGEMANAGEMENT.CASTE")}`,
			dataIndex: 'caste',
			key: 'caste',
		},
		{
			title: `${t("GENERAL.ACTIONS")}`,
			key: 'action',
			render: (text: any, record: any) => (
				<div className='listActionbtn'>
					<span onClick={() => handleAddEdit(record, "editModal")}>
						<EditOutlined />
					</span>
					<span onClick={() => deleteModal(record)}>
						<DeleteOutlined />
					</span>
				</div>
			),
		},
	];

	return (
		<Drawer
			title={`${t("VILLAGEMANAGEMENT.VILLAGE_MALNUTRIONED_CHILDREN")}`}
			placement="right"
			onClose={() => drawerClose()}
			open={open}
			className='villageInnerPages'
			width={"70%"}
			footer={[
				<Button
					key="2"
					htmlType="button"
					className="cancelBtn"
					onClick={() => drawerClose()}
				>
					{t("GENERAL.CANCEL_BUTTON")}
				</Button>,

				// <Button
				// 	key="1"
				// 	disabled={disabled}
				// 	form="villageMalnutrionedChildren"
				// 	loading={saving}
				// 	htmlType="submit"
				// 	type="primary"
				// 	onClick={() => setSubmitForm("save")}
				// >
				// 	Save
				// </Button>,
				<Button
					key="3"
					type="primary"
					onClick={() => drawerClose("saveAndNext")}
				>
					{t("GENERAL.CONTINUE_BUTTON")}
				</Button>
			]}
			{...rest}
		>
			<div className='add_modal_button'>
				<Button
					key="3"
					type="primary"
					onClick={() => handleAddEdit(null, "addModal")}
				>
					{t("GENERAL.ADD_DRAWER_TITLE")} <PlusOutlined />
				</Button>
			</div>
			<Table
				dataSource={malnutrionedChildrenList}
				columns={columns}
				onChange={(pagination: any) => setPagination(pagination)}
				pagination={pagination} // Apply pagination configuration
			/>
			<CommonModal
				open={openDeleteModal}
				className={"deleteModal"}
				title={`${t("VILLAGEMANAGEMENT.VILLAGE_MALNUTRIONED_CHILDREN")} ${t("GENERAL.DELETE__DRAWER_TITLE")}`}
				onCancel={closeDeleteModal}
				footer={[
					<Button key="2" htmlType="button" className="cancelBtn" onClick={closeDeleteModal}>
						{t("GENERAL.CLOSE_BUTTON")}
					</Button>,
					<Button
						key="1"
						type="primary"
						loading={saving}
						htmlType='submit'
						onClick={deleteData}
					>
						{t("GENERAL.DELETE_BUTTON")}
					</Button>
				]}
			>
				<div className="deleteNote">
				{t("GENERAL.DELETE_MODEL_MESSAGE")} <span>{openDeleteModal?.first_name}</span> ?
				</div>
			</CommonModal>
			<CommonModal
				open={openAddEditModal}
				// className={"statusModal"}
				title={openAddEditForm === "editModal" ? `${t("VILLAGEMANAGEMENT.VILLAGE_MALNUTRIONED_CHILDREN")} ${t("GENERAL.EDIT__DRAWER_TITLE")}` : `${t("VILLAGEMANAGEMENT.VILLAGE_MALNUTRIONED_CHILDREN")} ${t("GENERAL.ADD_DRAWER_TITLE")}`}
				onCancel={closeEditModal}
				width={"40%"}
				footer={[
					<Button key="2" htmlType="button" className="cancelBtn" onClick={closeEditModal}>
						{t("GENERAL.CLOSE_BUTTON")}
					</Button>,
					<Button
						key="1"
						type="primary"
						loading={saving}
						disabled={disabled}
						htmlType='submit'
						form='addEditVillageMalnutrionedChildren'
					>
						{t("GENERAL.SAVE_BUTTON")}
					</Button>
				]}
			>
				<FormBox
					form={addEditForm}
					id="addEditVillageMalnutrionedChildren"
					onValuesChange={validateForm}
					onFinish={handleAddEditMalnutrionedChildrens}
				>
					<Row gutter={15}>
						<Col sm={24} md={24} xl={12}  >
							<InputBox.Text
								required
								label={t("GENERAL.FIRST_NAME")}
								name="first_name"
								placeholder={t("GENERAL.FIRST_NAME")}
								rules={rules.name()}
							/>
						</Col>
						<Col sm={24} md={24} xl={12} >
							<InputBox.Text
								required
								label={t("GENERAL.MIDDLE_NAME")}
								name="middle_name"
								placeholder={t("GENERAL.MIDDLE_NAME")}
								rules={rules.name()}
							/>
						</Col>
						<Col sm={24} md={24} xl={12} >
							<InputBox.Text
								required
								label={t("GENERAL.Last_NAME")}
								name="last_name"
								placeholder={t("GENERAL.Last_NAME")}
								rules={rules.name()}
							/>
						</Col>
						<Col sm={24} md={24} xl={12}  >
							<InputBox.DatePicker
								required
								allowClear={false}
								name="dob"
								label={t("GENERAL.DATE_OF_BIRTH")}
								placeholder={t("GENERAL.DATE_OF_BIRTH")}
								rules={rules.dateOfBirth()}
								disabledDate={disableDate}
								onChange={(e: any) => {
									const dob = dayjs(e).format(CONSTANT.POST_DATE_FORMAT)
									const calculatedAge = dayjs().diff(dob, 'year');
									addEditForm.setFieldsValue({
										age: calculatedAge
									})

								}}
							/>
						</Col>

						<Col sm={24} md={24} xl={12}  >
							<InputBox.Text
								required
								label={t("VILLAGEMANAGEMENT.AGE")}
								name="age"
								placeholder={t("VILLAGEMANAGEMENT.AGE")}
								rules={rules.age()}
							/>
						</Col>
						<Col sm={24} md={24} xl={12}  >
							<InputBox.Text
								required
								label={t("VILLAGEMANAGEMENT.CONTECT_NUMBER")}
								name="phone"
								addonBefore={"+91"}
								placeholder="xxxxxxxxxxx"
								rules={rules.mobileNumber()}
								maxLength={10}
							/>
						</Col>
						<Col sm={24} md={24} xl={12}  >
							<InputBox.Select
								required
								name="gender"
								label={t("GENERAL.GENDER")}
								placeholder={t("GENERAL.GENDER")}
								options={{
									list: genderOptions,
									valueKey: "id",
									textKey: "name",
								}}
								rules={rules.gender()}
							/>
						</Col>
						<Col sm={24} md={24} xl={12}  >
							<InputBox.Text
								required
								label={t("VILLAGEMANAGEMENT.DEFICIENCY")}
								name="deficiency"
								placeholder={t("VILLAGEMANAGEMENT.DEFICIENCY")}
								rules={rules.deficiency()}
							/>
						</Col>
						<Col sm={24} md={24} xl={12}  >
							<InputBox.Text
								required
								label={t("VILLAGEMANAGEMENT.CASTE")}
								name="caste"
								placeholder={t("VILLAGEMANAGEMENT.CASTE")}
								rules={rules.caste()}
							/>
						</Col>
					</Row>
				</FormBox>
			</CommonModal>
		</Drawer>
	)
}

export default MalnutrionedChildren