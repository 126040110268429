import { ColDef } from "ag-grid-community";
import { renderNA } from "../../../../../../components/AgGridWrapper/utils/commonFunctions";
import { fetchRolesList } from "../../../../../../store/CommonSlice";
import { useAppDispatch } from "../../../../../../store/app";
import { useTranslation } from "react-i18next";
import { actionColumn, isActiveColumn, srNoColumn } from "../../../../../../components/AgGridWrapper/utils/commonColumns";

const columnDefs = (): ColDef[] => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const nameColumn: ColDef = {
    field: "name",
    headerName: `${t("GENERAL.NAME")}`,
    cellRenderer: (props: any) => {
      return renderNA(props.data?.name);
    },
    filter: "agSetColumnFilter",
    filterParams: {
      values: (params: any) => {
        dispatch(fetchRolesList()).then((data: any) => {
          params.success(data?.data?.map((role: any) => role?.name));
        })
          .catch((error: any) => error);
      },
      buttons: ["apply", "reset"],
      closeOnApply: true,
    },
  };
  
  return [srNoColumn(t), nameColumn, isActiveColumn(t), actionColumn(t)];
};

export default columnDefs;
