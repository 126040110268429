import React from "react";
import { Divider, Layout } from "antd";
import moment from "moment";
import { Link } from "react-router-dom";

const FooterView: React.FC = () => {
  return (
    <Layout.Footer >
      <div className="footerContent">
        <p >
          © {moment().format("Y")} <span>{process.env.REACT_APP_APP_NAME ?? ""}</span> (All right reserved)
        </p>
        <div className="termsAndPolicy">
          <Link to={"/"}>Terms of Use</Link>
          <Divider type="vertical" />
          <Link to={"/"}>Privacy Policy</Link>

        </div>
      </div>
    </Layout.Footer>
  );
};

export default FooterView;
