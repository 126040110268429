import { Descriptions, Drawer, DrawerProps, Form } from 'antd';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../store/app';
import SVGIcon from '../../../../utils/SVGIcon';
import Config from '../../../../config/Config';
import CancelButton from '../../../../components/Buttons/CancelButton';
import { renderNA } from '../../../../components/AgGridWrapper/utils/commonFunctions';
import { OfficeListDisplay } from '../../../../config/Global';

interface ViewDrawerProps extends DrawerProps {
    titleName: string;
    open: boolean;
    close: () => void;
}
const ViewDrawer: React.FC<ViewDrawerProps> = ({
    titleName,
    open,
    close,
    ...rest
}) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const details = useAppSelector(state => state.GovernmentNonGovermentContact.details);

    const drawerClose = () => {
        close();
        form.resetFields();
    };

    return (
        <>
            <Drawer
                title={`${titleName} ${t("GENERAL.VIEW__DRAWER_TITLE")}`}
                open={open}
                placement="right"
                width={"70%"}
                onClose={drawerClose}
                destroyOnClose
                closeIcon={<SVGIcon icon="close" color={Config.themePrimaryBtn} />}
                footer={[<CancelButton key={2} onClick={drawerClose} />]}
                {...rest}
            >
                <Descriptions column={2} bordered layout="horizontal">
                    <Descriptions.Item label={t("GENERAL.FIRST_NAME")}>
                        {renderNA(details?.fname)}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("GENERAL.MIDDLE_NAME")}>
                        {renderNA(details?.middle_name)}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("GENERAL.Last_NAME")}>
                        {renderNA(details?.lname)}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("GOVERNMENTNONGOVERMENTCONTACTMANAGEMENT.DEPARTMENT")}>
                        {renderNA(details?.department?.dept)}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("GOVERNMENTNONGOVERMENTCONTACTMANAGEMENT.DESIGNNATION")}>
                        {renderNA(details?.designation)}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("GENERAL.MOBILE_NUMBER")}>
                        {renderNA(details?.mobile_number)}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("GOVERNMENTNONGOVERMENTCONTACTMANAGEMENT.TELEPHONENUMBER")}>
                        {renderNA(details?.tel_number)}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("GENERAL.EMAIL_ID")}>
                        {renderNA(details?.email)}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("GOVERNMENTNONGOVERMENTCONTACTMANAGEMENT.OFFICE")}>
                        {renderNA(OfficeListDisplay[details?.office_id])}
                    </Descriptions.Item>
                    <Descriptions.Item label={t("GENERAL.ADDRESS")}>
                        {renderNA(details?.address)}
                    </Descriptions.Item>
                </Descriptions>
            </Drawer>
        </>
    )
}
export default ViewDrawer