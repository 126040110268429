import { DrawerProps, Drawer, Descriptions, Button } from "antd";
import { useAppDispatch, useAppSelector } from "../../../../../../store/app";
import SVGIcon from "../../../../../../utils/SVGIcon";
import Config from "../../../../../../config/Config";
import CancelButton from "../../../../../../components/Buttons/CancelButton";
import { renderNA } from "../../../../../../components/AgGridWrapper/utils/commonFunctions";
import { CONSTANT } from "../../../../../../config/Constant";
import { useEffect } from "react";
import { viewVillageDetails } from "../../utils/slice";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface ViewDrawerProps extends DrawerProps {
    open: boolean;
    close: () => void;
    id: any
    saveAndNext: any;
    dynamicSteps: any
}

const VillageDetailsView: React.FC<ViewDrawerProps> = ({
    open,
    close,
    id,
    saveAndNext,
    dynamicSteps,
    ...rest
}) => {
    const { t } = useTranslation();
    const { action } = useParams();
    const dispatch = useAppDispatch();
    const details = useAppSelector(state => state.village.villageDetails);
    const drawerClose = () => {
        close();
    };
    const continueBtn = () => {
        saveAndNext(CONSTANT.STEP.PARTY_WORKER)
    }   
    
    useEffect(() => {
        if (id && action === CONSTANT.DRAWER_TYPE.VIEW && dynamicSteps === CONSTANT.STEP.VILLAGE_DETAILS) {
            dispatch(viewVillageDetails(id)).catch((error: any) => error)
        }
    }, [dynamicSteps,action])

    return (
        <Drawer
            title={`${t("VILLAGEMANAGEMENT.VILLAGE_DETAILS")}`}
            open={open}
            placement="right"
            width={"70%"}
            onClose={drawerClose}
            destroyOnClose
            closeIcon={<SVGIcon icon="close" color={Config.themePrimaryBtn} />}
            footer={[
                <CancelButton key={2} onClick={drawerClose} />,
                <Button
                    htmlType="button"
                    className="cancelBtn"
                    key="2"
                    type="primary"
                    onClick={continueBtn}
                >
                    {t("GENERAL.CONTINUE_BUTTON")}
                </Button>
            ]}
            {...rest}
        >
            <Descriptions column={3} bordered layout="horizontal">
                <Descriptions.Item label={t("VILLAGEMANAGEMENT.POPULATION_OF_VILLAGE")} className="village_view_width">
                    {(details?.population && details?.population > 0) ? renderNA(details?.population) : "0"}
                </Descriptions.Item>
            </Descriptions>
            <hr />
            <Descriptions column={4} bordered layout="horizontal">
                <Descriptions.Item label={t("VILLAGEMANAGEMENT.TOTAL_VOTER")} className="village_total_voter_width">
                    {(details?.total_voter && details?.total_voter > 0) ? renderNA(details?.total_voter) : "0"}
                </Descriptions.Item>
                <Descriptions.Item label={`${t("VILLAGEMANAGEMENT.TOTAL_VOTING")} (%)`} className="village_total_voter_width">
                    {(details?.total_voting && details?.total_voting > 0) ? renderNA(details?.total_voting) : "0"}
                </Descriptions.Item>
                <Descriptions.Item label={t("VILLAGEMANAGEMENT.TOTAL_VOTING_CENTER")} className="village_total_voter_width">
                    {(details?.total_voting_center && details?.total_voting_center > 0) ? renderNA(details?.total_voting_center) : "0"}
                </Descriptions.Item>
                <Descriptions.Item label={t("VILLAGEMANAGEMENT.TOTAL_BOOTH")} className="village_total_voter_width">
                    {(details?.total_booth && details?.total_booth > 0) ? renderNA(details?.total_booth) : "0"}
                </Descriptions.Item>
            </Descriptions>
            <hr />
            <Descriptions column={4} bordered layout="horizontal">
                <Descriptions.Item label={`${t("GENERAL.BJP")} (%)`}>
                    {(details?.bjp && details?.bjp > 0) ? renderNA(details?.bjp) : "0"}
                </Descriptions.Item>
                <Descriptions.Item label={`${t("GENERAL.CONGRASS")} (%)`}>
                    {(details?.congress && details?.congress > 0) ? renderNA(details?.congress) : "0"}
                </Descriptions.Item>
                <Descriptions.Item label={`${t("GENERAL.AAP")} (%)`}>
                    {(details?.aap && details?.aap > 0) ? renderNA(details?.aap) : "0"}
                </Descriptions.Item>
                <Descriptions.Item label={`${t("GENERAL.OTHER")} (%)`}>
                    {(details?.other && details?.other > 0) ? renderNA(details?.other) : "0"}
                </Descriptions.Item>
            </Descriptions>
        </Drawer>
    );
};

export default VillageDetailsView;
