import React from 'react'
import ActionButtons from '../../../../../../components/ActionButtons';
import AgGridWrapper from '../../../../../../components/AgGridWrapper/AgGridWrapper';
import columnDefs from './columnDefs';
import { setupGrid } from '../../utils/slice';

const Listing: React.FC<any> = ({
    moduleInfo,
    handleViewClick
}) => {
    const ActionRenderer = (props: any) => {
        return (
            <ActionButtons
                data={props}
                view={{
                    action: handleViewClick,
                    permissionKey: `CITIZEN_${moduleInfo.permissionPrefix}_DETAILS`,
                }}
            />
        )
    }
    return (
        <>
            <AgGridWrapper
                type="serverSide"
                components={{
                    ActionRenderer,
                }}
                onGridReadyWithDispatch={setupGrid}
                columnDefs={columnDefs()}
            />
        </>
    )
}

export default Listing