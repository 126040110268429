import { Button, Col, Drawer, DrawerProps, Form, Row } from 'antd';
import React, { useEffect, useState } from 'react'
import SVGIcon from '../../../../../utils/SVGIcon';
import Config from '../../../../../config/Config';
import { validateFields } from '../../../../../config/Global';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../store/app';
import { assignErrorToInput } from '../../../../../store/api';
import { createRecord } from '../../../Administration/TalukaManagement/utils/slice';
import { FormBox, InputBox } from '../../../../../components/AntdAddons';
import { rules } from './rules';
interface TalukaDrawerProps extends DrawerProps {
    titleName: string;
    open: boolean;
    close: () => void;
    pincodeId: any;
}
const NewTaluka: React.FC<TalukaDrawerProps> = ({
    open,
    titleName,
    close,
    pincodeId,
    ...rest
}) => {
    const [form] = Form.useForm();
    const [disabled, setDisabled] = useState<boolean>(true);
    const [saving, setSaving] = useState<boolean>(false);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const onValuesChange = () => {
        validateFields(form, setDisabled);
    };
    const { pincodeList } = useAppSelector(
        state => state.pincode
    );
    const onFinish = (data: any) => {
        setSaving(true)
        dispatch(createRecord(data))
            .then(() => {
                drawerClose();
            })
            .catch((error: any) => {
                assignErrorToInput(form, error?.STATUS);
            })
            .finally(() => setSaving(false));
    }
    const drawerClose = () => {
        form.resetFields();
        close()
    }
    useEffect(() => {
        const setPincode = pincodeList && pincodeList?.filter((item: any) => item?.id === pincodeId)
        if (setPincode) {
            form.setFieldValue("pincode_id", pincodeId)
        }
    }, [pincodeId,open])

    return (
        <>
            <Drawer
                open={open}
                title={`${titleName} ${t("GENERAL.ADD_DRAWER_TITLE")}`}
                placement="right"
                width={"70%"}
                onClose={drawerClose}
                closeIcon={<SVGIcon icon="close" color={Config.themePrimaryBtn} />}
                footer={[
                    <Button
                        key="2"
                        htmlType="button"
                        className="cancelBtn"
                        onClick={drawerClose}
                    >
                        {t("GENERAL.CANCEL_BUTTON")}
                    </Button>,
                    <Button
                        key="1"
                        disabled={disabled}
                        form="addNewTalukaForm"
                        loading={saving}
                        htmlType="submit"
                        type="primary"
                    >
                        {t("GENERAL.SAVE_BUTTON")}
                    </Button>,
                ]}
                {...rest}
            >
                <>
                    <FormBox
                        form={form}
                        id={"addNewTalukaForm"}
                        onFinish={onFinish}
                        onValuesChange={onValuesChange}
                    >
                        <Row gutter={15}>
                            <Col xs={{ span: 12 }} >
                                <InputBox.Select
                                    required
                                    allowClear
                                    showSearch
                                    name="pincode_id"
                                    optionFilterProp="children"
                                    label={t("GENERAL.PINCODES")}
                                    placeholder={t("GENERAL.PINCODES")}
                                    options={{
                                        list: pincodeList,
                                        valueKey: "id",
                                        textKey: "pincode",
                                    }}
                                    onChange={() => {
                                        form.setFieldsValue({
                                            name: null,
                                        });
                                    }}
                                    rules={rules.pincode()}
                                />
                            </Col>
                            <Col xs={{ span: 12 }} >
                                <InputBox.Text
                                    required
                                    label={t("GENERAL.TALUKA")}
                                    name="name"
                                    placeholder={t("GENERAL.TALUKA")}
                                    rules={rules.taluka()}
                                />
                            </Col>
                        </Row>
                    </FormBox>
                </>
            </Drawer>
        </>
    )
}

export default NewTaluka