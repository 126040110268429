import React from 'react';
import AgGridWrapper from '../../../../../components/AgGridWrapper/AgGridWrapper';
import ActionButtons from '../../../../../components/ActionButtons';
import columnDefs from '../Listing/columnDefs';
import { useAppDispatch, useAppSelector } from '../../../../../store/app';
import { setGrid, setPerPageSize } from '../../utils/slice';
import RecordPerPage from '../../../../../components/ToolBar/Dropdown/RecordPerPage';
import Config from '../../../../../config/Config';
import { useTranslation } from 'react-i18next';

const MeetingTable: React.FC<any> = ({ permissionPrefix, handleViewClick }) => {
    const { t } = useTranslation();
    const rowData = useAppSelector(state => state.meeting.rowData);
    const { perPage } = useAppSelector((state) => state.meeting);
    const dispatch = useAppDispatch();
    const ActionRenderer = (props: any) => {
        return (
            <ActionButtons
                data={props}
                view={{
                    action: handleViewClick,
                    permissionKey: `${permissionPrefix}_MEETING_DETAILS`,
                }}

            />
        );
    };

    const localGridConfig = {
        ...Config.grid.local,
        gridOptions: {
            ...Config.grid.local.gridOptions,
            paginationPageSize: 10
        }
    };

    return (
        <div className='addCitizenList'>
            <div className='tag'><span>{t("CITIZENMANAGEMENT.HISTORY_TAG")}</span></div>
            <div className='meetingList'>
                <RecordPerPage
                    perPage={perPage}
                    onChange={(perPageSize: number) => {
                        dispatch(setPerPageSize(perPageSize));
                    }}
                />
            </div>
            <AgGridWrapper
                components={{
                    ActionRenderer,
                }}
                rowData={rowData}
                onGridReadyWithDispatch={setGrid}
                columnDefs={columnDefs()}
                gridConfig={localGridConfig}
            />
        </div>
    );
};

export default MeetingTable;
