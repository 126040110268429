import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form } from "antd";
// import { useNavigate } from "react-router-dom";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import LoginBG from "../../../../assets/images/apps/login-pattern.png";
import { LoginRequest } from "../rules";
import { doLogin } from "../../../../store/AuthSlice";
import { Link, useNavigate } from "react-router-dom";
import { stringDecryption } from "../../../../config/Global";
import { assignErrorToInput } from "../../../../store/api";
import { useAppDispatch, useAppSelector } from "../../../../store/app";
import { EnvironmentOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next"
const LoginForm: React.FC = () => {
	const dispatch = useAppDispatch();
	const [rememberMe, setRememberMe] = useState(false);
	const isLoading = useAppSelector(state => state.auth.isLoading);
	// const syncData = useAppSelector(state => state.auth.initialData?.syncData)
	const syncDataNew = useAppSelector(state => state?.configuration?.details)
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const handleSubmit = (data: any) => {
		data.remember = rememberMe;
		dispatch(doLogin(data))
			.then(() => {
				navigate("/");
			})
			.catch((error: any) => {
				assignErrorToInput(form, error?.STATUS);
			});
	};

	useEffect(() => {
		let rememberMe: any = localStorage.getItem("remember_me");
		if (rememberMe) {
			rememberMe = JSON.parse(stringDecryption(rememberMe));
			form.setFieldsValue({
				username: rememberMe.email,
				password: rememberMe.password,
				remember: true,
			});
			setRememberMe(true);
		}
	}, [form]);

	return (
		<div className="loginForm" style={{ backgroundImage: `url(${LoginBG})` }}>
			<div className="loginFormMain">
				<div className="loginFormBigWrap">
					<div className="loginFormWrap">
						{/* <h2 className="formTitle"><span>{t("LOGIN.SIGN_IN_H2")}</span>{(syncData?.brand_name) ? (syncData?.brand_name + t("LOGIN.WELCOME_PORTAL")) : t("LOGIN.WELCOME_TO_JAN_UTKARSH_PORTAL")}</h2> */}
						<h2 className="formTitle"><span>{t("LOGIN.SIGN_IN_H2")}</span>{t("LOGIN.WELCOME_TO_JAN_UTKARSH_PORTAL")}</h2>


						<FormBox form={form} onFinish={handleSubmit}>
							<InputBox.Text
								name="username"
								label={t("GENERAL.MOBILE_NUMBER")}
								placeholder="XXXXXX9878"
								rules={LoginRequest().email("Mobile")}
							/>
							<InputBox.Password
								name="password"
								label={t("GENERAL.PASSWORD")}
								placeholder="xxxxxxx"
								rules={LoginRequest().password("Password")}
							/>
							<div
								className="login_extra_fields"
								style={{ display: "flex", justifyContent: "space-between" }}
							>
								<Checkbox
									name="remember_me"
									checked={rememberMe}
									onChange={() => setRememberMe(!rememberMe)}
									className="rememberMe"
								>
									{t("LOGIN.REMEMBER_ME_CHECKBOX")}
								</Checkbox>
								<Link to="/forgot-password" className="forgotPass">{t("LOGIN.FORGOT_PASSWORD_LINK")}</Link>
							</div>

							<Button loading={isLoading} type="primary" htmlType="submit" className="loginBtn">
								{t("LOGIN.LOG_IN_BUTTON")}
							</Button>

						</FormBox>
					</div>
				</div>
				<div className="loginLocation">
					{/* <p className="loginLocationContent"><EnvironmentOutlined /> {(syncData?.office_address) ? (syncData?.office_address) : t("LOGIN.SAVARKUNDLA_LILIYA_VIDHANSABHA")}</p> */}
					<p className="loginLocationContent"><EnvironmentOutlined /> {syncDataNew?.office_address}</p>
				</div>
			</div>
		</div>
	);
};

export default LoginForm;
