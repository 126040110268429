import { Drawer, DrawerProps, Form } from 'antd';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../store/app';
import { validateFields } from '../../../../config/Global';
import { createRecord } from '../utils/slice';
import { assignErrorToInput } from '../../../../store/api';
import SVGIcon from '../../../../utils/SVGIcon';
import Config from '../../../../config/Config';
import FormComponent from './FormComponent';

interface AddDrawerProps extends DrawerProps {
    titleName: string;
    close: () => void;
}
const AddComponent: React.FC<AddDrawerProps> = ({
    titleName,
    close,
    ...rest
}) => {
    const { t } = useTranslation();
    const [form] = Form.useForm()
    const [disabled, setDisabled] = useState<boolean>(true);
    const [saving, setSaving] = useState<boolean>(false);
    const dispatch = useAppDispatch()

    const drawerClose = () => {
        close()
        form.resetFields()
        setDisabled(true)
    }
    const validateForm = () => {
        validateFields(form, setDisabled)
    }
    const handleFormSubmit = (data: any) => {
        setSaving(true);
        dispatch(createRecord(data))
            .then(() => {
                drawerClose();
            })
            .catch((error: any) => {
                assignErrorToInput(form, error?.STATUS);
            })
            .finally(() => setSaving(false));
    }
    return (
        <>
            <>
                <Drawer
                    title={`${titleName} ${t("GENERAL.ADD_DRAWER_TITLE")}`}
                    placement="right"
                    width={"70%"}
                    onClose={drawerClose}
                    destroyOnClose
                    closeIcon={<SVGIcon icon="close" color={Config.themePrimaryBtn} />}
                    className="mlaGrantForm"
                    {...rest}
                >
                    <FormComponent
                        form={form}
                        id="addForm"
                        handleSubmit={handleFormSubmit}
                        onValuesChange={validateForm}
                        disabled={disabled}
                        saving={saving}
                        drawerClose={drawerClose}
                        setDisabled={setDisabled}
                    />
                </Drawer>
            </>
        </>
    )
}

export default AddComponent