import { ColDef } from "ag-grid-community";
import { renderNA } from "../../../../../../components/AgGridWrapper/utils/commonFunctions";
import { useAppDispatch } from "../../../../../../store/app";
import { fetchRolesList } from "../../../../../../store/CommonSlice";
import { useTranslation } from "react-i18next";
import { isActiveColumn, srNoColumn,actionColumn } from "../../../../../../components/AgGridWrapper/utils/commonColumns";

const columnDefs = (): ColDef[] => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const firstNameColumn: ColDef = {
    field: "name",
    headerName: `${t("GENERAL.FIRST_NAME")}`,
    cellRenderer: (props: any) => {
      return renderNA(props.data.name);
    },
  };

  const lastNameColumn: ColDef = {
    field: "last_name",
    headerName: `${t("GENERAL.Last_NAME")}`,
    cellRenderer: (props: any) => {
      return renderNA(props.data.last_name);
    },
  };

  const emailColumn: ColDef = {
    field: "email",
    headerName: `${t("GENERAL.EMAIL")}`,
    cellRenderer: (props: any) => {
      return renderNA(props.data.email);
    },
  };

  const mobileNumberColumn: ColDef = {
    field: "mobile_no",
    headerName: `${t("GENERAL.MOBILE_NUMBER")}`,
    cellRenderer: (props: any) => {
      return renderNA(props.data.mobile_no);
    },
  };

  const roleColumn: ColDef = {
    field: "role_id",
    headerName: `${t("GENERAL.ROLE")}`,
    cellRenderer: (props: any) => {
      return renderNA(props.data?.role_name)
    },
    filter: "agSetColumnFilter",
    filterParams: {
      values: (params: any) => {
        dispatch(fetchRolesList()).then((data: any) => {
          params.success(data?.data?.map((role: any) => role?.name));
        });
      },
      buttons: ["apply", "reset"],
      closeOnApply: true,
    },
  };
  
  return [
    srNoColumn(t),
    firstNameColumn,
    lastNameColumn,
    emailColumn,
    mobileNumberColumn,
    roleColumn,
    isActiveColumn(t),
    { ...actionColumn(t), width: 165, minWidth: 165 },
  ];
};
export default columnDefs;
