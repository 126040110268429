import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../store/app';
import { useNavigate, useParams } from 'react-router-dom';
import { checkPrivileges } from '../../../config/Global';
import { CONSTANT } from '../../../config/Constant';
import ToolBar from '../../../components/ToolBar';
import { MLACalenderBreadcrumb } from '../../../config/BreadcrumbConfig';
import CreateButton from '../../../components/ToolBar/Buttons/CreateButton';
import ContentBox from '../../../components/ContentBox/ContentBox';
import Listing from './Components/Listing/Listing';
import AddComponent from './Components/Form/AddComponent';
import { ModuleInfoProps } from '../../../config/InterfacesAndTypes';
import { Tabs, TabsProps } from 'antd';
import ListingData from './Components/Listing/ListingData';
import DeleteModal from '../../../components/Modals/DeleteModal';
import { deleteRecord, details } from './utils/slice';
import ViewDrawer from './Components/ViewDrawer';
import EditComponent from './Components/Form/EditComponent';
import { useTranslation } from 'react-i18next';

const MLAMeetingManagement: React.FC<ModuleInfoProps> = (moduleInfo) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { action, id } = useParams();
	const [visibleDrawer, setVisibleDrawer] = useState<string | null>(null);
	const userDetail = useAppSelector((state) => state.auth.userDetail);
	const [deleteValue, setDeleteValue] = useState<any>();

	const getDetails = (id: any, type: string) => {
		dispatch(details(id))
			.then(() => {
				setVisibleDrawer(type)
			})
			.catch(() => {
				navigateToIndex()
			})
	}
	const navigateToIndex = () => {
		setVisibleDrawer(null);
		navigate(`${moduleInfo.indexRoute}`);
	};

	useEffect(() => {
		if (action) {
			if (action === CONSTANT.DRAWER_TYPE.ADD && userDetail && !checkPrivileges(userDetail, `${moduleInfo.permissionPrefix}_CREATE`)) {
				navigateToIndex()
				return
			}
			if (action === CONSTANT.DRAWER_TYPE.EDIT && userDetail && !checkPrivileges(userDetail, `${moduleInfo.permissionPrefix}_UPDATE`)) {
				navigateToIndex()
				return
			}
			if (action === CONSTANT.DRAWER_TYPE.VIEW && userDetail && !checkPrivileges(userDetail, `${moduleInfo.permissionPrefix}_DETAILS`)) {
				navigateToIndex()
				return
			}
			if (!id && action) {
				setVisibleDrawer(action);
			} else if (id && action) {
				getDetails(id, action);
			} else {
				navigateToIndex();
			}
		}
	}, [userDetail, action, id]);

	// const onChange = (key: string) => {
	// 	console.log(key);

	// };

	const items: TabsProps['items'] = [
		{
			key: '1',
			label: `${t("MLAMEETINGMANAGEMENT.CALENDER")}`,
			children: <Listing />,
		},
		{
			key: '2',
			label: `${t("MLAMEETINGMANAGEMENT.LIST")}`,
			children: <ListingData
				moduleInfo={moduleInfo}
				handleViewClick={(data: any) => {
					navigate(
						`${moduleInfo.indexRoute}/${CONSTANT.DRAWER_TYPE.VIEW}/${data.id}`
					);
				}}
				handleEditClick={(data: any) => {
					navigate(
						`${moduleInfo.indexRoute}/${CONSTANT.DRAWER_TYPE.EDIT}/${data.id}`
					);
				}}
				handleDeleteClick={setDeleteValue}
			/>,
		},
	];

	return (
		<>
			<ToolBar breadcrumbs={MLACalenderBreadcrumb(t)}>
				<CreateButton
					action={() =>
						navigate(`${moduleInfo.indexRoute}/${CONSTANT.DRAWER_TYPE.ADD}`)
					}
					permissionKey={`${moduleInfo.permissionPrefix}_CREATE`}
				/>
			</ToolBar>
			<ContentBox>
				<Tabs defaultActiveKey="1" items={items}  />
				<AddComponent
					titleName={t("MLAMEETINGMANAGEMENT.MLA_CALENDER")}
					open={visibleDrawer === CONSTANT.DRAWER_TYPE.ADD}
					close={navigateToIndex}
				/>
				<DeleteModal
					title={t("MLAMEETINGMANAGEMENT.MLA_CALENDER")}
					deleteValues={deleteValue}
					callApi={deleteRecord}
					close={() => setDeleteValue(null)}
					keyName="title"
				/>
				<EditComponent
					titleName={t("MLAMEETINGMANAGEMENT.MLA_CALENDER")}
					open={visibleDrawer === CONSTANT.DRAWER_TYPE.EDIT}
					close={navigateToIndex}
				/>
				<ViewDrawer
					titleName={t("MLAMEETINGMANAGEMENT.MLA_CALENDER")}
					open={visibleDrawer === CONSTANT.DRAWER_TYPE.VIEW}
					close={navigateToIndex}
				/>
			</ContentBox>
		</>
	)
}

export default MLAMeetingManagement