import React, { useEffect } from 'react'
import { FormBox, InputBox } from '../../../../../../components/AntdAddons';
import { Col, Row } from 'antd';
import { rules } from './rules';
import { useAppDispatch, useAppSelector } from '../../../../../../store/app';
import { fetchPincodeList } from '../../../PincodeManagement/utils/slice';
import { useTranslation } from 'react-i18next';

const FormComponent: React.FC<any> = ({
    form,
    id,
    handleSubmit,
    onValuesChange,
    editValues,
}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch()
    const { pincodeList } = useAppSelector(
        state => state.pincode
    );

    useEffect(() => {
        dispatch(fetchPincodeList({ is_active: 1 })).catch((e: any) => e)
    }, [])
    useEffect(() => {
        if (editValues) {
            form.setFieldsValue({
                ...editValues,
            });
        }
    }, [editValues, form]);
    const onFinish = (data: any) => {
        handleSubmit(data);
    };
    return (
        <FormBox
            form={form}
            id={id}
            onFinish={onFinish}
            onValuesChange={onValuesChange}
        >
            <Row gutter={15}>
                <Col xs={{ span: 12 }} >
                    <InputBox.Select
                        required
                        showSearch
                        name="pincode_id"
                        optionFilterProp="children"
                        label={t("GENERAL.PINCODES")}
                        placeholder={t("GENERAL.PINCODES")}
                        options={{
                            list: pincodeList,
                            valueKey: "id",
                            textKey: "pincode",
                        }}
                        rules={rules.pincode()}
                    />
                </Col>
                <Col xs={{ span: 12 }} >
                    <InputBox.Text
                        required
                        label={t("GENERAL.TALUKA")}
                        name="name"
                        placeholder={t("GENERAL.TALUKA")}
                        rules={rules.taluka()}
                    />
                </Col>
            </Row>
        </FormBox>
    )
}

export default FormComponent