import React, { useState } from 'react'


import { changeStatus, setupGrid } from '../../utils/slice';

import columnDefs from './columnDefs';
import { useAppSelector } from '../../../../../../store/app';
import ActionButtons from '../../../../../../components/ActionButtons';
import ChangeStatusModal from '../../../../../../components/Modals/ChangeStatusModal';
import AgGridWrapper from '../../../../../../components/AgGridWrapper/AgGridWrapper';
import { camelCaseString, checkPrivileges } from '../../../../../../config/Global';
import { Popover } from 'antd';
import { MoreOutlined } from "@ant-design/icons";
import { CONSTANT } from '../../../../../../config/Constant';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as VillageDetails } from "../../../../../../assets/images/icon/VillageDetails.svg";
import { ReactComponent as PartyWorker } from "../../../../../../assets/images/icon/VillagePartyPersons.svg";
import { ReactComponent as MalnutrionedChildren } from "../../../../../../assets/images/icon/VillageMalnutrionedChildren.svg";
import { ReactComponent as GovSchemes } from "../../../../../../assets/images/icon/VillageGovSchemes.svg";
import { ReactComponent as UnavailableServicesGovSchemes } from "../../../../../../assets/images/icon/VillageUnavailableServices.svg";
import { ReactComponent as VillageProblem } from "../../../../../../assets/images/icon/VillageProblems.svg";
import { ReactComponent as VillageAchievements } from "../../../../../../assets/images/icon/VillageAchievements.svg";
import { ReactComponent as VillageFestival } from "../../../../../../assets/images/icon/VillageFestival.svg";
import { ReactComponent as VillageAttraction } from "../../../../../../assets/images/icon/VillageAttraction.svg";
import { useTranslation } from 'react-i18next';



const Listing: React.FC<any> = ({
	moduleInfo,
	handleViewClick,
	handleDeleteClick,
	handleEditClick,
	setVillageId,
	setVillageDetailsId
}) => {
	const { t } = useTranslation();
	const svgIcons: any = {
		VillageDetails: <VillageDetails />,
		PartyWorker: <PartyWorker />,
		MalnutrionedChildren: <MalnutrionedChildren />,
		GovSchemes: <GovSchemes />,
		UnavailableServices: <UnavailableServicesGovSchemes />,
		VillageProblem: <VillageProblem />,
		VillageAchievements: <VillageAchievements />,
		VillageFestival: <VillageFestival />,
		VillageAttraction: <VillageAttraction />
	};
	const navigate = useNavigate()
	const [statusData, setStatusData] = useState<any>();
	const userDetail = useAppSelector(state => state.auth.userDetail);
	const content = (data: any) => {
		return (
			<div>
				{CONSTANT.STEPS_CONTENT.map((item: any, index: number) => {
					return (
						<span
							title={item.name}
							className='stepsIcon'
							key={index}
							onClick={() => {
								setVillageId(data?.village_id)
								setVillageDetailsId(data?.id),
									navigate(
										`${moduleInfo.indexRoute}/${CONSTANT.DRAWER_TYPE.EDIT}/${data.id}?step=${item.id}&village=${data?.village_id}`
									)
							}}
						>
							{svgIcons[camelCaseString(item.name).replaceAll(" ", "")]}
						</span>
					)
				})}
			</div>
		)
	}

	const ActionRenderer = (props: any) => {

		return (
			<>

				<ActionButtons
					data={props}
					view={{
						action: handleViewClick,
						permissionKey: `${moduleInfo.permissionPrefix}_VIEW`,
					}}
					edit={{
						action: handleEditClick,
						permissionKey: `${moduleInfo.permissionPrefix}_UPDATE`,
					}}
					deleteButton={{
						action: handleDeleteClick,
						permissionKey: `${moduleInfo.permissionPrefix}_DELETE`,
					}}
				/>
				<Popover
					placement="topLeft"
					content={() => content(props.data)}
					trigger={'hover'}>
					<MoreOutlined />
				</Popover>
				{/* <div>
					{CONSTANT.STEPS_CONTENT.map((item: any, index: number) => {
						return (
							<span
								title={item.name}
								className='stepsIcon'
								key={index}
								onClick={() => {
									setVillageId(props?.data?.village_id)
									setVillageDetailsId(props?.data?.id),
										navigate(
											`${moduleInfo.indexRoute}/${CONSTANT.DRAWER_TYPE.EDIT}/${props?.data.id}?step=${item.id}&village=${props?.data?.village_id}`
										)
								}}
							>
								{svgIcons[camelCaseString(item.name).replaceAll(" ", "")]}
							</span>
						)
					})}
				</div> */}
			</>
		)
	}
	return (
		<>
			<ChangeStatusModal
				titleName={t("VILLAGEMANAGEMENT.VILLAGE")}
				data={statusData}
				close={() => setStatusData(null)}
				callApi={changeStatus}
				keyName={"name"}
			/>
			<AgGridWrapper
				type="serverSide"
				components={{
					ActionRenderer,
				}}
				onGridReadyWithDispatch={setupGrid}
				columnDefs={columnDefs()}
				context={{
					setStatusData, changeStatusPermission: checkPrivileges(
						userDetail,
						`${moduleInfo.permissionPrefix}_CHANGE_STATUS`
					)
				}}
			/>
		</>
	);
}

export default Listing