import { useState } from "react";
import { Form, DrawerProps, Drawer, Button } from "antd";
import { updateRecord } from "../../utils/slice";
import FormComponent from "./FormComponent";
import { assignErrorToInput } from "../../../../../../store/api";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../store/app";
import { validateFields } from "../../../../../../config/Global";
import SVGIcon from "../../../../../../utils/SVGIcon";
import Config from "../../../../../../config/Config";
import { setPincodeList } from "../../../PincodeManagement/utils/slice";
import { setTalukaList } from "../../../TalukaManagement/utils/slice";
import { CONSTANT } from "../../../../../../config/Constant";
import { setVillageList } from "../../../VillageVsWard/utils/slice";
import { useTranslation } from "react-i18next";

interface EditDrawerProps extends DrawerProps {
  titleName: string;
  open: boolean;
  saveAndNext: any;
  setVillageId: any;
  close: () => void;
}

const EditComponent: React.FC<EditDrawerProps> = ({
  titleName,
  open,
  close,
  saveAndNext,
  setVillageId,
  ...rest
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const details = useAppSelector(state => state.village.details);
  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  const [fetchpincode, setFetchPincode] = useState<boolean>(true);
  const [fetchTaluka, setFetchTaluka] = useState<boolean>(true);
  const [submitForm, setSubmitForm] = useState<any>();
  const [fetchVillage, setFetchVillage] = useState<boolean>(true);

  const drawerClose = (submitForm = "") => {
    if (submitForm === "") {
      close()
    }
    form.resetFields();
    setDisabled(true);
    setFetchPincode(true);
    setFetchTaluka(true);
    dispatch(setTalukaList([]));
    dispatch(setPincodeList([]));
    setFetchVillage(true);
    dispatch(setVillageList([]));
    setSaving(false)
  }

  const validateForm = () => {
    validateFields(form, setDisabled);
  };
  const skipPage = () => {
    setVillageId(details?.id)
    saveAndNext(CONSTANT.STEP.VILLAGE_DETAILS)
  }
  const handleSubmit = (data: any) => {
    setSaving(true);
    dispatch(updateRecord(details.id, data))
      .then(() => {
        setVillageId(details.id)
        if (submitForm === "saveAndNext") {
          drawerClose(submitForm);
          saveAndNext(CONSTANT.STEP.VILLAGE_DETAILS)
        } else {
          drawerClose();
        }
      })
      .catch((error: any) => {
        assignErrorToInput(form, error?.STATUS);
      })
      .finally(() => setSaving(false));
  };

  return (
    <Drawer
      title={`${titleName} ${t("GENERAL.EDIT__DRAWER_TITLE")}`}
      open={open}
      width={"70%"}
      onClose={() => drawerClose()}
      destroyOnClose
      closeIcon={<SVGIcon icon="close" color={Config.themePrimaryBtn} />}
      footer={[
        <Button
          key="2"
          htmlType="button"
          className="cancelBtn"
          onClick={() => drawerClose()}
        >
          {t("GENERAL.CANCEL_BUTTON")}
        </Button>,
        <Button
          key="4"
          htmlType="button"
          type="primary"
          onClick={() => skipPage()}
        >
          {t("GENERAL.CONTINUE_BUTTON")}
        </Button>,
        <Button
          key="1"
          form="editForm"
          disabled={disabled}
          loading={saving}
          htmlType="submit"
          type="primary"
          onClick={() => setSubmitForm("save")}
        >
          {t("GENERAL.SAVE_BUTTON")}
        </Button>,
        <Button
          key="3"
          disabled={disabled}
          form="editForm"
          loading={saving}
          htmlType="submit"
          type="primary"
          onClick={() => setSubmitForm("saveAndNext")}
        >
          {t("GENERAL.SAVE_NEXT_BUTTON")}
        </Button>

      ]}
      {...rest}
    >
      <FormComponent
        form={form}
        id="editForm"
        editValues={details}
        handleSubmit={handleSubmit}
        onValuesChange={validateForm}
        fetchpincode={fetchpincode}
        setFetchPincode={setFetchPincode}
        fetchTaluka={fetchTaluka}
        setFetchTaluka={setFetchTaluka}
        fetchVillage={fetchVillage}
        setFetchVillage={setFetchVillage}
      />
    </Drawer>
  );
};

export default EditComponent;
