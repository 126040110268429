const baseUrl = "/v1/admin/villages";
export const API_URL = {
  LIST: `${baseUrl}`,
  CREATE: `${baseUrl}/create`,
  UPDATE: (id: number): string => `${baseUrl}/${id}/update`,
  DELETE: (id: number): string => `${baseUrl}/${id}/delete`,
  DETAILS: (id: number): string => `${baseUrl}/${id}/details`,
  CHANGE_STATUS: (id: number): string => `${baseUrl}/${id}/change-status`,
  VILLAGE_LIST: (search?: string): string =>
    `/v1/admin/villages/lov${search ? `?taluka_id=${search}` : ""}`,
  VILLAGE_DETAILS: `${baseUrl}/village-details/create`,
  VILLAGE_PARTY_FORM_LIST: `${baseUrl}/village-details/lov`
};