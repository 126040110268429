import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import Config, { getServerListPayload } from "../../../../../config/Config";
import api from "../../../../../store/api";
import { AppThunk, RootState } from "../../../../../store/app";
import { GridOptions, GridReadyEvent, IServerSideGetRowsParams } from "ag-grid-community";
import { convertTextToID, meetingStatusOptions } from "../../../../../config/Global";
import { API_URL } from "./apiUrls";

interface InitialState {
    isLoading: boolean;
    agGrid: any;
    perPage: any;
    details: any;
    meetingList: [];
}
const initialState = {
    isLoading: false,
    agGrid: null,
    meetingList: [],
    perPage: Config.grid.server.gridOptions?.paginationPageSize,
    details: null,
} as InitialState;

const citizenMeetingSlice = createSlice({
    name: "citizen meeting",
    initialState,
    reducers: {
        start: state => {
            state.isLoading = true;
        },
        success: state => {
            state.isLoading = false;
        },
        failure: state => {
            state.isLoading = false;
        },
        setGrid: (state, action: PayloadAction<any>) => {
            state.agGrid = action?.payload;
        },
        setCitizenMeetingList: (state, action: PayloadAction<any>) => {
            state.meetingList = action.payload;
        },
        setPerPage: (state, action: PayloadAction<any>) => {
            state.perPage = action?.payload;
            if (state.agGrid) {
                state.agGrid.api.paginationSetPageSize(Number(state.perPage));
                state.agGrid.api.setCacheBlockSize(state.perPage);
            }
        },
        setDetails: (state, action: PayloadAction<any>) => {
            state.details = action?.payload;
        },
    }
})
export const { start, success, failure, setGrid, setPerPage, setDetails, setCitizenMeetingList } =
    citizenMeetingSlice.actions;

/** For Listing:Start */

const fetchList = async (payload?: any): Promise<any> => {
    return await api
        .post(API_URL.LIST, payload)
        .then(({ data }) => {
            return data;
        })
        .catch(error => error);
};
export const setPerPageSize =
    (size: number): AppThunk<any> =>
        async (dispatch, getState: () => RootState) => {
            dispatch(setPerPage(size));
            dispatch(setupGrid(getState().citizenMeeting.agGrid));
        };
export const setupGrid =
    (params: GridReadyEvent): AppThunk<any> =>
        async (dispatch, getState: () => RootState) => {
            try {
                dispatch(setGrid(params));
                const dataSource = await createDataSource(
                    getState,
                    Config.grid.server.gridOptions
                );
                params.api.setServerSideDatasource(dataSource);
            } catch (error: any) {
                //
            }
        };
const changeFilterAndSort = (params: any) => {
    params;
    if (params.filterModel && params.filterModel["status"]) {
        params.filterModel["status"].values = convertTextToID(
            params.filterModel["status"],
            meetingStatusOptions
        );
    }
};
const createDataSource = (
    getState: () => RootState,
    gridOptions?: GridOptions
) => {
    return {
        gridOptions,
        getRows: (params: IServerSideGetRowsParams) => {
            changeFilterAndSort(params.request);
            const payload = getServerListPayload(params);
            fetchList(payload).then(data => {
                if (data.count > 0) {
                    const startPage = (payload.page - 1) * payload.per_page;
                    data.rows.map((item: any, index: number) => {
                        item.srno = startPage + index + 1;
                        return null;
                    });
                }
                params.success({ rowData: data?.rows, rowCount: data?.count });
                if (data.count <= 0) {
                    params.api.showNoRowsOverlay();
                } else {
                    params.api.hideOverlay();
                }
            }).catch((error: any) => error);
        },
    };
};
/** For Listing:End */

const citizenMeetingReducer = citizenMeetingSlice.reducer;
export default citizenMeetingReducer;