import React, { useState } from 'react'
import { Button, Drawer, DrawerProps, Form } from 'antd';
import { useAppDispatch } from '../../../../../../store/app';
import { validateFields } from '../../../../../../config/Global';
import { assignErrorToInput } from '../../../../../../store/api';
import SVGIcon from '../../../../../../utils/SVGIcon';
import Config from '../../../../../../config/Config';
import FormComponent from './FormComponent';
import { createRecord } from '../../utils/slice';
import { useTranslation } from 'react-i18next';

interface AddDrawerProps extends DrawerProps {
    titleName: string;
    close: () => void;
}
const AddComponent: React.FC<AddDrawerProps> = ({
    titleName,
    close,
    ...rest
}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();
    const [disabled, setDisabled] = useState<boolean>(true);
    const [saving, setSaving] = useState<boolean>(false);
    const drawerClose = () => {
        close()
        form.resetFields();
        setDisabled(true)
    }
    const validateForm = () => {
        validateFields(form, setDisabled);
    };

    const handleFormSubmit = (data: any) => {
        setSaving(true);
   
        dispatch(createRecord(data))
            .then(() => {
                drawerClose();
            })
            .catch((error: any) => {
                assignErrorToInput(form, error?.STATUS);
            })
            .finally(() => setSaving(false));
    };
    return (
        <>
            <Drawer
                title={`${titleName} ${t("GENERAL.ADD_DRAWER_TITLE")}`}
                placement="right"
                width={"70%"}
                onClose={drawerClose}
                destroyOnClose
                closeIcon={<SVGIcon icon="close" color={Config.themePrimaryBtn} />}
                footer={[
                    <Button
                        key="2"
                        htmlType="button"
                        className="cancelBtn"
                        onClick={drawerClose}
                    >
                        {t("GENERAL.CANCEL_BUTTON")}
                    </Button>,
                    <Button
                        key="1"
                        disabled={disabled}
                        form="addForm"
                        loading={saving}
                        htmlType="submit"
                        type="primary"
                    >
                        {t("GENERAL.SAVE_BUTTON")}
                    </Button>,
                ]}
                {...rest}
            >
                <FormComponent
                    form={form}
                    id="addForm"
                    handleSubmit={handleFormSubmit}
                    onValuesChange={validateForm}
                ></FormComponent>
            </Drawer>
        </>
    )
}

export default AddComponent