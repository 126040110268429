import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Config, { getServerListPayload } from "../../../../../config/Config";
import api from "../../../../../store/api";
import { API_URL } from "./apiUrls";
import { GridOptions, GridReadyEvent, IServerSideGetRowsParams } from "ag-grid-community";
import { AppThunk, RootState } from "../../../../../store/app";
import { convertTextToID, GrantStatusList } from "../../../../../config/Global";

interface InitialState {
    isLoading: boolean;
    agGrid: any;
    perPage: any;
    details: any;
    pinnedData: any;
    grant_yearList: any;
    gov_deptList: any
}
const initialState = {
    isLoading: false,
    agGrid: null,
    perPage: Config.grid.server.gridOptions?.paginationPageSize,
    details: null,
    pinnedData: [],
    grant_yearList: [],
    gov_deptList: []

} as InitialState;
const MLAGrantSlice = createSlice({
    name: "mlagrant",
    initialState,
    reducers: {
        start: state => {
            state.isLoading = true;
        },
        success: state => {
            state.isLoading = false;
        },
        failure: state => {
            state.isLoading = false;
        },
        setGrid: (state, action: PayloadAction<any>) => {
            state.agGrid = action?.payload;
        },
        setPerPage: (state, action: PayloadAction<any>) => {
            state.perPage = action?.payload;
            if (state.agGrid) {
                state.agGrid.api.paginationSetPageSize(Number(state.perPage));
                state.agGrid.api.setCacheBlockSize(state.perPage);
            }
        },
        setDetails: (state, action: PayloadAction<any>) => {
            state.details = action?.payload;
        },
        setPinnedData: (state, action: PayloadAction<any>) => {
            state.pinnedData = action?.payload;
        },
        setGrant_yearData: (state, action: PayloadAction<any>) => {
            state.grant_yearList = action?.payload;
        },
        setGov_deptData: (state, action: PayloadAction<any>) => {
            state.gov_deptList = action?.payload;
        },
    }
})
export const { start,
    success,
    failure,
    setGrid,
    setPerPage,
    setDetails,
    setPinnedData,
    setGrant_yearData,
    setGov_deptData
} = MLAGrantSlice.actions

/** For Listing:Start */
const fetchList = async (payload?: any): Promise<any> => {
    return await api.post(API_URL.LIST, payload)
        .then(({ data }) => {
            return data;
        })
        .catch(error => error);
}
export const setPerPageSize =
    (size: number): AppThunk<any> =>
        async (dispatch, getState: () => RootState) => {
            dispatch(setPerPage(size));
            dispatch(setupGrid(getState().mlaGrant.agGrid));
        };
export const setupGrid =
    (params: GridReadyEvent): AppThunk<any> =>
        async (dispatch, getState: () => RootState) => {
            try {
                dispatch(setGrid(params));
                const dataSource = await createDataSource(
                    getState,
                    Config.grid.server.gridOptions,
                    dispatch
                );
                params.api.setServerSideDatasource(dataSource);
            } catch (error: any) {
                //
            }
        };
const changeStatusFilter = (params: any) => {
    params;
    if (params.filterModel && params.filterModel["status"]) {
        params.filterModel["status"].values = convertTextToID(
            params.filterModel["status"],
            GrantStatusList
        );
    }
};
const createDataSource = (
    getState: () => RootState,
    gridOptions?: GridOptions,
    dispatch?: any
) => {
    return {
        gridOptions,
        getRows: (params: IServerSideGetRowsParams) => {
            changeStatusFilter(params.request);
            const payload = getServerListPayload(params);
            fetchList(payload).then(data => {
                if (data.count > 0) {
                    const startPage = (payload.page - 1) * payload.per_page;
                    data.rows.map((item: any, index: number) => {
                        item.srno = startPage + index + 1;
                        return null;
                    });
                }
                const footerData = [
                    {
                        ...data?.footer,
                        actionColumn: ""
                    }
                ];
                dispatch(setPinnedData(footerData));
                params.success({ rowData: data?.rows, rowCount: data?.count });
                if (data.count <= 0) {
                    params.api.showNoRowsOverlay();
                } else {
                    params.api.hideOverlay();
                }
            }).catch((error: any) => error);
        },
    };
};
/** For Listing:End */
export const createRecord = (action: any): AppThunk<any> =>
    async (dispatch, getState) => {
        try {
            dispatch(start())
            const response = await api.post(API_URL.CREATE, action)
            dispatch(setupGrid(getState().mlaGrant.agGrid));
            dispatch(success(response.data));
            return Promise.resolve(response.data);

        } catch (error: any) {
            dispatch(failure(error.data))
            return Promise.reject(error.data)
        }
    }
export const details =
    (id: number): AppThunk<any> =>
        async dispatch => {
            try {
                const response = await api.get(API_URL.DETAILS(id));
                dispatch(setDetails(response.data.data));
                return Promise.resolve(response.data);
            } catch (error: any) {
                return Promise.reject(error.data);
            }
        };
export const deleteRecord =
    (id: number, action: any): AppThunk<any> =>
        async (dispatch, getState) => {
            try {
                dispatch(start());
                const response = await api.delete(API_URL.DELETE(id), action);
                dispatch(setupGrid(getState().mlaGrant.agGrid));
                dispatch(success(response.data));
                return Promise.resolve(response.data);
            } catch (error: any) {
                dispatch(failure(error.data));
                return Promise.reject(error.data);
            }
        };
export const updateRecord =
    (id: number, action: any): AppThunk<any> =>
        async (dispatch, getState) => {
            try {
                dispatch(start());
                const response = await api.post(API_URL.UPDATE(id), action);
                dispatch(setupGrid(getState().mlaGrant.agGrid));
                dispatch(success(response.data));
                return Promise.resolve(response.data);
            } catch (error: any) {
                dispatch(failure(error.data));
                return Promise.reject(error.data);
            }
        };

export const doDownloadFile =
    (action?: any): AppThunk<any> =>
        async (dispatch) => {
            try {
                const payload = {
                    "filter_data": {},
                    "sort_data": [],
                    "per_page": 50,
                    "page": 1,
                    do_export: action
                }
                const response = await api.post(API_URL.LIST, payload);
                dispatch(success(response.data));
                return Promise.resolve(response.data);
            } catch (error: any) {
                dispatch(failure(error.data));
                return Promise.reject(error.data);
            }
        };

export const grantYearLov =
    (): AppThunk<any> =>
        async dispatch => {
            try {
                const response = await api.get(API_URL.GRANTYEARLOV);
                dispatch(setGrant_yearData(response.data.data));
                return Promise.resolve(response.data);
            } catch (error: any) {
                return Promise.reject(error.data);
            }
        };
export const govDeptLov =
    (): AppThunk<any> =>
        async dispatch => {
            try {
                const response = await api.get(API_URL.GOVDEPTLOV);
                dispatch(setGov_deptData(response.data.data));
                return Promise.resolve(response.data);
            } catch (error: any) {
                return Promise.reject(error.data);
            }
        };
export const updateStatus =
    (payload?: any): AppThunk<any> =>
        async (dispatch, getState) => {
            try {
                const response = await api.post(API_URL.STATUS, payload);
                dispatch(setupGrid(getState().mlaGrant.agGrid));
                return Promise.resolve(response.data);
            } catch (error: any) {
                return Promise.reject(error.data);
            }
        };
const mlaGrantReducer = MLAGrantSlice.reducer
export default mlaGrantReducer