import { Button, Col, Form, ModalProps, Row } from 'antd';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import CommonModal from '../../../components/Modals/CommonModal';
import { FormBox, InputBox } from '../../../components/AntdAddons';
import { GrantStatusList, validateFields } from '../../../config/Global';
import { CONSTANT } from '../../../config/Constant';
import { rules } from './Components/Form/rules';
import dayjs from 'dayjs';
import { useAppDispatch } from '../../../store/app';
import { updateStatus } from './Components/utils/slice';
import { assignErrorToInput } from '../../../store/api';

interface DeleteModalProps extends ModalProps {
    title?: string;
    close: any;
    open: boolean;
    grantStatus: any;
}
const StatusModel: React.FC<DeleteModalProps> = ({
    title,
    close,
    open,
    grantStatus
}) => {
    const { t } = useTranslation();
    const [statusId, setStatusId] = useState(1)
    const [form] = Form.useForm();
    const [disabled, setDisabled] = useState<boolean>(true);
    const [saving, setSaving] = useState<boolean>(false);
    const dispatch = useAppDispatch()

    const onCloseModel = () => {
        setDisabled(true)
        setStatusId(1)
        form.resetFields();
        close()
    }

    const handleSubmit = (data: any) => {
        data.grant_id = grantStatus?.grant_id
        if (data.status_date) {
            data.status_date = dayjs(data.status_date).format(CONSTANT.POST_DATE_FORMAT);
        }
        setSaving(true)
        dispatch(updateStatus(data)).then(() => {
            onCloseModel()
        }).catch((error: any) => {
            assignErrorToInput(form, error?.STATUS);
        }).finally(() => setSaving(false));
    }
    const onValuesChange = () => {
        validateFields(form, setDisabled);
    };
    useEffect(() => {
        if (grantStatus !== null) {
            const nextStatusId = grantStatus?.status ? grantStatus?.status + 1 : 1
            setStatusId(nextStatusId)
            form.setFieldValue("status_id", nextStatusId)
        }
    }, [grantStatus])

    const minDate = grantStatus?.status_date ? dayjs(grantStatus?.status_date).startOf("day") : dayjs().startOf("day");
    const disablePreviousDates = (current: any) => {
        if (grantStatus?.status_date === null) {
            return current && current > minDate;
        } else {
            return current && current < minDate;
        }
    };

    // Configuration for dynamic fields based on statusId
    const statusConfig = {
        [CONSTANT.MLAGRANTMANAGEMENT.STATUSMODEL.Date_of_Preliminary_Approval]: {
            datePickerLabel: t('MLAGRANTMANAGEMENT.Date_of_Preliminary_Approval'),
            textLabel: t('MLAGRANTMANAGEMENT.Preliminary_Approval_number'),
        },
        [CONSTANT.MLAGRANTMANAGEMENT.STATUSMODEL.Tantric_approval_Date]: {
            datePickerLabel: t('MLAGRANTMANAGEMENT.Tantric_approval_Date'),
            textLabel: t('MLAGRANTMANAGEMENT.Tantric_approval_number'),
        },
        [CONSTANT.MLAGRANTMANAGEMENT.STATUSMODEL.Date_of_Administration_approval]: {
            datePickerLabel: t('MLAGRANTMANAGEMENT.Date_of_Administration_approval'),
            textLabel: t('MLAGRANTMANAGEMENT.Administration_approval_number'),
        },
        [CONSTANT.MLAGRANTMANAGEMENT.STATUSMODEL.Grant_Allotment_Authority_Date]: {
            datePickerLabel: t('MLAGRANTMANAGEMENT.Grant_Allotment_Authority_Date'),
            textLabel: t('MLAGRANTMANAGEMENT.Grant_Allotment_Authority_number'),
        },
    };

    const currentConfig = statusConfig[statusId];

    return (
        <CommonModal
            title={`${title}`}
            open={open}
            onCancel={onCloseModel}
        >
            <FormBox
                form={form}
                id={"statusForm"}
                onFinish={handleSubmit}
                onValuesChange={onValuesChange}
            >
                <Row gutter={15}>
                    <Col sm={24} md={24}  >
                        <InputBox.Select
                            disabled
                            name="status_id"
                            label={t("MLAGRANTMANAGEMENT.STATUS")}
                            placeholder={t("MLAGRANTMANAGEMENT.STATUS")}
                            options={{
                                list: GrantStatusList,
                                valueKey: "id",
                                textKey: "name",
                            }}
                            onChange={(e) => setStatusId(e)}
                        />
                    </Col>
                
                    {currentConfig && (
                        <>
                            <Col sm={24} md={24}>
                                <InputBox.DatePicker
                                    rules={rules.grant_status()}
                                    name="status_date"
                                    label={currentConfig.datePickerLabel}
                                    placeholder={currentConfig.datePickerLabel}
                                    format={CONSTANT.DATE_FORMAT}
                                    defaultPickerValue={minDate}
                                    disabledDate={disablePreviousDates}
                                />
                            </Col>
                            <Col sm={24} md={24}>
                                <InputBox.Text
                                    label={currentConfig.textLabel}
                                    name="status_number"
                                    rules={rules.grant_id_number()}
                                    maxLength={15}
                                />
                            </Col>
                        </>
                    )}
                </Row>
                <div className='statusModelBtn'>
                    <Button
                        key="1"
                        disabled={disabled}
                        form={"statusForm"}
                        loading={saving}
                        htmlType="submit"
                        type="primary"
                    >
                        {t("GENERAL.SAVE_BUTTON")}
                    </Button>
                    <Button
                        key="2"
                        htmlType="button"
                        className="cancelBtn statusModelCancelBtn"
                        onClick={() => onCloseModel()}
                    >
                        {t("GENERAL.CANCEL_BUTTON")}
                    </Button>
                </div>
            </FormBox>
        </CommonModal>
    )
}

export default StatusModel