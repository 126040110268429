import { Messages } from "../../../../../config/Messages";
import {
    validations,
} from "../../../../../config/validations/validations";
export const rules: any = {

    taluka: (field?: string) => [
        validations.required.other(field),
    ],
    categories: (field?: string) => [
        validations.required.other(field),
    ],
    village: (field?: string) => [
        validations.required.other(field),
        ({ getFieldValue }: any) => ({
            validator() {
                if (getFieldValue("taluka_id") === undefined) {
                    return Promise.reject(Messages.SELECT_TALUKA);
                }
                return Promise.resolve();
            },
        }),
    ],
}