import { Form, DrawerProps, Drawer, Descriptions } from "antd";
import SVGIcon from "../../../../utils/SVGIcon";
import Config from "../../../../config/Config";
import CancelButton from "../../../../components/Buttons/CancelButton";
import { renderNA } from "../../../../components/AgGridWrapper/utils/commonFunctions";
// import { genderOptions } from "../../../../config/Constant";
import { useAppSelector } from "../../../../store/app";
import { EntryFromOptionsTypeDisplay, genderOptionsDisplay, villageTypeDisplay } from "../../../../config/Global";
import { useTranslation } from "react-i18next";
import { CONSTANT } from "../../../../config/Constant";
import dayjs from "dayjs"

interface ViewDrawerProps extends DrawerProps {
  titleName: string;
  open: boolean;
  close: () => void;
}

const ViewDrawer: React.FC<ViewDrawerProps> = ({
  titleName,
  open,
  close,
  ...rest
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const details = useAppSelector(state => state.citizen.details);

  const drawerClose = () => {
    close();
    form.resetFields();
  };

  return (
    <Drawer
      title={`${titleName} ${t("GENERAL.REGISTER")} ${t("GENERAL.VIEW__DRAWER_TITLE")}`}
      open={open}
      placement="right"
      width={"70%"}
      onClose={drawerClose}
      destroyOnClose
      closeIcon={<SVGIcon icon="close" color={Config.themePrimaryBtn} />}
      footer={[<CancelButton key={2} onClick={drawerClose} />]}
      {...rest}
    >
      <Descriptions column={2} bordered layout="horizontal">
        <Descriptions.Item label={`${t("CITIZENMANAGEMENT.FIRST_NAME")}`}>
          {renderNA(details?.first_name)}
        </Descriptions.Item>
        <Descriptions.Item label={`${t("CITIZENMANAGEMENT.MIDDLE_NAME")}`}>
          {renderNA(details?.middle_name)}
        </Descriptions.Item>
        <Descriptions.Item label={`${t("CITIZENMANAGEMENT.LAST_NAME")}`}>
          {renderNA(details?.last_name)}
        </Descriptions.Item>
        <Descriptions.Item label={`${t("GENERAL.EMAIL_ID")}`}>
          {renderNA(details?.email)}
        </Descriptions.Item>
        <Descriptions.Item label={`${t("GENERAL.DATE_OF_BIRTH")}`}>
          {details?.dob ? renderNA(dayjs(details?.dob).format(CONSTANT.DATE_FORMAT)) : "N/A"}
        </Descriptions.Item>
        <Descriptions.Item label={`${t("GENERAL.GENDER")}`}>
          {renderNA(genderOptionsDisplay[details?.gender])}
        </Descriptions.Item>
        <Descriptions.Item label={`${t("GENERAL.MOBILE_NUMBER")}`}>
          {renderNA(details?.phone)}
        </Descriptions.Item>
        <Descriptions.Item label={`${t("CITIZENMANAGEMENT.WHATSAPP_NUMBER")}`}>
          {renderNA(details?.whatsapp_number)}
        </Descriptions.Item>
        <Descriptions.Item label={`${t("GENERAL.PINCODES")}`}>
          {renderNA(details?.pincode?.pincode)}
        </Descriptions.Item>
        <Descriptions.Item label={`${t("GENERAL.TALUKA")}`}>
          {renderNA(details?.taluka?.name)}
        </Descriptions.Item>
        <Descriptions.Item label={`${t("GENERAL.VILLAGES")}`}>
          {renderNA(details?.village?.name)}
        </Descriptions.Item>
        <Descriptions.Item label={`${t("GENERAL.NAGERPALLIKA_VILLAGE_TYPE")}`}>
          {renderNA(villageTypeDisplay[details?.village_type_id])}
        </Descriptions.Item>
        <Descriptions.Item label={`${t("CITIZENMANAGEMENT.Entry_from")}`}>
          {renderNA(EntryFromOptionsTypeDisplay[details?.entry_from])}
        </Descriptions.Item>
        <Descriptions.Item label={`${t("GENERAL.ADDRESS")}`}>
          {renderNA(details?.address)}
        </Descriptions.Item>
      </Descriptions>
    </Drawer>
  );
};

export default ViewDrawer;
