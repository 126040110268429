import { Button, Form, } from "antd";
import React, { useState } from "react";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import { Link, useNavigate } from "react-router-dom";
import { assignErrorToInput } from "../../../../store/api";
import { LoginRequest } from "../rules";
import { forgotPassword } from "../../../../store/AuthSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/app";
import loginBgImage from "../../../../assets/images/apps/loginBgImage.png"
import logoImage from "../../../../assets/images/apps/New-Jan-Utkarsh-Yojna-Logo.png"
import { useTranslation } from "react-i18next";
import LanguageDropdown from "../../../../components/LanguageDropdown/LanguageDropdown";
// import LoginBanner from "../../../../assets/images/apps/login-image.png";

const ForgotPassword: React.FC = () => {
	const dispatch = useAppDispatch();
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const navigate = useNavigate();
	// const syncData = useAppSelector(state => state.auth.initialData?.syncData)
	const syncDataNew = useAppSelector(state => state?.configuration?.details)
	const { t } = useTranslation();
	const handleSubmit = (data: any) => {
		setSaving(true);
		dispatch(forgotPassword(data))
			.then(() => {
				form.resetFields();
				navigate("/login");
			})
			.catch((error: any) => {
				assignErrorToInput(form, error?.STATUS);
			})
			.finally(() => setSaving(false));
	};

	return (
		<section className="loginSection" style={{ backgroundImage: `url(${(syncDataNew?.hero_image) ? (syncDataNew?.hero_image) : loginBgImage})`, paddingLeft: "25px", paddingRight: "25px" }}>
			<div className="loginLogo">
				<img src={(syncDataNew?.logo) ? (syncDataNew?.logo) : logoImage} alt="logoImage" />
				<h4 className="loginTitle">{syncDataNew?.office_name}</h4>
			</div>
			<div className="loginWrap"  >
				<LanguageDropdown />
				<div className="loginForm">
					<div className="loginFormBigWrap">
						<div className="loginFormWrap">
							<div className="formTitle">
								<h2>{t("GENERAL.FORGOT_PASSWORD")}</h2>
							</div>
							<FormBox form={form} onFinish={handleSubmit}>
								<InputBox.Text
									name="email"
									label={t("GENERAL.MOBILE_NUMBER")}
									placeholder={t("GENERAL.MOBILE_NUMBER")}
									rules={LoginRequest().email()}
								/>

								<Button
									loading={saving}
									type="primary"
									htmlType="submit"
									className="loginBtn"
								>
									{t("GENERAL.FORGOT_PASSWORD")}
								</Button>
								<div className="forgotPassLink">
									<Link to="/login" >{t("GENERAL.BACK_TO_LOGIN")}</Link>
								</div>
							</FormBox>
						</div>
					</div>
				</div>

			</div>
		</section>
	);
};

export default ForgotPassword;
