import { formRules } from "../../../../../config/validations/formRules";
import { maxName, minName, validations } from "../../../../../config/validations/validations";

export const rules: any = {
  visit_type: (field?: string) => [
    validations.required.text(field),
    validations.min.text(minName),
    validations.max.text(50),
  ],
  detail: (field?: string) => [
    validations.required.text(field),
    validations.min.text(minName),
    validations.max.text(50),
  ],
  place: (field?: string) => [
    validations.required.text(field),
    validations.min.text(minName),
    validations.max.text(250),
  ],
  date_range: (field?: string) => [
    validations.required.other(field)
  ],
  name: () => [
    validations.min.text(minName),
    validations.max.text(maxName),
  ],
  mobileNumber: () => formRules.whatsNumber(),
};
