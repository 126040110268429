import React from 'react'
import { Form, DrawerProps, Drawer, Descriptions } from "antd";
import SVGIcon from '../../../../../utils/SVGIcon';
import Config from '../../../../../config/Config';
import CancelButton from '../../../../../components/Buttons/CancelButton';
import { renderNA } from '../../../../../components/AgGridWrapper/utils/commonFunctions';
import { useAppSelector } from '../../../../../store/app';
import { useTranslation } from 'react-i18next';

interface ViewDrawerProps extends DrawerProps {
    titleName: string;
    open: boolean;
    close: () => void;
}
const ViewDrawer: React.FC<ViewDrawerProps> = ({
    titleName,
    open,
    close,
    ...rest
}) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const details = useAppSelector(state => state.scheme.details);
    const drawerClose = () => {
        close();
        form.resetFields();
    };
    return (
        <>
            <Drawer
                title={`${titleName} ${t("GENERAL.VIEW__DRAWER_TITLE")}`}
                open={open}
                placement="right"
                width={"70%"}
                onClose={drawerClose}
                destroyOnClose
                closeIcon={<SVGIcon icon="close" color={Config.themePrimaryBtn} />}
                footer={[<CancelButton key={2} onClick={drawerClose} />]}
                {...rest}
            >
                <Descriptions column={2} bordered layout="horizontal">
                    <Descriptions.Item label={t("GENERAL.NAME")}>
                        {renderNA(details?.name)}
                    </Descriptions.Item>
                </Descriptions>
            </Drawer>
        </>
    )
}

export default ViewDrawer