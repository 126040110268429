import React, { useEffect, useState } from 'react'
import { Button, Col, Drawer, DrawerProps, Form, Row, Table } from 'antd'
import { useAppDispatch } from '../../../../../../store/app';
import { assignErrorToInput } from '../../../../../../store/api';
import { genderOptions, genderOptionsDisplay, tablePagination, validateFields } from '../../../../../../config/Global';
import { rules } from '../Form/rules';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import CommonModal from '../../../../../../components/Modals/CommonModal';
import { CONSTANT } from '../../../../../../config/Constant';
import { PlusOutlined } from "@ant-design/icons";
import { FormBox, InputBox } from '../../../../../../components/AntdAddons';
import { addVillageAchievements, deleteVillageAchievements, getVillageAchievementsList, updateVillageAchievements } from '../../utils/slice';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from "dayjs"

interface VillageAchievementsDrawerProps extends DrawerProps {
    saveAndNext: any;
    open: any;
    villageId: any;
    dynamicSteps: any;
    villageDetailsId: any
    close: () => void;
}
const VillageAchievements: React.FC<VillageAchievementsDrawerProps> = ({
    close,
    open,
    saveAndNext,
    villageId,
    dynamicSteps,
    villageDetailsId,
    ...rest
}) => {
    const { t } = useTranslation();
    const { action } = useParams();
    const dispatch = useAppDispatch();
    const [addEditForm] = Form.useForm();
    const [disabled, setDisabled] = useState<boolean>(true);
    const [saving, setSaving] = useState<boolean>(false);
    const [villageAchievementsList, setVillageAchievementsList] = useState<any>([]);
    const [openDeleteModal, setOpenDeleteModal] = useState<any>();
    const [openAddEditModal, setOpenAddEditModal] = useState<any>();
    const [openAddEditForm, setOpenAddEditForm] = useState<any>();
    const [pagination, setPagination] = useState(tablePagination);
    const minDate = dayjs().subtract(18, "years");
    const disableDate = (current: any) => {
        return current && current > minDate;
    };

    useEffect(() => {
        if (villageDetailsId && action === CONSTANT.DRAWER_TYPE.EDIT && dynamicSteps === CONSTANT.STEP.VILLAGE_ACHIEVEMENTS) {
            dispatch(getVillageAchievementsList({ village_detail_id: villageDetailsId, village_id: villageId }))
                .then((res: any) => {
                    if (res.count > 0) {
                        setVillageAchievementsList([...res.rows])
                    } else {
                        setVillageAchievementsList(res.rows)
                    }
                })
                .catch((e: any) => e)
        }
    }, [dynamicSteps])
    const columns = [
        {
			title: t("GENERAL.FIRST_NAME"),
			dataIndex: 'first_name',
			key: 'first_name',
		},
		{
			title: t("GENERAL.MIDDLE_NAME"),
			dataIndex: 'middle_name',
			key: 'middle_name',
		},
		{
			title: t("GENERAL.Last_NAME"),
			dataIndex: 'last_name',
			key: 'last_name',
		},
		{
			title: t("GENERAL.DATE_OF_BIRTH"),
			dataIndex: 'dob',
			key: 'dob',
			render: (text: any) => {
				return (
					<span>{text !== null && dayjs(text).format(CONSTANT.DATE_FORMAT)}</span>
				)
			}
		},
		{
			title: `${t("GENERAL.GENDER")}`,
			dataIndex: 'gender',
			key: 'gender',
			render: (text: any) => {
				return (
					<span>{genderOptionsDisplay[text]}</span>
				)
			}
		},
        {
            title: `${t("VILLAGEMANAGEMENT.TYPE")}`,
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: `${t("VILLAGEMANAGEMENT.CONTECT_NUMBER")}`,
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: `${t("GENERAL.ACTIONS")}`,
            key: 'action',
            render: (text: any, record: any) => (
                <div className='listActionbtn'>
                    <span onClick={() => handleAddEdit(record, "editModal")}>
                        <EditOutlined />
                    </span>
                    <span onClick={() => deleteModal(record)}>
                        <DeleteOutlined />
                    </span>
                </div>
            ),
        },
    ];
    const drawerClose = (submitForm = "") => {
        if (submitForm === "") {
            close()
        } else {
            saveAndNext(CONSTANT.STEP.VILLAGE_FESTIVAL)
        }
        setVillageAchievementsList([])
        addEditForm.resetFields();
        setDisabled(true);
    }

    const handleAddEdit = (data: any, openForm: any) => {
        setOpenAddEditForm(openForm)
        if (openForm === "editModal") {
            const setValues = villageAchievementsList.find((item: any) => item.id === data.id);
            addEditForm.setFieldsValue({
                ...setValues,
                dob: setValues.dob && dayjs(setValues.dob),
            })
            setOpenAddEditModal(data)
        } else {
            setOpenAddEditModal(true)
        }
    }
    const deleteModal = (data: any) => {
        setOpenDeleteModal(data)
    }
    const closeEditModal = () => {
        setOpenAddEditModal(null)
        addEditForm.resetFields();
        setDisabled(true);
    }
    const validateEditForm = () => {
        validateFields(addEditForm, setDisabled);
    };

    const handleUpdateVillageAchievements = (data: any) => {
        setSaving(true)
        data.village_id = villageId
        data.village_detail_id = villageDetailsId
        data.dob = dayjs(data.dob).format(CONSTANT.POST_DATE_FORMAT)
        if (openAddEditForm === "editModal") {
            dispatch(updateVillageAchievements(openAddEditModal.id, data))
                .then((res: any) => {
                    const indexToUpdate = villageAchievementsList.findIndex((item: any) => item.id === res.data.id);
                    if (indexToUpdate !== -1) {
                        const updatedVillageAchievementsList = [...villageAchievementsList];
                        updatedVillageAchievementsList[indexToUpdate] = res.data;
                        setVillageAchievementsList(updatedVillageAchievementsList);
                    }
                    setSaving(false)
                    closeEditModal();
                })
                .catch((error: any) => {
                    assignErrorToInput(addEditForm, error?.STATUS);
                    setSaving(false)
                })
                .finally(() => setSaving(false));
        } else {
            dispatch(addVillageAchievements(data))
                .then((res: any) => {
                    setVillageAchievementsList([res.data, ...villageAchievementsList])
                    closeEditModal();
                    addEditForm.resetFields();
                    setSaving(false);
                })
                .catch((error: any) => {
                    assignErrorToInput(addEditForm, error?.STATUS);
                    setSaving(false)
                })
                .finally(() => setSaving(false));
        }
    }
    const closeDeleteModal = () => {
        setOpenDeleteModal(null)
    }
    const deleteData = () => {
        setSaving(true)
        dispatch(deleteVillageAchievements(openDeleteModal.id, openDeleteModal))
            .then(() => {
                const deleteData = villageAchievementsList.filter((item: any) => item.id !== openDeleteModal.id)
                setVillageAchievementsList(deleteData)
                setOpenDeleteModal(null)
                setSaving(false)
            })
            .catch((error: any) => error)
            .finally(() => setSaving(false));
    }
    return (
        <>
            <Drawer
                title={`${t("VILLAGEMANAGEMENT.VILLAGE_ACHIEVEMENTS")}`}
                placement="right"
                onClose={() => drawerClose()}
                open={open}
                className='villageInnerPages'
                width={"70%"}
                footer={[
                    <Button
                        key="2"
                        htmlType="button"
                        className="cancelBtn"
                        onClick={() => drawerClose()}
                    >
                        {t("GENERAL.CANCEL_BUTTON")}
                    </Button>,
                    <Button
                        key="3"
                        type="primary"
                        onClick={() => drawerClose("saveAndNext")}
                    >
                        {t("GENERAL.CONTINUE_BUTTON")}
                    </Button>
                ]}
                {...rest}
            >
                <div className='add_modal_button'>
                    <Button
                        key="3"
                        type="primary"
                        onClick={() => handleAddEdit(null, "addModal")}
                    >
                        {t("GENERAL.ADD_DRAWER_TITLE")} <PlusOutlined />
                    </Button>
                </div>
                <Table
                    dataSource={villageAchievementsList}
                    columns={columns}
                    onChange={(pagination: any) => setPagination(pagination)}
                    pagination={pagination} // Apply pagination configuration
                />
                <CommonModal
                    open={openDeleteModal}
                    className={"deleteModal"}
                    title={`${t("VILLAGEMANAGEMENT.VILLAGE_ACHIEVEMENTS")} ${t("GENERAL.DELETE__DRAWER_TITLE")}`}
                    onCancel={closeDeleteModal}
                    footer={[
                        <Button key="2" htmlType="button" className="cancelBtn" onClick={closeDeleteModal}>
                            {t("GENERAL.CLOSE_BUTTON")}
                        </Button>,
                        <Button
                            key="1"
                            type="primary"
                            loading={saving}
                            htmlType='submit'
                            onClick={deleteData}
                        >
                            {t("GENERAL.DELETE_BUTTON")}
                        </Button>
                    ]}
                >
                    <div className="deleteNote">
                        {t("GENERAL.DELETE_MODEL_MESSAGE")} <span>{openDeleteModal?.first_name}</span>?
                    </div>
                </CommonModal>
                <CommonModal
                    open={openAddEditModal}
                    title={openAddEditForm === "editModal" ? `${t("VILLAGEMANAGEMENT.VILLAGE_ACHIEVEMENTS")} ${t("GENERAL.EDIT__DRAWER_TITLE")}` : `${t("VILLAGEMANAGEMENT.VILLAGE_ACHIEVEMENTS")} ${t("GENERAL.ADD_DRAWER_TITLE")}`}
                    onCancel={closeEditModal}
                    footer={[
                        <Button key="2" htmlType="button" className="cancelBtn" onClick={closeEditModal}>
                            {t("GENERAL.CLOSE_BUTTON")}
                        </Button>,
                        <Button
                            key="1"
                            type="primary"
                            loading={saving}
                            htmlType='submit'
                            disabled={disabled}
                            form='villageAchievementsForm'
                        >
                            {t("GENERAL.SAVE_BUTTON")}
                        </Button>
                    ]}
                >
                    <FormBox
                        form={addEditForm}
                        id="villageAchievementsForm"
                        onValuesChange={validateEditForm}
                        onFinish={handleUpdateVillageAchievements}
                    >
                        <Row gutter={15}>
                            <Col sm={24} md={24}  >
                                <InputBox.Text
                                    required
                                    label={t("GENERAL.FIRST_NAME")}
                                    name="first_name"
                                    placeholder={t("GENERAL.FIRST_NAME")}
                                    rules={rules.name()}
                                />
                            </Col>
                            <Col sm={24} md={24}  >
                                <InputBox.Text
                                    required
                                    label={t("GENERAL.MIDDLE_NAME")}
                                    name="middle_name"
                                    placeholder={t("GENERAL.MIDDLE_NAME")}
                                    rules={rules.name()}
                                />
                            </Col>
                            <Col sm={24} md={24}  >
                                <InputBox.Text
                                    required
                                    label={t("GENERAL.Last_NAME")}
                                    name="last_name"
                                    placeholder={t("GENERAL.Last_NAME")}
                                    rules={rules.name()}
                                />
                            </Col>
                            <Col sm={24} md={24}  >
                                <InputBox.DatePicker
                                    required
                                    allowClear={false}
                                    name="dob"
                                    label={t("GENERAL.DATE_OF_BIRTH")}
                                    placeholder={t("GENERAL.DATE_OF_BIRTH")}
                                    rules={rules.dateOfBirth()}
                                    defaultPickerValue={minDate}
                                    disabledDate={disableDate}
                                />
                            </Col>
                            <Col sm={24} md={24}  >
                                <InputBox.Select
                                    required
                                    name="gender"
                                    label={t("GENERAL.GENDER")}
                                    placeholder={t("GENERAL.GENDER")}
                                    options={{
                                        list: genderOptions,
                                        valueKey: "id",
                                        textKey: "name",
                                    }}
                                    rules={rules.gender()}
                                />
                            </Col>
                            <Col sm={24} md={24}>
                                <InputBox.Text
                                    required
                                    label={t("VILLAGEMANAGEMENT.TYPE")}
                                    name="type"
                                    placeholder={t("VILLAGEMANAGEMENT.TYPE")}
                                    rules={rules.name()}
                                />
                            </Col>
                            <Col sm={24} md={24}>
                                <InputBox.Text
                                    required
                                    label={t("VILLAGEMANAGEMENT.CONTECT_NUMBER")}
                                    name="phone"
                                    addonBefore={"+91"}
                                    placeholder="xxxxxxxxxxx"
                                    rules={rules.mobileNumber()}
                                    maxLength={10}
                                />
                            </Col>
                        </Row>
                    </FormBox>
                </CommonModal>
            </Drawer>
        </>
    )
}

export default VillageAchievements