import { Drawer, DrawerProps, Form } from 'antd'
import React, { useState } from 'react'
import { useAppDispatch } from '../../../../../store/app';
import { dataToFormDataConverterNew, validateFields } from '../../../../../config/Global';
import SVGIcon from '../../../../../utils/SVGIcon';
import Config from '../../../../../config/Config';
import FormComponent from './FormComponent';
import { createRecord } from '../utils/slice';
import { assignErrorToInput } from '../../../../../store/api';
import { setTalukaList } from '../../../Administration/TalukaManagement/utils/slice';
import { setSchemeList } from '../../../Administration/SchemeManagement/utils/slice';
import { setVillageList } from '../../../Administration/VillageVsWard/utils/slice';
import { useTranslation } from 'react-i18next'; 
interface AddDrawerProps extends DrawerProps {
    titleName: string;
    close: () => void;
}
const AddComponent:React.FC<AddDrawerProps> = ({
    titleName,
    close,
    ...rest
}) => {
    const { t } = useTranslation();
    const [form] = Form.useForm()
    const [disabled, setDisabled] = useState<boolean>(true);
    const [saving, setSaving] = useState<boolean>(false);
    const [fetchTaluka, setFetchTaluka] = useState<boolean>(true);
    const [fetchVillage, setFetchVillage] = useState<boolean>(true);
    const [fetchScheme, setFetchScheme] = useState<boolean>(true);
    const dispatch = useAppDispatch()
    const drawerClose = () => {
        close()
        form.resetFields()
        setDisabled(true)
        setFetchTaluka(true)
        setFetchVillage(true)
        setFetchScheme(true)
        dispatch(setTalukaList([]))
        dispatch(setVillageList([]))
        dispatch(setSchemeList([]))
    }
    const validateForm = () => {
        validateFields(form, setDisabled)
    }
    const handleFormSubmit = (data: any) => {
        setSaving(true);
        const formData = dataToFormDataConverterNew(data)
        dispatch(createRecord(formData))
        .then(() => {
            drawerClose();
        })
        .catch((error: any) => {
            assignErrorToInput(form, error?.STATUS);
        })
        .finally(() => setSaving(false));
    }
    return (
        <>
            <Drawer
                title={`${titleName} ${t("GENERAL.ADD_DRAWER_TITLE")}`}
                placement="right"
                width={"70%"}
                onClose={drawerClose}
                destroyOnClose
                closeIcon={<SVGIcon icon="close" color={Config.themePrimaryBtn} />}
                className="mlaGrantForm"
                {...rest}
            >
                <FormComponent
                    form={form}
                    id="addForm"
                    handleSubmit={handleFormSubmit}
                    onValuesChange={validateForm}
                    fetchTaluka={fetchTaluka}
                    setFetchTaluka={setFetchTaluka}
                    fetchVillage={fetchVillage}
                    setFetchVillage={setFetchVillage}
                    fetchScheme={fetchScheme}
                    setFetchScheme={setFetchScheme}
                    disabled={disabled}
                    saving={saving}
                    drawerClose={drawerClose}
                    setDisabled={setDisabled}
                ></FormComponent>
            </Drawer>
        </>
    )
}
export default AddComponent