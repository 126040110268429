import React, { useState } from "react";
import { Button, Form, Popover } from "antd";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
// import LoginBanner from "../../../../assets/images/apps/login-image.png";
// import LoginBG from "../../../../assets/images/apps/login-pattern.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { LoginRequest } from "../rules";
import { assignErrorToInput } from "../../../../store/api";
import { updatePassword } from "../../../../store/AuthSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/app";
import loginBgImage from "../../../../assets/images/apps/loginBgImage.png";
import logoImage from "../../../../assets/images/apps/New-Jan-Utkarsh-Yojna-Logo.png"
import { useTranslation } from "react-i18next";
import LanguageDropdown from "../../../../components/LanguageDropdown/LanguageDropdown";

const ResetPassword: React.FC = () => {
  const dispatch = useAppDispatch();
  // const syncData = useAppSelector(state => state.auth.initialData?.syncData)
  const syncDataNew = useAppSelector(state => state?.configuration?.details)
  const [form] = Form.useForm();
  const [saving, setSaving] = useState<boolean>(false);
  const [tooltip, setTooltip] = useState(false);
  const navigate = useNavigate();
  const { otp }: any = useParams();
  const { t } = useTranslation();
  const resetPassword = (data: any) => {
    localStorage.removeItem("token");
    setSaving(true);
    dispatch(updatePassword(otp, data))
      .then(() => {
        navigate("/login");
      })
      .catch((error: any) => {
        assignErrorToInput(form, error?.STATUS);
      })
      .finally(() => setSaving(false));
  };

  const onChange = () => {
    form
      .validateFields(["password"])
      .then(() => {
        setTooltip(false);
      })
      .catch(() => {
        setTooltip(true);
      });
  };

  // password criteria tool tip
  const passwordTooltip = (
    <div>
      <div>{t("GENERAL.PASSWORDTOOLTIP_AT_LEAST_1_NUMERIC_CHARACTER")}</div>
      <div>{t("GENERAL.PASSWORDTOOLTIP_AT_LEAST_1_SPECIAL_CHARACTER")}</div>
      <div>{t("GENERAL.PASSWORDTOOLTIP_AT_LEAST_1_UPPERCASE_LETTER")}</div>
      <div>{t("GENERAL.PASSWORDTOOLTIP_AT_LEAST_8_CHARACTER")}</div>
    </div>
  );

  return (
    <section className="loginSection" style={{ backgroundImage: `url(${(syncDataNew?.hero_image) ? (syncDataNew?.hero_image) : loginBgImage})`, paddingLeft: "25px", paddingRight: "25px" }}>
      <div className="loginLogo">
        {/* <img src={(syncData?.logo) ? (syncData?.logo) : logoImage} alt="logoImage" /> */}
        <img src={(syncDataNew?.logo) ? (syncDataNew?.logo) : logoImage} alt="logoImage" />
        {/* <h4 className="loginTitle">{(syncData?.office_name) ? (syncData?.office_name) : t("GENERAL.ATALDHARA")}</h4> */}
        <h4 className="loginTitle">{syncDataNew?.office_name}</h4>
      </div>
      <div className="loginWrap"  >

        <LanguageDropdown />
        <div className="loginForm">
          <div className="loginFormBigWrap">
            <div className="loginFormWrap">
              <div className="formTitle">
                <h2 >{t("RESETPASSWORD.RESET_PASSWORD")}</h2>
              </div>
              <FormBox form={form} onFinish={resetPassword} onChange={onChange}>
                <Popover
                  placement="topRight"
                  content={passwordTooltip}
                  open={tooltip}
                >
                  <InputBox.Password
                    name="password"
                    label={t("GENERAL.PASSWORD")}
                    placeholder="xxxxxxx"
                    rules={LoginRequest().password()}
                  />
                </Popover>
                <InputBox.Password
                  name="password_confirmation"
                  label={t("GENERAL.CONFIRM_PASSWORD")}
                  placeholder="xxxxxxx"
                  rules={LoginRequest().confirmPassword()}
                />
                <Button
                  loading={saving}
                  type="primary"
                  htmlType="submit"
                  className="loginBtn"
                >
                  {t("RESETPASSWORD.RESET_PASSWORD")}
                </Button>
                <div className="forgotPassLink">
                  <Link to="/login">{t("GENERAL.BACK_TO_LOGIN")}</Link>
                </div>
              </FormBox>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResetPassword;
