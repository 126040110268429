export const CONSTANT = {
  DATE_FORMAT: "DD/MM/YYYY",
  TIME_FORMAT: "HH:mm",
  DATE_TIME_FORMAT: "DD/MM/YYYY HH:mm",
  DATE_RANGE_TIME_FORMAT: "DD/MM/YYYY hh:mm A",
  POST_DATE_FORMAT: "YYYY-MM-DD",
  POST_TIME_FORMAT: "HH:mm",
  POST_DATE_TIME_FORMAT: "YYYY-MM-DD HH:mm",
  POST_DATE_FULL_TIME_FORMAT: "YYYY-MM-DD HH:mm:ss",
  MONTH_FORMAT: "YYYY-MM",
  MONTH_FORMAT_YEAR: "MM-YYYY",
  COMMENT_PER_PAGE: 3,
  UTCOFFSET: 330,
  INVALID_FILE: "The file format you provided is invalid!",
  BIGGER_SIZE: "It must smaller than 1MB!",
  DRAWER_TYPE: {
    ADD: "add",
    EDIT: "edit",
    VIEW: "view",
    CITIZENSWITHMEETING: "meeting",
    NEWPINCODE: "newpincode",
    NEWTALUKA: "newtaluka",
    NEWVILLAGE: "newvillage",
    STATUS: "status",
  },
  GENDER_TYPES: {
    MALE: "Male",
    FEMALE: "Female",
    OTHER: "Other"
  },
  VILLAGE_TYPE: {
    RURAL: "Rural",
    URBAN: "Urban"
  },
  MEETING_TYPE: {
    IN_PROGRESS: "In Progress",
    CLOSE: "Close",
  },
  PARTY_PERSONS_TYPE: {
    PARTY_WORKER: "Party Worker",
    ELECTED_MEMBERS: "Elected Members",
    COOPERATIVE_LEADERS: "Cooperative Leaders"
  },
  ENTRY_FROM_TYPES: {
    SAVERKUNDLA: "Savar Kundla Office",
    MANINAGAR: "Maninagar Office",
  },
  GrantStatusList: {
    Date_of_Preliminary_Approval: "Preliminary Approval Date",
    Tantric_approval_Date: "Technical Approval Date",
    Date_of_Administration_approval: "Administrative Approval Date",
    Grant_Allotment_Authority_Date: "Grant Allotment Authority Date",
  },
  PER_PAGE_RECORD: [
    { id: "5", value: "5 Per Page" },
    { id: "10", value: "10 Per Page" },
    { id: "50", value: "50 Per Page" },
    { id: "100", value: "100 Per Page" },
    { id: "500", value: "500 Per Page" },
    { id: "1000", value: "1000 Per Page" },
  ],
  STEP: {
    VILLAGE_DETAILS: "1",
    PARTY_WORKER: "2",
    MALNUTRIONED_CHILDREN: "3",
    VILLAGE_GOV_SCHEMES: "4",
    VILLAGE_UNAVAILABLE_SERVICES: "5",
    VILLAGE_PROBLEM: "6",
    VILLAGE_ACHIEVEMENTS: "7",
    VILLAGE_FESTIVAL: "8",
    VILLAGE_ATTRACTION: "9"
  },
  STEPS_CONTENT: [
    { id: "1", name: "Village Details", key: "VILLAGE_DETAILS" },
    { id: "2", name: "Party worker", key: "PARTY_WORKER" },
    { id: "3", name: "Malnutrioned Children", key: "MALNUTRIONED_CHILDREN" },
    { id: "4", name: "Gov Schemes", key: "VILLAGE_GOV_SCHEMES" },
    { id: "5", name: "Unavailable Services", key: "VILLAGE_UNAVAILABLE_SERVICES" },
    { id: "6", name: "Village Problem", key: "VILLAGE_PROBLEM" },
    { id: "7", name: "Village Achievements", key: "VILLAGE_ACHIEVEMENTS" },
    { id: "8", name: "Village Festival", key: "VILLAGE_FESTIVAL" },
    { id: "9", name: "Village Attraction", key: "VILLAGE_ATTRACTION" },
  ],
  SYSTEM_CONFIG: {
    LOGO: 1,
    FAV_IMG: 2,
    HERO_IMG: 3,
  },
  ATTRACTION_TYPE: {
    TOURIST: "Tourist",
    RELIGIOUS: "Religious"
  },
  LANGUAGE_LABEL: {
    ENGLISH: "ENGLISH",
    GUJARATI: "ગુજરાતી",
  },
  NAVIGATE_LINK: {
    CITIZENS: "citizens",
    CITIZEN_MEETING: "citizen-meetings"
  },
  MLAGRANTMANAGEMENT: {
    STATUSMODEL: {
      Date_of_Preliminary_Approval: 1,
      Tantric_approval_Date: 2,
      Date_of_Administration_approval: 3,
      Grant_Allotment_Authority_Date: 4
    }
  },
  GOVERNMENTNONGOVERMENTCONTACTMANAGEMENT: {
    OFFICE: {
      State: "State",
      District: "District",
      Taluka: "Taluka",
      Municipality: "Municipality",
      Village: "Village",
    },
  }
};
export const BASE_URL = process.env.REACT_APP_API_URL;


