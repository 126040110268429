import { Button, Col, Drawer, DrawerProps, Form, Row, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useAppDispatch } from '../../../../../../store/app';
import { assignErrorToInput } from '../../../../../../store/api';
import { addPartyWorker, deletePartyWorker, getWorkerList, updatePartyWorker } from '../../utils/slice';
import { FormBox, InputBox } from '../../../../../../components/AntdAddons';
import { genderOptions, genderOptionsDisplay, partyPersonType, partyPersonTypeDisplay, tablePagination, validateFields } from '../../../../../../config/Global';
import { rules } from '../Form/rules';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import CommonModal from '../../../../../../components/Modals/CommonModal';
import { CONSTANT } from '../../../../../../config/Constant';
import { PlusOutlined } from "@ant-design/icons";
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from "dayjs"

interface PartyWorkerDrawerProps extends DrawerProps {
	saveAndNext: any;
	open: any;
	villageId: any;
	dynamicSteps: any;
	villageDetailsId: any
	close: () => void;
}

const PartyWorker: React.FC<PartyWorkerDrawerProps> = ({
	close,
	open,
	saveAndNext,
	villageId,
	dynamicSteps,
	villageDetailsId,
	...rest
}) => {
	const { t } = useTranslation();
	const { action } = useParams();
	const dispatch = useAppDispatch();
	const [addEditForm] = Form.useForm();
	const [disabled, setDisabled] = useState<boolean>(true);
	const [saving, setSaving] = useState<boolean>(false);
	const [partyWorkerList, setPartyWorkerList] = useState<any>([]);
	const [openDeleteModal, setOpenDeleteModal] = useState<any>();
	const [openAddEditModal, setOpenAddEditModal] = useState<any>();
	const [openAddEditForm, setOpenAddEditForm] = useState<any>();
	const [pagination, setPagination] = useState(tablePagination);
	useEffect(() => {
		if (villageDetailsId && action === CONSTANT.DRAWER_TYPE.EDIT && dynamicSteps === CONSTANT.STEP.PARTY_WORKER) {
			dispatch(getWorkerList({ village_detail_id: villageDetailsId, village_id: villageId }))
				.then((res: any) => {
					if (res.count > 0) {
						setPartyWorkerList([...res.rows])
					} else {
						setPartyWorkerList(res.rows)
					}
				})
				.catch((e: any) => e)
		}
	}, [dynamicSteps])

	const handleAddEdit = (data: any, openForm: any) => {
		setOpenAddEditForm(openForm)
		if (openForm === "editModal") {
			const setValues = partyWorkerList.find((item: any) => item.id === data.id);
			addEditForm.setFieldsValue({
				...setValues,
				dob: setValues.dob && dayjs(setValues.dob),
			})
			setOpenAddEditModal(data)
		} else {
			setOpenAddEditModal(true)
		}
	}
	const closeEditModal = () => {
		setOpenAddEditModal(null)
		addEditForm.resetFields();
		setDisabled(true);
	}
	const handleUpdatePartyWorker = (data: any) => {
		setSaving(true)
		data.village_id = villageId
		data.village_detail_id = villageDetailsId
		data.dob = dayjs(data.dob).format(CONSTANT.POST_DATE_FORMAT)
		if (openAddEditForm === "editModal") {
			dispatch(updatePartyWorker(openAddEditModal.id, data))
				.then((res: any) => {
					const indexToUpdate = partyWorkerList.findIndex((item: any) => item.id === res.data.id);
					if (indexToUpdate !== -1) {
						const updatedPartyWorkerList = [...partyWorkerList];
						updatedPartyWorkerList[indexToUpdate] = res.data;
						setPartyWorkerList(updatedPartyWorkerList);
					}
					setSaving(false)
					closeEditModal();
				})
				.catch((error: any) => {
					assignErrorToInput(addEditForm, error?.STATUS);
					setSaving(false)
				})
				.finally(() => setSaving(false));
		} else {

			dispatch(addPartyWorker(data))
				.then((res: any) => {
					setPartyWorkerList([res.data, ...partyWorkerList])
					closeEditModal();
					addEditForm.resetFields();
					setSaving(false);
				})
				.catch((error: any) => {
					assignErrorToInput(addEditForm, error?.STATUS);
					setSaving(false)
				})
				.finally(() => setSaving(false));
		}
	}
	const deleteModal = (data: any) => {
		setOpenDeleteModal(data)
	}
	const closeDeleteModal = () => {
		setOpenDeleteModal(null)
	}
	const deleteData = () => {
		setSaving(true)
		dispatch(deletePartyWorker(openDeleteModal.id, openDeleteModal))
			.then(() => {
				const deleteData = partyWorkerList.filter((item: any) => item.id !== openDeleteModal.id)
				setPartyWorkerList(deleteData)
				setOpenDeleteModal(null)
				setSaving(false)
			})
			.catch((error: any) => error)
			.finally(() => setSaving(false));
	}


	const drawerClose = (submitForm = "") => {
		if (submitForm === "") {
			close()
		} else {
			saveAndNext(CONSTANT.STEP.MALNUTRIONED_CHILDREN)
		}
		setPartyWorkerList([])
		addEditForm.resetFields();
		setDisabled(true);
	}
	const minDate = dayjs().subtract(18, "years");
	const disableDate = (current: any) => {
		return current && current > minDate;
	};
	const validateEditForm = () => {
		validateFields(addEditForm, setDisabled);
	};
	const columns = [
		{
			title: `${t("VILLAGEMANAGEMENT.PERSION_TYPE")}`,
			dataIndex: 'person_type',
			key: 'person_type',
			render: (text: any) => {
				return (
					<span>{partyPersonTypeDisplay[text]}</span>
				)
			}
		},
		{
			title: t("GENERAL.FIRST_NAME"),
			dataIndex: 'first_name',
			key: 'first_name',
		},
		{
			title: t("GENERAL.MIDDLE_NAME"),
			dataIndex: 'middle_name',
			key: 'middle_name',
		},
		{
			title: t("GENERAL.Last_NAME"),
			dataIndex: 'last_name',
			key: 'last_name',
		},
		{
			title: t("GENERAL.DATE_OF_BIRTH"),
			dataIndex: 'dob',
			key: 'dob',
			render: (text: any) => {
				return (
					<span>{text !== null && dayjs(text).format(CONSTANT.DATE_FORMAT)}</span>
				)
			}
		},
		{
			title: `${t("GENERAL.GENDER")}`,
			dataIndex: 'gender',
			key: 'gender',
			render: (text: any) => {
				return (
					<span>{genderOptionsDisplay[text]}</span>
				)
			}
		},
		{
			title: `${t("VILLAGEMANAGEMENT.DESIGNNATION")}`,
			dataIndex: 'designation',
			key: 'designation',
		},
		{
			title: `${t("VILLAGEMANAGEMENT.CONTECT_NUMBER")}`,
			dataIndex: 'phone',
			key: 'phone',
		},
		{
			title: `${t("GENERAL.ACTIONS")}`,
			key: 'action',
			render: (text: any, record: any) => (

				<div className='listActionbtn'>
					<span onClick={() => handleAddEdit(record, "editModal")}>
						<EditOutlined />
					</span>
					<span onClick={() => deleteModal(record)}>
						<DeleteOutlined />
					</span>
				</div>
			),
		},
	];

	return (
		<Drawer
			title={`${t("VILLAGEMANAGEMENT.PARTY_WORKER")}`}
			placement="right"
			onClose={() => drawerClose()}
			open={open}
			className='villageInnerPages'
			width={"70%"}
			footer={[
				<Button
					key="1"
					htmlType="button"
					className="cancelBtn"
					onClick={() => drawerClose()}
				>
					{t("GENERAL.CANCEL_BUTTON")}
				</Button>,
				<Button
					key="2"
					type="primary"
					onClick={() => drawerClose("saveAndNext")}
				>
					{t("GENERAL.CONTINUE_BUTTON")}
				</Button>
			]}
			{...rest}
		>
			<div className='add_modal_button'>
				<Button
					key="3"
					type="primary"
					onClick={() => handleAddEdit(null, "addModal")}
				>
					{t("GENERAL.ADD_DRAWER_TITLE")} <PlusOutlined />
				</Button>
			</div>
			<Table
				dataSource={partyWorkerList}
				columns={columns}
				onChange={(pagination: any) => setPagination(pagination)}
				pagination={pagination} // Apply pagination configuration
			/>
			<CommonModal
				open={openDeleteModal}
				className={"deleteModal"}
				title={`${t("GENERAL.DELETE__DRAWER_TITLE")} ${t("VILLAGEMANAGEMENT.PARTY_WORKER")}`}
				onCancel={closeDeleteModal}
				footer={[
					<Button key="4" htmlType="button" className="cancelBtn" onClick={closeDeleteModal}>
						{t("GENERAL.CLOSE_BUTTON")}
					</Button>,
					<Button
						key="5"
						type="primary"
						loading={saving}
						htmlType='submit'
						onClick={deleteData}
					>
						{t("GENERAL.DELETE_BUTTON")}
					</Button>
				]}
			>
				<div className="deleteNote">
					{t("GENERAL.DELETE_MODEL_MESSAGE")} <span>{openDeleteModal?.first_name}</span>?
				</div>
			</CommonModal>
			<CommonModal
				open={openAddEditModal}
				// className={"statusModal"}
				className={"common_modal_width"}
				title={openAddEditForm === "editModal" ? `${t("VILLAGEMANAGEMENT.PARTY_WORKER")} ${t("GENERAL.EDIT__DRAWER_TITLE")}` : `${t("VILLAGEMANAGEMENT.PARTY_WORKER")} ${t("GENERAL.ADD_DRAWER_TITLE")}`}
				onCancel={closeEditModal}
				footer={[
					<Button key="6" htmlType="button" className="cancelBtn" onClick={closeEditModal}>
						{t("GENERAL.CLOSE_BUTTON")}
					</Button>,
					<Button
						key="7"
						type="primary"
						loading={saving}
						htmlType='submit'
						disabled={disabled}
						form='partyWorkerForm'
					>
						{t("GENERAL.SAVE_BUTTON")}
					</Button>
				]}
			>
				<FormBox
					form={addEditForm}
					id="partyWorkerForm"
					onValuesChange={validateEditForm}
					onFinish={handleUpdatePartyWorker}
				>
					<Row gutter={15}>
						<Col sm={24} md={12}  >
							<InputBox.Select
								required
								name="person_type"
								label={t("VILLAGEMANAGEMENT.PERSION_TYPE")}
								placeholder={t("VILLAGEMANAGEMENT.PERSION_TYPE")}
								options={{
									list: partyPersonType,
									valueKey: "id",
									textKey: "name",
								}}
								rules={rules.partyPersonType()}
							/>
						</Col>
						{/* <Col sm={24} md={12}  >
							<InputBox.Text
								required
								label={t("GENERAL.NAME")}
								name="name"
								placeholder={t("GENERAL.NAME")}
								rules={rules.name()}
							/>
						</Col> */}
						<Col sm={24} md={12}  >
							<InputBox.Text
								required
								label={t("GENERAL.FIRST_NAME")}
								name="first_name"
								placeholder={t("GENERAL.FIRST_NAME")}
								rules={rules.name()}
							/>
						</Col>
						<Col sm={24} md={12}  >
							<InputBox.Text
								required
								label={t("GENERAL.MIDDLE_NAME")}
								name="middle_name"
								placeholder={t("GENERAL.MIDDLE_NAME")}
								rules={rules.name()}
							/>
						</Col>
						<Col sm={24} md={12}  >
							<InputBox.Text
								required
								label={t("GENERAL.Last_NAME")}
								name="last_name"
								placeholder="Last Name"
								rules={rules.name()}
							/>
						</Col>
						<Col sm={24} md={12}  >
							<InputBox.DatePicker
								required
								allowClear={false}
								name="dob"
								label={t("GENERAL.DATE_OF_BIRTH")}
								placeholder={t("GENERAL.DATE_OF_BIRTH")}
								rules={rules.dateOfBirth()}
								defaultPickerValue={minDate}
								disabledDate={disableDate}
							/>
						</Col>
						<Col sm={24} md={12}  >
							<InputBox.Select
								required
								name="gender"
								label={t("GENERAL.GENDER")}
								placeholder={t("GENERAL.GENDER")}
								options={{
									list: genderOptions,
									valueKey: "id",
									textKey: "name",
								}}
								rules={rules.gender()}
							/>
						</Col>
						<Col sm={24} md={12}  >
							<InputBox.Text
								required
								label={t("VILLAGEMANAGEMENT.DESIGNNATION")}
								name="designation"
								placeholder={t("VILLAGEMANAGEMENT.DESIGNNATION")}
								rules={rules.name()}
							/>
						</Col>
						<Col sm={24} md={12}  >
							<InputBox.Text
								required
								label={t("VILLAGEMANAGEMENT.CONTECT_NUMBER")}
								name="phone"
								addonBefore={"+91"}
								placeholder="xxxxxxxxxxx"
								rules={rules.mobileNumber()}
								maxLength={10}
							/>
						</Col>
					</Row>
				</FormBox>
			</CommonModal>
		</Drawer>
	)
}

export default PartyWorker