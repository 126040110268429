import { useState } from "react";
import { Form, DrawerProps, Drawer } from "antd";
import { updateRecord } from "../../utils/slice";
import { validateFields } from "../../../../../config/Global";
import SVGIcon from "../../../../../utils/SVGIcon";
import Config from "../../../../../config/Config";
import FormComponent from "./FormComponent";
import { assignErrorToInput } from "../../../../../store/api";
import {
	useAppDispatch,
	useAppSelector,
} from "../../../../../store/app";
import { setPincodeList } from "../../../Administration/PincodeManagement/utils/slice";
import { setTalukaList } from "../../../Administration/TalukaManagement/utils/slice";
import { setVillageList } from "../../../Administration/VillageVsWard/utils/slice";
import { useTranslation } from "react-i18next";

interface EditDrawerProps extends DrawerProps {
	titleName: string;
	open: boolean;
	close: () => void;
}

const EditComponent: React.FC<EditDrawerProps> = ({
	titleName,
	open,
	close,
	...rest
}) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const details = useAppSelector(state => state.citizen.details);
	const [form] = Form.useForm();
	const [disabled, setDisabled] = useState<boolean>(true);
	const [saving, setSaving] = useState<boolean>(false);
	const [fetchpincode, setFetchPincode] = useState<boolean>(true);
	const [fetchTaluka, setFetchTaluka] = useState<boolean>(true);
	const [fetchVillage, setFetchVillage] = useState<boolean>(true);

	const drawerClose = () => {
		close();
		form.resetFields();
		setDisabled(true);
		setFetchPincode(true);
		setFetchTaluka(true);
		setFetchVillage(true);
		dispatch(setTalukaList([]))
		dispatch(setPincodeList([]))
		dispatch(setVillageList([]))
	};

	const validateForm = () => {
		validateFields(form, setDisabled);
	};

	const handleSubmit = (data: any) => {
		setSaving(true);
		data.whatsapp_number = data.whatsapp_number ? data.whatsapp_number : " "
		dispatch(updateRecord(details.id, data))
			.then(() => {
				drawerClose();
			})
			.catch((error: any) => {
				assignErrorToInput(form, error?.STATUS);
			})
			.finally(() => setSaving(false));
	};

	return (
		<Drawer
			title={`${titleName} ${t("GENERAL.EDIT__DRAWER_TITLE")}`}
			open={open}
			width={"70%"}
			onClose={drawerClose}
			destroyOnClose
			closeIcon={<SVGIcon icon="close" color={Config.themePrimaryBtn} />}
			className="citizenForm"
			{...rest}
		>
			<FormComponent
				form={form}
				id="editForm"
				editValues={details}
				handleSubmit={handleSubmit}
				onValuesChange={validateForm}
				fetchpincode={fetchpincode}
				setFetchPincode={setFetchPincode}
				fetchTaluka={fetchTaluka}
				setFetchTaluka={setFetchTaluka}
				fetchVillage={fetchVillage}
				setFetchVillage={setFetchVillage}
				disabled={disabled}
				saving={saving}
				drawerClose={drawerClose}
			/>
		</Drawer>
	);
};

export default EditComponent;
