import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../store/app'
import { FormBox, InputBox } from '../../../../../components/AntdAddons';
import { Button, Col, Row, Spin, Upload, message } from 'antd';
import { Messages } from '../../../../../config/Messages';
import { rules } from './rules';
import { CONSTANT } from '../../../../../config/Constant';
import dayjs from 'dayjs';
import { fetchTalukaList, setTalukaList } from '../../../Administration/TalukaManagement/utils/slice';
import { fetchschemeList, setSchemeList } from '../../../Administration/SchemeManagement/utils/slice';
import { deleteMeetingCommentAttachment } from '../../../CitizenManagement/utils/meetingSlice';
import { assignErrorToInput } from '../../../../../store/api';
import CommonModal from '../../../../../components/Modals/CommonModal';
import { fetchVillageList, setVillageList } from '../../../Administration/VillageVsWard/utils/slice';
import { villageType } from '../../../../../config/Global';

import { useTranslation } from 'react-i18next';
import { govDeptLov, grantYearLov, setGov_deptData, setGrant_yearData } from '../utils/slice';
const FormComponent: React.FC<any> = ({
	form,
	id,
	handleSubmit,
	onValuesChange,
	fetchTaluka,
	setFetchTaluka,
	fetchVillage,
	setFetchVillage,
	setFetchScheme,
	disabled,
	saving,
	drawerClose,
	editValues,
	setDisabled,
}) => {
	const { t } = useTranslation();
	const is_active = 1
	const dispatch = useAppDispatch()
	const [deleteAttachment, setDeleteAttachment] = useState<any>();
	const [deleteAttachmentSaving, setDeleteAttachmentSaving] = useState<boolean>(false);
	const [village_type_idDisable, setVillage_type_idDisable] = useState<boolean>(false);
	const { talukaList } = useAppSelector(
		state => state.taluka
	);
	const { villageList } = useAppSelector(
		state => state.village
	);
	const { schemeList } = useAppSelector(
		state => state.scheme
	);
	const { grant_yearList } = useAppSelector(
		state => state.mlaGrant
	);
	const { gov_deptList } = useAppSelector(
		state => state.mlaGrant
	);
	const {
		entry_date
	} = editValues ?? {};
	useEffect(() => {
		if (editValues) {
			dispatch(fetchTalukaList({ is_active: is_active })).catch((e: any) => e)
			dispatch(fetchVillageList({ is_active: is_active })).catch((e: any) => e)
			dispatch(fetchschemeList({ is_active: is_active })).catch((e: any) => e)
			dispatch(grantYearLov()).catch((e: any) => e)
			dispatch(govDeptLov()).catch((e: any) => e)
			if (editValues?.taluka) {
				dispatch(setTalukaList([editValues.taluka]))
			}
			if (editValues?.village) {
				dispatch(setVillageList([editValues.village]))
			}
			if (editValues?.scheme) {
				dispatch(setSchemeList([editValues.scheme]))
			}
			if (editValues?.grant_years) {
				dispatch(setGrant_yearData([editValues?.grant_years]))
			}
			if (editValues?.gov_depts) {
				dispatch(setGov_deptData([editValues?.gov_depts]))
			}
			form.setFieldsValue({
				...editValues,
				grant_year: editValues?.grant_year === 0 ? null : editValues?.grant_year,
				gov_dept : editValues?.gov_dept === 0 ? null : editValues?.gov_dept,
				entry_date: entry_date && dayjs(entry_date)
			});
		}
	}, [editValues, form]);

	const onFinish = (data: any) => {
		data.entry_date = data?.entry_date && dayjs(data?.entry_date).format(CONSTANT.POST_DATE_FORMAT)
		handleSubmit(data)
	}
	const checkFileType = (file: any) => {
		const isLt5M = file.size / 1024 / 1024 < 1;
		const acceptedFormats = [
			"application/pdf",
			"image/png",
			"image/jpeg",
			"image/jpg",
		];
		if (!acceptedFormats.includes(file.type)) {
			message.error(CONSTANT.INVALID_FILE);
			form.setFields([{ name: "attachment", errors: [CONSTANT.INVALID_FILE] }]);
			return Upload.LIST_IGNORE;
		}
		if (!isLt5M) {
			message.error(CONSTANT.BIGGER_SIZE);
			form.setFields([{ name: "attachment", errors: [CONSTANT.BIGGER_SIZE] }]);
			return Upload.LIST_IGNORE;
		} else {
			return false;
		}
	};
	const closeDeletAttachmentModal = () => {
		setDeleteAttachment(null)
	}
	const closeAttachmentDelete = () => {
		setDisabled(false)
		setDeleteAttachment(null)
	}
	const openAttachmentDelete = (data: any) => {
		setDeleteAttachment(data)
		return false
	}

	const handleRemoveImage = () => {
		if (!deleteAttachment.id) {
			const removedImage = form.getFieldValue("attachment").filter((item: any) => item?.uid !== deleteAttachment.uid)
			form.setFieldsValue({
				attachment: removedImage
			})
			closeAttachmentDelete()
		} else {
			setDeleteAttachmentSaving(true);
			const removedImage = form.getFieldValue("attachment").filter((item: any) => item?.id !== deleteAttachment.id)
			dispatch(deleteMeetingCommentAttachment(deleteAttachment.id))
				.then(() => {
					setDeleteAttachmentSaving(false)
					form.setFieldsValue({
						attachment: removedImage
					})
					closeAttachmentDelete()
				}).catch((error: any) => {
					assignErrorToInput(form, error?.STATUS);
					setDeleteAttachmentSaving(false)
				})
				.finally(() => setDeleteAttachmentSaving(false));
		}
	}
	return (
		<>
			<FormBox
				form={form}
				id={id}
				onFinish={onFinish}
				onValuesChange={onValuesChange}
			>
				<Row gutter={15}>
					<Col sm={24} md={12}  >
						<InputBox.Select
							required
							name="village_id"
							label={t("GENERAL.VILLAGES")}
							placeholder={t("GENERAL.VILLAGES")}
							showSearch
							optionFilterProp="children"
							onSelect={(e) => {
								setVillage_type_idDisable(true)
								dispatch(fetchVillageList({ village_id: e, is_active: is_active })).then((res: any) => {
									const villageRecord = res?.data[0]
									dispatch(setTalukaList([villageRecord.taluka]))
									form.setFieldsValue({
										taluka_id: villageRecord?.taluka_id,
										village_type_id: villageRecord?.village_type_id
									});
								}).catch(() => {
									//
								}).finally(() => (document.activeElement as HTMLElement)?.blur())
							}}
							onFocus={() => {
								// fetchVillage && form.getFieldValue("taluka_id") &&
								dispatch(fetchVillageList({ taluka_id: form.getFieldValue("taluka_id"), is_active: is_active }))
									.then(() => setFetchVillage(false))
									.catch((e: any) => e)
									.finally(() => setFetchVillage(false))
							}}
							notFoundContent={fetchVillage ? <Spin size="small" /> : Messages.NO_RECORD_FOUND}
							options={{
								list: villageList,
								valueKey: "id",
								textKey: "name",
							}}
							rules={rules.village()}
						/>
					</Col>
					<Col sm={24} md={12} >
						<InputBox.Select
							required
							showSearch
							disabled={village_type_idDisable}
							name="village_type_id"
							optionFilterProp="children"
							label={`${t("GENERAL.NAGERPALLIKA_VILLAGE_TYPE")}`}
							placeholder={`${t("GENERAL.NAGERPALLIKA_VILLAGE_TYPE")}`}
							options={{
								list: villageType,
								valueKey: "id",
								textKey: "name",
							}}
							rules={rules.taluka()}
						/>
					</Col>
					<Col sm={24} md={12}  >
						<InputBox.Select
							required
							showSearch
							name="taluka_id"
							optionFilterProp="children"
							label={t("GENERAL.TALUKA")}
							placeholder={t("GENERAL.TALUKA")}
							onFocus={() => {
								dispatch(fetchTalukaList({ is_active: is_active }))
									.then(() => setFetchTaluka(false))
									.catch((e: any) => e)
									.finally(() => setFetchTaluka(false))
							}}
							notFoundContent={fetchTaluka ? <Spin size="small" /> : Messages.NO_RECORD_FOUND}
							onChange={() => {
								form.setFieldsValue({
									village_id: null,
									village_type_id: null
								});
								dispatch(setVillageList([]));
								setFetchVillage(true);
								setVillage_type_idDisable(false)
							}}
							options={{
								list: talukaList,
								valueKey: "id",
								textKey: "name",
							}}
							rules={rules.taluka()}
						/>
					</Col>

					<Col sm={24} md={12}  >
						<InputBox.Text
							required
							label={t("MLAGRANTMANAGEMENT.TYPE_OF_WORK")}
							name="type_of_work"
							rules={rules.typeOfWork()}
						/>
					</Col>
					<Col sm={24} md={12}  >
						<InputBox.Select
							required
							name="scheme_id"
							label={t("GENERAL.NAME_OF_SHCEME")}
							placeholder={t("GENERAL.NAME_OF_SHCEME")}
							onFocus={() => {
								dispatch(fetchschemeList({ is_active: is_active }))
									.then(() => setFetchScheme(false))
									.catch((e: any) => e)
									.finally(() => setFetchScheme(false))
							}}
							options={{
								list: schemeList,
								valueKey: "id",
								textKey: "name",
							}}
							rules={rules.taluka()}
						/>
					</Col>
					<Col sm={24} md={12}  >
						<InputBox.Text
							required
							type='number'
							label={t("MLAGRANTMANAGEMENT.AMOUNT")}
							name="amount"
							rules={rules.amount()}
							onChange={(e) => {
								let { value } = e.target;
								if (value.includes('.')) {
									const [integerPart, decimalPart] = value.split('.');
									value = integerPart + '.' + decimalPart.slice(0, 2);
								}
								e.target.value = value;
								if (value) {
									form.setFieldValue("amount", value)
								}
							}}
						/>
					</Col>

					<Col sm={24} md={12}>
						<InputBox.DatePicker
							name="entry_date"
							label={t("GENERAL.DATE_OF_GRANT")}
							placeholder={t("GENERAL.DATE_OF_GRANT")}
							format={CONSTANT.DATE_FORMAT}
						/>
					</Col>
					<Col sm={12}>
						<InputBox.Upload
							required={false}
							label={t("GENERAL.ATTECHMENT")}
							name="attachment"
							maxCount={5}
							multiple={true}
							listType="picture"
							className="inputUpload"
							fileList={form.getFieldValue("attachment")}
							beforeUpload={checkFileType}
							onRemove={openAttachmentDelete}
						/>
					</Col>
					<Col sm={24} md={12}  >
						<InputBox.Text
							label={t("MLAGRANTMANAGEMENT.Id_Number")}
							name="grant_id_number"
							placeholder={t("MLAGRANTMANAGEMENT.Id_Number")}
							rules={rules.grant_id_number()}
							maxLength={15}
						/>
					</Col>
					<Col sm={24} md={12}  >
						<InputBox.Select
							name="grant_year"
							label={t("MLAGRANTMANAGEMENT.Year_of_grant")}
							placeholder={t("MLAGRANTMANAGEMENT.Year_of_grant")}
							onFocus={() => {
								dispatch(grantYearLov())
									.catch((e: any) => e)
							}}
							options={{
								list: grant_yearList,
								valueKey: "id",
								textKey: "year",
							}}
						// rules={rules.entry_date()}
						/>
					</Col>
					<Col sm={24} md={12}  >
						<InputBox.Select
							name="gov_dept"
							label={t("MLAGRANTMANAGEMENT.Government_Department")}
							placeholder={t("MLAGRANTMANAGEMENT.Government_Department")}
							onFocus={() => {
								dispatch(govDeptLov())
									.catch((e: any) => e)
							}}
							options={{
								list: gov_deptList,
								valueKey: "id",
								textKey: "dept",
							}}
						// rules={rules.entry_date()}
						/>
					</Col>
					<Col sm={24}>
						<InputBox.TextArea
							label={t("MLAGRANTMANAGEMENT.ANY_RECOMMENDATION")}
							name="description"
							rules={rules.description()}
						/>
					</Col>

				</Row>
				<div className='citizenFormBtn'>
					<Button
						key="1"
						disabled={disabled}
						form={id}
						loading={saving}
						htmlType="submit"
						type="primary"
					>
						{t("GENERAL.SAVE_BUTTON")}
					</Button>
					<Button
						key="2"
						htmlType="button"
						className="cancelBtn"
						onClick={drawerClose}
					>
						{t("GENERAL.CANCEL_BUTTON")}
					</Button>
				</div>
			</FormBox>

			<CommonModal
				className="deleteModal"
				title={`${t("GENERAL.ATTECHMENT")} ${t("GENERAL.DELETE__DRAWER_TITLE")}`}
				open={deleteAttachment}
				onCancel={closeDeletAttachmentModal}
				footer={[
					<>
						<Button
							key="3"
							htmlType="button"
							className="cancelBtn"
							onClick={closeAttachmentDelete}
						>
							{t("GENERAL.CLOSE_BUTTON")}
						</Button>
						<Button
							key="4"
							type="primary"
							loading={deleteAttachmentSaving}
							htmlType='submit'
							onClick={handleRemoveImage}
						>
							{t("GENERAL.DELETE_BUTTON")}
						</Button>
					</>
				]}
			>
				<div className="deleteNote">
					{t("GENERAL.DELETE_MODEL_MESSAGE")} {t("GENERAL.ATTECHMENT")} ?
				</div>

			</CommonModal>
		</>
	)
}
export default FormComponent