import React, { useEffect, useState } from 'react'
import { deleteRecord, details, setDetails, setPerPageSize } from './utils/slice';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Listing from './Components/Listing/Listing';
import AddComponent from './Components/Form/AddComponent';
import EditComponent from './Components/Form/EditComponent';
import ViewDrawer from './Components/villageFormsView/ViewDrawer';
import { useAppDispatch, useAppSelector } from '../../../../store/app';
import { CONSTANT } from '../../../../config/Constant';
import { checkPrivileges } from '../../../../config/Global';
import ToolBar from '../../../../components/ToolBar';
import { VillageDetailBreadcrumb } from '../../../../config/BreadcrumbConfig';
import CreateButton from '../../../../components/ToolBar/Buttons/CreateButton';
import RecordPerPage from '../../../../components/ToolBar/Dropdown/RecordPerPage';
import ContentBox from '../../../../components/ContentBox/ContentBox';
import DeleteModal from '../../../../components/Modals/DeleteModal';
import { ModuleInfoProps } from '../../../../config/InterfacesAndTypes';
import VillageDetailsComponent from './Components/VillageInnerForm/VillageDetailsComponent';
import PartyWorker from './Components/VillageInnerForm/PartyWorker';
import MalnutrionedChildren from './Components/VillageInnerForm/MalnutrionedChildren';
import VillageDetailsView from './Components/villageFormsView/VillageDetailsView';
// import PartyWorkerView from './Components/villageFormsView/PartyWorkerView';
// import MalnutrionedChildrenView from './Components/villageFormsView/MalnutrionedChildrenView';
// import GovSchemeView from './Components/villageFormsView/GovSchemeView';
// import UnavailableServicesView from './Components/villageFormsView/UnavailableServicesView';
// import VillageProblemView from './Components/villageFormsView/VillageProblemView';
// import VillageAchievementsView from './Components/villageFormsView/VillageAchievementsView';
// import VillageFestivalView from './Components/villageFormsView/VillageFestivalView';
// import VillageAttractionView from './Components/villageFormsView/VillageAttractionView';
import VillageGovSchemes from './Components/VillageInnerForm/VillageGovSchemes';
import VillageUnavailableServices from './Components/VillageInnerForm/VillageUnavailableServices';
import VillageProblems from './Components/VillageInnerForm/VillageProblems';
import VillageAchievements from './Components/VillageInnerForm/VillageAchievements';
import VillageFestival from './Components/VillageInnerForm/VillageFestival';
import VillageAttraction from './Components/VillageInnerForm/VillageAttraction';
import { useTranslation } from 'react-i18next';

const VillageDetailsManagement: React.FC<ModuleInfoProps> = (moduleInfo) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { action, id } = useParams();
	const [params] = useSearchParams()
	const [visibleDrawer, setVisibleDrawer] = useState<string | null | number>(null);
	const userDetail = useAppSelector((state) => state.auth.userDetail);
	const { perPage } = useAppSelector((state) => state.villageDetails);
	const [deleteValue, setDeleteValue] = useState<any>();
	const [dynamicSteps, setDynamicSteps] = useState<number>(0);
	const [villageId, setVillageId] = useState<any>(params.get("village"));
	const [villageDetailsId, setVillageDetailsId] = useState<any>();
	const location = useLocation();

	const getDetails = (id: any, type: string) => {
		return dispatch(details(id))
			.then(() => {
				setVisibleDrawer(type)
			})
			.catch(() => {
				navigateToIndex()
			})
	}
	const navigateToIndex = () => {
		setDetails(null);
		setVisibleDrawer(null);
		navigate(`${moduleInfo.indexRoute}`);
		setDynamicSteps(0)
	};
	const useQuery = () => {
		return React.useMemo(() => new URLSearchParams(location.search), [location.search]);
	}

	const query = useQuery();
	const saveAndNext = (step: any) => {
		setDynamicSteps(step)
		if (action === CONSTANT.DRAWER_TYPE.ADD) {
			// navigate(`${moduleInfo.indexRoute}/add/${villageDetailId}?step=${step}&village=${villagesId}`)
			navigate(`${location?.pathname}?step=${step}`)
		} else {
			navigate(`${location?.pathname}?step=${step}&village=${params.get("village")}`)
		}
		setVisibleDrawer(step.toString())
	}

	const skip = (steps: any) => {
		const step = Number(steps)
		navigate(`${location?.pathname}?step=${step}`)
	}
	useEffect(() => {
		if (action) {
			if (action === CONSTANT.DRAWER_TYPE.ADD && userDetail && !checkPrivileges(userDetail, `${moduleInfo.permissionPrefix}_CREATE`)) {
				navigateToIndex()
				return
			}
			if (action === CONSTANT.DRAWER_TYPE.EDIT && userDetail && !checkPrivileges(userDetail, `${moduleInfo.permissionPrefix}_UPDATE`)) {
				navigateToIndex()
				return
			}
			if (action === CONSTANT.DRAWER_TYPE.VIEW && userDetail && !checkPrivileges(userDetail, `${moduleInfo.permissionPrefix}_VIEW`)) {
				navigateToIndex()
				return
			}
			if (!id && action) {
				if (query.size !== 0) {
					const step: any = query.get("step");
					setDynamicSteps(step)
					navigate(`${location.pathname}?step=${step}`)
					setVisibleDrawer(step)
				} else {
					setVisibleDrawer(action);
				}
			} else if (id && action) {
				setVillageDetailsId(id);
				setVillageId(params.get("village"))
				if (query.size !== 0) {
					const step: any = query.get("step");
					// getDetails(id, action)
					// 	.then(() => {
					// 		setDynamicSteps(step)
					// 		navigate(`${location.pathname}?step=${step}&village=${params.get("village")}`)
					// 		setVisibleDrawer(step)
					// 	}).catch((e: any) => e)
					setDynamicSteps(step)
					navigate(`${location.pathname}?step=${step}&village=${villageId}`)
					setVisibleDrawer(step)
				} else {
					getDetails(id, action);
				}
			} else {
				navigateToIndex();
			}
		}
	}, [userDetail, action, id]);

	return (
		<>
			<ToolBar breadcrumbs={VillageDetailBreadcrumb(t)}>
				<CreateButton
					action={() =>
						navigate(`${moduleInfo.indexRoute}/${CONSTANT.DRAWER_TYPE.ADD}`)
					}
					permissionKey={`${moduleInfo.permissionPrefix}_CREATE`}
				/>
				<RecordPerPage
					perPage={perPage}
					onChange={(perPageSize: number) => {
						dispatch(setPerPageSize(perPageSize));
					}}
				/>
			</ToolBar>
			<ContentBox>
				<Listing
					moduleInfo={moduleInfo}
					handleViewClick={(data: any) => {
						navigate(
							`${moduleInfo.indexRoute}/${CONSTANT.DRAWER_TYPE.VIEW}/${data.id}`
						);
					}}
					handleEditClick={(data: any) => {
						// navigate(
						// 	`${moduleInfo.indexRoute}/${CONSTANT.DRAWER_TYPE.EDIT}/${data.id}`
						// );
						setVillageId(data?.village_id)
						navigate(
							`${moduleInfo.indexRoute}/${CONSTANT.DRAWER_TYPE.EDIT}/${data.id}?step=${1}&village=${data?.village_id}`
						);
					}}
					handleDeleteClick={setDeleteValue}
					setVillageId={setVillageId}
					setVillageDetailsId={setVillageDetailsId}
				/>
				<AddComponent
					titleName={t("VILLAGEMANAGEMENT.VILLAGE")}
					open={visibleDrawer === CONSTANT.DRAWER_TYPE.ADD}
					close={navigateToIndex}
					saveAndNext={saveAndNext}
					setVillageId={setVillageId}
					setVillageDetailsId={setVillageDetailsId}
				/>

				<EditComponent
					titleName={t("VILLAGEMANAGEMENT.VILLAGE")}
					open={visibleDrawer === CONSTANT.DRAWER_TYPE.EDIT}
					close={navigateToIndex}
					saveAndNext={saveAndNext}
					setVillageId={setVillageId}
				/>
				<ViewDrawer
					titleName={t("VILLAGEMANAGEMENT.VILLAGE")}
					open={visibleDrawer === CONSTANT.DRAWER_TYPE.VIEW}
					close={navigateToIndex}
					saveAndNext={saveAndNext}
				/>
				<DeleteModal
					title={t("VILLAGEMANAGEMENT.VILLAGE")}
					deleteValues={deleteValue}
					callApi={deleteRecord}
					close={() => setDeleteValue(null)}
					keyName="name"
				/>
				<VillageDetailsComponent
					open={visibleDrawer === CONSTANT.STEP.VILLAGE_DETAILS ? true : false}
					titleName={t("VILLAGEMANAGEMENT.VILLAGE")}
					close={navigateToIndex}
					saveAndNext={saveAndNext}
					villageId={villageId}
					skip={skip}
					dynamicSteps={dynamicSteps}
					villageDetailsId={villageDetailsId}
				/>
				<PartyWorker
					close={navigateToIndex}
					open={visibleDrawer === CONSTANT.STEP.PARTY_WORKER ? true : false}
					saveAndNext={saveAndNext}
					villageId={villageId}
					dynamicSteps={dynamicSteps}
					villageDetailsId={villageDetailsId}
				/>
				<MalnutrionedChildren
					close={navigateToIndex}
					open={visibleDrawer === CONSTANT.STEP.MALNUTRIONED_CHILDREN ? true : false}
					saveAndNext={saveAndNext}
					villageId={villageId}
					dynamicSteps={dynamicSteps}
					villageDetailsId={villageDetailsId}
				/>
				<VillageGovSchemes
					close={navigateToIndex}
					open={visibleDrawer === CONSTANT.STEP.VILLAGE_GOV_SCHEMES ? true : false}
					saveAndNext={saveAndNext}
					villageId={villageId}
					dynamicSteps={dynamicSteps}
					villageDetailsId={villageDetailsId}
				/>
				<VillageUnavailableServices
					close={navigateToIndex}
					open={visibleDrawer === CONSTANT.STEP.VILLAGE_UNAVAILABLE_SERVICES ? true : false}
					saveAndNext={saveAndNext}
					villageId={villageId}
					dynamicSteps={dynamicSteps}
					villageDetailsId={villageDetailsId}
				/>
				<VillageProblems
					close={navigateToIndex}
					open={visibleDrawer === CONSTANT.STEP.VILLAGE_PROBLEM ? true : false}
					saveAndNext={saveAndNext}
					villageId={villageId}
					dynamicSteps={dynamicSteps}
					villageDetailsId={villageDetailsId}
				/>
				<VillageAchievements
					close={navigateToIndex}
					open={visibleDrawer === CONSTANT.STEP.VILLAGE_ACHIEVEMENTS ? true : false}
					saveAndNext={saveAndNext}
					villageId={villageId}
					dynamicSteps={dynamicSteps}
					villageDetailsId={villageDetailsId}
				/>
				<VillageFestival
					close={navigateToIndex}
					open={visibleDrawer === CONSTANT.STEP.VILLAGE_FESTIVAL ? true : false}
					saveAndNext={saveAndNext}
					villageId={villageId}
					dynamicSteps={dynamicSteps}
					villageDetailsId={villageDetailsId}
				/>
				<VillageAttraction
					close={navigateToIndex}
					open={visibleDrawer === CONSTANT.STEP.VILLAGE_ATTRACTION ? true : false}
					saveAndNext={saveAndNext}
					villageId={villageId}
					dynamicSteps={dynamicSteps}
					villageDetailsId={villageDetailsId}
				/>
				{/* all form view part */}
				<VillageDetailsView
					open={action === CONSTANT.DRAWER_TYPE.VIEW && visibleDrawer === CONSTANT.STEP.VILLAGE_DETAILS ? true : false}
					close={navigateToIndex}
					saveAndNext={saveAndNext}
					id={id}
					dynamicSteps={dynamicSteps}
				/>
				{/* <PartyWorkerView
					open={action === CONSTANT.DRAWER_TYPE.VIEW && visibleDrawer === CONSTANT.STEP.PARTY_WORKER ? true : false}
					close={navigateToIndex}
					saveAndNext={saveAndNext}
					id={id}
					dynamicSteps={dynamicSteps}
				/> */}
				{/* <MalnutrionedChildrenView
					open={action === CONSTANT.DRAWER_TYPE.VIEW && visibleDrawer === CONSTANT.STEP.MALNUTRIONED_CHILDREN ? true : false}
					close={navigateToIndex}
					saveAndNext={saveAndNext}
					id={id}
					dynamicSteps={dynamicSteps}
				/> */}
				{/* <GovSchemeView
					open={action === CONSTANT.DRAWER_TYPE.VIEW && visibleDrawer === CONSTANT.STEP.VILLAGE_GOV_SCHEMES ? true : false}
					close={navigateToIndex}
					saveAndNext={saveAndNext}
					id={id}
					dynamicSteps={dynamicSteps}
				/> */}
				{/* <UnavailableServicesView
					open={action === CONSTANT.DRAWER_TYPE.VIEW && visibleDrawer === CONSTANT.STEP.VILLAGE_UNAVAILABLE_SERVICES ? true : false}
					close={navigateToIndex}
					saveAndNext={saveAndNext}
					id={id}
					dynamicSteps={dynamicSteps}
				/> */}
				{/* <VillageProblemView
					open={action === CONSTANT.DRAWER_TYPE.VIEW && visibleDrawer === CONSTANT.STEP.VILLAGE_PROBLEM ? true : false}
					close={navigateToIndex}
					saveAndNext={saveAndNext}
					id={id}
					dynamicSteps={dynamicSteps}
				/> */}
				{/* <VillageAchievementsView
					open={action === CONSTANT.DRAWER_TYPE.VIEW && visibleDrawer === CONSTANT.STEP.VILLAGE_ACHIEVEMENTS ? true : false}
					close={navigateToIndex}
					saveAndNext={saveAndNext}
					id={id}
					dynamicSteps={dynamicSteps}
				/> */}
				{/* <VillageFestivalView
					open={action === CONSTANT.DRAWER_TYPE.VIEW && visibleDrawer === CONSTANT.STEP.VILLAGE_FESTIVAL ? true : false}
					close={navigateToIndex}
					saveAndNext={saveAndNext}
					id={id}
					dynamicSteps={dynamicSteps}
				/> */}
				{/* <VillageAttractionView
					open={action === CONSTANT.DRAWER_TYPE.VIEW && visibleDrawer === CONSTANT.STEP.VILLAGE_ATTRACTION ? true : false}
					close={navigateToIndex}
					id={id}
					dynamicSteps={dynamicSteps}
				/> */}
			</ContentBox>
		</>
	)
}
export default VillageDetailsManagement