import { Form, DrawerProps, Drawer, Descriptions, Divider, Empty, Table } from "antd";
import { useAppSelector } from "../../../../../../store/app";
import SVGIcon from "../../../../../../utils/SVGIcon";
import Config from "../../../../../../config/Config";
import CancelButton from "../../../../../../components/Buttons/CancelButton";
import { renderNA } from "../../../../../../components/AgGridWrapper/utils/commonFunctions";
// import { CONSTANT } from "../../../../../../config/Constant";
// import { attractionDisplay, genderOptionsDisplay, partyPersonTypeDisplay } from "../../../../../../config/Global";
import { useTranslation } from "react-i18next";
import PartyWorkerView from "./PartyWorkerView";
import MalnutrionedChildrenView from "./MalnutrionedChildrenView";
import GovSchemeView from "./GovSchemeView";
import UnavailableServicesView from "./UnavailableServicesView";
import VillageProblemView from "./VillageProblemView";
import VillageAchievementsView from "./VillageAchievementsView";
import VillageFestivalView from "./VillageFestivalView";
import VillageAttractionView from "./VillageAttractionView";
import { CONSTANT } from "../../../../../../config/Constant";
import dayjs from "dayjs"
// import { genderOptions } from "../../../../config/Constant";

interface ViewDrawerProps extends DrawerProps {
	titleName: string;
	open: boolean;
	saveAndNext: any;
	close: () => void;
}

const ViewDrawer: React.FC<ViewDrawerProps> = ({
	titleName,
	open,
	close,
	// saveAndNext,
	...rest
}) => {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const details = useAppSelector(state => state.villageDetails.details);

	const drawerClose = () => {
		close();
		form.resetFields();
	};

	// const continueBtn = () => {
	// 	saveAndNext(CONSTANT.STEP.VILLAGE_DETAILS)
	// }

	const village = details?.village;
	const villageDetail = details?.villageDetail
	const partyWorkerDetail = details?.VillagePartyPerson
	const malnutrionedChildren = details?.VillageMalnutrionedChildren
	const govScheme = details?.VillageGovScheme
	const unavailableServices = details?.VillageUnavailableService
	const villageProblem = details?.VillageProblem
	const villageAchievements = details?.VillageAchievement
	const villageFestival = details?.VillageFestival
	const villageAttraction = details?.VillageAttraction


	const voterInformationcolumns: any = [
		{
			title: `${t("VILLAGEMANAGEMENT.TOTAL_VOTER")}`,
			dataIndex: 'total_voter',
			key: 'total_voter',
			dataLabel: `${t("VILLAGEMANAGEMENT.TOTAL_VOTER")}`,
		},
		{
			title: `${t("VILLAGEMANAGEMENT.TOTAL_VOTING")} (%)`,
			dataIndex: 'total_voting',
			key: 'total_voting',
			dataLabel: `${t("VILLAGEMANAGEMENT.TOTAL_VOTING")} (%)`
		},
		{
			title: `${t("VILLAGEMANAGEMENT.TOTAL_VOTING_CENTER")}`,
			dataIndex: 'total_voting_center',
			key: 'total_voting_center',
			dataLabel: `${t("VILLAGEMANAGEMENT.TOTAL_VOTING_CENTER")}`
		},
		{
			title: `${t("VILLAGEMANAGEMENT.TOTAL_BOOTH")}`,
			dataIndex: 'total_booth',
			key: 'total_booth',
			dataLabel: `${t("VILLAGEMANAGEMENT.TOTAL_BOOTH")}`
		},

	];


	const partyColumn: any = [];
	villageDetail && villageDetail?.map((item: any) => item.party.map((item: any, innerIndex: number) => {
		partyColumn.push({
			title: item.label,
			dataIndex: `${item.label}_percentage`,
			key: innerIndex,
			dataLabel: item.label
		});
	}))
	const partyArray = villageDetail && villageDetail[0]?.party?.reduce((result: any, item: any) => {
		result[`${item.label}_percentage`] = item.percentage;
		return result;
	}, {});


	return (
		<Drawer
			// title={`${titleName} ${GENERAL.VIEW__DRAWER_TITLE}`}
			open={open}
			placement="right"
			width={"80%"}
			onClose={drawerClose}
			className="villageDetailView  responsive-table"
			destroyOnClose
			closeIcon={<SVGIcon icon="close" color={Config.themePrimaryBtn} />}
			footer={[
				<CancelButton key={2} onClick={drawerClose} />,
				// <Button
				// 	htmlType="button"
				// 	className="cancelBtn"
				// 	key="2"
				// 	type="primary"
				// 	onClick={continueBtn}>

				// 	{GENERAL.CONTINUE_BUTTON}
				// </Button>
			]}
			{...rest}
		>
			<>
				<div className='tag'><span>{`${titleName} ${t("GENERAL.VIEW__DRAWER_TITLE")}`}</span></div>
				<Descriptions column={{ xs: 1, sm: 1, md: 1, xxl: 2 }} bordered layout="horizontal">
					<Descriptions.Item label={`${t("GENERAL.VILLAGES")}`}>
						{renderNA(village?.village?.name)}
					</Descriptions.Item>
					<Descriptions.Item label="Date">
						{renderNA((dayjs(village?.date).format(CONSTANT.MONTH_FORMAT_YEAR)))}
					</Descriptions.Item>
				</Descriptions>
				<div className='tag'>
					<span>
						{`${titleName}`}
					</span>
				</div>
				{villageDetail && villageDetail?.length > 0 ? villageDetail?.map((item: any, index: number) => {
					return (
						<div key={index}>
							<Descriptions column={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2, xxl: 2 }} bordered layout="horizontal">
								<Descriptions.Item label={t("VILLAGEMANAGEMENT.POPULATION_OF_VILLAGE")}>
									{renderNA(item?.population)}
								</Descriptions.Item>
							</Descriptions>
							<Divider />
							<h3>{t("VILLAGEMANAGEMENT.VOTER_INFO_H3")}</h3>
							<Table
								dataSource={villageDetail}
								columns={voterInformationcolumns}
								pagination={false}
								scroll={{ x: 992 }}
							/>
							<Divider />
							<h3>{t("VILLAGEMANAGEMENT.VOTING_RESULTS_PARTY_WISE_H3")}</h3>
							{/* <Descriptions column={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2, xxl: 2 }} bordered layout="horizontal">
								{item?.party && item?.party?.length > 0 && item.party.map((partyItem: any, index: number) => {
									return (
										<Descriptions.Item label={`${partyItem.label} (%)`} key={index}>
											{renderNA(partyItem?.percentage.toString())}
										</Descriptions.Item>
									)
								})}
							</Descriptions> */}
							<Table
								dataSource={[partyArray]}
								columns={partyColumn}
								pagination={false}
								scroll={{ x: 992 }}
							/>


						</div>
					)
				}) :
					<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
				}



				{/* <Descriptions column={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2, xxl: 2 }} bordered layout="horizontal">
					<Descriptions.Item label={t("VILLAGEMANAGEMENT.TOTAL_VOTER")}>
						{renderNA(item?.total_voter)}
					</Descriptions.Item>
					<Descriptions.Item label={`${t("VILLAGEMANAGEMENT.TOTAL_VOTING")} (%)`}>
						{renderNA(item?.total_voting)}
					</Descriptions.Item>
					<Descriptions.Item label={t("VILLAGEMANAGEMENT.TOTAL_VOTING_CENTER")}>
						{renderNA(item?.total_voting_center)}
					</Descriptions.Item>
					<Descriptions.Item label={t("VILLAGEMANAGEMENT.TOTAL_BOOTH")}>
						{renderNA(item?.total_booth)}
					</Descriptions.Item>
				</Descriptions>
				*/}


				<div className='tag'>
					<span>
						{`${t("VILLAGEMANAGEMENT.PARTY_WORKER")}`}
					</span>
				</div>
				{/* {
					partyWorkerDetail && partyWorkerDetail?.length > 0 ? partyWorkerDetail?.map((partyWorkerItem: any, index: number) => {
						return (
							<Descriptions column={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2, xxl: 2 }} bordered layout="horizontal" key={index}>
								<Descriptions.Item label={VILLAGEMANAGEMENT.PERSION_TYPE}>
									{renderNA(partyPersonTypeDisplay[partyWorkerItem?.person_type])}
								</Descriptions.Item>
								<Descriptions.Item label={GENERAL.NAME}>
									{renderNA(partyWorkerItem?.name)}
								</Descriptions.Item>
								<Descriptions.Item label={VILLAGEMANAGEMENT.DESIGNNATION}>
									{renderNA(partyWorkerItem?.designation)}
								</Descriptions.Item>
								<Descriptions.Item label={VILLAGEMANAGEMENT.CONTECT_NUMBER}>
									{renderNA(partyWorkerItem?.phone)}
								</Descriptions.Item>
							</Descriptions>
						)
					}) :
						<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
				} */}
				<PartyWorkerView partyWorkerDetail={partyWorkerDetail} />

				<div className='tag'>
					<span>
						{`${t("VILLAGEMANAGEMENT.VILLAGE_MALNUTRIONED_CHILDREN")}`}
					</span>
				</div>
				{/* {
					malnutrionedChildren && malnutrionedChildren?.length > 0 ? malnutrionedChildren?.map((malnutrionedChildrenItem: any, index: number) => {
						return (
							<Descriptions column={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2, xxl: 2 }} bordered layout="horizontal" key={index}>
								<Descriptions.Item label={VILLAGEMANAGEMENT.CHILDREN_NAME}>
									{renderNA(malnutrionedChildrenItem?.child_name)}
								</Descriptions.Item>
								<Descriptions.Item label={VILLAGEMANAGEMENT.AGE}>
									{renderNA(malnutrionedChildrenItem?.age)}
								</Descriptions.Item>
								<Descriptions.Item label={VILLAGEMANAGEMENT.CONTECT_NUMBER}>
									{renderNA(malnutrionedChildrenItem?.phone)}
								</Descriptions.Item>
								<Descriptions.Item label={GENERAL.GENDER}>
									{renderNA(genderOptionsDisplay[malnutrionedChildrenItem?.gender])}
								</Descriptions.Item>
								<Descriptions.Item label={VILLAGEMANAGEMENT.DEFICIENCY}>
									{renderNA(malnutrionedChildrenItem?.deficiency)}
								</Descriptions.Item>
								<Descriptions.Item label={VILLAGEMANAGEMENT.CASTE}>
									{renderNA(malnutrionedChildrenItem?.caste)}
								</Descriptions.Item>
							</Descriptions>
						)
					}) :
						<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
				} */}
				<MalnutrionedChildrenView malnutrionedChildren={malnutrionedChildren} />
				<div className='tag'>
					<span>
						{`${t("VILLAGEMANAGEMENT.VILLAGE_GOV_SHCMES")}`}
					</span>
				</div>
				{/* {
					govScheme && govScheme?.length > 0 ? govScheme.map((govSchemeItem: any, index: number) => {
						return (
							<Descriptions column={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2, xxl: 2 }} bordered layout="horizontal" key={index}>
								<Descriptions.Item label={GENERAL.NAME}>
									{renderNA(govSchemeItem?.name)}
								</Descriptions.Item>
								<Descriptions.Item label={VILLAGEMANAGEMENT.CONTECT_NUMBER}>
									{renderNA(govSchemeItem?.phone)}
								</Descriptions.Item>
								<Descriptions.Item label={GENERAL.NAME_OF_SHCEME}>
									{renderNA(govSchemeItem?.scheme?.name)}
								</Descriptions.Item>
							</Descriptions>
						)
					}) :
						<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
				} */}
				<GovSchemeView govScheme={govScheme} />
				<div className='tag'>
					<span>
						{`${t("VILLAGEMANAGEMENT.VILLAGE_UNAVAILABLE_SERVICES")}`}
					</span>
				</div>
				{/* {
					unavailableServices && unavailableServices?.length > 0 ? unavailableServices.map((unavailableServicesItem: any, index: number) => {
						return (
							<Descriptions column={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2, xxl: 2 }} bordered layout="horizontal" key={index}>
								<Descriptions.Item label={VILLAGEMANAGEMENT.TYPE}>
									{renderNA(unavailableServicesItem?.service_type)}
								</Descriptions.Item>
								<Descriptions.Item label={VILLAGEMANAGEMENT.DETAIL}>
									{renderNA(unavailableServicesItem?.service_detail)}
								</Descriptions.Item>
							</Descriptions>
						)
					}) :
						<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
				} */}
				<UnavailableServicesView
					unavailableServices={unavailableServices}
				/>
				<div className='tag'>
					<span>
						{`${t("VILLAGEMANAGEMENT.VILLAGE_PROBLEMS")}`}
					</span>
				</div>
				{/* {
					villageProblem && villageProblem?.length > 0 ? villageProblem.map((villageProblemItem: any, index: number) => {
						return (
							<Descriptions column={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2, xxl: 2 }} bordered layout="horizontal" key={index}>
								<Descriptions.Item label={VILLAGEMANAGEMENT.TYPE}>
									{renderNA(villageProblemItem?.type)}
								</Descriptions.Item>
								<Descriptions.Item label={VILLAGEMANAGEMENT.DETAIL}>
									{renderNA(villageProblemItem?.detail)}
								</Descriptions.Item>
							</Descriptions>
						)
					}) :
						<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
				} */}
				<VillageProblemView villageProblem={villageProblem} />
				<div className='tag'>
					<span>
						{`${t("VILLAGEMANAGEMENT.VILLAGE_ACHIEVEMENTS")}`}
					</span>
				</div>
				{/* {
					villageAchievements && villageAchievements?.length > 0 ? villageAchievements.map((villageAchievementsItem: any, index: number) => {
						return (
							<Descriptions column={{ xs: 1, sm: 1, md: 1, xxl: 2 }} bordered layout="horizontal" key={index}>
								<Descriptions.Item label={GENERAL.NAME}>
									{renderNA(villageAchievementsItem?.name)}
								</Descriptions.Item>
								<Descriptions.Item label={VILLAGEMANAGEMENT.TYPE}>
									{renderNA(villageAchievementsItem?.type)}
								</Descriptions.Item>
								<Descriptions.Item label={VILLAGEMANAGEMENT.CONTECT_NUMBER}>
									{renderNA(villageAchievementsItem?.phone)}
								</Descriptions.Item>
							</Descriptions>
						)
					}) :
						<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
				} */}
				<VillageAchievementsView villageAchievements={villageAchievements} />
				<div className='tag'>
					<span>
						{`${t("VILLAGEMANAGEMENT.VILLAGE_FESTIVAL")}`}
					</span>
				</div>
				{/* {
					villageFestival && villageFestival?.length > 0 ? villageFestival.map((villageFestivalItem: any, index: number) => {
						return (
							<Descriptions column={{ xs: 1, sm: 1, md: 1, xxl: 2 }} bordered layout="horizontal" key={index}>
								<Descriptions.Item label={GENERAL.NAME}>
									{renderNA(villageFestivalItem?.name)}
								</Descriptions.Item>
								<Descriptions.Item label={VILLAGEMANAGEMENT.RELIGION}>
									{renderNA(villageFestivalItem?.religion?.name)}
								</Descriptions.Item>
								<Descriptions.Item label={GENERAL.FROM_DATE}>
									{renderNA(villageFestivalItem?.from_date)}
								</Descriptions.Item>
								<Descriptions.Item label={GENERAL.TO_DATE}>
									{renderNA(villageFestivalItem?.to_date)}
								</Descriptions.Item>
							</Descriptions>
						)
					}) :
						<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
				} */}
				<VillageFestivalView villageFestival={villageFestival} />
				<div className='tag'>
					<span>
						{`${t("VILLAGEMANAGEMENT.VILLAGE_ATTRACTION")}`}
					</span>
				</div>
				{/* {
					villageAttraction && villageAttraction?.length > 0 ? villageAttraction.map((villageAttractionItem: any, index: number) => {
						return (
							<Descriptions column={{ xs: 1, sm: 1, md: 1, xxl: 2 }} bordered layout="horizontal" key={index}>
								<Descriptions.Item label={GENERAL.NAME}>
									{renderNA(villageAttractionItem?.name)}
								</Descriptions.Item>
								<Descriptions.Item label={VILLAGEMANAGEMENT.TYPE}>
									{renderNA(attractionDisplay[villageAttractionItem?.type])}
								</Descriptions.Item>
								<Descriptions.Item label={VILLAGEMANAGEMENT.DETAIL}>
									{renderNA(villageAttractionItem?.detail)}
								</Descriptions.Item>
							</Descriptions>
						)
					}) :
						<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
				} */}
				<VillageAttractionView villageAttraction={villageAttraction} />
			</>
		</Drawer>
	);
};

export default ViewDrawer;
