import React, { useEffect, useState } from 'react'
import { ModuleInfoProps } from '../../../config/InterfacesAndTypes'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/app';
import { deleteRecord, details, setPerPageSize } from './utils/slice';
import { CONSTANT } from '../../../config/Constant';
import { checkPrivileges } from '../../../config/Global';
import ToolBar from '../../../components/ToolBar';
import CreateButton from '../../../components/ToolBar/Buttons/CreateButton';
import RecordPerPage from '../../../components/ToolBar/Dropdown/RecordPerPage';
import { GovernmentNonGovermentContactBreadcrumb } from '../../../config/BreadcrumbConfig';
import ContentBox from '../../../components/ContentBox/ContentBox';
import Listing from './Listing/Listing';
import AddComponent from './Form/AddComponent';
import DeleteModal from '../../../components/Modals/DeleteModal';
import EditComponent from './Form/EditComponent';
import ViewDrawer from './Form/ViewDrawer';

const GovernmentNonGovermentContactManagement: React.FC<ModuleInfoProps> = (moduleInfo) => {
  const { t } = useTranslation();
  const { action, id } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [visibleDrawer, setVisibleDrawer] = useState<string | null>(null);
  const userDetail = useAppSelector((state) => state.auth.userDetail);
  const { perPage } = useAppSelector((state) => state.mlaGrant);
  const [deleteValue, setDeleteValue] = useState<any>();

  const navigateToIndex = () => {
    setVisibleDrawer(null)
    navigate(`${moduleInfo.indexRoute}`)
  }

  const getDetails = (id: any, type: string) => {
    dispatch(details(id))
      .then(() => {
        setVisibleDrawer(type)
      })
      .catch(() => {
        navigateToIndex()
      })
  }
  useEffect(() => {
    if (action) {
      if (action === CONSTANT.DRAWER_TYPE.ADD && userDetail && !checkPrivileges(userDetail, `${moduleInfo.permissionPrefix}_CREATE`)) {
        navigateToIndex()
        return
      }
      if (action === CONSTANT.DRAWER_TYPE.EDIT && userDetail && !checkPrivileges(userDetail, `${moduleInfo.permissionPrefix}_UPDATE`)) {
        navigateToIndex()
        return
      }
      if (action === CONSTANT.DRAWER_TYPE.VIEW && userDetail && !checkPrivileges(userDetail, `${moduleInfo.permissionPrefix}_VIEW`)) {
        navigateToIndex()
        return
      }

      if (!id && action) {
        setVisibleDrawer(action);
      } else if (id && action) {
        getDetails(id, action);
      } else {
        navigateToIndex();
      }
    }
  }, [userDetail, action, id]);
  return (
    <>
      <ToolBar breadcrumbs={GovernmentNonGovermentContactBreadcrumb(t)}>
        <CreateButton
          action={() =>
            navigate(`${moduleInfo.indexRoute}/${CONSTANT.DRAWER_TYPE.ADD}`)
          }
          permissionKey={`${moduleInfo.permissionPrefix}_CREATE`}
        />
        <RecordPerPage
          perPage={perPage}
          onChange={(perPageSize: number) => {
            dispatch(setPerPageSize(perPageSize));
          }}
        />
      </ToolBar>
      <ContentBox>
        <Listing
          moduleInfo={moduleInfo}
          handleViewClick={(data: any) => {
            navigate(
              `${moduleInfo.indexRoute}/${CONSTANT.DRAWER_TYPE.VIEW}/${data.id}`
            );
          }}
          handleEditClick={(data: any) => {
            navigate(
              `${moduleInfo.indexRoute}/${CONSTANT.DRAWER_TYPE.EDIT}/${data.id}`
            );
          }}
          handleDeleteClick={setDeleteValue}
        />
        <AddComponent
          titleName={`${t("GOVERNMENTNONGOVERMENTCONTACTMANAGEMENT.GOVERNMENT_NON_GOVERMENT_CONTACT")}`}
          open={visibleDrawer === CONSTANT.DRAWER_TYPE.ADD}
          close={navigateToIndex}
        />
        <DeleteModal
          title={t("GOVERNMENTNONGOVERMENTCONTACTMANAGEMENT.GOVERNMENT_NON_GOVERMENT_CONTACT")}
          deleteValues={deleteValue}
          callApi={deleteRecord}
          close={() => setDeleteValue(null)}
          keyName="fname"
        />
        <EditComponent
          titleName={`${t("GOVERNMENTNONGOVERMENTCONTACTMANAGEMENT.GOVERNMENT_NON_GOVERMENT_CONTACT")}`}
          open={visibleDrawer === CONSTANT.DRAWER_TYPE.EDIT}
          close={navigateToIndex}
        />
        <ViewDrawer
          titleName={`${t("GOVERNMENTNONGOVERMENTCONTACTMANAGEMENT.GOVERNMENT_NON_GOVERMENT_CONTACT")}`}
          open={visibleDrawer === CONSTANT.DRAWER_TYPE.VIEW}
          close={navigateToIndex}
        />
      </ContentBox>
    </>
  )
}

export default GovernmentNonGovermentContactManagement