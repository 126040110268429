import React from 'react'
// import { useAppDispatch } from '../../../../../../store/app';
// import { getMalnutrionedChildrensList } from '../../utils/slice';
// import { Button, Drawer,  } from 'antd';
// import SVGIcon from '../../../../../../utils/SVGIcon';
// import Config from '../../../../../../config/Config';
// import CancelButton from '../../../../../../components/Buttons/CancelButton';
// import { tablePagination } from '../../../../../../config/Global';
// import { useParams } from 'react-router-dom';
import { CONSTANT } from '../../../../../../config/Constant'
import { DrawerProps } from 'antd/lib';
import { genderOptionsDisplay } from '../../../../../../config/Global';
import { useTranslation } from 'react-i18next';
import dayjs from "dayjs"
import { Table } from 'antd';


interface MalnutrionedChildrenViewProps extends DrawerProps {
    // open: boolean;
    // close: () => void;
    // id: any
    // saveAndNext: any;
    // dynamicSteps: any
    malnutrionedChildren: any
}

const MalnutrionedChildrenView: React.FC<MalnutrionedChildrenViewProps> = ({
    malnutrionedChildren
}) => {
    const { t } = useTranslation();
    // const { action } = useParams();
    // const dispatch = useAppDispatch();
    // const [malnutrionedChildrenList, setMalnutrionedChildrenList] = useState<any>([]);
    // const [pagination, setPagination] = useState(tablePagination);

    // const continueBtn = () => {
    //     saveAndNext(CONSTANT.STEP.VILLAGE_GOV_SCHEMES)
    // }

    // useEffect(() => {
    //     if (id && action === CONSTANT.DRAWER_TYPE.VIEW && dynamicSteps === CONSTANT.STEP.MALNUTRIONED_CHILDREN){

    //         dispatch(getMalnutrionedChildrensList({ village_id: id }))
    //         .then((res: any) => {
    //             setMalnutrionedChildrenList(res.rows)
    //         })
    //         .catch((e: any) => e)
    //     }
    // }, [dynamicSteps])

    const columns:any = [
        {
			title: t("GENERAL.FIRST_NAME"),
			dataIndex: 'first_name',
			key: 'first_name',
            fixed:'left',
            width: 120,
		},
		{
			title: t("GENERAL.MIDDLE_NAME"),
			dataIndex: 'middle_name',
			key: 'middle_name',
            width: 120,
		},
		{
			title: t("GENERAL.Last_NAME"),
			dataIndex: 'last_name',
			key: 'last_name',
            width: 120,
		},
		{
			title: t("GENERAL.DATE_OF_BIRTH"),
			dataIndex: 'dob',
			key: 'dob',
            width: 120,
			render: (text: any) => {
				return (
					<span>{text !== null && dayjs(text).format(CONSTANT.DATE_FORMAT)}</span>
				)
			}
		},		
        {
            title: `${t("VILLAGEMANAGEMENT.AGE")}`,
            dataIndex: 'age',
            key: 'age',
            width: 120,
        },
        {
            title: `${t("VILLAGEMANAGEMENT.CONTECT_NUMBER")}`,
            dataIndex: 'phone',
            key: 'phone',
            width: 120,
        },
        {
            title: `${t("GENERAL.GENDER")}`,
            dataIndex: 'gender',
            key: 'gender',
            width: 120,
            render: (text: any) => {
                return (
                    <span>{genderOptionsDisplay[text]}</span>
                )
            }
        },
        {
            title: `${t("VILLAGEMANAGEMENT.DEFICIENCY")}`,
            dataIndex: 'deficiency',
            key: 'deficiency',
            width: 120,
        },
        {
            title: `${t("VILLAGEMANAGEMENT.CASTE")}`,
            dataIndex: 'caste',
            key: 'caste',
            width: 120,
        },
        // {
        // 	title: 'Action',
        // 	key: 'action',
        // 	render: (text: any, record: any) => (
        // 		<div className='listActionbtn'>
        // 			<span onClick={() => handleAddEdit(record, "editModal")}>
        // 				<EditOutlined />
        // 			</span>
        // 			<span onClick={() => deleteModal(record)}>
        // 				<DeleteOutlined />
        // 			</span>
        // 		</div>
        // 	),
        // },
    ];
    // const drawerClose = () => {
    //     close();
    //     setMalnutrionedChildrenList([])
    // };
    return (
        <Table
            dataSource={malnutrionedChildren}
            columns={columns}
            pagination={false}
            scroll={{ x: 992 }}
        />
    );
}

export default MalnutrionedChildrenView