import React, { useState } from 'react'
import ActionButtons from '../../../../../../components/ActionButtons';
import ChangeStatusModal from '../../../../../../components/Modals/ChangeStatusModal';
import { changeStatus, setupGrid } from '../../utils/slice';
import AgGridWrapper from '../../../../../../components/AgGridWrapper/AgGridWrapper';
import columnDefs from './columnDefs';
import { checkPrivileges } from '../../../../../../config/Global';
import { useAppSelector } from '../../../../../../store/app';
import { useTranslation } from 'react-i18next';

const Listing: React.FC<any> = ({
	moduleInfo,
	handleViewClick,
	handleDeleteClick,
	handleEditClick,
}) => {
	const { t } = useTranslation();
	const [statusData, setStatusData] = useState<any>();
	const userDetail = useAppSelector(state => state.auth.userDetail);
	const ActionRenderer = (props: any) => {
		return (
			<ActionButtons
				data={props}
				view={{
					action: handleViewClick,
					permissionKey: `${moduleInfo.permissionPrefix}_DETAILS`,
				}}
				edit={{
					action: handleEditClick,
					permissionKey: `${moduleInfo.permissionPrefix}_UPDATE`,
				}}
				deleteButton={{
					action: handleDeleteClick,
					permissionKey: `${moduleInfo.permissionPrefix}_DELETE`,
				}}
			/>
		)
	}

	return (
		<>
			<ChangeStatusModal
				titleName={t("PINCODEMANAGEMENT.PINCODES")}
				data={statusData}
				close={() => setStatusData(null)}
				callApi={changeStatus}
				keyName={"pincode"}
			/>
			<AgGridWrapper
				type="serverSide"
				components={{
					ActionRenderer,
				}}
				onGridReadyWithDispatch={setupGrid}
				columnDefs={columnDefs()}
				context={{
					setStatusData, changeStatusPermission: checkPrivileges(
						userDetail,
						`${moduleInfo.permissionPrefix}_CHANGE_STATUS`
					)
				}}
			/>
		</>
	);
}

export default Listing