import { ColDef } from "ag-grid-community"
import { renderNA, setDateFilterDefs } from "../../../../../../components/AgGridWrapper/utils/commonFunctions";
import { useTranslation } from "react-i18next";
import { dateFormatter,
    //  meetingDisplay,
    //   meetingStatusOptions
     } from "../../../../../../config/Global";
import { srNoColumn,actionColumn } from "../../../../../../components/AgGridWrapper/utils/commonColumns";

const columnDefs = (): ColDef[] => {
    const { t } = useTranslation();
    const typeColumn: ColDef = {
        field: "type",
        headerName: `${t("CITIZENMANAGEMENT.TYPE_OF_MEETING")}`,
        cellRenderer: (props: any) => {
            return renderNA(props.data?.type);
        },
    };
    const dateOfEntryColumn: ColDef = {
        field: "date",
        headerName: `${t("GENERAL.DATE_OF_ENTRY")}`,
        sortable: true,
        width: 200,
        minWidth: 200,
        cellRenderer: (props: any) => {
            return props.data?.date ? dateFormatter(props.data.date) : "N/A";
        },
        ...setDateFilterDefs()
    };
    // const statusColumn: ColDef = {
    //     field: "status",
    //     headerName: `${t("CITIZENMANAGEMENT.ACTIVITY_STATUS")}`,
    //     cellRenderer: (props: any) => {
    //         return renderNA(meetingDisplay[props.data?.status]);
    //     },
    //     filter: "agSetColumnFilter",
	// 	filterParams: {
	// 		values: (params: any) => {
	// 			if (meetingStatusOptions) {
	// 				params.success(meetingStatusOptions.map((x:any) => x.name));
	// 			}
	// 		},
	// 		buttons: ["apply", "reset"],
	// 		closeOnApply: true,
	// 	},
    // };
    const firstnameColumn: ColDef = {
        field: "first_name",
        headerName: `${t("CITIZENMANAGEMENT.FIRST_NAME")}`,
        cellRenderer: (props: any) => {
            return renderNA(props.data?.first_name);
        },
    };
    const middlenameColumn: ColDef = {
        field: "middle_name",
        headerName: `${t("CITIZENMANAGEMENT.MIDDLE_NAME")}`,
        cellRenderer: (props: any) => {
            return renderNA(props.data?.middle_name);
        },
    };
    const lastnameColumn: ColDef = {
        field: "last_name",
        headerName: `${t("CITIZENMANAGEMENT.LAST_NAME")}`,
        cellRenderer: (props: any) => {
            return renderNA(props.data?.last_name);
        },
    };
    const whatsNoColumn: ColDef = {
        field: "whatsapp_number",
        headerName: `${t("CITIZENMANAGEMENT.WHATSAPP_NUMBER")}`,
        // filter: "agNumberColumnFilter",
        cellRenderer: (props: any) => {
            return renderNA(props.data?.whatsapp_number);
        },
    };
    const phoneColumn: ColDef = {
        field: "phone",
        headerName: `${t("GENERAL.MOBILE_NUMBER")}`,
        // filter: "agNumberColumnFilter",
        cellRenderer: (props: any) => {
            return renderNA(props.data?.phone);
        },
    };
    return [srNoColumn(t),
        typeColumn,
        dateOfEntryColumn,
        // statusColumn,
        firstnameColumn,
        middlenameColumn,
        lastnameColumn,
        whatsNoColumn,
        phoneColumn,
        { ...actionColumn(t), width: 165, minWidth: 165 },
    ]
}

export default columnDefs;