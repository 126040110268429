import React, { useState } from "react";
import AgGridWrapper from "../../../../../../components/AgGridWrapper/AgGridWrapper";
import ActionButtons from "../../../../../../components/ActionButtons";
import { changeStatus, setGrid } from "../../utils/slice";
import columnDefs from "./columnDefs";
import ChangeStatusModal from "../../../../../../components/Modals/ChangeStatusModal";
import { useAppSelector } from "../../../../../../store/app";
import { checkPrivileges } from "../../../../../../config/Global";
import { useTranslation } from "react-i18next";

const Listing: React.FC<any> = ({
  moduleInfo,
  handleViewClick,
  handleDeleteClick,
  handleEditClick,
}) => {
  const { t } = useTranslation();
  const rowData = useAppSelector(state => state.role.rowData);
  const [statusData, setStatusData] = useState<any>();
  const userDetail = useAppSelector(state => state.auth.userDetail);
  const ActionRenderer = (props: any) => {
    return (
      <ActionButtons
        data={props}
        view={{
          action: handleViewClick,
          permissionKey: `${moduleInfo.permissionPrefix}_DETAILS`,
        }}
        edit={{
          action: handleEditClick,
          permissionKey: `${moduleInfo.permissionPrefix}_UPDATE`,
        }}
        deleteButton={{
          action: handleDeleteClick,
          permissionKey: `${moduleInfo.permissionPrefix}_DELETE`,
        }}
      />
    );
  };

  return (
    <>
      <ChangeStatusModal
        titleName={t("ROLEMANAGEMENT.ROLE")}
        data={statusData}
        close={() => setStatusData(null)}
        callApi={changeStatus}
        keyName={"name"}
      />
      <AgGridWrapper
        components={{
          ActionRenderer,
        }}
        rowData={rowData}
        onGridReadyWithDispatch={setGrid}
        columnDefs={columnDefs()}
        context={{
					setStatusData, changeStatusPermission: checkPrivileges(
						userDetail,
						`${moduleInfo.permissionPrefix}_CHANGE_STATUS`
					)
				}}
      />
    </>
  );
};

export default Listing;
