import React from 'react'
import { FormBox, InputBox } from '../../../../../components/AntdAddons'
import { Button, Col, Row, Spin } from 'antd'
import { useAppDispatch, useAppSelector } from '../../../../../store/app'
import { fetchTalukaList } from '../../../Administration/TalukaManagement/utils/slice'
import { Messages } from '../../../../../config/Messages'
import { rules } from './rules'
import { fetchCategoriesList, setCategories } from '../../../../../store/CommonSlice'
import { fetchVillageList, setVillageList } from '../../../Administration/VillageVsWard/utils/slice'
import { useTranslation } from 'react-i18next'

const ReportForm: React.FC<any> = ({
    form,
    id,
    handleSubmit,
    onValuesChange,
    fetchTaluka,
    setFetchTaluka,
    fetchVillage,
    setFetchVillage,
    disabled,
    saving,
    blankForm,
    fetchCategories,
    setFetchCategories
}) => {
    const { t } = useTranslation();
    const is_active = 1;
    const dispatch = useAppDispatch();
    const { talukaList } = useAppSelector(
        state => state.taluka
    );
    const { villageList } = useAppSelector(
        state => state.village
    );
    const { categories } = useAppSelector(
        state => state.common
    );
    const onFinish = (data: any) => {
        handleSubmit(data, null)
    }
    return (
        <FormBox
            form={form}
            id={id}
            onFinish={onFinish}
            onValuesChange={onValuesChange}
            className='villageReportForm'
        >
            <Row gutter={15}>
                <Col sm={24} md={12} xl={8} >
                    <InputBox.Select
                        required
                        showSearch
                        name="taluka_id"
                        optionFilterProp="children"
                        label={t("GENERAL.TALUKA")}
                        placeholder={t("GENERAL.TALUKA")}
                        onFocus={() => {
                            dispatch(fetchTalukaList({ is_active: is_active }))
                                .then(() => setFetchTaluka(false))
                                .catch((e: any) => e)
                                .finally(() => setFetchTaluka(false))
                        }}
                        notFoundContent={fetchTaluka ? <Spin size="small" /> : Messages.NO_RECORD_FOUND}
                        onChange={() => {
                            form.setFieldsValue({
                                village_ids: [],
                            });
                            dispatch(setVillageList([]));
                            setFetchVillage(true);
                        }}
                        options={{
                            list: talukaList,
                            valueKey: "id",
                            textKey: "name",
                        }}
                        rules={rules.taluka()}
                    />
                </Col>
                <Col sm={24} md={12} xl={8} >
                    <InputBox.Select
                        required
                        showSearch
                        name="village_ids"
                        optionFilterProp="children"
                        label={t("GENERAL.VILLAGES")}
                        placeholder={t("GENERAL.VILLAGES")}
                        mode="multiple"
                        onChange={() => {
                            dispatch(setCategories([]))
                            setFetchCategories(true)
                        }}
                        onFocus={() => {
                            fetchVillage && form.getFieldValue("taluka_id") &&
                                dispatch(fetchVillageList({ taluka_id: form.getFieldValue("taluka_id"), is_active: is_active }))
                                    .then(() => setFetchVillage(false))
                                    .catch((e: any) => e)
                                    .finally(() => setFetchVillage(false))
                        }}
                        notFoundContent={fetchVillage ? <Spin size="small" /> : Messages.NO_RECORD_FOUND}
                        options={{
                            list: villageList,
                            valueKey: "id",
                            textKey: "name",
                        }}
                        rules={rules.village()}
                    />
                </Col>
                <Col sm={24} md={12} xl={8} >
                    <InputBox.Select
                        required
                        name="category_id"
                        label={t("REPORTSMANAGEMENT.CATEGORIES")}
                        placeholder={t("REPORTSMANAGEMENT.CATEGORIES")}
                        onFocus={() => {
                            fetchCategories &&
                                dispatch(fetchCategoriesList({ is_active: is_active }))
                                    .then(() => setFetchVillage(false))
                                    .catch((e: any) => e)
                                    .finally(() => setFetchVillage(false))
                        }}

                        notFoundContent={fetchCategories ? <Spin size="small" /> : Messages.NO_RECORD_FOUND}
                        options={{
                            list: categories,
                            valueKey: "id",
                            textKey: "name",
                        }}
                        rules={rules.categories()}
                    />
                </Col>
            </Row>
            <div className='citizenFormBtn'>
                <Button
                    key="1"
                    disabled={disabled}
                    form={id}
                    loading={saving}
                    htmlType="submit"
                    type="primary"
                >
                    {t("REPORTSMANAGEMENT.SUBMIT_BUTTON")}
                </Button>
                <Button
                    key="2"
                    htmlType="button"
                    className="cancelBtn"
                    onClick={blankForm}
                >
                    {t("GENERAL.CANCEL_BUTTON")}
                </Button>
            </div>
        </FormBox>
    )
}

export default ReportForm   