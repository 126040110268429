import React, { useEffect } from 'react'
import { Col, Row } from 'antd';
import { rules } from './rules';
import { FormBox, InputBox } from '../../../../../../components/AntdAddons';
import { useTranslation } from 'react-i18next';
const FormComponent: React.FC<any> = ({
    form,
    id,
    handleSubmit,
    onValuesChange,
    editValues,
}) => {
    const { t } = useTranslation();
    useEffect(() => {
        if (editValues) {
            form.setFieldsValue({
                ...editValues,
            });
        }
    }, [editValues, form]);
    const onFinish = (data: any) => {
        handleSubmit(data);
    };
  return (
    <>
    <FormBox
            form={form}
            id={id}
            onFinish={onFinish}
            onValuesChange={onValuesChange}
        >
            <Row gutter={15}>
                <Col xs={{ span: 24 }} >
                    <InputBox.Text
                        required
                        label={t("GENERAL.NAME")}
                        name="name"
                        placeholder={t("GENERAL.NAME")}
                        rules={rules.name()}
                    />
                </Col>
            </Row>
        </FormBox>
    </>
  )
}

export default FormComponent