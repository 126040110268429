import React, { useState } from 'react'


import { changeStatus, setupGrid } from '../../utils/slice';

import columnDefs from './columnDefs';
import { useAppSelector } from '../../../../../../store/app';
import ActionButtons from '../../../../../../components/ActionButtons';
import ChangeStatusModal from '../../../../../../components/Modals/ChangeStatusModal';
import AgGridWrapper from '../../../../../../components/AgGridWrapper/AgGridWrapper';
import { checkPrivileges } from '../../../../../../config/Global';
import { useTranslation } from 'react-i18next';


const Listing: React.FC<any> = ({
	moduleInfo,
	handleViewClick,
	handleDeleteClick,
	handleEditClick,
}) => {
	const { t } = useTranslation();
	const [statusData, setStatusData] = useState<any>();
	const userDetail = useAppSelector(state => state.auth.userDetail);


	const ActionRenderer = (props: any) => {

		return (
			<>

				<ActionButtons
					data={props}
					view={{
						action: handleViewClick,
						permissionKey: `${moduleInfo.permissionPrefix}_DETAILS`,
					}}
					edit={{
						action: handleEditClick,
						permissionKey: `${moduleInfo.permissionPrefix}_UPDATE`,
					}}
					deleteButton={{
						action: handleDeleteClick,
						permissionKey: `${moduleInfo.permissionPrefix}_DELETE`,
					}}
				/>

			</>
		)
	}
	return (
		<>
			<ChangeStatusModal
				titleName={t("VILLAGEVSWORDMANAGEMENT.DRAWER_TYPE_ADD_TITLE")}
				data={statusData}
				close={() => setStatusData(null)}
				callApi={changeStatus}
				keyName={"name"}
			/>
			<AgGridWrapper
				type="serverSide"
				components={{
					ActionRenderer,
				}}
				onGridReadyWithDispatch={setupGrid}
				columnDefs={columnDefs()}
				context={{
					setStatusData, changeStatusPermission: checkPrivileges(
						userDetail,
						`${moduleInfo.permissionPrefix}_CHANGE_STATUS`
					)
				}}
			/>
		</>
	);
}

export default Listing