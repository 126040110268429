import React from 'react'
// import { useAppDispatch } from '../../../../../../store/app';
// import { getWorkerList } from '../../utils/slice';
// import { Button, Drawer, Table } from 'antd';
// import SVGIcon from '../../../../../../utils/SVGIcon';
// import Config from '../../../../../../config/Config';
// import CancelButton from '../../../../../../components/Buttons/CancelButton';
// import {, partyPersonType, tablePagination } from '../../../../../../config/Global';
// import { useParams } from 'react-router-dom';
import { CONSTANT } from '../../../../../../config/Constant'
import { Table } from 'antd';
import { DrawerProps } from 'antd/lib';
import { partyPersonTypeDisplay, genderOptionsDisplay } from '../../../../../../config/Global';
import { useTranslation } from 'react-i18next';
import dayjs from "dayjs";

interface PartyWorkerViewProps extends DrawerProps {
    partyWorkerDetail: any;

}
const PartyWorkerView: React.FC<PartyWorkerViewProps> = ({
    partyWorkerDetail
}) => {
    const { t } = useTranslation();
    // const { action } = useParams();
    // const dispatch = useAppDispatch();
    // const [partyWorkerList, setPartyWorkerList] = useState<any>([]);
    // const [pagination, setPagination] = useState(tablePagination);
    // const continueBtn = () => {
    //     saveAndNext(CONSTANT.STEP.MALNUTRIONED_CHILDREN)
    // }
    // useEffect(() => {
    //     if (id && action === CONSTANT.DRAWER_TYPE.VIEW && dynamicSteps === CONSTANT.STEP.PARTY_WORKER) {
    //         dispatch(getWorkerList({ village_id: id }))
    //             .then((res: any) => {
    //                 setPartyWorkerList(res.rows)
    //             })
    //             .catch((e: any) => e)
    //     }
    // }, [dynamicSteps])
    
    const columns:any = [
        {
            title: `${t("VILLAGEMANAGEMENT.PERSION_TYPE")}`,
            dataIndex: 'person_type',
            key: 'person_type',
            fixed:'left',
            render: (text: any) => {
                return (
                    <span>{partyPersonTypeDisplay[text]}</span>
                )
            }
        },
        {
            title: t("GENERAL.FIRST_NAME"),
            dataIndex: 'first_name',
            key: 'first_name',
        },
        {
            title: t("GENERAL.MIDDLE_NAME"),
            dataIndex: 'middle_name',
            key: 'middle_name',
        },
        {
            title: t("GENERAL.Last_NAME"),
            dataIndex: 'last_name',
            key: 'last_name',
        },
        {
            title: t("GENERAL.DATE_OF_BIRTH"),
            dataIndex: 'dob',
            key: 'dob',
            render: (text: any) => {
                return (
                    <span>{text !== null && dayjs(text).format(CONSTANT.DATE_FORMAT)}</span>
                )
            }
        },
        {
            title: `${t("GENERAL.GENDER")}`,
            dataIndex: 'gender',
            key: 'gender',
            render: (text: any) => {
                return (
                    <span>{genderOptionsDisplay[text]}</span>
                )
            }
        },
        {
            title: `${t("VILLAGEMANAGEMENT.DESIGNNATION")}`,
            dataIndex: 'designation',
            key: 'designation',
        },
        {
            title: `${t("VILLAGEMANAGEMENT.CONTECT_NUMBER")}`,
            dataIndex: 'phone',
            key: 'phone',
        },
        // {
        // 	title: 'Action',person_type
        // 			</span>
        // 		</div>
        // 	),
        // },
    ];

    return (
        <Table
            dataSource={partyWorkerDetail}
            columns={columns}
            pagination={false}
            scroll={{ x: 992 }}
        />
    );
}
export default PartyWorkerView