import React, { Suspense } from "react";
import PageSpinner from "./PageSpinner/PageSpinner";
import ErrorBoundary from "antd/lib/alert/ErrorBoundary";
import { useAppSelector } from "../store/app";
import { checkPrivileges } from "../config/Global";
import RestrictedAccessPage from "../views/errors/RestrictedAccessPage";

interface ModuleWrapProps {
  module: React.ComponentType<any>;
  title?: string;
  permissionPrefix?: string;
  indexRoute?: string;
}

const ModuleWrap: React.FC<ModuleWrapProps> = ({ module: Module, title, permissionPrefix, indexRoute }) => {
  const userDetail = useAppSelector((state) => state.auth.userDetail);

  if (permissionPrefix && userDetail && !checkPrivileges(userDetail, permissionPrefix + "_INDEX")) {
    return <RestrictedAccessPage />;
  }

  return (
    <Suspense fallback={<PageSpinner />}>
      <ErrorBoundary description={"Report the Error You Faced"}>
        <div className="mainPageWrap">
          <Module title={title} permissionPrefix={permissionPrefix} indexRoute={indexRoute} />
        </div>
      </ErrorBoundary>
    </Suspense>
  );
};

export default ModuleWrap;
